import React from "react";
import { View, StyleSheet, ScrollView, RefreshControl } from "react-native";
import NtText from "../../components/NtText";
import BaseNewsController from "./BaseNewsController";
import NtNewsSelectItem from "../components/NtNewsSelectItem";
import { fetchUserSources, saveUserSources } from "../../api/sources";
import { fetchStories } from "../../api/stories";
import Logger from "../../common/utils/Logger";
import { fetchUserAuthors, saveUserAuthors } from "../../api/authors";
import { fetchUserFeeds, saveUserFeeds } from "../../api/feeds";
import { fetchUserCategories, saveUserCategories } from "../../api/categories";
import AnalysisController from "./AnalysisController";
import routes from "../../navigation/routes";
import NtFollowingItem from "../components/NtFollowingItem";
import colors from "../../config/colors";
import FilterNewsController from "./FilterNewsController";
import { parseIdFromNewsItem } from "../utils/NewsParserUtils";
import NtTouchableEffect from "../../components/NtTouchableEffect";

import NtNewsCardView from "../components/NtNewsCardView";
import settings from "../../config/settings";
import {
  asyncStorageGetData,
  asyncStorageSetData,
} from "../../common/utils/ControllerUtils";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtNewsPageContainer from "../components/NtNewsPageContainer";
import NtNewsLoadMoreButton from "../components/NtNewsLoadMoreButton";
import NtErrorView from "../../components/NtErrorView";

const DEFAULT_SELECTED_TAB_STORAGE_KEY = "@DEFAULT_SELECTED_TAB_STORAGE_KEY";

class MyNewsController extends BaseNewsController {
  newsSelectOptions = [
    {
      id: FilterNewsController.sFILTER_SOURCE,
      name: "Sources",
    },
    {
      id: FilterNewsController.sFILTER_CATEGORY,
      name: "Categories",
    },
    {
      id: FilterNewsController.sFILTER_FEED,
      name: "Feeds",
    },
    {
      id: FilterNewsController.sFILTER_AUTHOR,
      name: "Authors",
    },
  ];

  skip = 0;
  filter = "";
  filterValue = [];

  state = {
    ...this.getParentState(),
    selection: null,
    data: [],
    stories: [],
    refreshing: false,
    firstTimeLoading: true,
  };

  componentDidMount() {
    super.componentDidMount();
    this.handleSelectedTab();
  }

  handleSelectedTab = async () => {
    const result = await asyncStorageGetData(DEFAULT_SELECTED_TAB_STORAGE_KEY);
    if (result != null) {
      this.setState({ selection: JSON.parse(result) });
    } else {
      this.setState({ selection: this.newsSelectOptions[0] });
    }

    //will now request the data
    setTimeout(() => {
      this.handleDataFetch();
    }, 100);
  };

  handleDataFetch = async (selection = this.state.selection) => {
    this.setState({ refreshing: true, stories: [] });

    let response = [];
    if (selection.id === FilterNewsController.sFILTER_SOURCE) {
      response = await fetchUserSources();
    } else if (selection.id === FilterNewsController.sFILTER_CATEGORY) {
      response = await fetchUserCategories();
    } else if (selection.id === FilterNewsController.sFILTER_FEED) {
      response = await fetchUserFeeds();
    } else if (selection.id === FilterNewsController.sFILTER_AUTHOR) {
      response = await fetchUserAuthors();
    }

    this.filter = selection.id;
    this.filterValue = response;

    if (this.filterValue && this.filterValue.length > 0) {
      Logger(
        "Noticed that we have saved data.... will proceed to the API request...."
      );
      this.skip = 0;
      this.handleStoriesDataFetch(
        settings.pageSizeLoad,
        this.skip,
        null,
        false
      );
    } else {
      Logger("Oops... no saved data for this type.... will stop the loading");
      this.setState({ refreshing: false, firstTimeLoading: false });
    }
  };

  handleStoriesDataFetch = async (
    limit,
    skip,
    startId,
    loadingMore,
    sortColumn = this.state.rankBy.sortColumn,
    sortOrder = this.state.rankBy.sortOrder,
    hours = this.state.rankWithin.hours
  ) => {
    Logger(
      "Will fetch the Stories for list view....",
      limit,
      skip,
      startId,
      {
        [this.filter]: this.filterValue.map((each) => {
          return each.name;
        }),
      },
      loadingMore,
      sortColumn,
      sortOrder,
      hours
    );

    this.setState({ refreshing: true });

    const response = await fetchStories(
      limit,
      skip,
      startId,
      {
        [this.filter]: this.filterValue.map((each) => {
          return each.name;
        }),
      },
      sortColumn,
      sortOrder,
      hours
    );

    if (response.ok) {
      if (loadingMore) {
        // Logger('We are loading more... need to add to the state');
        this.setState({ stories: this.state.stories.concat(response.data) });
      } else {
        // Logger('First time loading.... we need to set the state to first ');
        this.setState({ stories: response.data });
      }
    }
    this.setState({ refreshing: false, firstTimeLoading: false });
  };

  handleRankChange = (rankBy, rankWithin) => {
    Logger("Will refresh now....");

    this.skip = 0;
    this.handleStoriesDataFetch(
      settings.pageSizeLoad,
      this.skip,
      null,
      false,
      rankBy.sortColumn,
      rankBy.sortOrder,
      rankWithin.hours
    );
  };

  handleViewAll = () => {
    if (this.state.selection.id === FilterNewsController.sFILTER_SOURCE) {
      this.props.navigation.navigate(routes.sources);
    } else if (
      this.state.selection.id === FilterNewsController.sFILTER_CATEGORY
    ) {
      this.props.navigation.navigate(routes.categories);
    } else if (this.state.selection.id === FilterNewsController.sFILTER_FEED) {
      this.props.navigation.navigate(routes.feeds);
    } else if (
      this.state.selection.id === FilterNewsController.sFILTER_AUTHOR
    ) {
      this.props.navigation.navigate(routes.authors);
    }
  };

  renderSelectionController = () => {
    return (
      <View
        style={{
          flexWrap: "wrap",
          flexDirection: "row",
          marginTop: 10,
        }}
      >
        {this.newsSelectOptions.map((each) => (
          <NtNewsSelectItem
            key={each.id}
            style={{ marginRight: 10 }}
            selected={each.id === this.state.selection.id}
            name={each.name}
            loading={
              each.id === this.state.selection.id && this.state.refreshing
            }
            onPress={() => {
              this.setState({ selection: each });
              this.handleDataFetch(each);
              asyncStorageSetData(DEFAULT_SELECTED_TAB_STORAGE_KEY, each);
            }}
          />
        ))}
      </View>
    );
  };

  renderNoSelection = () => {
    if (this.filterValue && this.filterValue.length === 0) {
      return (
        <NtErrorView
          containerStyle={{ marginTop: 20 }}
          title={`No ${this.state.selection.name} Being Followed`}
          message={`This screen is populated with news stories based on the ${this.state.selection.name.toLowerCase()} you follow.`}
          renderBody={
            <NtTouchableEffect
              style={{ marginTop: 10, marginLeft: 4 }}
              onPress={this.handleViewAll}
            >
              <NtText style={[{ color: colors.blue }]}>
                Click here to start following
              </NtText>
            </NtTouchableEffect>
          }
        />
      );
    }
    return null;
  };

  renderRow = (item, index) => {
    return (
      <NtNewsCardView
        key={parseIdFromNewsItem(item).toString() + index.toString()}
        story={item}
        metric={item.read_count}
        metricIcon={"eye"}
        messageCount={0}
        type={"row"}
        onCardPress={() => {
          Logger("Pressed on the card for item: ", item);
          this.handleStoryClickRedirect(item);
        }}
        onOptionPress={(key) => {
          Logger("Pressed on the more", key);
          if (key === "share") {
            this.handleShare(item);
          } else if (key === "morelikethis") {
            this.handleMoreLikeThisRedirect(item);
          }
        }}
        onNoozScorePress={() => {
          this.handleAnalysisNavigate(
            item,
            AnalysisController.TYPE_QUICKANALYSIS
          );
        }}
      />
    );
  };

  renderFollowing = () => {
    return (
      <NtTouchableEffect
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
        }}
        onPress={() => {
          this.handleViewAll();
        }}
      >
        <NtText style={styles.title}>
          {this.state.selection.name} Followed ({this.filterValue.length})
        </NtText>
        <NtFollowingItem selected={true} style={{ marginLeft: 10 }} />
      </NtTouchableEffect>
    );
  };

  renderListFooter = () => {
    if (this.filterValue && this.filterValue.length == 0) {
      return null;
    }
    return (
      <NtTouchableEffect
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
          marginLeft: 10,
        }}
        onPress={() => {
          if (this.state.selection.id === FilterNewsController.sFILTER_SOURCE) {
            saveUserSources([]);
          } else if (
            this.state.selection.id === FilterNewsController.sFILTER_CATEGORY
          ) {
            saveUserCategories([]);
          } else if (
            this.state.selection.id === FilterNewsController.sFILTER_FEED
          ) {
            saveUserFeeds([]);
          } else if (
            this.state.selection.id === FilterNewsController.sFILTER_AUTHOR
          ) {
            saveUserAuthors([]);
          }

          setTimeout(() => {
            this.handleDataFetch();
          }, 100);
        }}
      >
        <NtMaterialIcon name={"trash-can"} size={18} color={"#BF8585"} />
        <NtText style={styles.footerText}>
          Unfollow all {this.state.selection.name}
        </NtText>
      </NtTouchableEffect>
    );
  };

  render() {
    if (this.state.selection === null) {
      //we are not ready yet
      return null;
    }

    return (
      <NtNewsPageContainer
        showFooter={true}
        sidePannelContainerStyle={{ paddingTop: 130 }}
        renderFilter={this.renderFilterController()}
        pageTitle="My News"
        pageSubtitle={"News I am following"}
        isLoading={this.state.firstTimeLoading}
      >
        <View style={{ flex: 1 }}>
          {this.renderSelectionController()}

          {this.renderFollowing()}

          {this.renderNoSelection()}

          {this.state.stories.map((each, index) => this.renderRow(each, index))}

          {this.renderListFooter()}

          {/* Will load more storyes button  */}
          {this.filterValue && this.filterValue.length > 0 && (
            <View>
              <NtNewsLoadMoreButton
                loadingText={"Loading More"}
                loading={this.state.refreshing}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  const lastId = parseIdFromNewsItem(this.state.stories[0]);
                  this.skip = this.skip + settings.pageSizeLoad;
                  this.handleStoriesDataFetch(
                    settings.pageSizeLoad,
                    this.skip,
                    lastId,
                    true
                  );
                }}
              />
            </View>
          )}
        </View>

        {this.renderRankByModal()}
        {this.renderRankWithinModal()}
        {this.renderShareModal()}
      </NtNewsPageContainer>
    );
  }

  // render() {
  //   if (this.state.selection === null) {
  //     //we are not ready yet
  //     return null;
  //   }

  //   return (
  //     <View style={[styles.container, defaultStyles.container]}>
  //       {this.renderSelectionController()}
  //       <View style={{ height: 10 }} />

  //       {this.renderNoSelection()}

  //       {this.state.display === "grouped" && (
  //         <FlatList
  //           // key={this.state.selection.id}
  //           refreshing={this.state.refreshing}
  //           onRefresh={() => {
  //             this.handleDataFetch();
  //           }}
  //           ListHeaderComponent={this.renderListHeader()}
  //           ListFooterComponent={this.renderListFooter()}
  //           ref={this.listRef}
  //           style={styles.flatlist}
  //           data={this.state.data}
  //           showsVerticalScrollIndicator={false}
  //           ItemSeparatorComponent={() => {
  //             return <View style={{ height: 10 }} />;
  //           }}
  //           keyExtractor={(item, index) => item.name + index.toString()} //tamporary fix because getting of dupe stories
  //           renderItem={({ item }) => this.renderRow(item)}
  //           onEndReached={() => {
  //             Logger("Group end reach, will try to load more....");
  //             this.handleDataFetch(this.state.selection, true);
  //           }}
  //           onEndReachedThreshold={0.5}
  //         />
  //       )}

  //       {this.state.display === "list" && (
  //         <FlatList
  //           refreshing={this.state.refreshing}
  //           onRefresh={() => {
  //             this.skip = 0;
  //             this.handleStoriesDataFetch(
  //               settings.pageSizeLoad,
  //               this.skip,
  //               null,
  //               false
  //             );
  //           }}
  //           ListHeaderComponent={
  //             <View style={{ marginBottom: 15 }}>
  //               {this.renderListHeader()}
  //             </View>
  //           }
  //           ListFooterComponent={this.renderListFooter()}
  //           ref={this.listRef}
  //           style={[styles.flatlist]}
  //           data={this.state.stories}
  //           showsVerticalScrollIndicator={false}
  //           ItemSeparatorComponent={() => {
  //             return <View style={{ height: 10 }} />;
  //           }}
  //           keyExtractor={(item, index) =>
  //             parseIdFromNewsItem(item) + index.toString()
  //           }
  //           renderItem={({ item }) => this.renderRow(item)}
  //           onEndReached={() => {
  //             const lastId = parseIdFromNewsItem(this.state.stories[0]);
  //             this.skip = this.skip + settings.pageSizeLoad;
  //             this.handleStoriesDataFetch(
  //               settings.pageSizeLoad,
  //               this.skip,
  //               lastId,
  //               true
  //             );
  //           }}
  //           onEndReachedThreshold={0.5}
  //         />
  //       )}
  //     </View>
  //   );
  // }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
  },
  title: {
    color: colors.darkest,
    fontSize: 18,
  },
  footerText: {
    color: "#BF8585",
    fontSize: 14,
  },
});

export default MyNewsController;
