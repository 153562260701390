import React from "react";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator } from "react-native-web";
import BaseController from "../../common/base/BaseController";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import routes from "../../navigation/routes";
import NtRankOptionController from "../components/NtRankOptionController";
import rankbyoptions from "../data/rankbyoptions";
import rankwithinoptions from "../data/rankwithinoptions";
import NtModal from "../../components/NtModal";
import NtSelectionSheet from "../../components/NtSelectionSheet";
import {
  parseTextOnlyReaderRestrictionFromNewsItem,
  parseUrlFromNews,
} from "../utils/NewsParserUtils";
import Logger from "../../common/utils/Logger";
import Base64 from "Base64";
import NtShareController from "../components/NtShareController";
import { fetchHistory } from "../../api/stories";
import Lottie from "lottie-react";
import { StackActions } from "@react-navigation/routers";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtActiveFiltersController from "../components/NtActiveFiltersController";
import NtSearchBar from "../../components/NtSearchBar";
import { isAndroid } from "react-device-detect";

class BaseNewsController extends BaseController {
  state = {
    rankBy: rankbyoptions[0],
    rankWithin: rankwithinoptions[0],
    isMobile: false,
    storyItem: null,
    searchText: null,
  };

  rankByModalRef = React.createRef();
  rankWithinModalRef = React.createRef();
  shareRef = React.createRef();

  getParentState() {
    return this.state;
  }

  handleStoryClickRedirect = async (story) => {
    if (
      !this.context.user.developer &&
      parseTextOnlyReaderRestrictionFromNewsItem(story)
    ) {
      Logger("***User tried to access a restricted feature***");
      await fetchHistory(story.url);

      const url = parseUrlFromNews(item);
      window.open(url);
    } else {
      this.handleAnalysisNavigate(story, "textonlyreader");
    }
  };

  handleAnalysisNavigate = (story, type) => {
    this.props.navigation.navigate(routes.analysis, {
      token: Base64.btoa(story.url),
      type: type,
    });
  };

  handleMoreLikeThisRedirect = (story) => {
    this.props.navigation.dispatch(
      StackActions.push(routes.morelikethis, {
        token: Base64.btoa(story.url),
      })
    );
  };

  handleViewOnMobileRedirect = (story) => {
    var now = new Date().valueOf();
    setTimeout(function () {
      if (new Date().valueOf() - now > 1000) return;
      window.location = isAndroid
        ? "market://details?id=com.heimdall.ntnooz"
        : "https://apps.apple.com/us/app/nooz-ai-wise-up-to-media-bias/id1607868155";
    }, 900);
    window.location = `noozai://analysis?url=${Base64.btoa(
      story.url
    )}&type=textonlyreader`;
  };

  handleShare = (story) => {
    this.setState({ storyItem: story });
    this.shareRef.current.show();
  };

  renderLottieLoading = (loading) => {
    if (!loading) {
      return null;
    }

    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Lottie
          style={{ width: 250, heigth: 250 }}
          animationData={require("../../lottie/lottie_news_load_animation.json")}
          loop={true}
        />
      </View>
    );
  };

  renderDeviter = () => {
    return <View style={styles.deviter} />;
  };

  renderHeader = (icon, title, loading = false) => {
    return (
      <View style={styles.headerContainer}>
        <NtMaterialIcon name={icon} color={colors.darker} size={30} />
        <NtText style={styles.title}>{title}</NtText>
        {loading && (
          <ActivityIndicator
            style={styles.activityIndicator}
            size="large"
            color={colors.darkest}
          />
        )}
      </View>
    );
  };

  // controllers
  renderFilterController = (showSearchBar = true, showSort = true) => {
    return (
      <View>
        <View
          style={[
            {
              width: "100%",
              backgroundColor: "white",
            },
            this.state.isMobile
              ? { flexDirection: "column", alignItems: "flex-end" }
              : { alignItems: "center", flexDirection: "row" },
          ]}
        >
          {showSearchBar && (
            <NtSearchBar
              containerStyle={{ flex: 1 }}
              onSearch={(text) => {
                this.handleSearch(text);
              }}
            />
          )}

          {showSort && (
            <NtRankOptionController
              containerStyle={[
                this.state.isMobile ? { marginTop: 15 } : { marginLeft: 20 },
              ]}
              rankBySelection={this.state.rankBy}
              rankWithinSelection={this.state.rankWithin}
              onRankBy={() => {
                this.rankByModalRef.current.show();
              }}
              onRankWithin={() => {
                this.rankWithinModalRef.current.show();
              }}
            />
          )}
        </View>

        {showSearchBar && (
          <NtActiveFiltersController
            containerStyle={{ marginTop: 10 }}
            search={this.state.searchText}
            onClearSearch={() => {
              this.handleSearch(null);
            }}
          />
        )}
      </View>
    );
  };

  //modals
  renderRankByModal = () => {
    return (
      <NtModal
        ref={this.rankByModalRef}
        renderBody={
          <NtSelectionSheet
            options={rankbyoptions}
            title={"Sort By"}
            selected={this.state.rankBy.id}
            onSelection={(item) => {
              this.rankByModalRef.current.dismiss();
              this.setState({
                rankBy: item,
              });

              this.handleRankChange(item, this.state.rankWithin);
            }}
          />
        }
      />
    );
  };

  renderRankWithinModal = () => {
    return (
      <NtModal
        ref={this.rankWithinModalRef}
        renderBody={
          <NtSelectionSheet
            options={rankwithinoptions}
            title={"Time Range"}
            selected={this.state.rankWithin.id}
            onSelection={(item) => {
              this.rankWithinModalRef.current.dismiss();
              this.setState({
                rankWithin: item,
              });
              this.handleRankChange(this.state.rankBy, item);
            }}
          />
        }
      />
    );
  };

  renderShareModal = () => {
    return (
      <NtModal
        ref={this.shareRef}
        containerStyle={{ maxWidth: 400 }}
        renderBody={
          <NtShareController
            story={this.state.storyItem}
            onCopy={() => {
              this.shareRef.current.dismiss();
            }}
          />
        }
      />
    );
  };
}

const styles = StyleSheet.create({
  container: {},
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  deviter: {
    marginTop: 30,
    marginBottom: 30,
    height: 1,
    backgroundColor: colors.teal,
  },
  title: {
    color: colors.darker,
    fontSize: 30,
    fontWeight: "400",
    marginLeft: 10,
  },
  activityIndicator: {
    marginLeft: 15,
  },
});

export default BaseNewsController;
