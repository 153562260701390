import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import NtTextInput from "../../components/NtTextInput";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";

function NtDevInputField({ containerStyle, onSave, ...otherProps }) {
  const handleSave = () => {
    if (onSave) {
      onSave();
    }
  };
  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={{ flex: 1, alignItems: "flex-end", justifyContent: "center" }}
      >
        <NtTextInput
          containerStyle={{ flex: 1, borderRadius: 30 }}
          type={"text"}
          autoCapitalize="none"
          returnKeyType="search"
          bgColor={colors.lightest}
          textColor={colors.darkest}
          onSubmitEditing={handleSave}
          {...otherProps}
        />

        <View
          style={{
            position: "absolute",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <NtTouchableEffect style={styles.searchButton} onPress={handleSave}>
            <NtMaterialIcon name="check" size={25} color={"white"} />
          </NtTouchableEffect>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  searchButton: {
    height: 40,
    width: 40,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "green",
    marginLeft: 5,
  },
  cancelSearchButton: {
    height: 20,
    width: 20,
    backgroundColor: colors.teal,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default NtDevInputField;
