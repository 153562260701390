import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../../components/NtText";
import colors from "../../../config/colors";
import { parseSentencesFromNewsItem } from "../../utils/NewsParserUtils";
import { extractAllPropagandaSubstringsFromStory } from "../../utils/PropagandaUtils";
import NtLanguageAnalysisScorePerSentenceRow from "./NtLanguageAnalysisScorePerSentenceRow";

function NtLanguageAnalysisScorePerSentenceController({ story }) {
  const sentences = parseSentencesFromNewsItem(story);
  const propagandaSubstrings = extractAllPropagandaSubstringsFromStory(story);

  return (
    <View style={styles.container}>
      <View style={{ alignItems: "flex-end" }}>
        <NtText
          style={[
            styles.metrics,
            { marginRight: 50, marginBottom: 20, marginTop: 20 },
          ]}
        >
          Metrics
        </NtText>
      </View>
      {sentences.map((each, index) => (
        <View key={each.text + index.toString()}>
          <NtLanguageAnalysisScorePerSentenceRow
            containerStyle={{
              backgroundColor: index % 2 ? "white" : colors.lighestGray,
            }}
            sentence={each}
            propagandaSubstrings={propagandaSubstrings}
          />
          <View
            style={{
              flex: 1,
              height: 2,
              marginRight: -15,
              marginLeft: -15,
              backgroundColor: colors.lighter,
            }}
          />
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  metrics: {
    fontSize: 12,
    color: colors.darkest,
    fontWeight: "700",
  },
});

export default NtLanguageAnalysisScorePerSentenceController;
