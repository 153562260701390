import client from "./client";
import settings from "../config/settings";
import md5 from "react-native-md5";
import Logger from "../common/utils/Logger";

const endpoint = "/heimdall";

export const analyzeText = (title, text, uuid, link = null) => {
  let body = {
    apiKey: settings.apiKey,
    type: "Text",
    title: title,
    text: text,
    link: link ? link : `nooztext://${uuid}/`,
  };

  return client.post(endpoint, body, {
    headers: { "x-nooz-hash": md5.hex_md5(JSON.stringify(body)) },
  });
};

export const fetchHistory = (url) => {
  let body = {
    apiKey: settings.apiKey,
    type: "Stories",
    hours: 454545454545454,
    url: encodeURIComponent(url),
  };

  return client.post(endpoint, body, {
    headers: { "x-nooz-hash": md5.hex_md5(JSON.stringify(body)) },
  });
};

export const fetchStoryById = (uid) => {
  let body = {
    apiKey: settings.apiKey,
    type: "Stories",
    hours: 454545454545454,
    uid: uid,
  };

  return client.post(endpoint, body, {
    headers: { "x-nooz-hash": md5.hex_md5(JSON.stringify(body)) },
  });
};

export const fetchStories = (
  limit,
  skip,
  startId,
  filter = {},
  sortField = "_id",
  sortOrder = "desc",
  hours = 24, //will be 1 week (default)
  search = null,
  searchIndex = "keyword"
) => {
  const order = sortOrder === "asc" ? 1 : -1;

  let body = {
    apiKey: settings.apiKey,
    type: "Stories",
    skip: skip,
    limit: limit,

    startId: startId,
    sort_field: sortField,
    sort_order: order,
    hours: hours,
    date: Date(),
    ...filter, //will be the filters......
  };

  if (search) {
    body.search = search;
    body.index = searchIndex;
  }

  return client.post(endpoint, body, {
    headers: { "x-nooz-hash": md5.hex_md5(JSON.stringify(body)) },
  });
};
