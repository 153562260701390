import React from "react";
import { SafeAreaView, ScrollView, StyleSheet, View } from "react-native";
import JSONTree from "react-native-json-tree";
import NtNewsPageContainer from "../../news/components/NtNewsPageContainer";
import BaseDevelopmentController from "./BaseDevelopmentController";

class DevelopmentJSON extends BaseDevelopmentController {
  state = {};

  theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#000000",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#fd971f",
    base0A: "#f4bf75",
    base0B: "#ffffff",
    base0C: "#a1efe4",
    base0D: "#66d9ef",
    base0E: "#ae81ff",
    base0F: "#cc6633",
  };

  componentDidMount() {
    super.componentDidMount();
  }

  render() {
    return (
      <NtNewsPageContainer
        showFooter={true}
        pageTitle="Development JSON"
        pageSubtitle={"Analyze object"}
        showNavigation={false}
      >
        <View style={{ marginTop: 15 }}>
          <JSONTree
            data={this.props.route.params.item}
            theme={this.theme}
            invertTheme={false}
          />
        </View>
      </NtNewsPageContainer>
    );
    // return <Text>Alain</Text>;
  }
}

const styles = StyleSheet.create({
  container: { flex: 1 },
});

export default DevelopmentJSON;
