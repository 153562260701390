import React, { useState, useEffect, useContext } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtButton from "../../../components/NtButton";
import NtMaterialIcon from "../../../components/NtMaterialIcon";
import NtText from "../../../components/NtText";
import colors from "../../../config/colors";
import NtTouchableEffect from "../../../components/NtTouchableEffect";
import { parseTitleFromNewsItem } from "../../utils/NewsParserUtils";
import NtLanguageAnalysisScoreController2 from "./NtLanguageAnalysisScoreController2";
import { fetchHistory } from "../../../api/stories";
import NtLanguageAnalysisLoading from "./NtLanguageAnalysisLoading";
import Logger from "../../../common/utils/Logger";
import NtLanguageanalysisOptionController from "./NtLanguageanalysisOptionController";
import NtLanguageAnalysisHighlightPropagandaController from "./NtLanguageAnalysisHighlightPropagandaController";
import NtLanguageAnalysisScorePerSentenceController from "./NtLanguageAnalysisScorePerSentenceController";
import NtLanguageAnalysisScoreDisplay from "./NtLanguageAnalysisScoreDisplay";
import NtLanguageAnalysisOptionButton from "./NtLanguageAnalysisOptionButton";
import { useNavigation } from "@react-navigation/native";
import routes from "../../../navigation/routes";
import UserContext from "../../../context/UserContext";

function NtLanguageAnalysisDisplay({
  onReWrite,
  story,
  containerStyle,
  onMenuPress,
}) {
  const options = [
    {
      title: "Tooltip Highlight",
      icon: "marker",
    },
    {
      title: "Sentence Breakdown",
      icon: "format-list-numbered-rtl",
    },
  ];

  const [currentVersion, setCurrentVersion] = useState(0);
  const [history, setHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [analysisViewOption, setAnalysisViewOption] = useState(options[0]);
  const [scoreType, setScoreType] = useState(null);
  const navigation = useNavigation();
  const context = useContext(UserContext);

  const handleHistoryFetch = async () => {
    setLoading(true);

    const response = await fetchHistory(story.url);
    if (response.ok) {
      Logger("Successfully fetched the history: ", response.data);
      setHistory(response.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    handleHistoryFetch();
  }, []);

  const renderRevisionButton = (name, containerStyle, onPress) => {
    return (
      <NtTouchableEffect
        onPress={onPress}
        style={[
          {
            width: 20,
            height: 20,
            backgroundColor: "#8792be",
            borderRadius: 10,
            alignItems: "center",
            justifyContent: "center",
          },
          containerStyle,
        ]}
      >
        <NtMaterialIcon name={name} color={"white"} />
      </NtTouchableEffect>
    );
  };

  const renderHighlightPropaganda = () => {
    if (analysisViewOption.title !== options[0].title) {
      return null;
    }

    return (
      <View style={{ flex: 1, flexDirection: "row" }}>
        <NtLanguageAnalysisHighlightPropagandaController
          containerStyle={{ flex: 1 }}
          story={history[currentVersion]}
        />

        {/* <View style={{ width: 150, backgroundColor: "red" }}></View> */}
      </View>
    );
  };

  const renderSocresPerSentence = () => {
    if (analysisViewOption.title !== options[1].title) {
      return null;
    }

    return (
      <NtLanguageAnalysisScorePerSentenceController
        story={history[currentVersion]}
      />
    );
  };

  const renderRevisionNavigate = () => {
    if (history.length <= 1) {
      return null;
    }

    return (
      <View
        style={{
          width: 180,
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        {renderRevisionButton("chevron-left", { marginRight: 30 }, () => {
          if (currentVersion > 0) {
            setCurrentVersion(currentVersion - 1);
          }
        })}
        <NtText>Revision {currentVersion}</NtText>
        {renderRevisionButton("chevron-right", { marginLeft: 30 }, () => {
          if (currentVersion < history.length - 1) {
            setCurrentVersion(currentVersion + 1);
          }
        })}
      </View>
    );
  };

  const renderTitle = () => {
    return (
      <View
        style={{ marginTop: 20, flexDirection: "row", alignItems: "center" }}
      >
        <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
          <NtMaterialIcon
            name="file-document-outline"
            color={colors.lighGray}
            size={26}
          />
          <NtText style={[styles.docName, { marginLeft: 10 }]}>
            {parseTitleFromNewsItem(history[currentVersion])}
          </NtText>
        </View>
      </View>
    );
  };

  const renderDevider = (containerStyle) => {
    return <View style={[styles.devider, containerStyle]} />;
  };

  if (!history || loading) {
    return <NtLanguageAnalysisLoading containerStyle={containerStyle} />;
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
        }}
      >
        {renderDevider({ marginTop: 15 })}

        {/* container starts here... */}
        <View style={{ flex: 1, marginTop: 20, flexDirection: "row" }}>
          {/* Left content */}
          <View>
            <NtLanguageAnalysisScoreController2
              containerStyle={{ marginTop: 20 }}
              stories={history}
              story={history[currentVersion]}
              isSelected={scoreType}
              onScorePress={() => {
                setScoreType(null);
              }}
              onSentimentPress={() => {
                Logger("--- setting sentiment");
                setScoreType("sentiment");
              }}
              onPropagandaPress={() => {
                setScoreType("propaganda");
              }}
              onOpinionPress={() => {
                setScoreType("opinion");
              }}
            />
          </View>

          {/* Right content */}
          <View style={{ flex: 1, marginLeft: 20 }}>
            <NtLanguageanalysisOptionController
              containerStyle={{}}
              options={options}
              selectedOption={analysisViewOption}
              onOptionSelected={(option) => {
                setAnalysisViewOption(option);
              }}
            />

            {renderDevider({ marginTop: 15, height: 1 })}

            <NtLanguageAnalysisScoreDisplay
              containerStyle={{ marginTop: 20 }}
              story={history[currentVersion]}
              type={scoreType}
            />

            {renderRevisionNavigate()}
            {renderTitle()}

            <View style={{ height: 15 }} />

            {renderHighlightPropaganda()}
            {renderSocresPerSentence()}

            {renderDevider({ marginTop: 15 })}

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <NtButton
                containerStyle={{
                  backgroundColor: colors.blue,
                  width: 100,
                  height: 30,
                  borderRadius: 30,
                }}
                textStyle={{ fontSize: 12, fontWeight: "500" }}
                text={"Rewrite"}
                onPress={() => {
                  if (onReWrite) {
                    onReWrite(history[currentVersion]);
                  }
                }}
              />

              {context.user.developer && (
                <NtLanguageAnalysisOptionButton
                  icon={"code-json"}
                  title={"View JSON"}
                  selectedBackgroundColor={colors.lighGray}
                  selected={true}
                  containerStyle={{
                    marginLeft: 10,
                  }}
                  onPress={() => {
                    navigation.navigate(routes.developmentjson, {
                      item: history,
                    });
                  }}
                />
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
  },
  title: {
    color: colors.darkest,
    fontSize: 18,
    fontWeight: "500",
  },
  docName: {
    fontSize: 18,
    color: colors.lighGray,
    fontWeight: "500",
  },
  fullText: {
    fontSize: 15,
    color: "#727272",
    fontWeight: "400",
    lineHeight: 24,
  },
  devider: {
    width: "100%",
    height: 2,
    backgroundColor: colors.lighGray,
  },
});

export default NtLanguageAnalysisDisplay;
