import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";

function NtVaderProgress({ containerStyle, neg, neu, pos }) {
  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.progress}>
        {/* neg */}
        <View
          style={{
            flex: neg,
            backgroundColor: colors.sentimentNegativeFilled,
          }}
        />
        {/* neutral */}
        <View style={{ flex: neu, backgroundColor: colors.sentiment0 }} />
        {/* poss */}
        <View
          style={{ flex: pos, backgroundColor: colors.sentimentPositiveFilled }}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          marginTop: 3,
          justifyContent: "space-between",
        }}
      >
        <NtText
          style={[styles.text, { color: colors.sentimentNegativeFilled }]}
        >
          -{Math.round(neg)}%
        </NtText>
        <NtText style={[styles.text, { color: colors.sentiment0 }]}>
          {Math.round(neu)}%
        </NtText>
        <NtText
          style={[styles.text, { color: colors.sentimentPositiveFilled }]}
        >
          {Math.round(pos)}%
        </NtText>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  progress: {
    width: 100,
    height: 10,
    backgroundColor: "red",
    borderRadius: 4,
    flexDirection: "row",
    overflow: "hidden",
  },
  text: {
    fontSize: 12,
    fontWeight: "500",
  },
});

export default NtVaderProgress;
