import React, { useState } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import colors from "../../../config/colors";
import { parseRevisionFromNewsItem } from "../../utils/NewsParserUtils";
import { revisionIcon, revisionDescription } from "../../utils/NoozMetricUtils";
import NtQuickAnalysisMetricDescription from "./NtQuickAnalysisMetricDescription";
import NtQuickAnalysisMetricProgressbar from "./NtQuickAnalysisMetricProgressbar";
import NtQuickAnalysisMetricRevisionFlowEchart from "./NtQuickAnalysisMetricRevisionFlowEChart";
import NtQuickAnalysisReversedCard from "./NtQuickAnalysisReversedCard";
import NtQuickAnalysisTitleView from "./NtQuickAnalysisTitleView";

function NtQuickAnalysisMetricRevision({ story, stories }) {
  const [viewWidth, setViewWidth] = useState(0);
  const revision = parseRevisionFromNewsItem(story);
  return (
    <View style={styles.container}>
      <NtQuickAnalysisReversedCard style={{ width: "100%" }}>
        <NtQuickAnalysisTitleView
          containerStyle={{ marginTop: 8 }}
          title={"Revision Score & Label"}
          subtitle={"Range & Interpretation"}
        />
        <View
          style={{
            paddingLeft: 20,
            paddingRight: 30,
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          <NtQuickAnalysisMetricProgressbar
            icon={revisionIcon(0).icon}
            value={revision}
            multipliers={["0", "15", "30", "45", "60"]}
            labels={[
              { text: "Low", icon: "file-edit-outline" },
              { text: "Moderate", icon: "file-edit-outline" },
              { text: "High", icon: "file-edit" },
              { text: "Extreme", icon: "file-edit" },
            ]}
            fillColor={colors.noozscoreRevision}
            showPersentageOnBallun={false}
          />
        </View>

        <View style={{ paddingLeft: 25, paddingRight: 25 }}>
          <NtQuickAnalysisMetricDescription
            containerStyle={{ marginTop: 15, marginBottom: 15 }}
            text={revisionDescription(revision).description.replace(
              "<value>",
              revision
            )}
          />
        </View>
      </NtQuickAnalysisReversedCard>

      {revision > 1 && (
        <NtQuickAnalysisReversedCard
          style={{
            width: "100%",
            marginTop: 20,
          }}
          onLayoutChange={(size) => {
            setViewWidth(size.width);
          }}
        >
          <NtQuickAnalysisTitleView
            containerStyle={{ marginTop: 8 }}
            title={"Revision Flow"}
            subtitle={"from version to version"}
          />
          <NtQuickAnalysisMetricRevisionFlowEchart
            width={viewWidth - 20}
            height={250}
            stories={stories}
          />
        </NtQuickAnalysisReversedCard>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
});

export default NtQuickAnalysisMetricRevision;
