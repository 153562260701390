import React, { useState, useContext } from "react";
import { View, StyleSheet, TextInput } from "react-native";
import TextareaAutosize from "react-textarea-autosize";
import { analyzeText } from "../../../api/stories";
import NtButton from "../../../components/NtButton";
import colors from "../../../config/colors";
import UserContext from "../../../context/UserContext";
import NtLanguageAnalysisLoading from "./NtLanguageAnalysisLoading";
import {
  parseFullTextFromNewsItem,
  parseTitleFromNewsItem,
} from "../../utils/NewsParserUtils";

function NtLanguageAnalysisInputFields({
  containerStyle,
  onAnalyze,
  storyRewrite = null,
  onMenuPress,
}) {
  const isReAnalyze = storyRewrite != null;

  const [title, setTitle] = useState(
    isReAnalyze ? parseTitleFromNewsItem(storyRewrite) : ""
  );
  const [text, setText] = useState(
    isReAnalyze ? parseFullTextFromNewsItem(storyRewrite) : ""
  );
  const [loading, setLoading] = useState(false);
  const context = useContext(UserContext);

  const isReadyToSubmit = () => {
    return title && text;
  };

  const handleTextAnalyze = async () => {
    var Filter = require("bad-words");
    const filter = new Filter();

    setLoading(true);
    const response = await analyzeText(
      title,
      filter.clean(text),
      context.user.deviceUUID,
      isReAnalyze ? storyRewrite.url : null
    );

    if (response.ok && onAnalyze) {
      onAnalyze(isReAnalyze ? null : response.data[0]);
    }

    setLoading(false);
  };

  return (
    <View style={[styles.container, containerStyle]}>
      {loading && <NtLanguageAnalysisLoading />}
      {!loading && (
        <View style={{ flex: 1 }}>
          <TextareaAutosize
            style={styles.name}
            draggable={false}
            minRows={1}
            maxRows={1}
            placeholder="Enter document name"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <TextareaAutosize
            style={styles.body}
            draggable={false}
            minRows={10}
            placeholder="Enter your text here"
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
          <View style={{ alignItems: "center", marginTop: 20 }}>
            <NtButton
              containerStyle={{
                backgroundColor: isReadyToSubmit()
                  ? colors.blue
                  : colors.lighGray,
                width: 130,
                borderRadius: 50,
              }}
              text={"Analyze"}
              onPress={() => {
                if (isReadyToSubmit()) {
                  handleTextAnalyze();
                }
              }}
            />
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  name: {
    fontSize: 14,
    borderColor: colors.lighGray,
    borderWidth: 1,
    borderRadius: 4,
    marginTop: 30,
    padding: 10,
    color: colors.lighGray,
    outlineColor: colors.dark,
    // outlineStyle: "none",
    resize: "none",
  },
  body: {
    fontSize: 14,
    borderColor: colors.lighGray,
    borderWidth: 1,
    borderRadius: 4,
    marginTop: 15,
    padding: 10,
    color: colors.lighGray,
    outlineColor: colors.dark,
    // outlineStyle: "none",
  },
});

export default NtLanguageAnalysisInputFields;
