import React from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtNewsOptionVisibleButton({ option, onPress, color = "#a8a8a8" }) {
  return (
    <NtTouchableEffect
      style={styles.container}
      onPress={() => {
        if (onPress) {
          onPress(option);
        }
      }}
    >
      <NtMaterialIcon name={option.icon} size={20} color={color} />
      <NtText style={[styles.text, { marginTop: 4, color: color }]}>
        {option.title}
      </NtText>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  text: {
    fontSize: 15,
    color: colors.darker,
    fontWeight: "600",
  },
});

export default NtNewsOptionVisibleButton;
