import React from "react";
import { View, StyleSheet } from "react-native";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";

function NtanalysisTextOnlyReaderPerson({ item, onPress }) {
  return (
    <NtTouchableEffect
      onPress={() => {
        if (onPress) {
          onPress(item);
        }
      }}
      style={styles.container}
    >
      <NtMaterialIcon name="magnify" size={18} color={colors.dark} />
      <NtText style={[styles.text, { marginLeft: 4 }]} numberOfLines={1}>
        {item.text}
      </NtText>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    borderColor: colors.teal,
    borderWidth: 1,
    borderRadius: 20,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 5,
    paddingRight: 14,
    marginRight: 6,
    marginBottom: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    color: colors.dark,
    fontSize: 12,
    fontWeight: "400",
  },
});

export default NtanalysisTextOnlyReaderPerson;
