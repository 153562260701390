import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";

function NtHootProgress({
  containerStyle,
  height = 25,
  progress = 0.3,
  letter = "a",
  letterSize = 10,
  fillColor = "red",
  unfillColor = "#d9d9d9",
  showLabels = false,
}) {
  progress = Math.abs(progress);

  progress = progress > 0 ? progress / 100 : 0;

  return (
    <View style={[styles.container, containerStyle]}>
      {showLabels && (
        <NtText style={[styles.label, { marginBottom: 5 }]}>high</NtText>
      )}
      <View
        style={[
          styles.progressBackground,
          { backgroundColor: unfillColor, height: height },
        ]}
      >
        <View
          style={[
            styles.progressFill,
            { flex: progress, backgroundColor: fillColor },
          ]}
        />
      </View>
      {showLabels && (
        <NtText style={[styles.label, { marginBottom: 5 }]}>low</NtText>
      )}
      {letter && (
        <NtText
          style={[styles.text, { color: fillColor, fontSize: letterSize }]}
        >
          {letter}
        </NtText>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  progressBackground: {
    width: 6,
    height: 25,
    backgroundColor: "blue",
    justifyContent: "flex-end",
    borderRadius: 3,
    overflow: "hidden",
  },
  progressFill: {
    backgroundColor: "red",
    borderRadius: 3,
  },
  text: {
    fontSize: 10,
    fontWeight: "700",
  },
  label: {
    fontSize: 10,
    fontWeight: "700",
    color: colors.dark,
  },
});

export default NtHootProgress;
