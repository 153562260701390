import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";

function NtViewOnDeviceButton({ containerStyle, onPress }) {
  return (
    <NtTouchableEffect
      onPress={onPress}
      style={[styles.container, containerStyle]}
    >
      <NtText style={styles.text}>View in the NOOZ App</NtText>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.blue,

    borderRadius: 50,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  text: {
    color: "white",
    fontSize: 12,
    fontWeight: "700",
  },
});

export default NtViewOnDeviceButton;
