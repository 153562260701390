import React, { useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import colors from "../../config/colors";
import NtFollowingItem from "./NtFollowingItem";
import NtViewCount from "./NtViewCount";
import { parseCategoryImageUrl } from "../utils/NewsParserUtils";

function NtNewsSubcategoryRow({
  name,
  selected = false,
  metric,
  metricIcon = "eye",
  onSelection,
  containerStyle,
  categoryImage,
  categoryImageSize = 30,
  onPress,
}) {
  const [categoryImageUri, setCategoryImageUri] = useState(categoryImage);
  return (
    <NtTouchableEffect
      onPress={onPress}
      style={[styles.container, containerStyle]}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 10,
        }}
      >
        <NtFollowingItem
          style={{ width: 25, height: 25 }}
          onPress={onSelection}
          selected={selected}
        />

        {categoryImageUri && (
          <Image
            style={[
              styles.image,
              { width: categoryImageSize, height: categoryImageSize },
            ]}
            source={{
              uri: categoryImageUri,
            }}
            onError={(error) =>
              setCategoryImageUri(parseCategoryImageUrl("default"))
            }
          />
        )}

        <NtText style={[styles.text, { marginLeft: 10 }]}>{name}</NtText>

        {metric && metricIcon && (
          <NtViewCount
            style={{ marginLeft: 5 }}
            count={metric}
            icon={metricIcon}
          />
        )}
      </View>
      <View
        style={{ width: "100%", height: 1, backgroundColor: colors.lighter }}
      />
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    flex: 1,
    color: colors.darkest,
    fontSize: 13,
    fontWeight: "700",
  },
  image: {
    width: 60,
    height: 60,
    marginLeft: 5,
    resizeMode: "cover",
  },
});

export default NtNewsSubcategoryRow;
