import React from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import { ECharts } from "react-native-echarts-wrapper";
import {
  parseObjectivityFromNewsItem,
  parseSentimentFromNewsItem,
} from "../../utils/NewsParserUtils";
import ntcolors from "../../../config/colors";

function NtQuickAnalysisMetricRevisionFlowEchart({ stories, width, height }) {
  const getOpinionDataSet = () => {
    var data = [];
    for (var x = 0; x < stories.length; x++) {
      data = [...data, parseObjectivityFromNewsItem(stories[x])];
    }

    return data;
  };

  const getSentimentDataSet = () => {
    var data = [];
    for (var x = 0; x < stories.length; x++) {
      data = [...data, parseSentimentFromNewsItem(stories[x])];
    }

    return data;
  };

  const getLabels = () => {
    var data = ["v1"];
    for (var x = 0; x < stories.length - 1; x++) {
      data = [...data, ""];
    }
    // return data;
    data[data.length - 1] = `v${data.length}`;
    return data;
  };

  const colors = ["#5470C6", "#EE6666", "#91CC75"];
  const option = {
    color: colors,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },

    grid: {
      left: "15%",
      right: "15%",
    },
    legend: {
      data: ["Sentiment", "Opinion"],
    },
    xAxis: [
      {
        type: "category",
        axisTick: {
          alignWithLabel: false,
        },

        data: getLabels(),
      },
    ],
    yAxis: [
      {
        type: "value",
        position: "right",
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[0],
          },
        },
        axisLabel: {
          formatter: "{value}%",
        },
      },

      {
        type: "value",
        // name: 'Sentiment',
        position: "left",
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[1],
          },
        },
        axisLabel: {
          formatter: "{value}%",
        },
      },
    ],
    series: [
      {
        name: "Opinion",
        type: "line",

        data: getOpinionDataSet(),
      },

      {
        name: "Sentiment",
        type: "line",
        yAxisIndex: 1,

        data: getSentimentDataSet(),
      },
    ],
  };

  return (
    <View style={[styles.container, { width: width, height: height }]}>
      <ECharts option={option} backgroundColor={ntcolors.lightest} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtQuickAnalysisMetricRevisionFlowEchart;
