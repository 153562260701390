const features = [
  {
    icon: "message-outline",
    title: "Article Opinion",
    description: "View the amount of opinion expressed in each news article.",
  },
  {
    icon: "emoticon-happy-outline",
    title: "Article Sentiment",
    description: "View the degree of sentiment expressed in each news article.",
  },
  {
    icon: "bullhorn",
    title: "Article Propaganda",
    description:
      "View the types of persuasion techniques present in each news article.",
  },
  {
    icon: "file-edit-outline",
    title: "Article Revisions",
    description: "View the multiple versions of each news article.",
  },
  {
    icon: "ghost",
    title: "Ghost Edits",
    description: "Identifies stealth edits that were not properly published.",
  },
  {
    icon: "circle-slice-5",
    title: "NOOZ Score",
    description:
      "Represents the overall amount of influence present in the article.",
  },
  {
    icon: "emoticon-outline",
    title: "NOOZ Reaction",
    description:
      "Illustrations and idioms provide a more relatable interpretation of the NOOZ score.",
  },
  {
    icon: "share-variant",
    title: "NOOZ Sharing",
    description:
      "Sharing a news article includes opinion, sentiment, and propaganda scores.",
  },
  {
    icon: "file-compare",
    title: "Comparison Reader",
    description:
      "Track all the edits of a news article by comparing any two versions of the article.",
  },
  {
    icon: "palette",
    title: "Colored Reader",
    description:
      "Background and text colors change based on opinion and sentiment scores.",
  },
  {
    icon: "unfold-more-vertical",
    title: "Step-Thru Reader",
    description:
      "Toggle thru each sentence to view the scores for each one and for certain words.",
  },
  {
    icon: "grid",
    title: "Custom Layouts",
    description:
      "View news listings in condensed, text, magazine, or card view layouts.",
  },
  {
    icon: "chart-areaspline-variant",
    title: "Article Flow Graphs",
    description:
      "View how opinion and sentiment change from sentence to sentence.",
  },
  {
    icon: "chart-line",
    title: "Revision Flow Graphs",
    description:
      "View how opinion and sentiment change from version to version.",
  },
  {
    icon: "file-restore-outline",
    title: "Revision Archive",
    description:
      "Get exclusive access to read all previous versions of a news article.",
  },
  {
    icon: "podium",
    title: "Custom Rankings",
    description:
      "Rank stories, categories, feeds, authors, and sources based on various metrics.",
  },
  {
    icon: "content-duplicate",
    title: "More Like This",
    description: "View more articles that are like the current story.",
  },
  {
    icon: "key",
    title: "Keyword Links",
    description: "Find related stories using a keyword from the article.",
  },
  {
    icon: "newspaper-variant-outline",
    title: "Latest News",
    description:
      "View the most recently released news stories across all sources.",
  },
  {
    icon: "eye",
    title: "View Stories By Type",
    description:
      "View all news stories by a specific news source, feed, category or author.",
  },
];

export default features;
