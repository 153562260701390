import React from "react";
import { View, StyleSheet, Text } from "react-native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtRankOptionController({
  rankBySelection,
  rankWithinSelection,
  onRankBy,
  onRankWithin,
  containerStyle,
}) {
  const renderButton = (icon, title, onPress) => {
    return (
      <NtTouchableEffect style={styles.buttonContainer} onPress={onPress}>
        <NtMaterialIcon name={icon} size={20} color={colors.darkest} />
        <NtText style={styles.text}>{title}</NtText>
        <NtMaterialIcon name="chevron-down" size={25} color={colors.darkest} />
      </NtTouchableEffect>
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={[styles.rankText, { marginRight: 6 }]}>Sort by</NtText>

      {/* Rank By */}
      {renderButton(rankBySelection.icon, rankBySelection.title, onRankBy)}

      <View style={{ width: 10 }} />

      {/* Rank Within */}
      {renderButton(
        rankWithinSelection.icon,
        rankWithinSelection.title,
        onRankWithin
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    height: 40,
    alignItems: "center",
  },
  buttonContainer: {
    borderRadius: 20,
    backgroundColor: colors.lighter,
    alignItems: "center",
    flexDirection: "row",
    padding: 8,
  },

  text: {
    flex: 1,
    marginLeft: 6,
    fontWeight: "700",
    color: colors.darkest,
  },
  rankText: {
    fontWeight: "600",
    color: colors.darkest,
  },
});

export default NtRankOptionController;
