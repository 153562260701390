import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../../components/NtText";
import NtHootProgress from "../NtHootProgress";
import NtMaterialIcon from "../../../components/NtMaterialIcon";
import colors from "../../../config/colors";

function NtLanguageAnalysisScoreDisplayTitle({
  title,
  label,
  letter,
  icon,
  score,
  color,
}) {
  return (
    <View style={styles.container}>
      <NtText style={styles.title}>{title}</NtText>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtText style={[styles.letter, { color: color }]}>{letter}</NtText>
        <NtHootProgress
          containerStyle={{ marginLeft: 8 }}
          progress={score}
          fillColor={color}
          letter={null}
        />
        <NtText style={[styles.label, { marginLeft: 8, color: color }]}>
          {label}
        </NtText>
      </View>

      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtMaterialIcon name={icon} color={color} />
        <NtText style={[styles.title, { marginLeft: 8 }]}>{score}%</NtText>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: 16,
    fontWeight: "600",
    color: colors.darkest,
  },
  label: {
    fontSize: 14,
    fontWeight: "600",
  },
  letter: {
    fontSize: 13,
    fontWeight: "600",
  },
});

export default NtLanguageAnalysisScoreDisplayTitle;
