import React from "react";
import { View, StyleSheet } from "react-native";
import NtMaterialIcon from "../../../components/NtMaterialIcon";
import NtText from "../../../components/NtText";
import colors from "../../../config/colors";
import NtTouchableEffect from "../../../components/NtTouchableEffect";

function NtLanguageAnalysisOptionButton({
  icon,
  title,
  selected,
  containerStyle,
  selectedBackgroundColor = colors.dark,
  onPress,
}) {
  return (
    <NtTouchableEffect
      onPress={onPress}
      style={[
        styles.container,
        selected ? { backgroundColor: selectedBackgroundColor } : {},
        containerStyle,
      ]}
    >
      <NtMaterialIcon
        name={icon}
        color={selected ? "white" : colors.lighGray}
        size={16}
      />
      <NtText
        style={[
          styles.title,
          { marginLeft: 6, color: selected ? "white" : colors.lighGray },
        ]}
      >
        {title}
      </NtText>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 20,
  },
  title: {
    fontSize: 12,
    fontWeight: "600",
    color: colors.lighGray,
  },
});

export default NtLanguageAnalysisOptionButton;
