import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtText from "../../components/NtText";
import NtAnalysisRevisionSelect from "../components/NtAnalysisRevisionSelect";
import colors from "../../config/colors";
import { parseTimestampFromNewsItem } from "../utils/NewsParserUtils";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import { showOkDialog } from "../../common/utils/ControllerUtils";
import NtMaterialIcon from "../../components/NtMaterialIcon";

class AnalysisRevisionSelectController extends React.Component {
  state = {};

  graphData = [
    {
      id: 1,
    },
    {
      id: 2,
    },
  ];

  componentDidMount() {}

  handleIsStealth = (index) => {
    if (index === 0) {
      // the firstone is never stealh
      return false;
    } else {
      return (
        parseTimestampFromNewsItem(
          this.props.stories[index - 1],
          true,
          "pubDate"
        ) ===
        parseTimestampFromNewsItem(this.props.stories[index], true, "pubDate")
      );
    }
  };

  renderLegend = () => {
    return (
      <NtTouchableEffect
        style={styles.ghostEditContainer}
        onPress={() => {
          showOkDialog(
            "Ghost Edits",
            "Some revisions will be flagged as a ghost edit, which is a stealth edit, that occurs whenever the Source or Author do not update the Published Date after making a change to the news article."
          );
        }}
      >
        <NtMaterialIcon
          name={"ghost"}
          size={20}
          color={colors.sentimentNegativeFilled}
        />
        <NtText style={styles.ghostEditText}>Ghost Edits</NtText>
        <NtMaterialIcon
          containerStyle={{ marginLeft: 10 }}
          name={"information"}
          size={20}
          color={colors.blue}
        />
      </NtTouchableEffect>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            flexGrow: 1,
            paddingBottom: 20,
          }}
        >
          <View style={styles.titleContainer}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <NtMaterialIcon
                name={"file-edit"}
                size={30}
                color={colors.darkest}
              />
              <NtText style={styles.title}>
                Revisions 1 - {this.props.stories.length}
              </NtText>
            </View>
            <NtText style={styles.subtitle}>Select a version below.</NtText>
            {this.renderLegend()}
          </View>

          {/* will render the flat list */}
          {Array.from(Array(this.props.stories.length).keys()).map(
            (key, index) => (
              <NtAnalysisRevisionSelect
                key={index.toString()}
                containerStyle={{ paddingLeft: 10, paddingRight: 10 }}
                story={this.props.stories[index]}
                isStealthEdit={this.handleIsStealth(index)}
                onCardPress={() => {
                  if (this.props.onSelect) {
                    this.props.onSelect(index);
                  }
                }}
              />
            )
          )}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  titleContainer: {
    backgroundColor: colors.lighter,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    color: colors.darkest,
    fontSize: 18,
    fontWeight: "500",
    marginLeft: 10,
  },
  subtitle: {
    color: colors.darkest,
    fontSize: 17,
    fontWeight: "400",
    marginTop: 6,
  },
  ghostEditContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  ghostEditText: {
    color: colors.sentimentNegativeFilled,
    marginLeft: 6,
    fontWeight: "400",
  },
});

export default AnalysisRevisionSelectController;
