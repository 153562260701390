import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtCard from "../../components/NtCard";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import {
  parseImageUrlFromNewsItem,
  parseSourceName,
  parseTextFromNewsItem,
  parseTimestampFromNewsItem,
  parseRevisionFromNewsItem,
} from "../utils/NewsParserUtils";
import NtMenuButton from "../../components/NtMenuButton";
import NtRevisionProgress from "./NtRevisionProgress";
import { revisionStyle } from "../../common/utils/ControllerUtils";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import { useMobile } from "../../hooks/useMobile";

function NtAnalysisPreviewStoryCard({
  story,
  showVersionChange,
  onVersionChange,
  containerStyle,
  showImage,
}) {
  //revision
  const revision = parseRevisionFromNewsItem(story);
  const revStyle = revisionStyle(revision);
  const imageUrl = parseImageUrlFromNewsItem(story);
  const isMobile = useMobile();

  const renderStats = (
    allowDate = true,
    allowVersion = true,
    allowChangeVersion = true
  ) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {allowDate && (
          <NtText style={[styles.time]}>
            {parseTimestampFromNewsItem(story, true)}
          </NtText>
        )}

        {allowVersion && (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <NtRevisionProgress
              progress={revision}
              size={30}
              width={3}
              fontSize={10}
              iconSize={25}
              showProgress={false}
              fontColor={revStyle.color}
              filledColor={revStyle.color}
              icon={revStyle.icon}
              iconColor={revStyle.color}
            />
            <NtText
              style={[
                styles.revisionText,
                { marginLeft: 4, color: revStyle.fontColor },
              ]}
            >
              Version {revision}
            </NtText>
          </View>
        )}
        {allowChangeVersion && showVersionChange && (
          <NtTouchableEffect
            style={{ flexDirection: "row" }}
            onPress={onVersionChange}
          >
            <NtMaterialIcon
              name={"file-replace"}
              size={15}
              color={colors.blue}
            />
            <NtText style={styles.changeVersionText}>Change Version</NtText>
          </NtTouchableEffect>
        )}
      </View>
    );
  };

  const renderDivisor = (style = null) => {
    if (!isMobile) {
      return null;
    }
    return (
      <View
        style={[
          {
            width: "100%",
            height: 1,
            backgroundColor: "#a8a8a8",
          },
          style,
        ]}
      />
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      {renderDivisor({ marginBottom: 10 })}
      {renderStats(false, isMobile, isMobile)}

      {renderDivisor({ marginTop: 10, marginBottom: 20 })}

      {/* source container */}
      <View style={styles.sourceContainer}>
        <NtText style={styles.sourceTitle}>
          {parseSourceName(story.source)}
        </NtText>
      </View>

      {/* Middle container */}
      <View style={styles.middleContainer}>
        {/* Text */}
        <NtText style={styles.text} numberOfLines={3}>
          {parseTextFromNewsItem(story)}
        </NtText>

        {showImage && imageUrl && (
          <Image
            style={styles.image}
            source={{
              uri: imageUrl,
            }}
          />
        )}
      </View>

      {renderStats(true, !isMobile, !isMobile)}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 10,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  imageContainer: {},
  middleContainer: {
    marginTop: 10,
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    width: 90,
    height: 60,
    resizeMode: "cover",
    borderRadius: 15,
    marginLeft: 10,
  },
  text: {
    fontSize: 28,
    lineHeight: 35,
    flex: 1,
    color: colors.darkest,
    fontWeight: "800",
  },

  sourceContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  sourceTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
  time: {
    fontSize: 14,
    color: colors.darker,
  },
  more: {},
  separator: {
    width: "100%",
    height: 1,
    marginTop: 10,
    backgroundColor: colors.teal,
  },
  changeVersionText: {
    color: colors.blue,
    marginLeft: 5,
    fontSize: 13,
    fontWeight: "400",
  },
});

export default NtAnalysisPreviewStoryCard;
