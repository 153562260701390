import React from "react";
import { View, StyleSheet, Image, ScrollView } from "react-native";
import NtButton from "../../components/NtButton";
import colors from "../../config/colors";
import NtTextInput from "../../components/NtTextInput";
import { authenticateUser } from "../../api/auth";
import {
  asyncStorageSetData,
  showToast,
} from "../../common/utils/ControllerUtils";
import BaseController from "../../common/base/BaseController";
import NtFooterBar from "../../root/components/NtFooterBar";
import parse from "html-react-parser";

class AuthController extends BaseController {
  static sHEIMDALLWEB_USER_CREDENTIALS = "sHEIMDALLWEB_USER_CREDENTIALS";

  state = {
    email: "",
    password: "",
    isLoading: false,
  };

  componentDidMount() {
    document.title = "NOOZ.AI | Log In";
  }

  handleLogin = async () => {
    if (!this.state.email || !this.state.password) {
      showToast(
        "Oops...",
        "Please enter a valid username and password",
        "error"
      );
    }

    this.setState({ isLoading: true });
    const response = await authenticateUser(
      this.state.email,
      this.state.password
    );
    if (response.ok) {
      asyncStorageSetData(AuthController.sHEIMDALLWEB_USER_CREDENTIALS, {
        email: this.state.email,
        password: this.state.password,
      });
      this.setState({ email: "", password: "", isLoading: false });
      this.context.updateUser({ authenticated: true });
    } else {
      showToast("Oops...", "Invalid username/password convination", "error");
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <View style={styles.credsContainer}>
            <Image
              style={styles.image}
              source={require("../../assets/images/landing-footer-logo.png")}
            />

            <NtTextInput
              containerStyle={{
                backgroundColor: "white",
                height: 40,
                maxWidth: 400,
                marginTop: 60,
              }}
              placeholder="Enter your username"
              textColor={colors.darker}
              value={this.state.email}
              onChangeText={(text) => {
                this.setState({ email: text });
              }}
            />

            <NtTextInput
              containerStyle={{
                backgroundColor: "white",
                height: 40,
                maxWidth: 400,
                marginTop: 20,
              }}
              secureTextEntry={true}
              placeholder="Enter your password"
              textColor={colors.darker}
              value={this.state.password}
              onChangeText={(text) => {
                this.setState({ password: text });
              }}
            />

            <NtButton
              containerStyle={{ width: 200, marginTop: 60, marginBottom: 80 }}
              text={"Login"}
              isLoading={this.state.isLoading}
              onPress={() => this.handleLogin()}
            />
          </View>
          <NtFooterBar />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightest,
  },

  credsContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 350,
    height: 150,
    resizeMode: "contain",
  },
  footerContainer: {
    backgroundColor: colors.darkest,
    paddingTop: 35,
    paddingBottom: 35,
    paddingLeft: 20,
    paddingRight: 20,
  },
  footerCopyright: {
    color: "white",
    width: "100%",
    textAlign: "center",
    fontSize: 16,
    marginTop: 15,
  },
  links: {
    color: "white",
    fontSize: 14,
  },
});

export default AuthController;
