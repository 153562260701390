import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../../components/NtText";
import NtPropagandaImage from "../NtPropagandaImage";
import { propagandaStyle } from "../../utils/PropagandaUtils";
import colors from "../../../config/colors";
import { parseCleanText } from "../../utils/NewsParserUtils";
import NtMaterialIcon from "../../../components/NtMaterialIcon";
import { opinionIcon, sentimentIcon } from "../../utils/NoozMetricUtils";

function NtLanguageAnalysisScorePerSentenceRow({
  sentence,
  propagandaSubstrings,
  containerStyle,
}) {
  const objectivity = 100 - Math.round(sentence.opinion);
  const sentiment = Math.round(sentence.sentiment);

  let propagandaMetric = [];

  propagandaSubstrings.map((each) => {
    let count = 0;
    each.items.map((item) => {
      if (sentence.text.includes(item)) {
        count++;
      }
    });
    if (count > 0) {
      propagandaMetric = [
        ...propagandaMetric,
        { type: each.type, count: count },
      ];
    }
  });

  const renderPropaganda = () => {
    return propagandaMetric.map((each) => {
      const style = propagandaStyle(each.type);
      return (
        <View
          key={each.type}
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 6,
          }}
        >
          <NtPropagandaImage
            name={style.icon}
            color={style.color}
            size={20}
            backgroundColor={style.backgroundColor}
          />
          <NtText style={styles.propagandaCount}>{each.count}</NtText>
        </View>
      );
    });
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={{ flex: 1 }}>
        <NtText style={[styles.sentenceText]}>
          {parseCleanText(sentence.text)}
        </NtText>
      </View>

      <View style={{ marginLeft: 20, width: 80 }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <NtMaterialIcon
            name={opinionIcon(objectivity).icon}
            color={colors.noozscoreOpinion}
          />
          <NtText
            style={[styles.statsText, { color: colors.noozscoreOpinion }]}
          >
            {objectivity}%
          </NtText>
        </View>

        <View
          style={[
            {
              flexDirection: "row",
              alignItems: "center",
              marginTop: 6,
            },
          ]}
        >
          <NtMaterialIcon
            name={sentimentIcon(sentiment).icon}
            color={colors.noozscoreSentiment}
          />

          <NtText
            style={[styles.statsText, { color: colors.noozscoreSentiment }]}
          >
            {sentiment}%
          </NtText>
        </View>

        <View style={{ height: 10 }} />

        {renderPropaganda()}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    padding: 20,
  },

  sentenceText: {
    fontSize: 15,
    fontWeight: "500",
    color: colors.lighGray,
    lineHeight: 24,
  },
  stats: {
    alignItems: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  statsText: {
    fontSize: 11,
    fontWeight: "600",
    marginLeft: 8,
  },
  propagandaCount: {
    color: colors.darkest,
    marginLeft: 4,
    fontWeight: "400",
  },
});

export default NtLanguageAnalysisScorePerSentenceRow;
