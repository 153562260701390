import React from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import {
  parseImageUrlFromNewsItem,
  parseSourceName,
  parseTextFromNewsItem,
  parseTimestampFromNewsItem,
} from "../utils/NewsParserUtils";
import NtHootContainer from "./NtHootContainer";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import { useMobile } from "../../hooks/useMobile";
import NtHoverView from "../../components/NtHoverView";

function NtNewsCardViewHeader({
  story,
  metric,
  metricIcon,
  scoreType,
  onCardPress,
  onOptionPress,
  onNoozScorePress,
}) {
  const imageUrl = parseImageUrlFromNewsItem(story);
  const isMobile = useMobile();

  const renderMoreButton = (title, name, onPress) => {
    return (
      <NtTouchableEffect
        title={"Alain"}
        style={{ marginRight: 6 }}
        onPress={onPress}
      >
        <NtHoverView title={title}>
          <View
            style={{
              width: 30,
              height: 30,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NtMaterialIcon name={name} size={18} color={colors.teal} />
          </View>
        </NtHoverView>
      </NtTouchableEffect>
    );
  };

  return (
    <NtTouchableEffect onPress={onCardPress} style={styles.container}>
      <View
        style={{
          marginTop: 35,
          marginBottom: 35,
        }}
      >
        {imageUrl && (
          <Image
            style={[
              styles.image,
              { marginTop: 6 },
              isMobile ? { height: 280 } : {},
            ]}
            source={{
              uri: imageUrl,
            }}
          />
        )}

        <View style={{ flex: 1, justifyContent: "center" }}>
          <View style={[styles.sourceContainer, { marginTop: 20 }]}>
            <NtText style={styles.sourceTitle} numberOfLines={2}>
              {parseSourceName(story.source)}
            </NtText>
            <NtText style={styles.sourceNewsTime}>
              {parseTimestampFromNewsItem(story)}
            </NtText>
          </View>

          {/* Middle container */}
          <View style={styles.middleContainer}>
            {/* Text */}
            <View style={{ flex: 1 }}>
              <NtText
                style={[styles.text, { marginTop: 10 }]}
                selectable={true}
                numberOfLines={2}
              >
                {parseTextFromNewsItem(story)}
              </NtText>
            </View>
          </View>

          {/* style container */}
          <View style={[styles.styleContainer, { marginTop: 10 }]}>
            <NtHootContainer
              onPress={onNoozScorePress}
              story={story}
              type={scoreType}
            />

            {/* more actions */}
            <View style={{ flexDirection: "row" }}>
              {renderMoreButton("Share", "share-variant", () => {
                onOptionPress("share");
              })}
              {renderMoreButton("More Like This", "content-duplicate", () => {
                onOptionPress("morelikethis");
              })}
            </View>
          </View>
        </View>
      </View>
      <View
        style={{ width: "100%", height: 1, backgroundColor: colors.lighter }}
      />
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {},
  imageContainer: {},
  middleContainer: {
    paddingLeft: 5,
    paddingRight: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: 350,
    resizeMode: "cover",
  },
  statsContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 10,
  },
  stats: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  statsIcon: {
    fontSize: 18,
  },
  statsText: {
    marginLeft: 5,
    color: "white",
    fontWeight: "500",
    color: colors.teal,
  },
  styleContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  styleText: {
    color: colors.darker,
    fontSize: 15,
    fontWeight: "400",
    marginLeft: 5,
  },
  styleGraph: {},
  text: {
    fontSize: 24,
    lineHeight: 28,
    flex: 1,
    color: colors.darkest,
    fontWeight: "500",
  },
  bottomContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  sourceContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  sourceImage: {
    width: 25,
    height: 25,
    resizeMode: "cover",
  },
  sourceTitle: {
    fontSize: 13,
    fontWeight: "800",
    color: colors.darker,
  },
  sourceNewsTime: {
    fontSize: 13,
    marginLeft: 15,
    color: colors.dark,
  },
  more: {},
  separator: {
    width: "100%",
    height: 1,
    marginTop: 10,
    backgroundColor: colors.teal,
  },
  devTempNumbers: {
    color: colors.darkest,
    fontSize: 14,
  },
});

export default NtNewsCardViewHeader;
