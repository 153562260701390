import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import BaseNewsController from "./BaseNewsController";
import { fetchStories } from "../../api/stories";
import Logger from "../../common/utils/Logger";
import NtLanguageAnalysisInputFields from "../components/languageanalysis/NtLanguageAnalysisInputFields";
import NtFooterBar from "../../root/components/NtFooterBar";
import NtLanguageAnalysisDisplay from "../components/languageanalysis/NtLanguageAnalysisDisplay";
import NtLanguageAnalysisUserController from "../components/languageanalysis/NtLanguageAnalysisUserController";
import NtNewsPageContainer from "../components/NtNewsPageContainer";
import NtSideMenuController from "../../components/NtSideMenuController";
import NtLaunguageAnalysisTitle from "../components/languageanalysis/NtLaunguageAnalysisTitle";

class LanguageAnalysisController extends BaseNewsController {
  state = {
    isMobile: false,
    stories: [],
    view: "analyze",
    displayIndexSelected: null,
    storyRewrite: null,
    loading: false,
    menuOpen: false,
  };

  componentDidMount() {
    super.componentDidMount();
    this.handleFetchMyUUIDs();
  }

  handleFetchMyUUIDs = async () => {
    this.setState({ loading: true });
    const response = await fetchStories(
      10,
      0,
      null,
      {
        user: this.context.user.deviceUUID,
        type: "User",
      },
      "_id",
      "desc",
      200
    );

    if (response.ok) {
      Logger(
        "Successfully fetch all the analyzed text for user",
        response.data
      );
      this.setState({ stories: response.data });
    } else {
      Logger("Unable to process the request", response);
    }
    this.setState({ loading: false });
  };

  handleMenuPress = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  renderInputField = () => {
    if (this.state.view !== "analyze") {
      return null;
    }

    return (
      <NtLanguageAnalysisInputFields
        containerStyle={{ flex: 1 }}
        storyRewrite={this.state.storyRewrite}
        onAnalyze={(story) => {
          if (story) {
            this.state.stories.splice(0, 0, story);
          }
          this.setState({
            stories: this.state.stories,
            displayIndexSelected: 0,
            view: "display",
          });
        }}
        onMenuPress={() => {
          this.handleMenuPress();
        }}
      />
    );
  };

  renderAnalysisDisplay = () => {
    if (this.state.view !== "display") {
      return null;
    }

    return (
      <NtLanguageAnalysisDisplay
        key={this.state.displayIndexSelected}
        containerStyle={{ flex: 1 }}
        story={this.state.stories[this.state.displayIndexSelected]}
        onReWrite={(story) => {
          Logger("User wants to rewrite the article", story);
          this.setState({
            displayIndexSelected: null,
            view: "analyze",
            storyRewrite: story,
          });
        }}
        onMenuPress={() => {
          this.handleMenuPress();
        }}
      />
    );
  };

  renderUserController = () => {
    if (this.state.stories.length == 0) {
      return null;
    }

    return (
      <NtLanguageAnalysisUserController
        stories={this.state.stories}
        showNewAnalyzeButton={this.state.view !== "analyze"}
        selectedIndex={this.state.displayIndexSelected}
        onUserSelect={(index) => {
          this.setState({
            displayIndexSelected: index,
            view: "display",
          });
          this.handleMenuPress();
        }}
        onNewWriting={() => {
          this.setState({
            displayIndexSelected: null,
            view: "analyze",
            storyRewrite: null,
          });
          this.handleMenuPress();
        }}
      />
    );
  };

  render() {
    if (this.state.loading) {
      return this.renderLottieLoading(this.state.loading);
    }

    return (
      <NtNewsPageContainer
        containerStyle={{ paddingTop: 0, paddingBottom: 0 }}
        renderTitleView={<View />}
        showNavigation={false}
        showFooter={true}
      >
        <NtSideMenuController
          renderMenu={this.renderUserController()}
          open={this.state.menuOpen}
          onBackgroundPress={() => {
            this.handleMenuPress();
          }}
        >
          <View
            style={{
              flex: 1,
              padding: 20,
            }}
          >
            <NtLaunguageAnalysisTitle
              showMenuButton={this.state.stories.length > 0}
              showSubtitle={this.state.view === "analyze"}
              onMenuPress={() => {
                this.handleMenuPress();
              }}
            />
            {this.renderInputField()}
            {this.renderAnalysisDisplay()}
          </View>
        </NtSideMenuController>
      </NtNewsPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
});

export default LanguageAnalysisController;
