import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../../config/colors";
import NtLanguageAnalysisOptionButton from "./NtLanguageAnalysisOptionButton";

function NtLanguageanalysisOptionController({
  options,
  containerStyle,
  selectedOption,
  onOptionSelected,
}) {
  return (
    <View style={[styles.container, containerStyle]}>
      <View style={{ flexDirection: "row" }}>
        {options.map((each, index) => (
          <NtLanguageAnalysisOptionButton
            key={index}
            icon={each.icon}
            title={each.title}
            selected={each.title == selectedOption.title}
            containerStyle={{ marginLeft: 10 }}
            onPress={() => {
              if (onOptionSelected) {
                onOptionSelected(each);
              }
            }}
          />
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    color: colors.darkest,
    fontSize: 13,
    fontWeight: "700",
  },
});

export default NtLanguageanalysisOptionController;
