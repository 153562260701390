import colors from "../../config/colors";
import {
  parseCompoundTextFromNewsItem,
  parsePropagandaFromNewsItem,
  parseSentencesFromNewsItem,
} from "./NewsParserUtils";
import Logger from "../../common/utils/Logger";

export const groupPropaganda = (story) => {
  const propagandaArray = parsePropagandaFromNewsItem(story);
  var items = null;
  var found = false;

  if (propagandaArray === null) {
    /**
     * This means we have not process the story yet....
     */
    items = { processing: [] };
  } else {
    items = { none: [] };
    if (propagandaArray.length > 0) {
      found = true;
      items = propagandaArray.reduce((r, a) => {
        r[a.type] = [...(r[a.type] || []), a];
        return r;
      }, {});
    }
  }

  let itemKeys = Object.keys(items);

  itemKeys.sort(function (a, b) {
    return items[b].length - items[a].length;
  });

  return { itemKeys: itemKeys, items: items, found: found };
};

export const testingThis = (story) => {
  const propagandaArray = parsePropagandaFromNewsItem(story);
  for (let x = 0; x < propagandaArray.length; x++) {
    Logger(
      "Here is each propaganda: ",
      propagandaArray[x].type,
      story.compoundText.substring(
        propagandaArray[x].start,
        propagandaArray[x].end
      )
    );
  }
};

export const extractAllPropagandaSubstringsFromStory = (story) => {
  const group = groupPropaganda(story);
  let response = [];

  group.itemKeys.map((each) => {
    let substrings = [];
    let items = group.items[each];
    items.map((item) => {
      substrings = [
        ...substrings,
        parseCompoundTextFromNewsItem(story).substring(item.start, item.end),
      ];
    });
    response = [...response, { type: each, items: substrings }];
  });

  return response;
};

export const extractPropagandaFromItem = (story, item) => {
  const propaganda = parseCompoundTextFromNewsItem(story).substring(
    item.start,
    item.end
  );
  const text = parseCompoundTextFromNewsItem(story);

  //will find the first new line after
  let endIndex = 0;
  for (let x = item.end; x < text.length; x++) {
    if (text.charAt(x) === "\n" || text.charAt(x) === "\r") {
      endIndex = x;
      break;
    }
  }

  //will now find the first new line before
  let startIndex = 0;
  for (let x = item.start; x >= 0; x--) {
    if (text.charAt(x) === "\n" || text.charAt(x) === "\r") {
      startIndex = x + 1;
      break;
    }
  }

  const propagandaRelativeIndexStart = item.start - startIndex;
  const propagandaRelativeIndexEnd =
    propagandaRelativeIndexStart + propaganda.length;
  const unformattedText = parseCompoundTextFromNewsItem(story).substring(
    startIndex,
    endIndex
  );

  let formattedText =
    "<d>" +
    unformattedText.substring(0, propagandaRelativeIndexStart) +
    `<add>${propaganda}</add>` +
    unformattedText.substring(propagandaRelativeIndexEnd);
  ("</d>");

  return {
    text: parseCompoundTextFromNewsItem(story).substring(startIndex, endIndex),
    formattedText: formattedText,
    propaganda: propaganda,
    start: propagandaRelativeIndexStart,
    end: propagandaRelativeIndexEnd,
  };
  // return parseCompoundTextFromNewsItem(story).substring(item.start, item.end);
};

export const propagandaStyle = (type) => {
  let backgroundColor = "white";
  let color = colors.darker;
  let icon = "bow-tie";
  let name = type;
  let description = "";
  if (type === "none") {
    name = "No Propaganda Found";
    description = "No propaganda was detected in the article.";
    backgroundColor = colors.darkest;
    color = "#FFD700";
    icon = "trophy-variant";
  } else if (type === "processing") {
    name = "Pending Propaganda Review";
    description =
      "The system has not performed a propaganda analysis of the article.";
    icon = "progress-clock";
  } else if (type === "Appeal_to_Authority") {
    name = "Appeal to Authority";
    description =
      'Claiming something is true or false because an "expert", whether qualified or not, says it is.';
    icon = "gavel";
  } else if (type === "Appeal_to_fear-prejudice") {
    name = "Appeal to Fear & Prejudice";
    description =
      "Plays upon people's fear and presents a scary future if a certain decision is or is not made today.";
    icon = "halloween";
  } else if (type === "Bandwagon") {
    name = "Bandwagon";
    description =
      "Appealing to the fact that a group of people (or all people) hold an opinion, therefore that opinion must be right.";
    icon = "car-estate";
  } else if (type === "Black-and-White_Fallacy") {
    name = "Black and White Fallacy";
    description =
      "Presenting two alternative options as the only possibilities, when in fact more possibilities exist.";
    icon = "circle-half-full";
  } else if (type === "Causal_Oversimplification") {
    name = "Causal/Oversimplification";
    description = "Leaving out relevant considerations about an issue.";
    icon = "filter";
  } else if (type === "Doubt") {
    name = "Doubt";
    description = "Questioning the credibility of someone or something.";
    icon = "head-question-outline";
  } else if (type === "Exaggeration,Minimisation") {
    name = "Exaggeration, Minimisation";
    description =
      "Adding irrelevant causal influences to the argument OR Embellishing or Downplaying the cause of something";
    icon = "plus-minus-variant";
  } else if (type === "Flag-Waving") {
    name = "Flag Waving";
    description =
      "Playing on strong national feeling (or to any group; e.g., race, gender, political preference) to justify or promote an action or idea";
    icon = "flag-variant";
  } else if (type === "Loaded_Language") {
    name = "Loaded Language";
    description =
      "Using specific words and phrases with strong emotional implications (either positive or negative) to influence an audience.";
    icon = "emoticon-cry-outline";
  } else if (type === "Name_Calling,Labeling") {
    name = "Name Calling, Labeling";
    description =
      "Labeling the object of the propaganda campaign as either something the target audience fears, hates, finds undesirable or loves, praises.";
    icon = "tag";
  } else if (type === "Obfuscation,Intentional_Vagueness,Confusion") {
    name = "Obfuscation, Intentional Vagueness, Confusion";
    description =
      "Using words which are deliberately not clear so that the audience may have its own interpretations.";
    icon = "guy-fawkes-mask";
  } else if (type === "Red_Herring") {
    name = "Red Herring";
    description =
      "Attempting to distract from the main issue by bringing in irrelevant information.";
    icon = "fish";
  } else if (type === "Reductio_ad_hitlerum") {
    name = "Reduction ad hitlerum";
    description =
      "Attempting to prove an argument wrong by making comparisons to Nazi Germany, Hitler or any other commonly despised entity.";
    icon = "emoticon-devil";
  } else if (type === "Repetition") {
    name = "Repetition";
    description =
      "Repeating the same message over and over again so that the audience will eventually accept it.";
    icon = "repeat";
  } else if (type === "Slogans") {
    name = "Slogans";
    description =
      "A brief and striking phrase that may include labeling and stereotyping. Slogans tend to act as emotional appeals.";
    icon = "comment-quote";
  } else if (type === "Straw_Men") {
    name = "Straw Men";
    description =
      "When an opponent's proposition is substituted with a similar one which is then refuted in place of the original proposition.";
    icon = "human";
  } else if (type === "Thought-terminating_Cliches") {
    name = "Thought Terminating Cliches";
    description =
      "Using a commonly used phrase, which sometimes passes as wisdom, to end an argument.";
    icon = "stamper";
  } else if (type === "Whataboutism") {
    name = "Whataboutism";
    description =
      "A technique that attempts to discredit an opponent's position by charging them with hypocrisy without directly disproving their argument.";
    icon = "hand-pointing-right";
  }

  return {
    name: name,
    description: description,
    color: color,
    icon: icon,
    backgroundColor: backgroundColor,
  };
};
