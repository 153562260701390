import React from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import {
  parseHootNewsItem,
  parseHootValuesFromNewsItem,
} from "../../utils/NewsParserUtils";
import NtQuickAnalysisMetricReactionScoreProgressbar from "./NtQuickAnalysisMetricReactionScoreProgressbar";
import NtQuickAnalysisReversedCard from "./NtQuickAnalysisReversedCard";
import NtQuickAnalysisTitleView from "./NtQuickAnalysisTitleView";
import { reactionScoreDescription } from "../../utils/NoozMetricUtils";
import NtQuickAnalysisMetricDescription from "./NtQuickAnalysisMetricDescription";

function NtQuickAnalysisMetricReactionScore({ story }) {
  const hoot = parseHootValuesFromNewsItem(story);

  return (
    <View style={styles.container}>
      <NtQuickAnalysisReversedCard style={{ width: "100%" }}>
        <NtQuickAnalysisTitleView
          containerStyle={{ marginTop: 8 }}
          title={"NOOZ Score & Label"}
          subtitle={"Range & Explanation"}
        />
        <View
          style={{
            paddingLeft: 20,
            paddingRight: 30,
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          <NtQuickAnalysisMetricReactionScoreProgressbar story={story} />
        </View>

        <View style={{ paddingLeft: 25, paddingRight: 25 }}>
          <NtQuickAnalysisMetricDescription
            containerStyle={{ marginTop: 15, marginBottom: 15 }}
            text={reactionScoreDescription(hoot.hoot).description}
          />
        </View>
      </NtQuickAnalysisReversedCard>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
});

export default NtQuickAnalysisMetricReactionScore;
