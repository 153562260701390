import Moment from "moment";
import Logger from "../../common/utils/Logger";
import colors from "../../config/colors";
import { groupPropaganda } from "./PropagandaUtils";

export const parseImageUrlFromNewsItem = (item) => {
  let image = "";
  if (
    item.article &&
    item.article.top_image &&
    isValidImageUrl(item.article.top_image)
  ) {
    image = item.article.top_image;
  } else if (
    item.article &&
    item.article.meta_img &&
    isValidImageUrl(item.article.meta_img)
  ) {
    image = item.article.meta_img;
  } else if (
    item.story &&
    item.story.imageurl &&
    isValidImageUrl(item.story.imageurl)
  ) {
    image = item.story.imageurl;
  } else if (
    item.story &&
    item.story.media_group &&
    item.story.media_group.media_content &&
    Array.isArray(item.story.media_group.media_content)
  ) {
    for (let x = 0; x < item.story.media_group.media_content.length; x++) {
      if (isValidImageUrl(item.story.media_group.media_content[x].val.url)) {
        image = item.story.media_group.media_content[x].val.url;
        break;
      }
    }
  } else if (item.story && item.story.media_thumbnail) {
    if (Array.isArray(item.story.media_thumbnail)) {
      image = item.story.media_thumbnail[0].val.url;
    } else if (typeof item.story.media_thumbnail == "object") {
      image = item.story.media_thumbnail.val.url;
    }
  } else if (item.story && item.story.enclosure) {
    if (Array.isArray(item.story.enclosure)) {
      for (let x = 0; x < item.story.enclosure.length; x++) {
        if (isValidImageUrl(item.story.enclosure[x].val.url)) {
          image = item.story.enclosure[x].val.url;
          break;
        }
      }
    } else if (typeof item.story.enclosure == "object") {
      if (item.story.enclosure.val) {
        image = item.story.enclosure.val.url;
      }
    }
  } else if (item.images && Array.isArray(item.images)) {
    //Will first try to match it domains (requested by Ramon...)
    const mainDomain = extractHostname(item.url);
    for (let x = 0; x < item.images.length; x++) {
      if (
        mainDomain === extractHostname(item.images[x]) &&
        isValidImageUrl(item.images[x])
      ) {
        image = item.images[x];
        break;
      }
    }

    if (!image) {
      //will default to rest....
      for (let x = 0; x < item.images.length; x++) {
        if (isValidImageUrl(item.images[x])) {
          image = item.images[x];
          break;
        }
      }
    }
  } else if (item.story && item.story.image) {
    if (Array.isArray(item.story.image)) {
      for (let x = 0; x < item.story.image.length; x++) {
        if (isValidImageUrl(item.story.image[x])) {
          image = item.story.image[x];
          break;
        }
      }
    } else {
      image = item.story.image;
    }
  }

  if (isValidImageUrl(image)) {
    return image;
  }

  return null;
};

const extractHostname = (url) => {
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  //find & remove port number
  hostname = hostname.split(":")[0];
  //find & remove "?"
  hostname = hostname.split("?")[0];

  return hostname;
};

const isValidImageUrl = (image) => {
  return (
    image.toString().includes("http") &&
    (image.toString().toLowerCase().includes("jpg") ||
      image.toString().toLowerCase().includes("png"))
  );
};

export const parseAuthorfromNewsItem = (item) => {
  if (Array.isArray(item.authors) && item.authors.length > 0) {
    return item.authors[0];
  }
  return "Unknown";
};

export const parseTextFromNewsItem = (item) => {
  if (item.title) {
    if (Array.isArray(item.title)) {
      return item.title[0];
    }
    return item.title.replace(/_/g, ":");
  } else if (item.summary) {
    if (Array.isArray(item.summary)) {
      return item.summary[0];
    }
    return item.summary.replace(/_/g, ":");
  } else if (item.fullText) {
    if (Array.isArray(item.fullText)) {
      return item.fullText[0];
    }
    return item.fullText.replace(/_/g, ":");
  }
  return item._id.toString();
};

export const parseFullTextFromNewsItem = (item) => {
  if (item.fullText) {
    if (Array.isArray(item.fullText)) {
      return item.fullText[0];
    }
    return item.fullText.replace(/_/g, ":");
  }
};

export const parseCompoundTextFromNewsItem = (item) => {
  if (item.compoundText) {
    return item.compoundText;
  }
  return "";
};

export const parseTitleFromNewsItem = (item) => {
  if (item.title) {
    return item.title;
  }
  return "";
};

export const parseSummaryFromNewsItem = (item) => {
  if (item.summary) {
    return item.summary;
  }
  return "";
};

export const parseUrlFromNews = (item) => {
  if (item.url && Array.isArray(item.url)) {
    return item.url[0];
  }
  return item.url;
};

export const parseHootFromNewsItem = (item) => {
  if (item && item.hoot) {
    return item.hoot;
  }
  return {};
};

export const parseSentencesFromNewsItem = (item) => {
  if (
    item.spacy &&
    item.spacy.sentences &&
    Array.isArray(item.spacy.sentences)
  ) {
    return item.spacy.sentences;
  }
  return [];
};

export const parseAuthorsFromNewsItem = (item) => {
  let authorsStr = "";
  if (item.authors) {
    if (item.authors && item.authors.length > 0) {
      item.authors.map((each) => {
        authorsStr += each + ", ";
      });

      authorsStr = authorsStr.substring(0, authorsStr.length - 2);
    }
  }
  return authorsStr;
};

export const parsePropagandaFromNewsItem = (item) => {
  if (item.propaganda && Array.isArray(item.propaganda)) {
    return item.propaganda;
  }
  return null;
};

export const parseRevisionFromNewsItem = (item) => {
  if (item && item.version) {
    return item.version;
  }
  return 0;
};

export const parseGhostEditsFromNewsItem = (item) => {
  if (item && item.stealth_edits) {
    return item.stealth_edits;
  }
  return 0;
};

export const parseSentimentFromNewsItem = (item) => {
  if (item && item.spacy && item.spacy.vader && item.spacy.vader.compound) {
    return Math.round(item.spacy.vader.compound);
  }
  return 0;
};

export const parseObjectivityFromNewsItem = (item) => {
  if (item && item.spacy && item.spacy.opinion) {
    return Math.round(item.spacy.opinion);
  }
  return 0;
};

export const parseNegFromNewsItem = (item) => {
  if (item && item.spacy && item.spacy.vader && item.spacy.vader.neg) {
    return Math.round(item.spacy.vader.neg);
  }
  return 0;
};

export const parseNeuFromNewsItem = (item) => {
  if (item && item.spacy && item.spacy.vader && item.spacy.vader.neu) {
    return Math.round(item.spacy.vader.neu);
  }
  return 0;
};

export const parsePosFromNewsItem = (item) => {
  if (item && item.spacy && item.spacy.vader && item.spacy.vader.pos) {
    return Math.round(item.spacy.vader.pos);
  }
  return 0;
};

export const parseSourceFromNewsItem = (item) => {
  if (item && item.source) {
    return item.source;
  }
  return "";
};

export const parseCategoryFromNewsItem = (item) => {
  if (item && item.category) {
    return item.category;
  }
  return "";
};

export const parseFeedFromNewsItem = (item) => {
  if (item && item.story && item.story.feed_name) {
    return item.story.feed_name;
  }
  return "";
};

export const parseReadCountFromNewsItem = (item) => {
  if (item && item.read_count) {
    return item.read_count;
  }
  return 0;
};

export const parseIdFromNewsItem = (item) => {
  return item.id || item._id;
};

export const parseTimestampFromNewsItem = (
  item,
  exactDate = false,
  name = "update"
) => {
  if (item && item[name]) {
    Moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "sec",
        m: "%dm",
        mm: "%dm",
        h: "%dh",
        hh: "%dh",
        d: "%dd",
        dd: "%dd",
        M: "mth",
        MM: "%dmths",
        y: "y",
        yy: "%dy",
      },
    });

    if (exactDate) {
      return Moment(item[name]).format("lll");
    } else {
      return Moment(item[name]).fromNow();
    }
  }
  return "";
};

export const parseLastArticleUpdateFromNewsItem = (item, exactDate = false) => {
  if (item && item.last_article_update) {
    Moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "sec",
        m: "%dm",
        mm: "%dm",
        h: "%dh",
        hh: "%dh",
        d: "%dd",
        dd: "%dd",
        M: "mth",
        MM: "%dmths",
        y: "y",
        yy: "%dy",
      },
    });

    if (exactDate) {
      return Moment(item.item.last_article_update).format("lll");
    } else {
      return Moment(item.item.last_article_update).fromNow();
    }
  }
  return "";
};

export const parseReadTimeFromNewsItem = (item) => {
  if (item.compoundText) {
    return Math.round(item.compoundText.split(" ").length / 200);
  }

  return 0;
};

export const parseImageUrlFromFeed = (feed) => {
  if (feed.image_url && feed.image_url.includes("https")) {
    return feed.image_url;
  }
  return null;
};

export const parseSourceName = (name) => {
  if (name && !Array.isArray(name)) {
    return name.toString().replace("FNC", "Fox News");
  }
  return name;
};

export const parseCleanText = (text) => {
  if (text) {
    return text.replace(/\\"/g, '"');
  }
  return text;
};

export const parseCategoryImageUrl = (name) => {
  if (!name) {
    name = "default";
  }

  name = name.toLowerCase();
  name = name.replace(" ", "-");

  return `https://images.nooz.ai/icons/category/${name}.png`;
};

export const parseTextOnlyReaderRestrictionFromNewsItem = (item) => {
  if (item.story && item.story.restrictions) {
    return item.story.restrictions.text_only_reader;
  }
  return false;
};

export const parseColorReaderRestrictionFromNewsItem = (item) => {
  if (item.story && item.story.restrictions) {
    return item.story.restrictions.color_reader;
  }
  return false;
};

export const parseComparisonReaderRestrictionFromNewsItem = (item) => {
  if (item.story && item.story.restrictions) {
    return item.story.restrictions.comparison_reader;
  }
  return false;
};

export const parseStepThruReaderRestrictionFromNewsItem = (item) => {
  if (item.story && item.story.restrictions) {
    return item.story.restrictions.step_thru_reader;
  }
  return false;
};

export const parseKeywordsFromNewsItem = (item) => {
  if (item.keywords) {
    return item.keywords;
  }
  return [];
};

export const parseHootValuesFromNewsItem = (item) => {
  //reaction and score
  const hoot = parseHootFromNewsItem(item);

  //sentiment
  const sentiment = parseSentimentFromNewsItem(item);

  //propaganda
  const propaganda = groupPropaganda(item);
  const sentences = parseSentencesFromNewsItem(item);
  let propTotal = 0;
  let propPersentage = 0;
  if (propaganda.found) {
    propaganda.itemKeys.map((each) => {
      propTotal += propaganda.items[each].length;
    });
  }

  if (propTotal > 0) {
    propPersentage = Math.round((propTotal / sentences.length) * 100);
  }

  if (propPersentage > 100) {
    propPersentage = 100;
  }

  //opinion
  const opinion = parseObjectivityFromNewsItem(item);

  //revision
  const revision = parseRevisionFromNewsItem(item);

  //ghost edits
  const ghostEdits = parseGhostEditsFromNewsItem(item);
  const ghoEditsPersentage =
    ghostEdits > 0 ? Math.round((ghostEdits / revision) * 100) : 0;

  return {
    hoot: hoot.hootometer > 100 ? 100 : hoot.hootometer,
    hootObject: hoot,
    sentiment: sentiment,
    propagandaObject: propaganda,
    propagandaTotal: propTotal,
    propagandaPersentage: propPersentage,
    opinion: opinion,
    revision: revision,
    ghostEdits: ghostEdits,
    ghostEditsPersentage: ghoEditsPersentage,
  };
};

export const parsePersonsFromNewsItem = (item) => {
  //name filter.....
  const filterName = (name) => {
    return name.replace(/\\"/g, "");
  };

  //condition....
  const reduceCondition = (obj, o) => {
    return obj.text === o.text;
  };

  const filterCondition = (obj) => {
    return (
      obj.type === "PERSON" ||
      obj.type === "ORG" ||
      obj.type === "GPE" ||
      obj.type === "NORP" ||
      obj.type === "FAC"
    );
  };

  if (
    item.spacy &&
    item.spacy.entities &&
    Array.isArray(item.spacy.entities) &&
    item.spacy.entities.length > 0
  ) {
    return item.spacy.entities
      .filter((each) => filterCondition(each))
      .reduce((unique, o) => {
        if (!unique.some((obj) => reduceCondition(obj, o))) {
          unique.push({ ...o, text: filterName(o.text) });
        }
        return unique;
      }, []);
  }
  return [];
};

export const parseConversationAudioFromItem = (user, item) => {
  return `${user.apiUrl}/get_audio?url=${item.url}`;
};
