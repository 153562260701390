import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../../config/colors";

function NtQuickAnalysisReversedCard({ children, style, onLayoutChange }) {
  return (
    <View
      style={[styles.container, style]}
      onLayout={(event) => {
        if (onLayoutChange) {
          onLayoutChange(event.nativeEvent.layout);
        }
      }}
    >
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lightest,
    shadowColor: "black",
    shadowOffset: { width: -1, height: -2 },
    shadowRadius: 1,
    shadowOpacity: 0.26,
    borderRadius: 10,
  },
});

export default NtQuickAnalysisReversedCard;
