import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtCard from "../../components/NtCard";
import { parseHootValuesFromNewsItem } from "../utils/NewsParserUtils";
import { hootStyle } from "../utils/HootParserUtils";
import NtHootCircularProgress from "./NtHootCircularProgress";
import NtHootProgress from "./NtHootProgress";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import {
  opinionIcon,
  propagandaIcon,
  revisionIcon,
  sentimentIcon,
} from "../utils/NoozMetricUtils";
import { ghostEditIcon } from "../utils/NoozMetricUtils";
import { useMobile } from "../../hooks/useMobile";

function NtAnalysisScoreController({
  story,
  containerStyle,
  onQuickAnalysisPress,
  onSentimentPress,
  onPropagandaPress,
  onOpinionPress,
  onRevisionPress,
}) {
  const isMobile = useMobile();
  const hoot = parseHootValuesFromNewsItem(story);
  const style = hootStyle(hoot.hoot);

  const renderScore = () => {
    return (
      <NtCard
        onPress={onQuickAnalysisPress}
        style={{
          height: 60,
          top: 0,
          marginBottom: 17,
        }}
        containerStyle={{
          flex: 1,
        }}
      >
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <Image style={[styles.image]} source={style.image} />
          <View style={{ flex: 1, marginLeft: 8 }}>
            <NtText style={[styles.text]}>
              {hoot.hootObject.advanced_label}
            </NtText>
            <NtText style={[styles.text, { marginTop: 2, color: style.color }]}>
              {hoot.hootObject.label}
            </NtText>
          </View>
          <NtHootCircularProgress
            containerStyle={{ marginLeft: 6 }}
            score={hoot.hoot}
          />
        </View>
      </NtCard>
    );
  };

  const renderEachScore = (color, letter, score, icon, onPress) => {
    return (
      <NtCard
        onPress={onPress}
        style={{ height: 60, top: -10, marginBottom: 10 }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <NtHootProgress
            progress={score}
            fillColor={color}
            letter={letter}
            letterSize={15}
          />
          <View style={{ marginLeft: 6 }}>
            <NtMaterialIcon color={color} name={icon} />
            <NtText style={[styles.percentageText, { color: color }]}>
              {score}%
            </NtText>
          </View>
        </View>
      </NtCard>
    );
  };

  const renderRevisionGhostEdits = () => {
    return (
      <NtCard
        onPress={onRevisionPress}
        style={{ height: 60, top: -10, marginBottom: 10 }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <NtHootProgress
              progress={hoot.revision}
              fillColor={colors.noozscoreRevision}
              letter={"R"}
              letterSize={15}
            />
            <View style={{ marginLeft: 6 }}>
              <NtMaterialIcon
                color={colors.noozscoreRevision}
                name={revisionIcon(hoot.revision).icon}
              />
              <NtText
                style={[
                  styles.percentageText,
                  { color: colors.noozscoreRevision },
                ]}
              >
                {hoot.revision}%
              </NtText>
            </View>
          </View>

          <View style={{ flexDirection: "row", marginLeft: 6 }}>
            <NtHootProgress
              progress={hoot.ghostEditsPersentage}
              fillColor={colors.noozscoreGhostEdits}
              letter={"G"}
              letterSize={15}
            />
            <View style={{ marginLeft: 6 }}>
              <NtMaterialIcon
                color={colors.noozscoreGhostEdits}
                name={ghostEditIcon(hoot.ghostEditsPersentage).icon}
              />
              <NtText
                style={[
                  styles.percentageText,
                  { color: colors.noozscoreGhostEdits },
                ]}
              >
                {hoot.ghostEditsPersentage}%
              </NtText>
            </View>
          </View>
        </View>
      </NtCard>
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={{}}>
        {renderScore()}

        {/* sentiment */}
        {renderEachScore(
          colors.noozscoreSentiment,
          "S",
          hoot.sentiment,
          sentimentIcon(hoot.sentiment).icon,
          onSentimentPress
        )}

        {/* Propaganda */}
        {renderEachScore(
          colors.noozscorePropaganda,
          "P",
          hoot.propagandaPersentage,
          propagandaIcon(hoot.propagandaPersentage).icon,
          onPropagandaPress
        )}

        {/* Opinion */}
        {renderEachScore(
          colors.noozscoreOpinion,
          "O",
          hoot.opinion,
          opinionIcon(hoot.opinion).icon,
          onOpinionPress
        )}

        {/* Revision */}
        {renderRevisionGhostEdits()}
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // flexDirection: "row",
    backgroundColor: colors.lighestGray,
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 10,
  },
  image: {
    width: 35,
    height: 35,
    resizeMode: "contain",
  },
  text: {
    fontSize: 13,
    fontWeight: "600",
  },
  percentageText: {
    fontSize: 13,
    fontWeight: "500",
  },
});

export default NtAnalysisScoreController;
