import React, { version } from "react";
import { View, StyleSheet, Image } from "react-native";
import NtHootTMLabel from "../NtHootTMLabel";
import NtQuickAnalysisMetricContainer from "./NtQuickAnalysisMetricContainer";
import useSelection from "../../../hooks/useSelection";
import {
  revisionLabel,
  sentimentLabel,
  opinionLabel,
  ghostEditLabel,
  propagandaLabel,
  sentimentIcon,
  opinionIcon,
  revisionIcon,
  ghostEditIcon,
  propagandaIcon,
} from "../../utils/NoozMetricUtils";
import { parseHootValuesFromNewsItem } from "../../utils/NewsParserUtils";

import colors from "../../../config/colors";
import { hootStyle } from "../../utils/HootParserUtils";
import NtHootCircularProgress from "../NtHootCircularProgress";
import NtQuickAnalysisMetricSentiment from "./NtQuickAnalysisMetricSentiment";
import NtQuickAnalysisMetricOpinion from "./NtQuickAnalysisMetricOpinion";
import NtQuickAnalysisMetricGhostEdit from "./NtQuickAnalysisMetricGhostEdit";
import NtQuickAnalysisMetricRevision from "./NtQuickAnalysisMetricRevision";
import NtQuickAnalysisMetricPropaganda from "./NtQuickAnalysisMetricPropaganda";
import NtQuickAnalysisMetricReactionScore from "./NtQuickAnalysisMetricReactionScore";

function NtQuickAnalysisController({ containerStyle, story, stories }) {
  const selection = useSelection();

  const hoot = parseHootValuesFromNewsItem(story);

  //reaction and score
  const reactionScoreStyle = hootStyle(hoot.hoot);

  //sentiment
  const stmtLabel = sentimentLabel(hoot.sentiment);
  const stmtIcon = sentimentIcon(hoot.sentiment);

  //propaganda
  const propLabel = propagandaLabel(hoot.propagandaPersentage);
  const propIcon = propagandaIcon(hoot.propagandaPersentage);

  //opinion
  const opnLabel = opinionLabel(hoot.opinion);
  const opnIcon = opinionIcon(hoot.opinion);

  //revision
  const revLabel = revisionLabel(hoot.revision);
  const revIcon = revisionIcon(hoot.revision);

  //ghost edits
  const ghoLabel = ghostEditLabel(hoot.ghostEdits);
  const ghoIcon = ghostEditIcon(hoot.ghostEdits);

  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <NtHootTMLabel size={11} secondWord="REACTION" />

        <NtHootTMLabel size={11} secondWord="SCORE" />
      </View>

      {/* Reaction / Score */}
      <NtQuickAnalysisMetricContainer
        containerStyle={{ marginTop: 6 }}
        expanded={selection.isSelected("reactionandscore")}
        onPress={() => {
          if (selection.isSelected("reactionandscore")) {
            selection.removeSelection("reactionandscore");
          } else {
            selection.addSelection("reactionandscore");
          }
        }}
        renderReaction={
          <Image
            style={{ width: 32, height: 32, resizeMode: "contain" }}
            source={reactionScoreStyle.image}
          />
        }
        renderScore={<NtHootCircularProgress score={hoot.hoot} />}
        title={reactionScoreStyle.advancedLabel}
        subtitle={reactionScoreStyle.label}
        color={reactionScoreStyle.color}
        renderBody={<NtQuickAnalysisMetricReactionScore story={story} />}
      />

      <View style={{ alignItems: "center", marginTop: 25 }}>
        <NtHootTMLabel size={11} secondWord="METRICS" />
      </View>

      {/* Sentiment */}
      <NtQuickAnalysisMetricContainer
        containerStyle={{ marginTop: 6 }}
        expanded={selection.isSelected("sentiment")}
        onPress={() => {
          if (selection.isSelected("sentiment")) {
            selection.removeSelection("sentiment");
          } else {
            selection.addSelection("sentiment");
          }
        }}
        metric={"S"}
        value={hoot.sentiment}
        icon={stmtIcon.icon}
        unit={"%"}
        title={stmtLabel.label}
        subtitle={"Sentiment Impact"}
        color={colors.noozscoreSentiment}
        renderBody={<NtQuickAnalysisMetricSentiment story={story} />}
      />

      {/* Propaganda */}
      <NtQuickAnalysisMetricContainer
        containerStyle={{ marginTop: 6 }}
        expanded={selection.isSelected("propaganda")}
        onPress={() => {
          if (selection.isSelected("propaganda")) {
            selection.removeSelection("propaganda");
          } else {
            selection.addSelection("propaganda");
          }
        }}
        metric={"P"}
        value={hoot.propagandaPersentage}
        icon={propIcon.icon}
        unit={"%"}
        title={propLabel.label}
        subtitle={"Propaganda Usage"}
        color={colors.noozscorePropaganda}
        renderBody={<NtQuickAnalysisMetricPropaganda story={story} />}
      />

      {/* Opinion */}
      <NtQuickAnalysisMetricContainer
        containerStyle={{ marginTop: 6 }}
        expanded={selection.isSelected("opinion")}
        onPress={() => {
          if (selection.isSelected("opinion")) {
            selection.removeSelection("opinion");
          } else {
            selection.addSelection("opinion");
          }
        }}
        metric={"O"}
        value={hoot.opinion}
        icon={opnIcon.icon}
        unit={"%"}
        title={opnLabel.label}
        subtitle={"Opinion Expressed"}
        color={colors.noozscoreOpinion}
        renderBody={<NtQuickAnalysisMetricOpinion story={story} />}
      />

      {/* Revision */}
      <NtQuickAnalysisMetricContainer
        containerStyle={{ marginTop: 6 }}
        expanded={selection.isSelected("revision")}
        onPress={() => {
          if (selection.isSelected("revision")) {
            selection.removeSelection("revision");
          } else {
            selection.addSelection("revision");
          }
        }}
        metric={"R"}
        value={hoot.revision}
        icon={revIcon.icon}
        unit={" "}
        title={revLabel.label}
        subtitle={"Revision Activity"}
        color={colors.noozscoreRevision}
        renderBody={
          <NtQuickAnalysisMetricRevision story={story} stories={stories} />
        }
      />

      {/* Ghost Edits */}
      <NtQuickAnalysisMetricContainer
        containerStyle={{ marginTop: 6 }}
        expanded={selection.isSelected("ghostedits")}
        onPress={() => {
          if (selection.isSelected("ghostedits")) {
            selection.removeSelection("ghostedits");
          } else {
            selection.addSelection("ghostedits");
          }
        }}
        metric={"G"}
        value={hoot.ghostEditsPersentage}
        icon={ghoIcon.icon}
        unit={"%"}
        title={ghoLabel.label}
        subtitle={"Ghost Edits"}
        color={colors.noozscoreGhostEdits}
        renderBody={<NtQuickAnalysisMetricGhostEdit story={story} />}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 60,
  },
});

export default NtQuickAnalysisController;
