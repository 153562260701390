import React from "react";
import { View, StyleSheet } from "react-native";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtNewsPageNavigationHamburgerButton({
  icon = "menu-open",
  size = 22,
  color = "red",
  open = false,
}) {
  return (
    <View style={styles.container}>
      <NtMaterialIcon
        containerStyle={open ? styles.menuOpen : styles.menuClosed}
        name={icon}
        size={size}
        color={color}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  menuOpen: {
    transform: [{ rotate: "0deg" }],
  },
  menuClosed: {
    transform: [{ rotate: "180deg" }],
  },
});

export default NtNewsPageNavigationHamburgerButton;
