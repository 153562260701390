import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import Lottie from "lottie-react";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtNewsPageLoadError({ visible = false, message, containerStyle }) {
  if (!visible) {
    return null;
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <NtMaterialIcon name="alert-circle-outline" color="#B47070" size={40} />
      <NtText style={[styles.message, { marginTop: 10 }]}>{message}</NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  message: {
    color: "#B47070",
    textAlign: "center",
    fontSize: 16,
    lineHeight: 24,
  },
});

export default NtNewsPageLoadError;
