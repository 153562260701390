import React, { useState, useContext } from "react";
import { View, StyleSheet } from "react-native";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import { useNavigation, useRoute } from "@react-navigation/native";
import routes from "../../navigation/routes";
import { useMobile } from "../../hooks/useMobile";
import UserContext from "../../context/UserContext";
import Logger from "../../common/utils/Logger";
import NtHoverView from "../../components/NtHoverView";
import NtNewsPageNavigationHamburgerButton from "./NtNewsPageNavigationHamburgerButton";

function NtNewsPageNavigation({
  containerStyle,
  onLayoutChange,
  otherOptions,
}) {
  const context = useContext(UserContext);
  const [open, setOpen] = useState(context.user.navOpen);
  const navigation = useNavigation();
  const route = useRoute();

  const isMobile = useMobile();

  const navOption = {
    id: "home",
    title: "Navigation",
    icon: "menu-open",
    renderIcon: (
      <NtNewsPageNavigationHamburgerButton
        icon={"menu-open"}
        color={"#a8a8a8"}
        open={open}
      />
    ),
    hoverTitle: "Open/Close Navigation",
    hoverPosition: "bottom",
    action: () => {
      setOpen(!open);

      // will update the user to account for the
      context.updateUser({ ...context.user, navOpen: !open });
    },
  };

  const options = [
    {
      id: routes.news,
      icon: "newspaper-variant-outline",
      title: "Latest News",
      action: () => {
        navigation.navigate(routes.news);
      },
    },
    {
      id: routes.mynews,
      icon: "check-bold",
      title: "My News",
      action: () => {
        navigation.navigate(routes.mynews);
      },
    },
    // {
    //   id: routes.trending,
    //   icon: "compass-outline",
    //   title: "Trending",
    //   action: () => {
    //     navigation.navigate(routes.trending);
    //   },
    // },
    {
      id: routes.sources,
      icon: "domain",
      title: "Sources",
      action: () => {
        navigation.navigate(routes.sources);
      },
    },
    {
      id: routes.categories,
      icon: "shape",
      title: "Categories",
      action: () => {
        navigation.navigate(routes.categories);
      },
    },
    {
      id: routes.feeds,
      icon: "rss",
      title: "Feeds",
      action: () => {
        navigation.navigate(routes.feeds);
      },
    },
    {
      id: routes.authors,
      icon: "account-multiple",
      title: "Authors",
      action: () => {
        navigation.navigate(routes.authors);
      },
    },
  ];

  const renderItem = (item) => {
    const highlighted = item.id === route.name;

    return (
      <NtTouchableEffect
        onPress={() => {
          item.action();
        }}
        key={item.title}
        style={isMobile ? {} : { marginBottom: 20 }}
        onLayout={(event) => {
          if (onLayoutChange) {
            onLayoutChange(event.nativeEvent.layout);
          }
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <NtHoverView
            enabled={!open && !isMobile}
            position={item.hoverPosition || "right"}
            title={item.hoverTitle || item.title}
          >
            {item.renderIcon && item.renderIcon}
            {!item.renderIcon && (
              <NtMaterialIcon
                name={item.icon}
                size={22}
                color={highlighted ? colors.darkest : "#a8a8a8"}
              />
            )}
          </NtHoverView>
          {open && (
            <NtText
              style={[
                styles.title,
                {
                  marginLeft: 10,
                },
                highlighted ? { color: colors.darkest, fontWeight: "600" } : {},
              ]}
            >
              {item.title}
            </NtText>
          )}
        </View>
      </NtTouchableEffect>
    );
  };

  if (isMobile && open) {
    setOpen(false);
  }

  return (
    <View
      style={[
        styles.container,
        { flexDirection: isMobile ? "row" : "column" },
        isMobile ? { justifyContent: "space-between" } : {},
        containerStyle,
      ]}
    >
      {!isMobile && renderItem(navOption)}
      {!isMobile && <View style={{ height: 43 }} />}
      {options.map((each) => renderItem(each))}

      {otherOptions && otherOptions.map((each) => renderItem(each))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: "#a8a8a8",
    fontWeight: "400",
  },
});

export default NtNewsPageNavigation;
