import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import {
  propagandaStyle,
  extractPropagandaFromItem,
} from "../../utils/PropagandaUtils";

import NtPropagandaImage from "../NtPropagandaImage";
import NtText from "../../../components/NtText";
import NtTouchableEffect from "../../../components/NtTouchableEffect";
import NtDisclosure from "../../../components/NtDisclosure";
import colors from "../../../config/colors";
import NtProgressBar from "../../../components/NtProgressBar";
import Logger from "../../../common/utils/Logger";
import parse from "html-react-parser";

function NtQuickAnalysisMetricPropagandaRow({
  type,
  items,
  story,
  progress,
  expanded = false,
  onExpandedPress,
}) {
  const [textViewWidth, setTextViewWidth] = useState(0);

  const style = propagandaStyle(type);
  const css = require("../../data/propagandacss.html");

  const renderText = (each) => {
    const result = extractPropagandaFromItem(story, each);

    return (
      <View style={{ flex: 1, width: textViewWidth }}>
        <div>
          {parse(css)}
          {parse(result.formattedText)}
        </div>
      </View>
    );
  };

  return (
    <View>
      <NtTouchableEffect
        style={{
          flexDirection: "row",
          alignItems: "center",
          padding: 10,
          marginBottom: 3,
          borderRadius: 6,
          backgroundColor: colors.lightest,
        }}
        onPress={onExpandedPress}
        key={type}
      >
        <NtDisclosure
          name={expanded ? "chevron-down" : "chevron-right"}
          size={25}
          color={colors.darkest}
        />
        <NtPropagandaImage
          name={style.icon}
          color={style.color}
          backgroundColor={style.backgroundColor}
        />
        <View style={{ flex: 1, paddingLeft: 10, paddingRight: 10 }}>
          {progress && (
            <NtProgressBar
              containerStyle={{ marginBottom: 2 }}
              progress={progress}
            />
          )}
          <NtText style={[styles.analysisText]}>{style.name}</NtText>
        </View>
        {items.length > 0 && (
          <NtText
            style={[styles.analysisText, { fontSize: 18, marginRight: 6 }]}
          >
            {items.length}
          </NtText>
        )}
      </NtTouchableEffect>

      {/* expanded */}
      {expanded && (
        <View style={{ paddingLeft: 20, paddingRight: 20 }}>
          <NtText style={[styles.subtitle, { marginTop: 8 }]}>
            {style.description}
          </NtText>

          <NtText style={[styles.title, { marginTop: 15, marginBottom: 15 }]}>
            Instances found: {items.length}
          </NtText>

          {items.map((each, index) => (
            <View
              key={index.toString()}
              style={{
                flexDirection: "row",
                marginBottom: 10,
              }}
              onLayout={(event) => {
                setTextViewWidth(event.nativeEvent.layout.width);
              }}
            >
              <View style={{ width: 30 }}>
                <NtText style={styles.propagandaItem}>{index + 1}.</NtText>
              </View>
              <View style={{ marginRight: 10 }}>{renderText(each)}</View>
            </View>
          ))}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  analysisText: {
    fontSize: 15,
    color: colors.darkest,
    fontWeight: "500",
  },
  title: {
    color: colors.darkest,
    fontSize: 16,
    fontWeight: "500",
  },
  subtitle: {
    color: colors.darkest,
    fontSize: 16,
    fontWeight: "300",
  },
  propagandaItem: {
    color: colors.darkest,
    fontSize: 14,
    fontWeight: "300",
  },
  add: {
    backgroundColor: "yellow",
  },
  p: {
    color: colors.darkest,
  },
});

export default NtQuickAnalysisMetricPropagandaRow;
