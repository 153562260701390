import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import NtQuickAnalysisBallun from "./NtQuickAnalysisBallun";
import colors from "../../../config/colors";
import NtText from "../../../components/NtText";
import NtMaterialIcon from "../../../components/NtMaterialIcon";
import Logger from "../../../common/utils/Logger";

function NtQuickAnalysisMetricProgressbar({
  value = 40,
  fillColor = colors.noozscoreSentiment,
  unfillColor = colors.teal,
  multipliers = ["0", "25", "50", "75", "100"],
  labels = [
    { text: "Low", icon: "home" },
    { text: "Medium", icon: "home" },
    { text: "High", icon: "home" },
    { text: "Extreme", icon: "home" },
  ],
  icon = "home",
  showPersentageOnBallun = true,
}) {
  const [progressWidth, setProgressWidth] = useState(0);
  const flex = Math.abs(value / multipliers[multipliers.length - 1]);
  const selectedLabel = Math.floor(value / (100 / labels.length));

  const renderLabels = (item, index) => {
    return (
      <View key={index} style={{ flex: 1, alignItems: "center", marginTop: 4 }}>
        {item.icon && (
          <NtMaterialIcon
            name={item.icon}
            size={20}
            color={
              index === selectedLabel && value != 0 ? fillColor : unfillColor
            }
          />
        )}
        <NtText
          style={[
            styles.labels,
            { marginTop: 1 },
            index == selectedLabel && value != 0
              ? { fontWeight: "600", color: colors.darkest }
              : {},
          ]}
        >
          {item.text}
        </NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtMaterialIcon
        style={{ marginBottom: labels[0].icon ? 36 : 16, marginRight: 5 }}
        name={icon}
        size={20}
        color={value == 0 ? fillColor : unfillColor}
      />
      <View style={{ flex: 1 }}>
        <NtQuickAnalysisBallun
          value={`${value}${showPersentageOnBallun ? "%" : ""}`}
          containerStyle={{
            marginBottom: 18,
            marginLeft: progressWidth - 30,
            backgroundColor: fillColor,
          }}
        />

        <View style={{ flexDirection: "row", marginBottom: 4 }}>
          <NtText style={[styles.labels, { textAlign: "right", flex: 0 }]}>
            {multipliers[0]}
          </NtText>
          {Array.from(Array(4).keys()).map((key, index) => (
            <NtText
              key={index}
              style={[styles.labels, { flex: 1, textAlign: "right" }]}
            >
              {multipliers[index + 1]}
            </NtText>
          ))}
        </View>
        <View
          style={[styles.barUnfullContainer, { backgroundColor: unfillColor }]}
        >
          <View
            style={[
              styles.barContainer,
              { flex: flex, backgroundColor: fillColor },
            ]}
            onLayout={(event) => {
              setProgressWidth(event.nativeEvent.layout.width);
            }}
          />
        </View>

        <View style={{ flexDirection: "row" }}>
          {labels.map((each, index) => renderLabels(each, index))}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  labels: {
    fontSize: 12,
    textAlign: "center",
    color: colors.dark,
  },
  barUnfullContainer: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: colors.teal,
    borderRadius: 7,
  },
  barContainer: {
    height: 12,
    borderRadius: 7,
    backgroundColor: colors.noozscoreSentiment,
  },
});

export default NtQuickAnalysisMetricProgressbar;
