import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import {
  FacebookShareCount,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import NtText from "../../components/NtText";
import colors from "../../config/colors"; 
import NtTouchableEffect from "../../components/NtTouchableEffect";
import Base64 from "Base64";
import {
  parseTitleFromNewsItem,
  parseHootValuesFromNewsItem,
} from "../utils/NewsParserUtils";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import { hootStyle } from "../utils/HootParserUtils";
import {
  sentimentLabel,
  propagandaLabel,
  opinionLabel,
  revisionLabel,
  ghostEditLabel,
} from "../../news/utils/NoozMetricUtils";

function NtShareController({ story, onCopy }) {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const title = parseTitleFromNewsItem(story);
  const link = `https://nooz.ai/analysis/${Base64.btoa(story.id || story._id)}`;

  const handleClipboardText = (link) => {
    const hoot = parseHootValuesFromNewsItem(story);
    const style = hootStyle(hoot.hoot);

    return `${parseTitleFromNewsItem(story)}\n\n${link}\n\nNOOZ.AI Analysis: ${
      style.label
    } (${hoot.hoot}) ${style.advancedLabel} | ${
      sentimentLabel(hoot.sentiment).label
    } Sentiment Impact(${hoot.sentiment}%) | ${
      propagandaLabel(hoot.propagandaPersentage).label
    } Propaganda Usage(${hoot.propagandaPersentage}%) | ${
      opinionLabel(hoot.opinion).label
    } Opinion Expressed(${hoot.opinion}%) | ${
      revisionLabel(hoot.revision).label
    } Revision Activity(${hoot.revision}) | ${
      ghostEditLabel(hoot.ghostEditsPersentage).label
    } Ghost Edits(${hoot.ghostEditsPersentage}%)`;
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginTop: 5,
        }}
      >
        <NtText style={styles.title}>{title}</NtText>
      </View>
      <View
        style={{
          flexDirection: "row",
          marginTop: 30,
          justifyContent: "center",
        }}
      >
        <div>
          <FacebookShareButton url={link} quote={title}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <div>
            <FacebookShareCount url={link}>
              {(count) => count}
            </FacebookShareCount>
          </div>
        </div>

        <div style={{ marginLeft: 10 }}>
          <FacebookMessengerShareButton
            url={link}
            appId="521270401588372"
            className="Demo__some-network__share-button"
          >
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
        </div>

        <div style={{ marginLeft: 10 }}>
          <TwitterShareButton
            url={link}
            title={title}
            className="Demo__some-network__share-button"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <div className="Demo__some-network__share-count">&nbsp;</div>
        </div>

        <div style={{ marginLeft: 10 }}>
          <WhatsappShareButton
            url={link}
            title={title}
            separator=":: "
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <div className="Demo__some-network__share-count">&nbsp;</div>
        </div>

        <div style={{ marginLeft: 10 }}>
          <LinkedinShareButton
            url={link}
            className="Demo__some-network__share-button"
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>

        <div style={{ marginLeft: 10 }}>
          <EmailShareButton
            url={link}
            subject={title}
            body="body"
            className="Demo__some-network__share-button"
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
      </View>
      <View style={{ marginBottom: 5 }}>
        <NtTouchableEffect
          style={{ flexDirection: "row", alignItems: "center" }}
          onPress={() => {
            const text = handleClipboardText(link);
            navigator.clipboard.writeText(text);
            setCopiedToClipboard(true);

            if (onCopy) {
              setTimeout(() => {
                onCopy(text);
              }, 1500);
            }
          }}
        >
          <NtMaterialIcon
            name={copiedToClipboard ? "check-bold" : "content-copy"}
            size={20}
            color={colors.darkest}
          />
          <NtText style={[styles.copyLink, { marginLeft: 8 }]}>
            {copiedToClipboard ? "Copied to clipboard." : "Copy to clipboard."}
          </NtText>
        </NtTouchableEffect>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: colors.darkest,
    fontSize: 16,
    fontWeight: "600",
  },
  copyLink: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "500",
  },
});

export default NtShareController;
