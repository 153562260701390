import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../../components/NtText";
import { parseHootValuesFromNewsItem } from "../../utils/NewsParserUtils";
import NtQuickAnalysisReversedCard from "./NtQuickAnalysisReversedCard";
import NtQuickAnalysisTitleView from "./NtQuickAnalysisTitleView";
import colors from "../../../config/colors";
import {
  ghostEditIcon,
  revisionIcon,
  ghostEditDescription,
} from "../../utils/NoozMetricUtils";
import NtQuickAnalysisMetricProgressbar from "./NtQuickAnalysisMetricProgressbar";
import NtQuickAnalysisMetricDescription from "./NtQuickAnalysisMetricDescription";
import NtMaterialIcon from "../../../components/NtMaterialIcon";

function NtQuickAnalysisMetricGhostEdit({ story }) {
  const hoot = parseHootValuesFromNewsItem(story);
  const ghostIcon = ghostEditIcon(hoot.ghostEdits);
  const revIcon = revisionIcon(hoot.revision);

  const renderReverseCardContent = (icon, name, value) => {
    return (
      <NtQuickAnalysisReversedCard style={styles.topReverseCard}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <NtMaterialIcon name={icon} color={colors.dark} size={24} />
          <NtText style={[styles.topCardTitle, { marginLeft: 10 }]}>
            {name}
          </NtText>
        </View>
        <NtText style={styles.topCardValue}>{value}</NtText>
      </NtQuickAnalysisReversedCard>
    );
  };
  return (
    <View style={styles.container}>
      <View style={{ flex: 1, flexDirection: "row" }}>
        {renderReverseCardContent(
          ghostIcon.icon,
          "Ghost Edits",
          hoot.ghostEdits
        )}
        <View style={{ width: 20 }} />
        {renderReverseCardContent(revIcon.icon, "Revisions", hoot.revision)}
      </View>

      <NtQuickAnalysisReversedCard
        style={{
          width: "100%",
          marginTop: 20,
        }}
      >
        <NtQuickAnalysisTitleView
          containerStyle={{ marginTop: 8 }}
          title={"Ghost Edits Score & Label"}
          subtitle={"Range & Explanation"}
        />

        <View
          style={{
            paddingLeft: 20,
            paddingRight: 30,
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          <NtQuickAnalysisMetricProgressbar
            icon={ghostEditIcon(0).icon}
            value={hoot.ghostEditsPersentage}
            fillColor={colors.noozscoreGhostEdits}
            labels={[
              { text: "Low", icon: "ghost" },
              { text: "Moderate", icon: "ghost" },
              { text: "High", icon: "ghost" },
              { text: "Extreme", icon: "ghost" },
            ]}
          />
        </View>

        <View style={{ paddingLeft: 25, paddingRight: 25 }}>
          <NtQuickAnalysisMetricDescription
            containerStyle={{ marginTop: 15, marginBottom: 15 }}
            text={ghostEditDescription(hoot.ghostEditsPersentage)
              .description.replace("<value>", hoot.ghostEdits)
              .replace("<revision>", hoot.revision)
              .replace("<persentage>", hoot.ghostEditsPersentage)}
          />
        </View>
      </NtQuickAnalysisReversedCard>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  topReverseCard: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 10,
    paddingRight: 10,
  },
  topCardTitle: {
    fontSize: 14,
    fontWeight: "500",
    color: colors.dark,
  },
  topCardValue: {
    fontSize: 22,
    fontWeight: "500",
    color: colors.noozscoreGhostEdits,
  },
});

export default NtQuickAnalysisMetricGhostEdit;
