import React, { useEffect, useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import {
  parseSourceName,
  parseImageUrlFromNewsItem,
  parseReadTimeFromNewsItem,
  parseAuthorsFromNewsItem,
  parseSourceFromNewsItem,
  parseCategoryFromNewsItem,
  parseFeedFromNewsItem,
  parseReadCountFromNewsItem,
  parseFullTextFromNewsItem,
  parsePersonsFromNewsItem,
} from "../utils/NewsParserUtils";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtViewCount from "./NtViewCount";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtAnalysisTextOnlyReaderFollow from "./NtAnalysisTextOnlyReaderFollow";
import NtListSeparator from "../../components/NtListSeparator";
import { fetchUserSources, saveUserSources } from "../../api/sources";
import { fetchUserCategories, saveUserCategories } from "../../api/categories";
import { fetchUserFeeds } from "../../api/feeds";
import NtImage from "../../components/NtImage";
import NtHootContainer from "./NtHootContainer";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtNewsOptionVisibleButton from "./NtNewsOptionVisibleButton";
import newsoptionsvisible from "../data/newsoptionsvisible";
import Logger from "../../common/utils/Logger";
import NtanalysisTextOnlyReaderPerson from "./NtanalysisTextOnlyReaderPerson";

function NtAnalysisTextOnlyReaderController({
  story,
  onShare,
  onSave,
  onWebView,
  onMoreLikeThis,
  onKeyboard,
}) {
  const [userSources, setUserSources] = useState([]);
  const [userCategories, setUserCategories] = useState([]);
  const [userFeeds, setUserFeeds] = useState([]);

  useEffect(() => {
    handleFetchUserSource();
    handleFetchUserCategories();
    handleFetchUserFeeds();
  }, []);

  const authors = parseAuthorsFromNewsItem(story);
  const imageUrl = parseImageUrlFromNewsItem(story);
  const persons = parsePersonsFromNewsItem(story);

  Logger("Persons found: ", persons);

  const handleFetchUserSource = async () => {
    const response = await fetchUserSources();
    setUserSources(response);
  };

  const handleFetchUserCategories = async () => {
    const response = await fetchUserCategories();
    setUserCategories(response);
  };

  const handleFetchUserFeeds = async () => {
    const response = await fetchUserFeeds();
    setUserFeeds(response);
  };

  const checkIfUserDefault = (list, item) => {
    return list.filter((each) => each.name === item.name).length > 0;
  };

  const renderOptionItem = (item) => {
    return (
      <NtNewsOptionVisibleButton
        key={item.id}
        option={item}
        onPress={(item) => {
          if (item.id === "share") {
            onShare(story);
          } else if (item.id === "morelikethis") {
            onMoreLikeThis(story);
          } else if (item.id === "save") {
            onSave(story);
          } else if (item.id === "web") {
            onWebView(story);
          }
        }}
      />
    );
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          marginLeft: -15,
          marginRight: -15,
          backgroundColor: "white",
          padding: 15,
          marginTop: -30,
        }}
      >
        <NtImage key={imageUrl} style={styles.image} uri={imageUrl} />

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 10,
            paddingLeft: 4,
            paddingRight: 10,
          }}
        >
          {/* read time */}
          <NtViewCount
            icon={"timer-outline"}
            count={`${parseReadTimeFromNewsItem(story)}m read`}
            textColor={colors.dark}
          />

          <NtViewCount
            icon={"eye"}
            count={parseReadCountFromNewsItem(story)}
            textColor={colors.dark}
          />
        </View>

        {/* options */}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            backgroundColor: "#f5f5f5",
            borderRadius: 6,
            paddingTop: 15,
            paddingBottom: 15,
            marginTop: 12,
          }}
        >
          {newsoptionsvisible.map((each) => renderOptionItem(each))}
        </View>

        {authors !== "" && (
          <NtText style={[styles.author, { marginTop: 10 }]}>
            by {authors}
          </NtText>
        )}

        {/* full text... */}
        <View style={{ marginTop: 20 }}>
          <NtText style={styles.sentenceText} selectable={true}>
            {parseFullTextFromNewsItem(story)}
          </NtText>
        </View>

        {/* follow */}
        <View style={{ marginTop: 20 }}>
          <NtListSeparator />
          <NtAnalysisTextOnlyReaderFollow
            type={"Source"}
            name={parseSourceName(parseSourceFromNewsItem(story))}
            selected={checkIfUserDefault(userSources, {
              name: parseSourceFromNewsItem(story),
            })}
            onPress={() => {
              let newSelection = userSources;
              let item = { name: parseSourceFromNewsItem(story) };
              if (checkIfUserDefault(userSources, item)) {
                newSelection = newSelection.filter(
                  (each) => each.name !== item.name
                );
              } else {
                newSelection = [...newSelection, { name: item.name }];
              }
              setUserSources(newSelection);
              saveUserSources(newSelection);
            }}
          />
          <NtAnalysisTextOnlyReaderFollow
            type={"Category"}
            name={parseCategoryFromNewsItem(story)}
            selected={checkIfUserDefault(userCategories, {
              name: parseCategoryFromNewsItem(story),
            })}
            onPress={() => {
              let newSelection = userCategories;
              let item = { name: parseCategoryFromNewsItem(story) };
              if (checkIfUserDefault(userCategories, item)) {
                newSelection = newSelection.filter(
                  (each) => each.name !== item.name
                );
              } else {
                newSelection = [...newSelection, { name: item.name }];
              }
              setUserCategories(newSelection);
              saveUserCategories(newSelection);
            }}
          />
          <NtAnalysisTextOnlyReaderFollow
            type={"Feed"}
            name={parseFeedFromNewsItem(story)}
            selected={checkIfUserDefault(userFeeds, {
              name: parseFeedFromNewsItem(story),
            })}
            onPress={() => {
              let newSelection = userFeeds;
              let item = { name: parseFeedFromNewsItem(story) };
              if (checkIfUserDefault(userFeeds, item)) {
                newSelection = newSelection.filter(
                  (each) => each.name !== item.name
                );
              } else {
                newSelection = [...newSelection, { name: item.name }];
              }
              setUserFeeds(newSelection);
              saveUserCategories(newSelection);
            }}
          />
        </View>

        {/* persons */}
        {persons && persons.length > 0 && (
          <View style={{ marginTop: 25 }}>
            <NtText style={styles.peopleTitle}>Keywords</NtText>
            <NtText style={[styles.peopleMessage, { marginTop: 4 }]}>
              Find related stories using a keyword from this article.
            </NtText>
            <NtText style={{ marginTop: 15 }}>
              {persons.map((each, index) => (
                <NtanalysisTextOnlyReaderPerson
                  onPress={(item) => {
                    if (onKeyboard) {
                      onKeyboard(item);
                    }
                  }}
                  key={index}
                  item={each}
                />
              ))}
            </NtText>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  source: {
    fontSize: 15,
    fontWeight: "500",
    color: colors.darker,
  },
  title: {
    fontSize: 22,
    fontWeight: "500",
    color: colors.darkest,
  },
  author: {
    fontSize: 15,
    fontWeight: "400",
    color: colors.dark,
  },
  publishDate: {
    fontSize: 14,
    fontWeight: "300",
    color: colors.dark,
  },
  image: {
    width: "100%",
    height: 300,
    resizeMode: "cover",
    marginTop: 15,
  },
  revisionText: {},
  sentenceText: {
    fontSize: 15,
    color: colors.darkest,
    fontWeight: "400",
  },
  optionText: {
    fontSize: 15,
    color: "#a8a8a8",
    fontWeight: "600",
  },
  statsContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 4,
    justifyContent: "space-around",
  },
  peopleTitle: {
    color: colors.darkest,
    fontWeight: "600",
  },
  peopleMessage: {
    fontSize: 12,
    color: colors.darker,
    fontWeight: "400",
  },
});

export default NtAnalysisTextOnlyReaderController;
