import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../config/colors";

function NtProgressBar({ progress, color = colors.darker, containerStyle }) {
  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={{
          flex: 1,
          width: `${progress}%`,
          backgroundColor: color,
          borderRadius: 3,
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 15,
    width: "100%",
  },
});

export default NtProgressBar;
