import client from "./client";
import settings from "../config/settings";

const endpoint = "/login_post";

export const authenticateUser = (username, password) => {
  const body = {
    apiKey: settings.apiKey,
    username: username,
    password: password,
  };
  return client.post(endpoint, body);
};
