import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";

function NtHootTMLabel({
  containerStyle,
  size = 9,
  firstWord = "NOOZ",
  secondWord = "SCORE",
}) {
  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={[styles.text, { fontSize: size }]}>
        {firstWord}
        <NtText style={{ color: "#909090" }}>{secondWord}</NtText>
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    color: "#0256a3",
    fontWeight: "600",
    fontSize: 9,
  },
});

export default NtHootTMLabel;
