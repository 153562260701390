import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../../components/NtText";
import colors from "../../../config/colors";
import NtTouchableEffect from "../../../components/NtTouchableEffect";
import {
  parseImageUrlFromNewsItem,
  parseSourceFromNewsItem,
  parseTitleFromNewsItem,
  parseHootValuesFromNewsItem,
} from "../../utils/NewsParserUtils";
import NtHootCircularProgress from "../NtHootCircularProgress";
import NtCard from "../../../components/NtCard";
import NtMaterialIcon from "../../../components/NtMaterialIcon";
import NtHootContainer from "../NtHootContainer";

function NtTrendingRowArticleItem({ selected = false, story, onPress }) {
  const imageUrl = parseImageUrlFromNewsItem(story);
  const hoot = parseHootValuesFromNewsItem(story);

  return (
    <View style={{ alignItems: "center" }}>
      <View
        style={{ flexDirection: "row", alignItems: "center", marginBottom: 4 }}
      >
        <NtHootCircularProgress score={hoot.hoot} type={"default"} />
        <NtText
          style={[
            styles.text,
            { marginLeft: 4 },
            selected ? { color: "white" } : {},
          ]}
          numberOfLines={1}
        >
          {story.source}
        </NtText>
      </View>

      <NtCard
        onPress={() => {
          if (onPress) {
            onPress(story);
          }
        }}
        style={[
          styles.container,
          selected ? { backgroundColor: colors.darker } : {},
        ]}
      >
        {imageUrl && (
          <Image
            style={[styles.image]}
            source={{
              uri: imageUrl,
            }}
          />
        )}
        {!imageUrl && (
          <Image
            style={[styles.image]}
            source={{
              uri: require("../../../assets/images/news-image-placeholder-150x80.jpg"),
            }}
          />
        )}

        <View style={{ flex: 1, padding: 8 }}>
          <NtText
            style={[styles.text, selected ? { color: "white" } : {}]}
            numberOfLines={3}
          >
            {story.headline?.trim()}
          </NtText>
        </View>
      </NtCard>
      <NtMaterialIcon
        style={{ marginTop: -5 }}
        name="menu-down"
        color={selected ? colors.darker : "transparent"}
        size={22}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 120,
    marginTop: 4,
    marginBottom: 4,
    padding: 0,
  },
  image: {
    width: "100%",
    height: 80,
    resizeMode: "conver",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  text: {
    fontSize: 13,
    fontWeight: "700",
    color: colors.darker,
  },
  sourceTitle: {
    fontSize: 11,
    fontWeight: "500",
    color: colors.darker,
  },
});

export default NtTrendingRowArticleItem;
