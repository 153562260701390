import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtCard from "../../components/NtCard";
import colors from "../../config/colors";
import NtHootTMLabel from "./NtHootTMLabel";

function NtNoozFormulaInfoCard({ containerStyle }) {
  const renderRowText = (text, style, color) => {
    return (
      <NtText style={[styles.rowText, { fontSize: 16, color: color }, style]}>
        {text.substring(0, 1)}
        <NtText style={[styles.rowText, { fontSize: 13, color: color }]}>
          {text.substring(1)}
        </NtText>
      </NtText>
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={{ flexDirection: "row" }}>
        <NtHootTMLabel size={15} />
        <View style={{ flex: 1 }} />
        <NtText style={styles.title}>Factors</NtText>
      </View>

      <View
        style={{ flexDirection: "row", alignItems: "flex-end", marginTop: 15 }}
      >
        {renderRowText(
          "Sentiment Analysis",
          { flex: 1 },
          colors.noozscoreSentiment
        )}
        <NtText
          style={[
            styles.rowText,
            { textAlign: "right", color: colors.noozscoreSentiment },
          ]}
        >
          26
        </NtText>
      </View>

      <View
        style={{ flexDirection: "row", alignItems: "flex-end", marginTop: 10 }}
      >
        {renderRowText(
          "Propaganda Analysis",
          { flex: 1 },
          colors.noozscorePropaganda
        )}
        <NtText
          style={[
            styles.rowText,
            { textAlign: "right", color: colors.noozscorePropaganda },
          ]}
        >
          51
        </NtText>
      </View>

      <View
        style={{ flexDirection: "row", alignItems: "flex-end", marginTop: 10 }}
      >
        {renderRowText(
          "Opinion Analysis",
          { flex: 1 },
          colors.noozscoreOpinion
        )}
        <NtText
          style={[
            styles.rowText,
            { textAlign: "right", color: colors.noozscoreOpinion },
          ]}
        >
          26
        </NtText>
      </View>

      <View
        style={{ flexDirection: "row", alignItems: "flex-end", marginTop: 10 }}
      >
        {renderRowText(
          "Revision Analysis",
          { flex: 1 },
          colors.noozscoreRevision
        )}
        <NtText
          style={[
            styles.rowText,
            { textAlign: "right", color: colors.noozscoreRevision },
          ]}
        >
          13
        </NtText>
      </View>

      <View
        style={{ flexDirection: "row", alignItems: "flex-end", marginTop: 10 }}
      >
        {renderRowText("Ghost Edits", { flex: 1 }, colors.noozscoreGhostEdits)}
        <NtText
          style={[
            styles.rowText,
            { textAlign: "right", color: colors.noozscoreGhostEdits },
          ]}
        >
          13
        </NtText>
      </View>

      <NtText style={[styles.description, { marginTop: 15 }]}>
        Note: Individual NOOZ Metric scores are used to calculate a percentage
        of each Factor to determine the final NOOZ SCORE
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 14,
    paddingBottom: 18,
    backgroundColor: "#F5F5F5",
  },
  title: {
    fontSize: 14,
    color: colors.blue,
    fontWeight: "600",
  },
  rowText: {
    fontSize: 14,
    color: colors.blue,
    fontWeight: "600",
  },
  description: {
    flex: 1,
    fontSize: 11,
    fontWeight: "400",
    color: colors.darkest,
    lineHeight: 15,
  },
});

export default NtNoozFormulaInfoCard;
