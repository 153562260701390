import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import { parseHootValuesFromNewsItem } from "../utils/NewsParserUtils";
import { hootStyle } from "../utils/HootParserUtils";
import NtHootTMLabel from "./NtHootTMLabel";
import NtHootProgress from "./NtHootProgress";
import NtHootCircularProgress from "./NtHootCircularProgress";
import NtTouchableEffect from "../../components/NtTouchableEffect";

function NtHootContainer({ story, type = "default", containerStyle, onPress }) {
  const hoot = parseHootValuesFromNewsItem(story);
  const style = hootStyle(hoot.hoot);

  const renderGraph = () => {
    return (
      <View style={{ flexDirection: "row" }}>
        <NtHootProgress
          progress={Math.abs(hoot.sentiment)}
          fillColor={"#636f9b"}
          letter="S"
        />

        <NtHootProgress
          containerStyle={{ marginLeft: 2 }}
          progress={hoot.propagandaPersentage}
          fillColor={"#8792be"}
          letter="P"
        />

        <NtHootProgress
          containerStyle={{ marginLeft: 2 }}
          progress={hoot.opinion}
          fillColor={"#1e3f93"}
          letter="O"
        />

        <NtHootProgress
          containerStyle={{ marginLeft: 2 }}
          progress={hoot.revision}
          fillColor={"#3e4665"}
          letter="R"
        />

        <NtHootProgress
          containerStyle={{ marginLeft: 2 }}
          progress={hoot.ghostEditsPersentage}
          fillColor={"#B47070"}
          letter="G"
        />
      </View>
    );
  };

  const renderDefault = () => {
    if (type !== "default") {
      return null;
    }

    return (
      <View style={styles.normalContainer}>
        <NtHootCircularProgress score={hoot.hoot} />
        <View style={{ marginLeft: 6 }}>
          <NtHootTMLabel />
          <NtText
            style={{
              marginTop: 4,
              color: style.color,
              fontSize: 13,
              fontWeight: "600",
            }}
          >
            {hoot.hootObject.label}
          </NtText>
        </View>
      </View>
    );
  };

  const renderMetrics = () => {
    if (type !== "metrics") {
      return null;
    }

    return (
      <View style={styles.normalContainer}>
        {renderGraph()}
        <View style={{ width: 8 }} />
        <NtHootCircularProgress score={hoot.hoot} />

        <View style={{ marginLeft: 6 }}>
          <NtHootTMLabel />
          <NtText
            style={{
              marginTop: 4,
              color: style.color,
              fontSize: 13,
              fontWeight: "600",
            }}
          >
            {hoot.hootObject.label}
          </NtText>
        </View>
      </View>
    );
  };

  const renderAdvanced = () => {
    if (type !== "advanced") {
      return null;
    }

    return (
      <View style={styles.advancedContainer}>
        {renderGraph()}

        <Image style={[styles.image, { marginLeft: 8 }]} source={style.image} />
        <View style={{ marginLeft: 8, justifyContent: "center" }}>
          <NtText style={[styles.advancedText]}>{hoot.hoot}</NtText>
          <NtText style={[styles.advancedText, { fontWeight: "500" }]}>
            {hoot.hootObject.advanced_label}
          </NtText>
        </View>
      </View>
    );
  };

  const renderReaction = () => {
    if (type !== "reaction") {
      return null;
    }

    return (
      <View style={styles.advancedContainer}>
        <Image style={[styles.image]} source={style.image} />
        <View style={{ marginLeft: 8, justifyContent: "center" }}>
          <NtText style={[styles.advancedText]}>{hoot.hoot}</NtText>
          <NtText style={[styles.advancedText, { fontWeight: "500" }]}>
            {hoot.hootObject.advanced_label}
          </NtText>
        </View>
      </View>
    );
  };

  return (
    <NtTouchableEffect
      onPress={onPress}
      style={[styles.container, containerStyle]}
    >
      {renderDefault()}
      {renderMetrics()}
      {renderAdvanced()}
      {renderReaction()}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  normalContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 5,
  },
  advancedContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 5,
  },
  image: {
    width: 35,
    height: 35,
    resizeMode: "contain",
  },
  advancedText: {
    fontSize: 14,
    color: colors.blue,
    fontWeight: "800",
  },
});

export default NtHootContainer;
