import React from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import NtAnalysisTitleView from "./NtAnalysisTitleView";
import { parseSentimentFromNewsItem } from "../utils/NewsParserUtils";
import colors from "../../config/colors";
import NtQuickAnalysisMetricContainer from "./quickanalysis/NtQuickAnalysisMetricContainer";
import NtQuickAnalysisMetricSentiment from "./quickanalysis/NtQuickAnalysisMetricSentiment";
import { sentimentIcon, sentimentLabel } from "../utils/NoozMetricUtils";

function NtAnalysisSentimentController2({ story }) {
  const sentiment = parseSentimentFromNewsItem(story);
  const stmtLabel = sentimentLabel(sentiment);
  const stmtIcon = sentimentIcon(sentiment);

  return (
    <View style={styles.container}>
      <NtAnalysisTitleView
        icon={"emoticon-happy"}
        title={"Sentiment Analysis"}
        summary={
          "Analyze how much the author's sentiment changes throughout the article from start to finish."
        }
      />

      {/* Sentiment */}
      <NtQuickAnalysisMetricContainer
        containerStyle={{ marginTop: 20 }}
        expanded={true}
        metric={"S"}
        value={sentiment}
        icon={stmtIcon.icon}
        unit={"%"}
        title={stmtLabel.label}
        subtitle={"Sentiment Inpact"}
        color={colors.noozscoreSentiment}
        renderBody={<NtQuickAnalysisMetricSentiment story={story} />}
        showChevron={false}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginLeft: 15,
    marginRight: 15,
  },
  sentimentScoreTitle: {
    color: colors.darkest,
    fontSize: 19,
    fontWeight: "600",
  },
  sentimentScoreSubtitle: {
    color: colors.darker,
    fontSize: 17,
    fontWeight: "500",
  },
});

export default NtAnalysisSentimentController2;
