import React from "react";
import { View, StyleSheet } from "react-native";
import NtNewsPageContainer from "../components/NtNewsPageContainer";
import settings from "../../config/settings";
import BaseNewsController from "./BaseNewsController";
import {
  fetchAuthors,
  fetchUserAuthors,
  saveUserAuthors,
} from "../../api/authors";
import Logger from "../../common/utils/Logger";
import NtNewsLoadMoreButton from "../components/NtNewsLoadMoreButton";
import NtNewsSubcategoryRow from "../components/NtNewsSubcategoryRow";
import FilterNewsController from "./FilterNewsController";
import routes from "../../navigation/routes";
import rankbyoptions from "../data/rankbyoptions";
import NtText from "../../components/NtText";
import colors from "../../config/colors";

class TopAuthorsController extends BaseNewsController {
  state = {
    ...this.getParentState(),
    rankBy: rankbyoptions[2],
    refreshing: false,
    authors: [],
    userDefualts: [],
  };

  loadSkip = 0;

  componentDidMount() {
    super.componentDidMount();
    this.handleUserDefaultsFetch();
    this.handleAuthorsFetch(settings.pageSizeLoad, this.loadSkip, false);
  }

  handleUserDefaultsFetch = async () => {
    const response = await fetchUserAuthors();
    this.setState({ userDefualts: response });
  };

  handleAuthorsFetch = async (
    limit,
    skip,
    loadingMore,
    sortColumn = this.state.rankBy.sortColumn,
    sortOrder = this.state.rankBy.sortOrder,
    hours = this.state.rankWithin.hours,
    search = this.state.searchText
  ) => {
    Logger(
      "Will fetch the Categories....",
      limit,
      skip,
      loadingMore,
      sortColumn,
      sortOrder,
      hours,
      search
    );
    this.setState({ refreshing: true });
    const response = await fetchAuthors(
      limit,
      skip,
      search ? { [FilterNewsController.sFILTER_AUTHOR]: [search] } : {},
      sortColumn,
      sortOrder,
      hours
    );
    if (response.ok) {
      // Logger('Finished fetching the Authors: ', response.data);
      if (loadingMore) {
        Logger("We are loading more... need to add to the state");
        this.setState({
          authors: this.state.authors.concat(response.data),
        });
      } else {
        Logger(
          "First time loading.... we need to set the state to first ",
          response.data
        );
        this.setState({ authors: response.data });
      }
    }

    this.setState({ refreshing: false });
  };

  handleRankChange = (rankBy, rankWithin) => {
    this.loadSkip = 0;
    this.handleAuthorsFetch(
      settings.pageSizeLoad,
      this.loadSkip,
      false,
      rankBy.sortColumn,
      rankBy.sortOrder,
      rankWithin.hours
    );
  };

  handleSearch = (text) => {
    Logger("Handling search for text: ", text);
    this.setState({ searchText: text });

    setTimeout(() => {
      this.loadSkip = 0; //will start from the begining.....
      this.handleAuthorsFetch(settings.pageSizeLoad, this.loadSkip, false);
    }, 100);
  };

  checkIfUserDefault = (item) => {
    return (
      this.state.userDefualts.filter((each) => each.name === item.name).length >
      0
    );
  };

  renderRow = (item, index) => {
    return (
      <NtNewsSubcategoryRow
        key={index}
        name={item.name}
        metric={item.read_count}
        selected={this.checkIfUserDefault(item)}
        onSelection={() => {
          if (this.checkIfUserDefault(item)) {
            this.state.userDefualts = this.state.userDefualts.filter(
              (each) => each.name !== item.name
            );
          } else {
            this.state.userDefualts = [
              ...this.state.userDefualts,
              { name: item.name },
            ];
          }
          this.setState({ userDefaults: this.state.userDefualts });
          saveUserAuthors(this.state.userDefualts);
        }}
        onPress={() => {
          this.props.navigation.navigate(routes.filter, {
            type: FilterNewsController.sFILTER_AUTHOR,
            name: item.name,
          });
        }}
      />
    );
  };

  renderMessage = () => {
    return (
      <View style={{ padding: 20 }}>
        <NtText style={[styles.message, { fontWeight: "700" }]}>
          Note:{" "}
          <NtText style={[styles.message]}>
            Due to the method used by some media sources, a statement or
            sentence may appear in place of the author's name. NOOZ AI will not
            modify, edit or alter in any way, a published article.{" "}
          </NtText>
        </NtText>
      </View>
    );
  };

  render() {
    return (
      <NtNewsPageContainer
        pageTitle="Authors"
        showFooter={true}
        renderFilter={this.renderFilterController()}
      >
        <View>
          {this.renderMessage()}

          {this.state.authors.map((each, index) => this.renderRow(each, index))}

          {this.state.authors.length > 0 && (
            <View>
              <NtNewsLoadMoreButton
                title={"Load More Authors"}
                loadingText={"Loading"}
                loading={this.state.refreshing}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.loadSkip += settings.pageSizeLoad;
                  this.handleAuthorsFetch(
                    settings.pageSizeLoad,
                    this.loadSkip,
                    true
                  );
                }}
              />
            </View>
          )}
        </View>

        {this.renderRankByModal()}
        {this.renderRankWithinModal()}
      </NtNewsPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  message: {
    color: colors.darkest,
    fontWeight: "400",
  },
});

export default TopAuthorsController;
