const newsoptionsvisible = [
  {
    id: 'morelikethis',
    title: 'More Like This',
    icon: 'content-duplicate',
  },

  {
    id: 'share',
    title: 'Share',
    icon: 'share-variant',
  },
  {
    id: 'save',
    title: 'Save',
    //icon: 'content-save-outline',
    icon: 'playlist-plus',
  },
  {
    id: 'web',
    title: 'Visit Source',
    icon: 'open-in-app',
  },
];

export default newsoptionsvisible;
