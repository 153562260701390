import React, { useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import NtCard from "../../components/NtCard";
import NtHootTMLabel from "./NtHootTMLabel";
import { hootStyle } from "../utils/HootParserUtils";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtText from "../../components/NtText";
import NtHootCircularProgress from "./NtHootCircularProgress";

function NtNoozScoreInfoCard({ containerStyle }) {
  const [selection, setSelection] = useState(25);
  const selectionStyle = hootStyle(selection);

  const range = (score) => {
    let range = "76 to 100";

    if (score <= 25) {
      range = "0 to 25";
    } else if (score <= 50) {
      range = "26 to 50";
    } else if (score <= 75) {
      range = "51 to 75";
    }
    return range;
  };

  const renderButton = (score) => {
    const style = hootStyle(score);
    return (
      <View
        style={{
          alignItems: "center",
        }}
      >
        <NtTouchableEffect
          style={[
            styles.buttonContainer,
            { borderColor: selection === score ? colors.blue : "transparent" },
          ]}
          onPress={() => {
            setSelection(score);
          }}
        >
          <Image style={[styles.image]} source={style.image} />
        </NtTouchableEffect>
        <View
          style={{
            height: 5,
            width: 50,
            backgroundColor: style.color,
          }}
        />
      </View>
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtHootTMLabel size={15} />
        <View style={{ flex: 1 }} />
        <NtText style={[styles.rangeText]}>{range(selection)}</NtText>
      </View>

      <View style={{ flexDirection: "row", marginTop: 10, flex: 1 }}>
        <View>{renderButton(25)}</View>
        <View>{renderButton(50)}</View>
        <View>{renderButton(75)}</View>
        <View>{renderButton(100)}</View>
      </View>

      <View
        style={{
          flexDirection: "row",
          marginTop: 6,
          justifyContent: "space-between",
          marginRight: -8,
        }}
      >
        <NtText style={styles.bucketText}>0</NtText>
        <NtText style={styles.bucketText}>25</NtText>
        <NtText style={styles.bucketText}>50</NtText>
        <NtText style={styles.bucketText}>75</NtText>
        <NtText style={styles.bucketText}>100</NtText>
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: 12,
          paddingBottom: 10,
        }}
      >
        <View style={{}}>
          <NtText style={[styles.scoreText]}>
            {selectionStyle.advancedLabel}
          </NtText>
          <NtText
            style={[
              styles.scoreText,
              { marginTop: 7, fontSize: 13, color: selectionStyle.color },
            ]}
          >
            {selectionStyle.label}
          </NtText>
        </View>
        <NtHootCircularProgress size={30} score={selection} showScore={false} />
      </View>

      <NtText style={[styles.description]}>{selectionStyle.description}</NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 14,
    paddingBottom: 18,
    backgroundColor: "#F5F5F5",
  },
  image: {
    width: 32,
    height: 32,
    resizeMode: "contain",
  },
  buttonContainer: {
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 4,
    paddingLeft: 4,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderWidth: 1,
    borderColor: colors.blue,
    alignItems: "center",
    justifyContent: "center",
  },
  bucketText: {
    fontSize: 11,
    color: colors.dark,
    fontWeight: "600",
  },
  rangeText: {
    fontSize: 14,
    color: colors.darkest,
    fontWeight: "700",
  },
  scoreText: {
    fontSize: 15,
    color: colors.blue,
    fontWeight: "600",
  },
  description: {
    flex: 1,
    fontSize: 11,
    fontWeight: "400",
    color: colors.darkest,
    lineHeight: 15,
  },
});

export default NtNoozScoreInfoCard;
