import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtAnalysisColoredReaderColorGuide(props) {
  const renderOpinionColors = (label, color) => {
    return (
      <View
        style={{
          height: 30,
          backgroundColor: color,
          marginLeft: 15,
          marginRight: 15,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NtText style={styles.opinionLabel}>{label}</NtText>
      </View>
    );
  };

  const renderSentimentLabels = (label, color) => {
    return (
      <View
        style={{
          height: 30,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: colors.darkest,
          marginRight: 15,
          marginLeft: 15,
        }}
      >
        <NtText style={[styles.sentimentLabel, { color: color }]}>
          {label}
        </NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {/* title */}
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtMaterialIcon
          name={"palette-advanced"}
          size={24}
          color={colors.blue}
        />
        <NtText style={styles.title}>Color Guide</NtText>
      </View>
      <NtText style={styles.description}>
        The Colored Reader highlights each sentence of the news article. The
        background color represents the opinion score and the text color
        represents the sentiment score.
      </NtText>

      <View style={{ flexDirection: "row", marginTop: 15 }}>
        <View style={{ flex: 1, height: 45 }}>
          <NtText style={styles.colorTitle}>
            Text Color:{"\n"}Sentiment Label
          </NtText>
          <View style={{ height: 15 }} />
          {renderSentimentLabels("Very Positive", "#61C035")}
          {renderSentimentLabels("Positive", "#C3E98D")}
          {renderSentimentLabels("Neutral", "#BDC5E3")}
          {renderSentimentLabels("Negative", "#ECC0BD")}
          {renderSentimentLabels("Very Negative", "#EC6D66")}
        </View>
        <View style={{ width: 1, backgroundColor: colors.lighter }} />
        <View style={{ flex: 1 }}>
          <NtText style={styles.colorTitle}>
            Background Color:{"\n"}Opinion Label
          </NtText>
          <View style={{ height: 15 }} />
          {renderOpinionColors("Extreme", "#0C107A")}
          {renderOpinionColors("High", "#0B368A")}
          {renderOpinionColors("Partial", "#0E5486")}
          {renderOpinionColors("Slight", "#1A6B7C")}
          {renderOpinionColors("Neutral", "#0C615C")}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  title: {
    color: colors.blue,
    fontSize: 18,
    fontWeight: "600",
    marginLeft: 6,
  },
  description: {
    color: colors.darkest,
    fontSize: 13,
    fontWeight: "300",
    marginTop: 15,
  },
  colorTitle: {
    color: colors.darkest,
    textAlign: "center",
  },
  opinionLabel: {
    color: "white",
    fontWeight: "500",
  },

  sentimentLabel: {
    fontWeight: "400",
  },
});

export default NtAnalysisColoredReaderColorGuide;
