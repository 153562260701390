import colors from "../config/colors";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightest,
    padding: 10,
  },
});
