export const linking = {
  config: {
    screens: {
      auth: "/auth",
      marketing: "/app",
      news: "/news",
      mynews: "/mynews",
      trending: "/trending",
      morelikethis: "/morelikethis/:token",
      quickanalysis: "/quickanalysis/:id",
      analysis: "/analysis/:token",
      privacy: "/privacy",
      terms: "/terms",
      cookies: "/cookies",
      contestrules: "/contest-rules",
      insidercontest: "/insider-contest",
      deeplink: {
        path: "/share",
        parse: {
          token: (token) => `${token}`,
        },
      },
      filter: "/filter/:type/:name",
      settings: "/settings",
      development: "/development",
      developmentjson: "/developmentjson",
      sources: "/sources",
      categories: "/categories",
      feeds: "/feeds",
      authors: "/authors",
      keyword: "/keyword/:name",
      languageanalysis: "/languageanalysis",
      notfound: "*",
    },
  },
};

const routes = {
  marketing: "marketing",
  auth: "auth",
  news: "news",
  mynews: "mynews",
  trending: "trending",
  morelikethis: "morelikethis",
  quickanalysis: "quickanalysis",
  analysis: "analysis",
  privacy: "privacy",
  terms: "terms",
  cookies: "cookies",
  contestrules: "contestrules",
  insidercontest: "insidercontest",
  deeplink: "deeplink",
  filter: "filter",
  settings: "settings",
  development: "development",
  developmentjson: "developmentjson",
  sources: "sources",
  categories: "categories",
  feeds: "feeds",
  authors: "authors",
  keyword: "keyword",
  languageanalysis: "languageanalysis",
  notFound: "notfound",
};

export default routes;
