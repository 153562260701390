import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtFeatureItem({ icon, title, description, containerStyle }) {
  return (
    <View style={[styles.container, containerStyle]}>
      <NtMaterialIcon name={icon} size={30} color={"#57D1EF"} />
      <NtText style={styles.title}>{title}</NtText>
      <NtText style={styles.description}>{description}</NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    padding: 20,
  },
  title: {
    color: "white",
    fontSize: 15,
    fontWeight: 700,
    marginTop: 6,
  },
  description: {
    color: colors.teal,
    fontSize: 14,
    marginTop: 8,
    textAlign: "center",
  },
});

export default NtFeatureItem;
