import React, { useContext, useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtNavigationButton from "./NtNavigationButton";
import UserContext from "../../context/UserContext";
import { useMobile } from "../../hooks/useMobile";
import { useNavigation } from "@react-navigation/native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import routes from "../../navigation/routes";

function NavigationBar({ navItems, currentController, onNavPress }) {
  const userContext = useContext(UserContext);
  const [selection, setSelection] = useState(navItems[0].route);
  const nav = useNavigation();
  const isMobile = useMobile();

  const renderNavItem = (item) => {
    return (
      <View key={item.name} style={{ flexDirection: "row", marginRight: 30 }}>
        <NtNavigationButton
          isMobile={isMobile}
          title={item.name}
          icon={item.icon}
          selected={currentController === item.route}
          onPress={() => {
            nav.navigate(item.route);
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtTouchableEffect
        onPress={() => {
          nav.navigate(routes.news);
        }}
      >
        <Image
          style={styles.image}
          source={require("../../assets/images/nooz-web-logo-520x155.png")}
        />
      </NtTouchableEffect>

      <View style={{ flexDirection: "row" }}>
        {navItems && navItems.map((each) => renderNavItem(each))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: colors.darkest,
    elevation: 4,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 20,
  },
  image: {
    height: 40,
    width: 150,
    resizeMode: "contain",
  },
});

export default NavigationBar;
