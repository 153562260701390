import React from 'react';
import {View, StyleSheet} from 'react-native';
import NtText from '../../../components/NtText';
import colors from '../../../config/colors';

function NtQuickAnalysisBallun({value, containerStyle}) {
  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={styles.text}>{value}</NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 60,
    height: 60,

    backgroundColor: colors.noozscoreSentiment,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    borderBottomLeftRadius: 30,

    transform: [{rotate: '45deg'}],
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    transform: [{rotate: '-45deg'}],
    color: 'white',
    fontWeight: '500',
    fontSize: 16,
  },
});

export default NtQuickAnalysisBallun;
