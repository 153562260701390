import React, { useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { useMobile } from "../../hooks/useMobile";
import defaultStyle from "../styles";
import NtFooterBar from "../../root/components/NtFooterBar";
import Lottie from "lottie-react";
import NtNewsPageNavigation from "./NtNewsPageNavigation";
import NtNewsPageContainerHeader from "./NtNewsPageContainerHeader";
import colors from "../../config/colors";
import NtNewsPageInfoPannel from "./NtNewsPageInfoPannel";

function NtNewsPageContainer({
  children,
  pageTitle,
  pageSubtitle,
  renderTitleView,
  renderFilter,
  showFooter = false,
  showNavigation = true,
  showSidePannel = false,
  sidePannelContainerStyle = 0,
  otherNvigationOptions = null,
  scrollEnabled = true,
  isLoading,
  scrollViewRef,
  containerStyle,
}) {
  const [navigationWidth, setNavigationWidth] = useState(0);
  const isMobile = useMobile();
  const maxWidth = 850;
  const sidePannelWidth = 245;

  const renderLottieLoading = () => {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Lottie
          style={{ width: 250, heigth: 250 }}
          animationData={require("../../lottie/lottie_news_load_animation.json")}
          loop={true}
        />
      </View>
    );
  };

  return (
    <View style={[defaultStyle.container, styles.container]}>
      <ScrollView
        ref={scrollViewRef}
        scrollEnabled={scrollEnabled}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <View
          style={{ flex: 1, justifyContent: "center", flexDirection: "row" }}
        >
          <View style={{ width: navigationWidth }} />
          <View
            style={[
              {
                padding: isMobile ? 15 : 20,
                flex: 1,
                maxWidth: maxWidth,
              },
              containerStyle,
            ]}
          >
            {isMobile && showNavigation && (
              <NtNewsPageNavigation
                onLayoutChange={(size) => {
                  setNavigationWidth(0);
                }}
                containerStyle={{
                  paddingLeft: 25,
                  paddingRight: 25,
                  marginTop: 5,
                  marginBottom: 20,
                }}
                otherOptions={otherNvigationOptions}
              />
            )}
            {renderFilter}
            {!renderTitleView && (
              <NtNewsPageContainerHeader
                containerStyle={{ marginTop: renderFilter ? 30 : 0 }}
                title={pageTitle}
                subtitle={pageSubtitle}
                titleSize={isMobile ? 28 : 42}
                subtitleSize={isMobile ? 18 : 18}
              />
            )}
            {renderTitleView && renderTitleView}
            {/* will render the line before.... */}
            {!renderTitleView && (
              <View
                style={{
                  width: "100%",
                  height: 1,
                  marginTop: 15,
                  backgroundColor: colors.teal,
                }}
              />
            )}
            {isLoading && renderLottieLoading()}
            {!isLoading && children}
          </View>
          {!isMobile && showSidePannel && (
            <View
              style={[
                sidePannelContainerStyle,
                {
                  width: sidePannelWidth,
                  paddingRight: 10,
                  paddingLeft: 10,
                },
              ]}
            >
              <NtNewsPageInfoPannel />
            </View>
          )}
        </View>

        {showFooter && <NtFooterBar />}
      </ScrollView>

      {/* Needed to add this absolute view in order to mimic what it is above in the actual view so the sizes can be good */}
      {showNavigation && !isMobile && (
        <View
          style={{
            width: "100%",
            height: 1,
            position: "absolute",
            flexDirection: "row",
            justifyContent: "center",
            paddingLeft: 10,
          }}
        >
          <NtNewsPageNavigation
            onLayoutChange={(size) => {
              setNavigationWidth(size.width);
            }}
            containerStyle={{ marginTop: 30, marginRight: 20 }}
            otherOptions={otherNvigationOptions}
          />
          <View
            style={{
              flex: 1,
              width: "100%",
              maxWidth: maxWidth,
            }}
          />
          {!isMobile && showSidePannel && (
            <View style={{ width: sidePannelWidth }} />
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 0,
    backgroundColor: "white",
  },
});

export default NtNewsPageContainer;
