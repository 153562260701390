import React from "react";
import { View, StyleSheet } from "react-native";
import parse from "html-react-parser";
import NtFooterBar from "../../root/components/NtFooterBar";

class PrivacyController extends React.Component {
  state = {};

  render() {
    const privacy = require("../data/privacy.html");
    const css = require("../data/css.html");

    return (
      <View style={styles.container}>
        <View style={{  }}>
          <main>
            <div class="container">
              {parse(css)} 
              {/* {parse(nav)}         */}
              {parse(privacy)}
            </div>
          </main>           
        </View>

        <NtFooterBar />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default PrivacyController;
