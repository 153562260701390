import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../../config/colors";
import parse from "html-react-parser";

function NtQuickAnalysisMetricDescription({ text, containerStyle }) {
  const css = require("../../data/propagandacss.html");

  return (
    <View style={[styles.container, containerStyle]}>
      <div>
        {parse(css)}
        {parse(text)}
      </div>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  p: {
    color: colors.darker,
    fontSize: 13,
    fontWeight: "400",
  },
  bold: {
    color: colors.darkest,
    fontWeight: "700",
  },
});

export default NtQuickAnalysisMetricDescription;
