import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtAnalysisTitleView({ icon, title, summary, containerStyle }) {
  return (
    <View style={[styles.container, containerStyle]}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtMaterialIcon name={icon} size={30} color={colors.darkest} />
        <NtText style={styles.titleText}>{title}</NtText>
      </View>
      <NtText style={styles.titleSummary}>{summary}</NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  titleText: {
    fontSize: 25,
    color: colors.darkest,
    marginLeft: 4,
    fontWeight: "600",
  },
  titleSummary: {
    fontSize: 18,
    color: colors.darkest,
    marginTop: 4,
    fontWeight: "200",
  },
});

export default NtAnalysisTitleView;
