import React from "react";
import { View, StyleSheet } from "react-native";
import {
  sentimentLabel,
  sentimentIcon,
  propagandaLabel,
  propagandaIcon,
  opinionLabel,
  opinionIcon,
} from "../../utils/NoozMetricUtils";
import { parseHootValuesFromNewsItem } from "../../utils/NewsParserUtils";
import { hootStyle } from "../../utils/HootParserUtils";
import NtQuickAnalysisController from "../quickanalysis/NtQuickAnalysisController";
import NtQuickAnalysisMetricContainer from "../quickanalysis/NtQuickAnalysisMetricContainer";
import NtQuickAnalysisMetricSentiment from "../quickanalysis/NtQuickAnalysisMetricSentiment";
import colors from "../../../config/colors";
import NtLanguageAnalysisScoreDisplaySentiment from "./NtLanguageAnalysisScoreDisplaySentiment";
import NtText from "../../../components/NtText";
import NtLanguageAnalysisScoreDisplayPropaganda from "./NtLanguageAnalysisScoreDisplayPropaganda";
import NtLanguageAnalysisScoreDisplayOpinion from "./NtLanguageAnalysisScoreDisplayOpinion";

function NtLanguageAnalysisScoreDisplay({ story, type, containerStyle }) {
  if (type === null) {
    return null;
  }

  const hoot = parseHootValuesFromNewsItem(story);
  const style = hootStyle(hoot.hoot);

  //sentiment
  const stmtLabel = sentimentLabel(hoot.sentiment);
  const stmtIcon = sentimentIcon(hoot.sentiment);

  //propaganda
  const propLabel = propagandaLabel(hoot.propagandaPersentage);
  const propIcon = propagandaIcon(hoot.propagandaPersentage);

  //opinion
  const opnLabel = opinionLabel(hoot.opinion);
  const opnIcon = opinionIcon(hoot.opinion);

  return (
    <View style={[styles.container, containerStyle]}>
      {type === "sentiment" && (
        <NtLanguageAnalysisScoreDisplaySentiment story={story} />
      )}

      {type === "propaganda" && (
        <NtLanguageAnalysisScoreDisplayPropaganda story={story} />
      )}

      {type === "opinion" && (
        <NtLanguageAnalysisScoreDisplayOpinion story={story} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lighestGray,
    borderRadius: 6,
    padding: 10,
  },
});

export default NtLanguageAnalysisScoreDisplay;
