import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../../components/NtText";
import NtTouchableEffect from "../../../components/NtTouchableEffect";
import colors from "../../../config/colors";
import NtMaterialIcon from "../../../components/NtMaterialIcon";

function NtTrendingRowTabItem({
  containerStyle,
  selected = true,
  option,
  onPress,
}) {
  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={() => {
        if (onPress) {
          onPress(option);
        }
      }}
    >
      <View
        style={[
          styles.bubble,
          selected ? { backgroundColor: colors.darker } : {},
        ]}
      >
        <NtText style={[styles.name, selected ? { color: "white" } : {}]}>
          {option.name}
        </NtText>
      </View>

      <NtMaterialIcon
        style={{ marginTop: -3 }}
        name="menu-down"
        color={selected ? colors.darker : "transparent"}
        size={22}
      />
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  bubble: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.darker,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 2,
    paddingBottom: 2,
  },
  name: {
    fontSize: 12,
    color: colors.darker,
    fontWeight: "600",
  },
});

export default NtTrendingRowTabItem;
