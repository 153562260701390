import React, { useContext } from "react";
import UserContext from "../../context/UserContext";
import NtNewsCardViewRow from "./NtNewsCardViewRow";
import NtNewsCardViewHeader from "./NtNewsCardViewHeader";

function NtNewsCardView({
  story,
  metric = 0,
  metricIcon,
  onCardPress,
  onOptionPress,
  onNoozScorePress,
  type = "row",
}) {
  let context = useContext(UserContext);
  const scoreType = context.user.scoreLayout || "default";

  if (type == "row") {
    return (
      <NtNewsCardViewRow
        story={story}
        metric={metric}
        metricIcon={metricIcon}
        scoreType={scoreType}
        onCardPress={onCardPress}
        onOptionPress={onOptionPress}
        onNoozScorePress={onNoozScorePress}
      />
    );
  } else if (type == "header") {
    return (
      <NtNewsCardViewHeader
        story={story}
        metric={metric}
        metricIcon={metricIcon}
        scoreType={scoreType}
        onCardPress={onCardPress}
        onOptionPress={onOptionPress}
        onNoozScorePress={onNoozScorePress}
      />
    );
  }
}

export default NtNewsCardView;
