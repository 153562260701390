import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { linking } from "./src/navigation/routes";
import RootAppController from "./src/root/controllers/RootAppController";
import Toast from "react-native-toast-message";
import Logger from "./src/common/utils/Logger";
import {
  asyncStorageGetData,
  asyncStorageSetData,
} from "./src/common/utils/ControllerUtils";
import { UserProvider } from "./src/context/UserContext";
import settings from "./src/config/settings";
import client from "./src/api/client";
import twitterclient from "./src/api/twitterclient";
import uuid from "react-native-uuid";
import RootAuthController from "./src/root/controllers/RootAuthController";

export const navigationRef = React.createRef();

export default function App() {
  const [user, setUser] = useState();
  const [isReady, setIsReady] = useState(false);
  const DEFAULT_USER_STORE_LOCATION = "@heimdallweb_user_location";

  const loadUser = async () => {
    var user = await asyncStorageGetData(DEFAULT_USER_STORE_LOCATION);

    user = JSON.parse(user);

    //Will never initialize the user to null,
    if (user === null) {
      user = {};
    }

    if (!user.scoreLayout) {
      Logger(
        "User does not have a default socre layout, will set one...",
        settings.defaultScoreLayout
      );
      user.scoreLayout = settings.defaultScoreLayout;
    }

    if (!user.apiUrl) {
      Logger("Api URL not set for user... will set now...");
      user.apiUrl = settings.apiUrl;
    }

    if (!user.twitterApiUrl) {
      Logger("Twitter Api URL not set for user... will set now...");
      user.twitterApiUrl = settings.twitterApiUrl;
    }

    if (!user.deviceUUID) {
      Logger("User does not have a uuid, will set one now");
      user.deviceUUID = uuid.v4();
    }

    //will now set the global variables....
    Logger("Will set the global variables now....");
    global.apiUrl = user.apiUrl;
    global.twitterApiUrl = user.twitterApiUrl;
    client.setBaseURL(user.apiUrl);
    twitterclient.setBaseURL(user.twitterApiUrl);

    updateUser(user);
    setIsReady(true);
    Logger("Current user loaded: ", user);
  };

  const updateUser = (user) => {
    asyncStorageSetData(DEFAULT_USER_STORE_LOCATION, user);
    setUser(user);
  };

  const setLayout = (layout) => {
    const updatedUser = { ...user, layout: layout };
    updateUser(updatedUser);
  };

  const setScoreLayout = (layout) => {
    const updatedUser = { ...user, scoreLayout: layout };
    updateUser(updatedUser);
  };

  if (!isReady) {
    //will fix this in a bit....
    loadUser();
    return null;
  }

  return (
    <View style={styles.container}>
      <UserProvider
        value={{
          user,
          updateUser,
          setLayout,
          setScoreLayout,
        }}
      >
        <NavigationContainer linking={linking} ref={navigationRef}>
          {/* {user && user.authenticated ? (
            <RootAppController />
          ) : (
            <RootAuthController />
          )} */}
          <RootAppController />
          {/* <RootAuthController /> */}
          <Toast />
        </NavigationContainer>
      </UserProvider>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
