import React from "react";
import { View, StyleSheet } from "react-native";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtSentimentProgress({
  progress,
  size,
  width,
  fontSize = 16,
  fontColor = colors.darkest,
  unfillPositiveColor = colors.sentimentPositiveUnfill,
  unfillNegativeColor = colors.sentimentNegativeUnfill,
  deactivatedColor = colors.sentimentDeactivated,
  filledColor = colors.sentiment0,
  showProgress = true,
  icon,
  iconColor,
  iconSize = 18,
  onPress,
  containerStyle,
}) {
  const sign = progress < 0 ? "-" : "+";
  progress = Math.abs(progress);

  const renderFill = () => {
    if (!icon) {
      return (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: showProgress ? "column" : "row",
          }}
        >
          {progress !== 0 && (
            <NtText
              style={[
                styles.text,
                {
                  fontSize: fontSize,
                  color: fontColor,
                  marginTop: showProgress ? -5 : 0,
                },
              ]}
            >
              {sign}
            </NtText>
          )}
          <NtText
            style={[styles.text, { fontSize: fontSize, color: fontColor }]}
          >
            {progress}%
          </NtText>
        </View>
      );
    } else {
      return (
        <NtMaterialIcon
          style={styles.statsIcon}
          name={icon}
          size={iconSize}
          color={iconColor}
        />
      );
    }
  };

  return (
    <NtTouchableEffect
      onPress={onPress}
      style={[styles.container, containerStyle]}
    >
      {showProgress && (
        <View style={{}}>
          {/* negative sentiment */}
          <AnimatedCircularProgress
            style={{
              transform: [{ scaleY: -1 }],
            }}
            size={size}
            width={width}
            fill={sign === "-" ? progress : 0}
            rotation={180}
            arcSweepAngle={150}
            tintColor={filledColor}
            backgroundColor={
              sign === "-" ? unfillNegativeColor : deactivatedColor
            }
          />

          {/* positive sentiment */}
          <AnimatedCircularProgress
            style={{ position: "absolute" }}
            size={size}
            width={width}
            fill={sign === "+" ? progress : 0}
            rotation={0}
            arcSweepAngle={150}
            tintColor={filledColor}
            backgroundColor={
              sign === "+" ? unfillPositiveColor : deactivatedColor
            }
          >
            {(fill) => renderFill()}
          </AnimatedCircularProgress>
        </View>
      )}
      {!showProgress && renderFill()}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontWeight: "700",
  },
});

export default NtSentimentProgress;
