import React, { useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import Logger from "../common/utils/Logger";

function NtImage({ style, uri, ...otherProps }) {
  const [displayUrl, setDisplayUrl] = useState(uri);

  if (!displayUrl) {
    return null;
  }

  const handleCheckSize = (width, heith) => {
    Logger("Load image size is: ", width, heith);
    if (width < 200 || heith < 150) {
      setDisplayUrl(null);
    }
  };

  Image.getSize(displayUrl, (width, height) => {
    handleCheckSize(width, height);
  });

  return (
    <Image
      style={[styles.container, style]}
      source={{
        uri: displayUrl,
      }}
      onLoad={() => {
        Logger("Image finished loading with the following");
      }}
      onError={(nativeEvent) => {
        Logger("Image load error, will hide container");
        setDisplayUrl(null);
      }}
      {...otherProps}
    />
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtImage;
