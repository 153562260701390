import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import {
  parseImageUrlFromNewsItem,
  parseSourceFromNewsItem,
  parseTitleFromNewsItem,
  parseHootValuesFromNewsItem,
} from "../utils/NewsParserUtils";
import NtHootCircularProgress from "./NtHootCircularProgress";

function NtAnalysisMorelikeThisHint({ story, onPress }) {
  const imageUrl = parseImageUrlFromNewsItem(story);
  const hoot = parseHootValuesFromNewsItem(story);

  return (
    <NtTouchableEffect onPress={onPress} style={styles.container}>
      {imageUrl && (
        <Image
          style={[styles.image]}
          source={{
            uri: imageUrl,
          }}
        />
      )}
      {!imageUrl && (
        <Image
          style={[styles.image]}
          source={{
            uri: require("../../assets/images/news-image-placeholder-150x80.jpg"),
          }}
        />
      )}
      <View
        style={{ marginTop: 5, flexDirection: "row", alignItems: "center" }}
      >
        <NtHootCircularProgress score={hoot.hoot} />
        <NtText style={[styles.sourceTitle, { marginLeft: 6 }]}>
          {parseSourceFromNewsItem(story)}
        </NtText>
      </View>

      <NtText style={[styles.text, { marginTop: 3 }]} numberOfLines={3}>
        {parseTitleFromNewsItem(story)}
      </NtText>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    marginRight: 30,
    marginBottom: 20,
    width: 150,
  },
  image: {
    width: 150,
    height: 80,
    resizeMode: "cover",
  },
  text: {
    fontSize: 13,
    fontWeight: "700",
    color: colors.darker,
  },
  sourceTitle: {
    fontSize: 11,
    fontWeight: "500",
    color: colors.darker,
  },
});

export default NtAnalysisMorelikeThisHint;
