import Logger from "../../common/utils/Logger";
import {
  asyncStorageSetData,
  asyncStorageGetData,
} from "../../common/utils/ControllerUtils";

const KEY_ASYNC_HAIMDALLAPP_SAVED_STORIES =
  "@key_async_haimdallapp_saved_stories";

export const fetchSavedStories = async () => {
  const jsonValue = await asyncStorageGetData(
    KEY_ASYNC_HAIMDALLAPP_SAVED_STORIES
  );

  var data = [];
  if (jsonValue != null) {
    data = JSON.parse(jsonValue);
  }

  //will always make sure we have at least the 'Read Later' tag....
  if (data.length === 0) {
    data = [{ tag: "Read Later", items: [] }];
  }
  return data;
};

export const saveStory = async (story, tag = "Read Later") => {
  var storedData = await fetchSavedStories();
  var foundTag = false;
  for (let x = 0; x < storedData.length; x++) {
    if (storedData[x].tag === tag) {
      foundTag = true;

      if (
        storedData[x].items.filter((each) => each.url === story.url).length > 0
      ) {
        //There is nothing to do here since we already have the story on this tag
        Logger(
          "Will no save the story since we already have it on the tag",
          tag
        );
        return;
      }

      storedData[x].items = [...storedData[x].items, story];
      break;
    }
  }

  if (!foundTag) {
    /*
      In this case, it means that we just added a new tag and want to save the story into it
      therefore, will need to add a new item into the list
      */
    storedData = [...storedData, { tag: tag, items: [story] }];
  }

  asyncStorageSetData(KEY_ASYNC_HAIMDALLAPP_SAVED_STORIES, storedData);
};

export const updateStories = async (stories, tag) => {
  var storedData = await fetchSavedStories();

  for (var x = 0; x < storedData.length; x++) {
    if (storedData[x].tag === tag) {
      storedData[x].items = stories; //new items
      break;
    }
  }

  asyncStorageSetData(KEY_ASYNC_HAIMDALLAPP_SAVED_STORIES, storedData);
};

export const deleteTag = async (tag) => {
  var storedData = await fetchSavedStories();

  storedData = storedData.filter((item) => item.tag !== tag);

  asyncStorageSetData(KEY_ASYNC_HAIMDALLAPP_SAVED_STORIES, storedData);
};
