import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtObjectivityProgress({
  progress,
  size,
  width,
  fontSize = 16,
  fontColor = colors.objectivity0,
  unfillColor = colors.objectivityUnfill,
  filledColor = colors.objectivity0,
  showProgress = true,
  icon,
  iconColor,
  iconSize = 16,
  onPress,
  containerStyle,
}) {
  const renderFill = () => {
    if (!icon) {
      return (
        <NtText style={[styles.text, { fontSize: fontSize, color: fontColor }]}>
          {progress}%
        </NtText>
      );
    } else {
      return (
        <NtMaterialIcon
          style={styles.statsIcon}
          name={icon}
          size={iconSize}
          color={iconColor}
        />
      );
    }
  };

  return (
    <NtTouchableEffect
      onPress={onPress}
      style={[styles.container, containerStyle]}
    >
      {showProgress && (
        <AnimatedCircularProgress
          size={size}
          width={width}
          fill={progress}
          // rotation={0}
          rotation={210}
          arcSweepAngle={300}
          tintColor={filledColor}
          backgroundColor={unfillColor}
        >
          {(fill) => renderFill()}
        </AnimatedCircularProgress>
      )}
      {!showProgress && (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {renderFill()}
        </View>
      )}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontWeight: "700",
  },
});

export default NtObjectivityProgress;
