import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import Logger from "../../common/utils/Logger";
import { fetchStories } from "../../api/stories";
import BaseNewsController from "./BaseNewsController";
import { parseIdFromNewsItem, parseSourceName } from "../utils/NewsParserUtils";
import AnalysisController from "./AnalysisController";
import settings from "../../config/settings";
import NtNewsCardView from "../components/NtNewsCardView";
import NtNewsLoadMoreButton from "../components/NtNewsLoadMoreButton";
import NtNewsPageContainer from "../components/NtNewsPageContainer";
import { showToast } from "../../common/utils/ControllerUtils";
import routes from "../../navigation/routes";
import NtText from "../../components/NtText";
import NtNewsPageContainerHeader from "../components/NtNewsPageContainerHeader";
import colors from "../../config/colors";
import NtNewsPageLoadError from "../components/NtNewsPageLoadError";

class KeywordNewsController extends BaseNewsController {
  skip = 0;

  state = {
    ...super.getParentState(),
    stories: [],
    firstTimeLoading: true,
    loading: false,
    selectionType: null,
    menuOpen: false,

    pageTitle: "",
    showErrorView: false,
  };

  componentDidMount() {
    super.componentDidMount();

    // this.didFocusListener = this.props.navigation.addListener(
    //   "focus",
    //   this.handleFocus
    // );

    const params = this.handleParametersParse();

    if (params) {
      this.state.searchText = params.name;
      this.setState({
        pageTitle: params.name,
        searchText: params.name,
      });
    }

    this.handleStoriesFetch(settings.pageSizeLoad, this.skip, null, false);
  }

  handleParametersParse = () => {
    if (this.props.route && this.props.route.params) {
      const { name } = this.props.route.params;
      return { name: decodeURIComponent(name) };
    }
    return null;
  };

  handleStoriesFetch = async (
    limit,
    skip,
    startId,
    loadingMore,
    sortColumn = this.state.rankBy.sortColumn,
    sortOrder = this.state.rankBy.sortOrder,
    hours = this.state.rankWithin.hours,
    search = this.state.searchText
  ) => {
    Logger("Will fetch the Stories....", sortColumn, sortOrder, hours, search);

    this.setState({ loading: true, showErrorView: false });

    const response = await fetchStories(
      limit,
      skip,
      startId,
      {},
      sortColumn,
      sortOrder,
      hours,
      search
    );
    if (response.ok) {
      Logger("Finished fetching the Stories: ", response.data);

      if (loadingMore) {
        Logger("We are loading more... need to add to the state");
        this.setState({ stories: this.state.stories.concat(response.data) });
      } else {
        Logger("First time loading.... we need to set the state to first ");
        this.setState({ stories: response.data });
      }

      setTimeout(() => {
        if (this.state.stories && this.state.stories.length === 0) {
          this.setState({ showErrorView: true });
        }
      }, 100);
    } else {
      Logger("Unable to load the news.....: ", response);
      this.setState({
        showErrorView: true,
        stories: [],
      });
    }

    //will let the refreshing know is done loading....
    this.setState({ loading: false, firstTimeLoading: false });
  };

  handleRankChange = (rankBy, rankWithin) => {
    Logger("Will refresh now....");
    this.setState({ stories: [] });
    this.skip = 0;
    this.handleStoriesFetch(
      settings.pageSizeLoad,
      this.skip,
      null,
      false,
      rankBy.sortColumn,
      rankBy.sortOrder,
      rankWithin.hours
    );
  };

  handleSearch = (text) => {
    Logger("Handling search for text: ", text);
    this.setState({ searchText: text, stories: [] });

    setTimeout(() => {
      this.skip = 0; //will start from the begining.....
      this.handleStoriesFetch(
        settings.pageSizeLoad,
        this.skip,
        null,
        false,
        this.state.rankBy.sortColumn,
        this.state.rankBy.sortOrder,
        this.state.rankWithin.hours,
        text
      );
    }, 100);
  };

  renderRow = (item, index) => {
    return (
      <NtNewsCardView
        key={parseIdFromNewsItem(item).toString() + index.toString()}
        story={item}
        metric={item.read_count}
        metricIcon={"eye"}
        messageCount={0}
        onCardPress={() => {
          Logger("Pressed on the card for item: ", item);
          this.handleStoryClickRedirect(item);
        }}
        onOptionPress={(key) => {
          Logger("Pressed on the more", key);
          if (key === "share") {
            this.handleShare(item);
          } else if (key === "morelikethis") {
            this.handleMoreLikeThisRedirect(item);
          }
        }}
        onNoozScorePress={() => {
          this.handleAnalysisNavigate(
            item,
            AnalysisController.TYPE_QUICKANALYSIS
          );
        }}
      />
    );
  };

  isFirstTimeLoading = () => {
    return false;
    return this.state.stories.length === 0 && this.state.loading;
  };

  renderTitleView = () => {
    return (
      <View>
        <NtNewsPageContainerHeader
          containerStyle={{ flexDirection: "column-reverse", marginTop: 30 }}
          subtitleStyle={{ color: colors.darker }}
          title={this.state.pageTitle}
          subtitle={"Results for"}
        />
        <View
          style={{
            width: "100%",
            height: 1,
            marginTop: 15,
            backgroundColor: colors.teal,
          }}
        />
      </View>
    );
  };

  render() {
    return (
      <NtNewsPageContainer
        renderTitleView={this.renderTitleView()}
        isLoading={this.state.firstTimeLoading}
        showFooter={true}
        sidePannelContainerStyle={{ paddingTop: 150 }}
        renderFilter={this.renderFilterController(false, true)}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <NtNewsPageLoadError
            containerStyle={{}}
            visible={this.state.showErrorView}
            message={
              <NtText>
                No stories were found for "{this.state.pageTitle}" within the "
                {this.state.rankWithin.title}".{"\n"}Tip: Try changing the{" "}
                <NtText
                  style={{ textDecorationLine: "underline" }}
                  onPress={() => {
                    this.rankWithinModalRef.current.show();
                  }}
                >
                  time range.
                </NtText>
              </NtText>
            }
          />
          {this.state.stories.map((each, index) => this.renderRow(each, index))}
          {/* Will load more storyes button  */}
          {this.state.stories.length > 0 && (
            <View>
              <NtNewsLoadMoreButton
                loadingText={"Loading"}
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  const lastId = parseIdFromNewsItem(this.state.stories[0]);
                  this.skip = this.skip + settings.pageSizeLoad;
                  this.handleStoriesFetch(
                    settings.pageSizeLoad,
                    this.skip,
                    lastId,
                    true
                  );
                }}
              />
            </View>
          )}
        </View>
        {this.renderRankByModal()}
        {this.renderRankWithinModal()}
        {this.renderShareModal()}
      </NtNewsPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  filterContainer: {
    width: "100%",
    backgroundColor: "white",
    alignItems: "center",
    flexDirection: "row",
  },
  title: {},
  subtitle: {},
});

export default KeywordNewsController;
