import React, { useState } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import colors from "../../../config/colors";
import {
  parseObjectivityFromNewsItem,
  parseSentencesFromNewsItem,
} from "../../utils/NewsParserUtils";
import NtQuickAnalysisLineChart from "./NtQuickAnalysisLineChart";
import NtQuickAnalysisReversedCard from "./NtQuickAnalysisReversedCard";
import NtQuickAnalysisTitleView from "./NtQuickAnalysisTitleView";
import NtQuickAnalysisMetricProgressbar from "./NtQuickAnalysisMetricProgressbar";
import { opinionIcon, opinionDescription } from "../../utils/NoozMetricUtils";
import NtQuickAnalysisMetricDescription from "./NtQuickAnalysisMetricDescription";

function NtQuickAnalysisMetricOpinion({ story }) {
  const [viewWidth, setViewWidth] = useState(0);
  const sentences = parseSentencesFromNewsItem(story);
  const opinion = parseObjectivityFromNewsItem(story);

  const getLabels = () => {
    var data = ["Start"];
    for (var x = 0; x < sentences.length; x++) {
      data = [...data, ""];
    }
    // return data;
    if (data.length > 15) {
      data[data.length - 4] = `Finish`;
    } else {
      data[data.length - 1] = `Finish`;
    }

    return data;
  };

  const getOpinionDataSet = () => {
    var data = [];
    for (var x = 0; x < sentences.length; x++) {
      data = [...data, Math.round(sentences[x].opinion)];
    }

    return data;
  };

  return (
    <View style={styles.container}>
      <NtQuickAnalysisReversedCard style={{ width: "100%" }}>
        <NtQuickAnalysisTitleView
          containerStyle={{ marginTop: 8 }}
          title={"Opinion Score & Label"}
          subtitle={"Range & Explanation"}
        />
        <View
          style={{
            paddingLeft: 20,
            paddingRight: 30,
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          <NtQuickAnalysisMetricProgressbar
            icon={opinionIcon(0).icon}
            value={opinion}
            labels={[
              { text: "Low", icon: "comment-outline" },
              { text: "Moderate", icon: "comment-multiple-outline" },
              { text: "High", icon: "comment" },
              { text: "Extreme", icon: "comment-multiple" },
            ]}
            fillColor={colors.noozscoreOpinion}
          />
        </View>

        <View style={{ paddingLeft: 25, paddingRight: 25 }}>
          <NtQuickAnalysisMetricDescription
            containerStyle={{ marginTop: 15, marginBottom: 15 }}
            text={opinionDescription(opinion).description.replace(
              "<value>",
              opinion
            )}
          />
        </View>
      </NtQuickAnalysisReversedCard>

      <NtQuickAnalysisReversedCard
        style={{
          width: "100%",
          marginTop: 20,
        }}
        onLayoutChange={(size) => {
          setViewWidth(size.width);
        }}
      >
        <NtQuickAnalysisTitleView
          containerStyle={{ marginTop: 8 }}
          title={"Opinion Flow"}
          subtitle={"from sentence to sentence"}
        />
        <NtQuickAnalysisLineChart
          width={viewWidth - 20}
          height={200}
          lineColor={colors.noozscoreSentiment}
          labels={getLabels()}
          dataset={getOpinionDataSet()}
        />
      </NtQuickAnalysisReversedCard>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  description: {
    color: colors.darker,
    fontSize: 13,
    fontWeight: "400",
  },
});

export default NtQuickAnalysisMetricOpinion;
