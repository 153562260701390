import React from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "./NtTouchableEffect";
import colors from "../config/colors";
import NtMaterialIcon from "./NtMaterialIcon";

function NtMenuButton({ onPress, color = colors.darkest, size = 35 }) {
  return (
    <NtTouchableEffect onPress={onPress}>
      <NtMaterialIcon
        style={styles.more}
        name="dots-horizontal"
        size={size}
        color={color}
      />
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtMenuButton;
