import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import NtLanguageAnalysisScoreDisplayTitle from "./NtLanguageAnalysisScoreDisplayTitle";
import {
  parseHootValuesFromNewsItem,
  parseSentencesFromNewsItem,
} from "../../utils/NewsParserUtils";
import { hootStyle } from "../../utils/HootParserUtils";
import { sentimentLabel, sentimentIcon } from "../../utils/NoozMetricUtils";
import colors from "../../../config/colors";
import NtQuickAnalysisTitleView from "../quickanalysis/NtQuickAnalysisTitleView";
import NtQuickAnalysisLineChart from "../quickanalysis/NtQuickAnalysisLineChart";

function NtLanguageAnalysisScoreDisplaySentiment({ story }) {
  const [viewWidth, setViewWidth] = useState(0);
  const hoot = parseHootValuesFromNewsItem(story);
  const style = hootStyle(hoot.hoot);
  const sentences = parseSentencesFromNewsItem(story);

  //sentiment
  const stmtLabel = sentimentLabel(hoot.sentiment);
  const stmtIcon = sentimentIcon(hoot.sentiment);

  const getLabels = () => {
    var data = ["Start"];
    for (var x = 0; x < sentences.length; x++) {
      data = [...data, ""];
    }
    data[data.length - 1] = `Finish`;

    return data;
  };

  const getSentimentDataSet = () => {
    var data = [];
    for (var x = 0; x < sentences.length; x++) {
      data = [...data, Math.round(sentences[x].vader.compound)];
    }

    return data;
  };

  return (
    <View style={styles.container}>
      <NtLanguageAnalysisScoreDisplayTitle
        title={"Sentiment Analysis"}
        label={stmtLabel.label}
        letter={"S"}
        icon={stmtIcon.icon}
        score={hoot.sentiment}
        color={colors.noozscoreSentiment}
      />

      <View
        style={{
          marginTop: 20,
          backgroundColor: colors.lighestGray,
          borderRadius: 6,
        }}
        onLayout={(event) => {
          setViewWidth(event.nativeEvent.layout.width);
        }}
      >
        <NtQuickAnalysisTitleView
          containerStyle={{ marginTop: 8 }}
          title={"Sentiment Flow"}
          subtitle={"from sentence to sentence"}
        />

        <NtQuickAnalysisLineChart
          width={viewWidth - 20}
          backgroundColor={colors.lighestGray}
          height={200}
          lineColor={"#46AB17"}
          labels={getLabels()}
          dataset={getSentimentDataSet()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    borderRadius: 2,
    padding: 15,
  },
});

export default NtLanguageAnalysisScoreDisplaySentiment;
