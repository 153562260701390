import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtTouchableEffect from "../../components/NtTouchableEffect";

function NtNavigationButton({ isMobile, selected, title, icon, onPress }) {
  return (
    <NtTouchableEffect onPress={onPress} style={styles.container}>
      <NtMaterialIcon
        name={icon}
        size={20}
        color={selected ? "white" : colors.lighter}
      />
      {!isMobile && (
        <NtText
          style={[
            styles.title,
            selected ? styles.titleSelected : styles.titleUnSelected,
          ]}
        >
          {title}
        </NtText>
      )}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: colors.lighter,
    fontWeight: "400",
    fontSize: 13,
    marginTop: 8,
  },
  titleSelected: {
    color: "white",
    fontWeight: "600",
  },
  titleUnSelected: {
    color: colors.lighter,
    fontWeight: "400",
  },
  underline: {
    width: 80,
    height: 2,
    marginTop: 4,
  },
  underlineSelected: {
    backgroundColor: "white",
  },
  underlineUnselected: {
    backgroundColor: "transparent",
  },
});

export default NtNavigationButton;
