import React from "react";
import { View, StyleSheet } from "react-native";
import NtSideMenuButton from "../../../components/NtSideMenuButton";
import NtText from "../../../components/NtText";
import colors from "../../../config/colors";
import { useMobile } from "../../../hooks/useMobile";

function NtLaunguageAnalysisTitle({
  containerStyle,
  onMenuPress,
  showMenuButton = true,
  showSubtitle = true,
}) {
  const mobile = useMobile();
  return (
    <View style={[styles.container, containerStyle]}>
      {showMenuButton && (
        <NtSideMenuButton
          onPress={onMenuPress}
          containerStyle={{ marginRight: 10, marginTop: 6 }}
        />
      )}

      <View style={{ flex: 1 }}>
        <NtText style={[styles.title]}>Language Analysis Tool</NtText>

        {showSubtitle && (
          <NtText style={[styles.subtitle, { marginTop: 6 }]}>
            This tool analyzes your writing to identify propaganda usage,
            sentiment influence, and opinion expression as well as provide you
            with an overall influence score.
          </NtText>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  title: {
    fontSize: 28,
    fontWeight: "600",

    color: colors.darkest,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: "600",
    color: colors.lighGray,
    lineHeight: 20,
  },
});

export default NtLaunguageAnalysisTitle;
