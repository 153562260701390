import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtAnalysisTitleView from "./NtAnalysisTitleView";
import NtCard from "../../components/NtCard";
import { groupPropaganda, propagandaStyle } from "../utils/PropagandaUtils";
import useSelection from "../../hooks/useSelection";
import NtQuickAnalysisMetricPropaganda from "./quickanalysis/NtQuickAnalysisMetricPropaganda";
import NtQuickAnalysisMetricContainer from "./quickanalysis/NtQuickAnalysisMetricContainer";
import { parseSentencesFromNewsItem } from "../utils/NewsParserUtils";
import { propagandaIcon, propagandaLabel } from "../utils/NoozMetricUtils";

function NtAnalysisPropagandaController({ story }) {
  const propaganda = groupPropaganda(story);
  const sentences = parseSentencesFromNewsItem(story);
  let propTotal = 0;
  let propPersentage = 0;
  if (propaganda.found) {
    propaganda.itemKeys.map((each) => {
      propTotal += propaganda.items[each].length;
    });
  }

  if (propTotal > 0) {
    propPersentage = Math.round((propTotal / sentences.length) * 100);
  }

  if (propPersentage > 100) {
    propPersentage = 100;
  }

  const propLabel = propagandaLabel(propPersentage);
  const propIcon = propagandaIcon(propPersentage);

  const selections = useSelection();
  const propagandaResponse = groupPropaganda(story);
  const highestPropagandaValue =
    propagandaResponse.items[propagandaResponse.itemKeys[0]].length;

  return (
    <View style={styles.container}>
      <NtAnalysisTitleView
        icon={"poll"}
        title={"Propaganda"}
        summary={
          "View how often the different types of propaganda were used in the article"
        }
      />

      <NtQuickAnalysisMetricContainer
        containerStyle={{ marginTop: 20 }}
        expanded={true}
        metric={"P"}
        value={propPersentage}
        icon={propIcon.icon}
        unit={"%"}
        title={propLabel.label}
        subtitle={"Propaganda Usage"}
        color={colors.noozscorePropaganda}
        renderBody={<NtQuickAnalysisMetricPropaganda story={story} />}
        showChevron={false}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginLeft: 15,
    marginRight: 15,
  },
  propagandaCount: {
    color: colors.darkest,
    fontSize: 18,
    fontWeight: "500",
    marginTop: 15,
  },
  cardStyle: {
    marginTop: 30,
  },
  cardContainerStyle: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 8,
    paddingBottom: 8,
  },
  cardTitle: {
    color: colors.darkest,
    fontSize: 18,
    fontWeight: "500",
    flex: 1,
    textAlign: "center",
  },
  cardSubtitle: {
    color: colors.darkest,
    fontSize: 16,
    fontWeight: "400",
    flex: 1,
    textAlign: "center",
    marginTop: 5,
  },
  noPropagandaContainer: {
    flex: 1,
    alignItems: "center",
    marginTop: 10,
  },
  noPropagandaName: {
    color: colors.darkest,
    fontSize: 16,
    fontWeight: "400",
    marginTop: 5,
  },
});

export default NtAnalysisPropagandaController;
