import React from 'react';
import {View, StyleSheet} from 'react-native';
import colors from '../config/colors';

function NtListSeparator({color = colors.lighter, style}) {
  return (
    <View style={[styles.container, {backgroundColor: color}, style]}></View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 1,
    width: '100%',
    backgroundColor: colors.lighter,
  },
});

export default NtListSeparator;
