import colors from '../../config/colors';

export const reactionScoreDescription = value => {
  let description = 'Need some text here from Ramon';

  if (value <= 25) {
    description =
      '<p>Indicates <bold>a low</bold> amount of persuasive techniques that could be interpreted as <bold>reasonable or understandable</bold>.</p>';
  } else if (value <= 50) {
    description =
      "<p>Indicates <bold>a moderate</bold> amount of persuasive techniques that could be interpreted as <bold>something seems suspicious even if it's not</bold>.</p>";
  } else if (value <= 75) {
    description =
      '<p>Indicates <bold>a high</bold> amount of persuasive techniques that could be interpreted as <bold>trying to secretly control the narrative</bold>.</p>';
  } else {
    description =
      '<p>Indicates <bold>an extreme</bold> amount of persuasive techniques that could be interpreted as <bold>surprising, unserious, or unbelieveable</bold>.</p>';
  }
  return {description: description};
};

export const sentimentLabel = value => {
  let label = '';

  if (value < -75) {
    label = 'Extremely-Negative';
  } else if (value < -50) {
    label = 'Highly-Negative';
  } else if (value < -25) {
    label = 'Moderately-Negative';
  } else if (value < -1) {
    label = 'Lowly-Negative';
  } else if (value === 0) {
    label = 'Zero';
  } else if (value <= 25) {
    label = 'Lowly-Positive';
  } else if (value <= 50) {
    label = 'Moderately-Positive';
  } else if (value <= 75) {
    label = 'Highly-Positive';
  } else {
    label = 'Extremely-Positive';
  }

  return {label: label};
};

export const sentimentIcon = value => {
  let icon = 'home';

  if (value === 0) {
    icon = 'emoticon-neutral-outline';
  } else if (value < 0) {
    icon = 'emoticon-sad-outline';
  } else {
    icon = 'emoticon-happy-outline';
  }

  return {icon: icon};
};

export const sentimentDescription = value => {
  let description = 'Need some text here from Ramon';

  if (value < -75) {
    description =
      '<p>The language used in the news article has a <bold><value>%</bold> chance of having <bold>an extremely negative</bold> influence on the reader’s sentiment towards the subject matter.</p>';
  } else if (value < -50) {
    description =
      '<p>The language used in the news article has a <bold><value>%</bold> chance of having <bold>a highly negative</bold> influence on the reader’s sentiment towards the subject matter.</p>';
  } else if (value < -25) {
    description =
      '<p>The language used in the news article has a <bold><value>%</bold> chance of having <bold>a moderately negative</bold> influence on the reader’s sentiment towards the subject matter.</p>';
  } else if (value < -1) {
    description =
      '<p>The language used in the news article has a <bold><value>%</bold> chance of having <bold>a lowly negative</bold> influence on the reader’s sentiment towards the subject matter.</p>';
  } else if (value === 0) {
    description =
      '<p>The language used in the news article has <bold>zero influence</bold> on the reader’s sentiment towards the subject matter.</p>';
  } else if (value <= 25) {
    description =
      '<p>The language used in the news article has a <bold><value>%</bold> chance of having <bold>a lowly positive</bold> influence on the reader’s sentiment towards the subject matter.</p>';
  } else if (value <= 50) {
    description =
      '<p>The language used in the news article has a <bold><value>%</bold> chance of having <bold>a moderately positive</bold> influence on the reader’s sentiment towards the subject matter.</p>';
  } else if (value <= 75) {
    description =
      '<p>The language used in the news article has a <bold><value>%</bold> chance of having <bold>a highly positive</bold> influence on the reader’s sentiment towards the subject matter.</p>';
  } else {
    description =
      '<p>The language used in the news article has a <bold><value>%</bold> chance of having <bold>an extremely positive</bold> influence on the reader’s sentiment towards the subject matter.</p>';
  }

  return {description: description};
};

export const propagandaLabel = value => {
  let label = '';

  if (value == 0) {
    label = 'Zero';
  } else if (value <= 25) {
    label = 'Low';
  } else if (value <= 50) {
    label = 'Moderate';
  } else if (value <= 75) {
    label = 'High';
  } else {
    label = 'Extreme';
  }

  return {label: label};
};

export const propagandaIcon = value => {
  let icon = 'home';

  if (value === 0) {
    icon = 'trophy-variant-outline';
  } else if (value <= 50) {
    icon = 'bullhorn-outline';
  } else {
    icon = 'bullhorn';
  }

  return {icon: icon};
};

export const propagandaDescription = value => {
  let description = 'Need some text here from Ramon';

  if (value == 0) {
    description =
      '<p>The news article has <bold>zero</bold> propaganda usage because persuasive techniques occurred <bold>0</bold> times in a news article containing <bold><sentence></bold> sentences.</p>';
  } else if (value <= 25) {
    description =
      '<p>The news article has <bold>low</bold> propaganda usage because persuasive techniques occurred <bold><propaganda></bold> times in a news article containing <bold><sentence></bold> sentences.</p>';
  } else if (value <= 50) {
    description =
      '<p>The news article has <bold>moderate</bold> propaganda usage because persuasive techniques occurred <bold><propaganda></bold> times in a news article containing <bold><sentence></bold> sentences.</p>';
  } else if (value <= 75) {
    description =
      '<p>The news article has <bold>high</bold> propaganda usage because persuasive techniques occurred <bold><propaganda></bold> times in a news article containing <bold><sentence></bold> sentences.</p>';
  } else {
    description =
      '<p>The news article has <bold>extreme</bold> propaganda usage because persuasive techniques occurred <bold><propaganda></bold> times in a news article containing <bold><sentence></bold> sentences.</p>';
  }

  return {description: description};
};

export const opinionLabel = value => {
  let label = '';

  if (value == 0) {
    label = 'Zero';
  } else if (value <= 25) {
    label = 'Low';
  } else if (value <= 50) {
    label = 'Moderate';
  } else if (value <= 75) {
    label = 'High';
  } else {
    label = 'Extreme';
  }

  return {label: label};
};

export const opinionIcon = value => {
  let icon = 'home';

  if (value === 0) {
    icon = 'comment-remove-outline';
  } else if (value <= 50) {
    icon = 'comment-outline';
  } else {
    icon = 'comment';
  }

  return {icon: icon};
};

export const opinionDescription = value => {
  let description = 'Need some text here from Ramon';

  if (value == 0) {
    description =
      '<p>The news article expressed <bold>zero</bold> opinion because <bold><value>%</bold> of the language was considered opinionated.</p>';
  } else if (value <= 25) {
    description =
      '<p>The news article expressed <bold>a low</bold> amount of opinion because <bold><value>%</bold> of the language was considered opinionated.</p>';
  } else if (value <= 50) {
    description =
      '<p>The news article expressed <bold>a moderate</bold> amount of opinion because <bold><value>%</bold> of the language was considered opinionated.</p>';
  } else if (value <= 75) {
    description =
      '<p>The news article expressed <bold>a high</bold> amount of opinion because <bold><value>%</bold> of the language was considered opinionated.</p>';
  } else {
    description =
      '<p>The news article expressed <bold>an extreme</bold> amount of opinion because <bold><value>%</bold> of the language was considered opinionated.</p>';
  }
  return {description: description};
};

export const revisionLabel = value => {
  let label = '';

  if (value == 0) {
    label = 'Zero';
  } else if (value <= 15) {
    label = 'Low';
  } else if (value <= 30) {
    label = 'Moderate';
  } else if (value <= 45) {
    label = 'High';
  } else {
    label = 'Extreme';
  }

  return {label: label};
};

export const revisionIcon = value => {
  let icon = 'home';

  if (value === 0) {
    icon = 'file-outline';
  } else if (value <= 30) {
    icon = 'file-edit-outline';
  } else {
    icon = 'file-edit';
  }

  return {icon: icon};
};

export const revisionDescription = value => {
  let description = 'Need some text here from Ramon';

  if (value == 0) {
    description =
      "<p>The news article was altered and republished <bold><value></bold> times which means it's considered to have <bold>zero revision activity</bold>.</p>";
  } else if (value <= 15) {
    description =
      '<p>The news article was altered and republished <bold><value></bold> times which means the text is considered to have <bold>low revision activity</bold>.</p>';
  } else if (value <= 30) {
    description =
      '<p>The news article was altered and republished <bold><value></bold> times which means the text is considered to have <bold>moderate revision activity</bold>.</p>';
  } else if (value <= 45) {
    description =
      '<p>The news article was altered and republished <bold><value></bold> times which means the text is considered to have <bold>high revision activity</bold>.</p>';
  } else {
    description =
      '<p>The news article was altered and republished <bold><value></bold> times which means the text is considered to have <bold>extreme revision activity</bold>.</p>';
  }

  return {description: description};
};

export const ghostEditLabel = value => {
  let label = '';

  if (value == 0) {
    label = 'Zero';
  } else if (value <= 25) {
    label = 'Low';
  } else if (value <= 50) {
    label = 'Moderate';
  } else if (value <= 75) {
    label = 'High';
  } else {
    label = 'Extreme';
  }

  return {label: label};
};

export const ghostEditIcon = value => {
  let icon = 'home';

  if (value === 0) {
    icon = 'ghost-off';
  } else if (value <= 50) {
    icon = 'ghost';
  } else {
    icon = 'ghost';
  }
  return {icon: icon};
};

export const ghostEditDescription = value => {
  let description = 'Need some text here from Ramon';

  if (value == 0) {
    description =
      '<p><bold><value></bold> of the <bold><revision></bold> revisions were ghost edits meaning this news article has <bold>zero</bold> stealth edits.</p>';
  } else if (value <= 25) {
    description =
      '<p><bold><value></bold> of the <bold><revision></bold> revisions were ghost edits meaning this news article has <bold>a low</bold> ratio of “stealth edits” to “revisions” at <bold><persentage>%</bold>.</p>';
  } else if (value <= 50) {
    description =
      '<p><bold><value></bold> of the <bold><revision></bold> revisions were ghost edits meaning this news article has <bold>a moderate</bold> ratio of “stealth edits” to “revisions” at <bold><persentage>%</bold>.</p>';
  } else if (value <= 75) {
    description =
      '<p><bold><value></bold> of the <bold><revision></bold> revisions were ghost edits meaning this news article has <bold>a high</bold> ratio of “stealth edits” to “revisions” at <bold><persentage>%</bold>.</p>';
  } else {
    description =
      '<p><bold><value></bold> of the <bold><revision></bold> revisions were ghost edits meaning this news article has <bold>an extreme</bold> ratio of “stealth edits” to “revisions” at <bold><persentage>%</bold>.</p>';
  }

  return {description: description};
};
