import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtDisclosure from "../../../components/NtDisclosure";
import NtText from "../../../components/NtText";
import colors from "../../../config/colors";
import NtHootProgress from "../NtHootProgress";
import NtCard from "../../../components/NtCard";
import NtMaterialIcon from "../../../components/NtMaterialIcon";

function NtQuickAnalysisMetricContainer({
  expanded,
  onPress,
  metric,
  value,
  unit,
  icon,
  title,
  subtitle,
  color = "red",
  renderBody,
  renderReaction,
  renderScore,
  showChevron = true,
  containerStyle,
}) {
  return (
    <NtCard
      style={[{ padding: 0, marginLeft: 0, marginRight: 0 }, containerStyle]}
      onPress={onPress}
    >
      <>
        <View style={[styles.container]}>
          {showChevron && (
            <NtMaterialIcon
              name={expanded ? "chevron-down" : "chevron-right"}
              size={25}
              color={colors.darkest}
            />
          )}

          {renderReaction}

          {!renderReaction && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginLeft: showChevron ? 0 : 10,
              }}
            >
              <NtText style={[styles.metric, { color: color }]}>
                {metric}
              </NtText>
              <NtHootProgress
                containerStyle={{ marginLeft: 10 }}
                height={30}
                progress={Math.abs(value)}
                fillColor={color}
                letter={null}
              />
            </View>
          )}

          <View style={{ flex: 1, marginLeft: 10 }}>
            <NtText style={styles.title}>{title}</NtText>
            <NtText style={[styles.subtitle, { color: color }]}>
              {subtitle}
            </NtText>
          </View>

          {renderScore}

          {icon && <NtMaterialIcon name={icon} size={20} color={color} />}
          <NtText style={[styles.value, { marginLeft: 6 }]}>
            {value}
            {unit}
          </NtText>
        </View>
        {expanded && <View style={{ padding: 5 }}>{renderBody}</View>}
      </>
    </NtCard>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 6,
    paddingRight: 12,
  },
  title: {
    fontSize: 14,
    fontWeight: "600",
    color: colors.darkest,
  },
  subtitle: {
    fontSize: 11,
    fontWeight: "500",
    color: colors.dark,
  },
  metric: {
    fontSize: 18,
    fontWeight: "600",
    color: colors.darkest,
  },
  value: {
    fontSize: 13,
    fontWeight: "500",
    color: colors.darkest,
  },
});

export default NtQuickAnalysisMetricContainer;
