const rankbyoptions = [
  {
    id: 'mostresent',
    title: 'Most Recent',
    icon: 'calendar-export',
    sortColumn: 'update',
    sortOrder: 'desc',
  },
  {
    id: 'mostviews',
    title: 'Most Views',
    icon: 'eye',
    sortColumn: 'read_count',
    sortOrder: 'desc',
  },
  {
    id: 'nameasc',
    title: 'Name A-Z',
    icon: 'sort-alphabetical-ascending',
    sortColumn: 'name',
    sortOrder: 'asc',
  },
  {
    id: 'namedesc',
    title: 'Name Z-A',
    icon: 'sort-alphabetical-descending',
    sortColumn: 'name',
    sortOrder: 'desc',
  },
  {
    id: 'mostopinionated',
    title: 'Most Opinionated',
    icon: 'comment-multiple',
    sortColumn: 'spacy.opinion',
    sortOrder: 'desc',
  },
  {
    id: 'leastopinionated',
    title: 'Least Opinionated',
    icon: 'comment-outline',
    sortColumn: 'spacy.opinion',
    sortOrder: 'asc',
  },
  {
    id: 'mostpositive',
    title: 'Most Positive',
    icon: 'emoticon-happy',
    sortColumn: 'spacy.vader.compound',
    sortOrder: 'desc',
  },
  {
    id: 'mostnegative',
    title: 'Most Negative',
    icon: 'emoticon-angry',
    sortColumn: 'spacy.vader.compound',
    sortOrder: 'asc',
  },
  {
    id: 'mostrevisions',
    title: 'Most Revisions',
    icon: 'file-edit',
    sortColumn: 'version',
    sortOrder: 'desc',
  },
  {
    id: 'leastrevisions',
    title: 'Least Revisions',
    icon: 'file-outline',
    sortColumn: 'version',
    sortOrder: 'asc',
  },
];

export default rankbyoptions;
