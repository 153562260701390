import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import client from "../../api/client";
import NtDevInputField from "../components/NTDevInputField";
import NtNewsPageContainer from "../../news/components/NtNewsPageContainer";
import BaseDevelopmentController from "./BaseDevelopmentController";
import twitterclient from "../../api/twitterclient";

class DevelopmentController extends BaseDevelopmentController {
  state = {
    apiUrl: global.apiUrl,
    twitterApiUrl: global.twitterApiUrl,
  };

  componentDidMount() {
    super.componentDidMount();
  }

  render() {
    return (
      <NtNewsPageContainer
        showNavigation={false}
        pageTitle="Development"
        showFooter={true}
      >
        <View style={styles.sectionContainer}>
          <NtText style={[styles.sectionTitle, { marginLeft: 10 }]}>
            NOOZ API Domain
          </NtText>
          <NtDevInputField
            containerStyle={{ marginTop: 5 }}
            value={this.state.apiUrl}
            placeholder={"API"}
            onChangeText={(text) => {
              this.setState({ apiUrl: text });
            }}
            onSave={() => {
              this.context.updateUser({
                ...this.context.user,
                apiUrl: this.state.apiUrl,
              });
              global.apiUrl = this.state.apiUrl;
              client.setBaseURL(this.state.apiUrl);
            }}
          />
        </View>

        <View style={styles.sectionContainer}>
          <NtText style={[styles.sectionTitle, { marginLeft: 10 }]}>
            Twitter API Domain
          </NtText>
          <NtDevInputField
            containerStyle={{ marginTop: 5 }}
            value={this.state.twitterApiUrl}
            placeholder={"Twitter API Domain"}
            onChangeText={(text) => {
              this.setState({ twitterApiUrl: text });
            }}
            onSave={() => {
              this.context.updateUser({
                ...this.context.user,
                twitterApiUrl: this.state.twitterApiUrl,
              });
              global.twitterApiUrl = this.state.twitterApiUrl;
              twitterclient.setBaseURL(this.state.twitterApiUrl);
            }}
          />
        </View>
      </NtNewsPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightest,
    padding: 10,
  },
  title: {
    color: colors.darkest,
    fontSize: 18,
    fontWeight: "400",
  },
  sectionContainer: {
    marginTop: 15,
  },
  sectionTitle: {
    color: colors.lighGray,
    fontWeight: "600",
    fontSize: 13,
  },
  textButton: {
    color: colors.blue,
    fontStyle: "italic",
  },
});

export default DevelopmentController;
