import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtActiveFiltersController({
  containerStyle,
  search,
  onClearSearch,
  sort,
  onClearSort,
  others,
}) {
  const renderItem = (name, onPress) => {
    if (!name) {
      return null;
    }

    return (
      <View style={styles.itemContainer}>
        <NtText style={styles.itemText}>{name}</NtText>
        <NtTouchableEffect onPress={onPress}>
          <NtMaterialIcon size={20} color={colors.darkest} name={"close"} />
        </NtTouchableEffect>
      </View>
    );
  };
  return (
    <View style={[styles.container, containerStyle]}>
      {renderItem(search, onClearSearch)}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  itemContainer: {
    alignSelf: "baseline",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.lighter,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 15,
    marginBottom: 10,
  },
  itemText: {
    color: colors.darkest,
    marginRight: 8,
    fontSize: 16,
  },
});

export default NtActiveFiltersController;
