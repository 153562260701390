import React from 'react';
import {View, StyleSheet} from 'react-native';
import colors from '../../../config/colors';
import NtText from '../../../components/NtText';
import {sentimentIcon} from '../../utils/NoozMetricUtils';
import NtMaterialIcon from '../../../components/NtMaterialIcon';

function NtQuickAnalysisMetricSentimentMoodChange({containerStyle, sentiment}) {
  const negativeIcon = sentimentIcon(-100);
  const positiveIcon = sentimentIcon(100);
  const isNegative = sentiment < 0;

  const renderButton = (icon, title, selected) => {
    return (
      <View
        style={[
          styles.buttonContainer,
          selected ? styles.buttonContainerSelected : {},
        ]}>
        <NtMaterialIcon name={icon} size={20} color={colors.darker} />
        <NtText
          style={[
            styles.buttonText,
            {marginLeft: 8},
            selected ? {fontWeight: '700'} : {color: colors.darker},
          ]}>
          {title}
        </NtText>
      </View>
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      {renderButton(negativeIcon.icon, 'Negative', isNegative)}
      {renderButton(positiveIcon.icon, 'Positive', !isNegative)}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 30,
    borderRadius: 15,
    backgroundColor: colors.teal,
    flexDirection: 'row',
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainerSelected: {
    borderWidth: 2,
    borderColor: colors.darkest,
    borderRadius: 15,
    backgroundColor: 'white',
  },
  buttonText: {
    fontSize: 12,
    fontWeight: '400',
  },
});

export default NtQuickAnalysisMetricSentimentMoodChange;
