import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../config/colors";
import NtMaterialIcon from "./NtMaterialIcon";
import NtTouchableEffect from "./NtTouchableEffect";

function NtSideMenuButton({ onPress, containerStyle }) {
  return (
    <NtTouchableEffect
      onPress={onPress}
      style={[styles.container, containerStyle]}
    >
      <NtMaterialIcon color={colors.darkest} name={"menu"} />
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtSideMenuButton;
