import { create } from "apisauce";
import settings from "../config/settings";
import Logger from "../common/utils/Logger";

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
const client = create({
  baseURL: global.apiUrl,
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});

client.addAsyncRequestTransform(async (request) => {
  request.headers["Content-Type"] = "application/json";
  request.headers["Accept"] = "application/json";
  request.headers["User-Agent"] = settings.apiUserAgent;
  // request.headers["Authorization"] =
  // "Basic bmNsOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=";
});

const post = client.post;
client.post = async (url, params, axiosConfig) => {
  const response = await post(url, params, axiosConfig);

  if (response.status === 204) {
    Logger(
      "Just got a 204 from API will handle differently to avoid breaking promise"
    );
    return { ok: true, data: [] };
  }

  return response;
};

export default client;
