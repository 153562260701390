const demostory = {
  version: 1,
  spacy: {
    sentiment: 6.574546485260771,
    opinion: 57.584561602418724,
    vader: {
      neg: 9.700000000000001,
      neu: 83.89999999999999,
      pos: 6.4,
      compound: -91.36999999999999,
    },
    sentences: [
      {
        text: "Federal Agency Warns Millions of Microsoft Users to Update Settings\n\nThe U.S. Cybersecurity and Infrastructure Security Agency (CISA) issued more alerts related to security vulnerabilities exploited in attacks targeting Microsoft Windows, Adobe products, and Mozilla software.",
        sentiment: 25,
        opinion: 55.00000000000001,
        words: [
          {
            word: "more",
            sentiment: 50,
            opinion: 50,
          },
          {
            word: "related",
            sentiment: 0,
            opinion: 60,
          },
        ],
        vader: {
          neg: 20.4,
          neu: 68.60000000000001,
          pos: 11,
          compound: -47.67,
        },
      },
      {
        text: "The fresh alerts come alongside a CISA alert that was sent out for administrators and users to update Apple products, including iPhones that use iOS software.",
        sentiment: 30,
        opinion: 50,
        words: [
          {
            word: "fresh",
            sentiment: 30,
            opinion: 50,
          },
        ],
        vader: {
          neg: 0,
          neu: 84.2,
          pos: 15.8,
          compound: 54.230000000000004,
        },
      },
      {
        text: "'Microsoft has released updates to address multiple vulnerabilities in Microsoft software,' it says.",
        sentiment: 0,
        opinion: 100,
        words: [
          {
            word: "multiple",
            sentiment: 0,
            opinion: 100,
          },
        ],
        vader: {
          neg: 11.799999999999999,
          neu: 88.2,
          pos: 0,
          compound: -15.310000000000002,
        },
      },
      {
        text: "'An attacker can exploit some of these vulnerabilities to take control of an affected system.'",
        sentiment: 0,
        opinion: 100,
        words: [],
        vader: {
          neg: 43,
          neu: 56.99999999999999,
          pos: 0,
          compound: -74.3,
        },
      },
      {
        text: "A similar bulletin was released for Mozilla and Adobe.",
        sentiment: 0,
        opinion: 60,
        words: [
          {
            word: "similar",
            sentiment: 0,
            opinion: 60,
          },
        ],
        vader: {
          neg: 0,
          neu: 100,
          pos: 0,
          compound: 0,
        },
      },
      {
        text: "CISA, which is operated by the Department of Homeland Security, said it advises users to review Microsoft's February 2023 Security Update Guide and Deployment Information and 'apply the necessary updates.'",
        sentiment: 0,
        opinion: 0,
        words: [
          {
            word: "necessary",
            sentiment: 0,
            opinion: 0,
          },
        ],
        vader: {
          neg: 0,
          neu: 85.39999999999999,
          pos: 14.6,
          compound: 58.589999999999996,
        },
      },
      {
        text: "According to Microsoft, it is patching three previously exploited vulnerabilities: CVE-2023-21715, CVE-2023-23376, and CVE-2023-21823.",
        sentiment: -16.666666666666664,
        opinion: 83.33333333333334,
        words: [
          {
            word: "previously",
            sentiment: -16.666666666666664,
            opinion: 83.33333333333334,
          },
        ],
        vader: {
          neg: 27.700000000000003,
          neu: 72.3,
          pos: 0,
          compound: -55.74,
        },
      },
      {
        text: "The February 2023 patch fixes those, the company says.",
        sentiment: 0,
        opinion: 100,
        words: [],
        vader: {
          neg: 0,
          neu: 100,
          pos: 0,
          compound: 0,
        },
      },
      {
        text: "'The attack itself is carried out locally by a user with authentication to the targeted system.",
        sentiment: 0,
        opinion: 100,
        words: [
          {
            word: "locally",
            sentiment: 0,
            opinion: 100,
          },
        ],
        vader: {
          neg: 17.1,
          neu: 82.89999999999999,
          pos: 0,
          compound: -47.67,
        },
      },
      {
        text: "An authenticated attacker could exploit the vulnerability by convincing a victim, through social engineering, to download and open a specially crafted file from a website which could lead to a local attack on the victim computer,' Microsoft says.",
        sentiment: 10.578231292517007,
        opinion: 68.02721088435374,
        words: [
          {
            word: "convincing",
            sentiment: 50,
            opinion: 0,
          },
          {
            word: "victim",
            sentiment: -7.500000000000001,
            opinion: 95,
          },
          {
            word: "social",
            sentiment: 3.3333333333333335,
            opinion: 93.33333333333333,
          },
          {
            word: "open",
            sentiment: 0,
            opinion: 50,
          },
          {
            word: "specially",
            sentiment: 35.714285714285715,
            opinion: 42.85714285714286,
          },
          {
            word: "local",
            sentiment: 0,
            opinion: 100,
          },
          {
            word: "victim",
            sentiment: -7.500000000000001,
            opinion: 95,
          },
        ],
        vader: {
          neg: 29.799999999999997,
          neu: 64.60000000000001,
          pos: 5.6000000000000005,
          compound: -86.25,
        },
      },
      {
        text: "One of the bugs, according to security expert Dustin Childs with Trend Micro, is likely being used 'to spread malware or ransomware … considering this was discovered by Microsoft's Threat Intelligence Center (aka MSTIC), it could mean it was used by advanced threat actors.",
        sentiment: -0.3124999999999989,
        opinion: 40.31249999999999,
        words: [
          {
            word: "likely",
            sentiment: 0,
            opinion: 0,
          },
          {
            word: "center",
            sentiment: -10,
            opinion: 90,
          },
          {
            word: "mean",
            sentiment: -31.25,
            opinion: 31.25,
          },
          {
            word: "advanced",
            sentiment: 40,
            opinion: 40,
          },
        ],
        vader: {
          neg: 12.8,
          neu: 73.2,
          pos: 14.099999999999998,
          compound: -7.720000000000001,
        },
      },
      {
        text: "Either way, make sure you test and roll these fixes quickly.'",
        sentiment: 41.666666666666664,
        opinion: 30.555555555555557,
        words: [
          {
            word: "sure",
            sentiment: 50,
            opinion: 11.111111111111116,
          },
          {
            word: "quickly",
            sentiment: 33.33333333333333,
            opinion: 50,
          },
        ],
        vader: {
          neg: 0,
          neu: 81.3,
          pos: 18.7,
          compound: 31.819999999999997,
        },
      },
      {
        text: "Microsoft says that impacted customers will receive automatic updates.",
        sentiment: 0,
        opinion: 100,
        words: [],
        vader: {
          neg: 0,
          neu: 100,
          pos: 0,
          compound: 0,
        },
      },
      {
        text: "But those who have disabled automatic updates can get them via the Microsoft Store by going to Library, Get updates, then click Update all.",
        sentiment: -20,
        opinion: 70,
        words: [
          {
            word: "disabled",
            sentiment: -20,
            opinion: 70,
          },
        ],
        vader: {
          neg: 0,
          neu: 100,
          pos: 0,
          compound: 0,
        },
      },
      {
        text: "Windows 10 users can also head to the Settings menu, then go to the Update & Security section before clicking on the update, which generally requires a restart.",
        sentiment: 5.000000000000002,
        opinion: 50,
        words: [
          {
            word: "generally",
            sentiment: 5.000000000000002,
            opinion: 50,
          },
        ],
        vader: {
          neg: 0,
          neu: 91.8,
          pos: 8.200000000000001,
          compound: 34,
        },
      },
      {
        text: "For Adobe, CISA advises users to enable updates for After Effects, Connect, FrameMaker, Bridge, Photoshop, InDesign, Premiere Rush, Animate, and Substance 3D Stager.",
        sentiment: 0,
        opinion: 100,
        words: [],
        vader: {
          neg: 0,
          neu: 100,
          pos: 0,
          compound: 0,
        },
      },
      {
        text: "Several of these patches are deemed as 'critical' in terms of severity, including the often-used Adobe Photoshop and Adobe InDesign.",
        sentiment: 0,
        opinion: 60,
        words: [
          {
            word: "several",
            sentiment: 0,
            opinion: 100,
          },
          {
            word: "critical",
            sentiment: 0,
            opinion: 19.999999999999996,
          },
        ],
        vader: {
          neg: 10.8,
          neu: 89.2,
          pos: 0,
          compound: -31.819999999999997,
        },
      },
      {
        text: "'Probably the most interesting fix is for PhotoShop.",
        sentiment: 50,
        opinion: 50,
        words: [
          {
            word: "most",
            sentiment: 50,
            opinion: 50,
          },
          {
            word: "interesting",
            sentiment: 50,
            opinion: 50,
          },
        ],
        vader: {
          neg: 0,
          neu: 70,
          pos: 30,
          compound: 45.76,
        },
      },
      {
        text: "This patch fixes five bugs, three of which are rated Critical.",
        sentiment: 0,
        opinion: 19.999999999999996,
        words: [
          {
            word: "critical",
            sentiment: 0,
            opinion: 19.999999999999996,
          },
        ],
        vader: {
          neg: 18.7,
          neu: 81.3,
          pos: 0,
          compound: -31.819999999999997,
        },
      },
      {
        text: "An attacker could get arbitrary code execution if they can convince a user on an affected system to open a malicious file,' Childs wrote in a blog post.",
        sentiment: -5,
        opinion: 44.99999999999999,
        words: [
          {
            word: "arbitrary",
            sentiment: -10,
            opinion: 40,
          },
          {
            word: "open",
            sentiment: 0,
            opinion: 50,
          },
        ],
        vader: {
          neg: 16.400000000000002,
          neu: 77.4,
          pos: 6.2,
          compound: -51.06,
        },
      },
      {
        text: "'This is the same scenario for Premier Rush, which corrects two Critical-rated code execution bugs.'",
        sentiment: 0,
        opinion: 87.5,
        words: [
          {
            word: "same",
            sentiment: 0,
            opinion: 87.5,
          },
        ],
        vader: {
          neg: 0,
          neu: 100,
          pos: 0,
          compound: 0,
        },
      },
      {
        text: "Mozilla, meanwhile, also released security updates to address vulnerabilities in Firefox 110, according to CISA.",
        sentiment: 0,
        opinion: 100,
        words: [],
        vader: {
          neg: 9.4,
          neu: 76.5,
          pos: 14.099999999999998,
          compound: 20.23,
        },
      },
      {
        text: "The agency advises users and administrations to look into Mozilla's security advisories for Firefox 110 and Firefox ESR 102.8.",
        sentiment: 0,
        opinion: 100,
        words: [],
        vader: {
          neg: 0,
          neu: 88.2,
          pos: 11.799999999999999,
          compound: 34,
        },
      },
      {
        text: "CISA also called on users to update their Apple iPhones, MacBooks, and other products due to similar vulnerabilities.",
        sentiment: -8.333333333333332,
        opinion: 61.66666666666667,
        words: [
          {
            word: "other",
            sentiment: -12.5,
            opinion: 62.5,
          },
          {
            word: "due",
            sentiment: -12.5,
            opinion: 62.5,
          },
          {
            word: "similar",
            sentiment: 0,
            opinion: 60,
          },
        ],
        vader: {
          neg: 8.6,
          neu: 91.4,
          pos: 0,
          compound: -15.310000000000002,
        },
      },
      {
        text: "Apple's updates include iOS 16.3.1, iPadOS 16.3.1, and macOS's Ventura 13.2.1, while the firm is rolling out Safari 16.3.1 to older Apple operating systems—including macOS Big Sur and macOS Monterey.",
        sentiment: -1.1111111111111118,
        opinion: 72.22222222222221,
        words: [
          {
            word: "firm",
            sentiment: -20,
            opinion: 60,
          },
          {
            word: "older",
            sentiment: 16.666666666666664,
            opinion: 66.66666666666667,
          },
          {
            word: "big",
            sentiment: 0,
            opinion: 90,
          },
        ],
        vader: {
          neg: 0,
          neu: 100,
          pos: 0,
          compound: 0,
        },
      },
      {
        text: "'Apple has released security updates to address vulnerabilities in multiple products.",
        sentiment: 0,
        opinion: 100,
        words: [
          {
            word: "multiple",
            sentiment: 0,
            opinion: 100,
          },
        ],
        vader: {
          neg: 12.3,
          neu: 69.19999999999999,
          pos: 18.5,
          compound: 20.23,
        },
      },
      {
        text: "An attacker could exploit these vulnerabilities to take control of an affected device,' CISA said.",
        sentiment: 0,
        opinion: 100,
        words: [],
        vader: {
          neg: 43,
          neu: 56.99999999999999,
          pos: 0,
          compound: -74.3,
        },
      },
      {
        text: "It includes updates for Safari 16.3.1, iOS 16.3.1 and iPadOS 16.3.1, and macOS 13.2.1, according to the notice.",
        sentiment: 0,
        opinion: 100,
        words: [],
        vader: {
          neg: 0,
          neu: 100,
          pos: 0,
          compound: 0,
        },
      },
      {
        text: "On Apple's website, the firm says the fix is warranted because 'an app may be able to execute arbitrary code with kernel privileges,' and another allows for 'processing maliciously crafted web content may lead to arbitrary code execution.",
        sentiment: 2.4999999999999996,
        opinion: 44.375,
        words: [
          {
            word: "firm",
            sentiment: -20,
            opinion: 60,
          },
          {
            word: "able",
            sentiment: 50,
            opinion: 37.5,
          },
          {
            word: "arbitrary",
            sentiment: -10,
            opinion: 40,
          },
          {
            word: "arbitrary",
            sentiment: -10,
            opinion: 40,
          },
        ],
        vader: {
          neg: 0,
          neu: 93.4,
          pos: 6.6000000000000005,
          compound: 38.18,
        },
      },
    ],
    entities: [
      {
        text: "Federal Agency",
        type: "ORG",
        start: 0,
        end: 14,
      },
      {
        text: "Millions",
        type: "CARDINAL",
        start: 21,
        end: 29,
      },
      {
        text: "The U.S. Cybersecurity",
        type: "ORG",
        start: 69,
        end: 91,
      },
      {
        text: "Infrastructure Security Agency",
        type: "ORG",
        start: 96,
        end: 126,
      },
      {
        text: "Microsoft Windows, Adobe",
        type: "ORG",
        start: 220,
        end: 244,
      },
      {
        text: "Mozilla",
        type: "PERSON",
        start: 259,
        end: 266,
      },
      {
        text: "Apple",
        type: "ORG",
        start: 380,
        end: 385,
      },
      {
        text: "iPhones",
        type: "ORG",
        start: 406,
        end: 413,
      },
      {
        text: "Microsoft",
        type: "ORG",
        start: 439,
        end: 448,
      },
      {
        text: "Microsoft",
        type: "ORG",
        start: 509,
        end: 518,
      },
      {
        text: "Mozilla and Adobe",
        type: "ORG",
        start: 670,
        end: 687,
      },
      {
        text: "the Department of Homeland Security",
        type: "ORG",
        start: 717,
        end: 752,
      },
      {
        text: "Microsoft",
        type: "ORG",
        start: 786,
        end: 795,
      },
      {
        text: "February 2023",
        type: "DATE",
        start: 798,
        end: 811,
      },
      {
        text: "Security Update Guide and Deployment Information",
        type: "ORG",
        start: 812,
        end: 860,
      },
      {
        text: "Microsoft",
        type: "ORG",
        start: 910,
        end: 919,
      },
      {
        text: "three",
        type: "CARDINAL",
        start: 936,
        end: 941,
      },
      {
        text: "CVE-2023-21715",
        type: "ORG",
        start: 980,
        end: 994,
      },
      {
        text: "CVE-2023-23376",
        type: "ORG",
        start: 996,
        end: 1010,
      },
      {
        text: "CVE-2023-21823",
        type: "ORG",
        start: 1016,
        end: 1030,
      },
      {
        text: "February 2023",
        type: "DATE",
        start: 1036,
        end: 1049,
      },
      {
        text: "fixes those",
        type: "PERSON",
        start: 1056,
        end: 1067,
      },
      {
        text: "Microsoft",
        type: "ORG",
        start: 1415,
        end: 1424,
      },
      {
        text: "One",
        type: "CARDINAL",
        start: 1432,
        end: 1435,
      },
      {
        text: "Dustin Childs",
        type: "PERSON",
        start: 1478,
        end: 1491,
      },
      {
        text: "Trend Micro",
        type: "ORG",
        start: 1497,
        end: 1508,
      },
      {
        text: "Microsoft",
        type: "ORG",
        start: 1601,
        end: 1610,
      },
      {
        text: "MSTIC",
        type: "ORG",
        start: 1645,
        end: 1650,
      },
      {
        text: "Microsoft",
        type: "ORG",
        start: 1769,
        end: 1778,
      },
      {
        text: "the Microsoft Store",
        type: "ORG",
        start: 1903,
        end: 1922,
      },
      {
        text: "Library",
        type: "GPE",
        start: 1935,
        end: 1942,
      },
      {
        text: "Update",
        type: "ORG",
        start: 1968,
        end: 1974,
      },
      {
        text: "10",
        type: "CARDINAL",
        start: 1988,
        end: 1990,
      },
      {
        text: "Settings",
        type: "PERSON",
        start: 2018,
        end: 2026,
      },
      {
        text: "Update & Security",
        type: "ORG",
        start: 2048,
        end: 2065,
      },
      {
        text: "Adobe",
        type: "ORG",
        start: 2146,
        end: 2151,
      },
      {
        text: "CISA",
        type: "GPE",
        start: 2153,
        end: 2157,
      },
      {
        text: "Connect",
        type: "ORG",
        start: 2209,
        end: 2216,
      },
      {
        text: "FrameMaker",
        type: "ORG",
        start: 2218,
        end: 2228,
      },
      {
        text: "Bridge",
        type: "PERSON",
        start: 2230,
        end: 2236,
      },
      {
        text: "Photoshop",
        type: "PERSON",
        start: 2238,
        end: 2247,
      },
      {
        text: "InDesign",
        type: "ORG",
        start: 2249,
        end: 2257,
      },
      {
        text: "Substance",
        type: "ORG",
        start: 2287,
        end: 2296,
      },
      {
        text: "Adobe InDesign",
        type: "PERSON",
        start: 2425,
        end: 2439,
      },
      {
        text: "PhotoShop",
        type: "ORG",
        start: 2484,
        end: 2493,
      },
      {
        text: "five",
        type: "CARDINAL",
        start: 2512,
        end: 2516,
      },
      {
        text: "three",
        type: "CARDINAL",
        start: 2523,
        end: 2528,
      },
      {
        text: "Childs",
        type: "PERSON",
        start: 2682,
        end: 2688,
      },
      {
        text: "two",
        type: "CARDINAL",
        start: 2771,
        end: 2774,
      },
      {
        text: "110",
        type: "CARDINAL",
        start: 2902,
        end: 2905,
      },
      {
        text: "Mozilla",
        type: "ORG",
        start: 2984,
        end: 2991,
      },
      {
        text: "Apple iPhones",
        type: "ORG",
        start: 3096,
        end: 3109,
      },
      {
        text: "MacBooks",
        type: "ORG",
        start: 3111,
        end: 3119,
      },
      {
        text: "Apple",
        type: "ORG",
        start: 3172,
        end: 3177,
      },
      {
        text: "16.3.1",
        type: "CARDINAL",
        start: 3200,
        end: 3206,
      },
      {
        text: "16.3.1",
        type: "CARDINAL",
        start: 3215,
        end: 3221,
      },
      {
        text: "Ventura",
        type: "GPE",
        start: 3235,
        end: 3242,
      },
      {
        text: "Apple",
        type: "ORG",
        start: 3304,
        end: 3309,
      },
      {
        text: "macOS Big Sur",
        type: "PERSON",
        start: 3338,
        end: 3351,
      },
      {
        text: "macOS Monterey",
        type: "PERSON",
        start: 3356,
        end: 3370,
      },
      {
        text: "Apple",
        type: "ORG",
        start: 3374,
        end: 3379,
      },
      {
        text: "Safari 16.3.1",
        type: "ORG",
        start: 3583,
        end: 3596,
      },
      {
        text: "16.3.1",
        type: "DATE",
        start: 3602,
        end: 3608,
      },
      {
        text: "16.3.1",
        type: "DATE",
        start: 3620,
        end: 3626,
      },
      {
        text: "macOS",
        type: "PERSON",
        start: 3632,
        end: 3637,
      },
      {
        text: "Apple",
        type: "ORG",
        start: 3674,
        end: 3679,
      },
    ],
    words: [
      {
        word: "more",
        sentiment: 50,
        opinion: 50,
      },
      {
        word: "related",
        sentiment: 0,
        opinion: 60,
      },
      {
        word: "fresh",
        sentiment: 30,
        opinion: 50,
      },
      {
        word: "multiple",
        sentiment: 0,
        opinion: 100,
      },
      {
        word: "similar",
        sentiment: 0,
        opinion: 60,
      },
      {
        word: "necessary",
        sentiment: 0,
        opinion: 0,
      },
      {
        word: "previously",
        sentiment: -16.666666666666664,
        opinion: 83.33333333333334,
      },
      {
        word: "locally",
        sentiment: 0,
        opinion: 100,
      },
      {
        word: "convincing",
        sentiment: 50,
        opinion: 0,
      },
      {
        word: "victim",
        sentiment: -7.500000000000001,
        opinion: 95,
      },
      {
        word: "social",
        sentiment: 3.3333333333333335,
        opinion: 93.33333333333333,
      },
      {
        word: "open",
        sentiment: 0,
        opinion: 50,
      },
      {
        word: "specially",
        sentiment: 35.714285714285715,
        opinion: 42.85714285714286,
      },
      {
        word: "local",
        sentiment: 0,
        opinion: 100,
      },
      {
        word: "victim",
        sentiment: -7.500000000000001,
        opinion: 95,
      },
      {
        word: "likely",
        sentiment: 0,
        opinion: 0,
      },
      {
        word: "center",
        sentiment: -10,
        opinion: 90,
      },
      {
        word: "mean",
        sentiment: -31.25,
        opinion: 31.25,
      },
      {
        word: "advanced",
        sentiment: 40,
        opinion: 40,
      },
      {
        word: "sure",
        sentiment: 50,
        opinion: 11.111111111111116,
      },
      {
        word: "quickly",
        sentiment: 33.33333333333333,
        opinion: 50,
      },
      {
        word: "disabled",
        sentiment: -20,
        opinion: 70,
      },
      {
        word: "generally",
        sentiment: 5.000000000000002,
        opinion: 50,
      },
      {
        word: "several",
        sentiment: 0,
        opinion: 100,
      },
      {
        word: "critical",
        sentiment: 0,
        opinion: 19.999999999999996,
      },
      {
        word: "most",
        sentiment: 50,
        opinion: 50,
      },
      {
        word: "interesting",
        sentiment: 50,
        opinion: 50,
      },
      {
        word: "critical",
        sentiment: 0,
        opinion: 19.999999999999996,
      },
      {
        word: "arbitrary",
        sentiment: -10,
        opinion: 40,
      },
      {
        word: "open",
        sentiment: 0,
        opinion: 50,
      },
      {
        word: "same",
        sentiment: 0,
        opinion: 87.5,
      },
      {
        word: "other",
        sentiment: -12.5,
        opinion: 62.5,
      },
      {
        word: "due",
        sentiment: -12.5,
        opinion: 62.5,
      },
      {
        word: "similar",
        sentiment: 0,
        opinion: 60,
      },
      {
        word: "firm",
        sentiment: -20,
        opinion: 60,
      },
      {
        word: "older",
        sentiment: 16.666666666666664,
        opinion: 66.66666666666667,
      },
      {
        word: "big",
        sentiment: 0,
        opinion: 90,
      },
      {
        word: "multiple",
        sentiment: 0,
        opinion: 100,
      },
      {
        word: "firm",
        sentiment: -20,
        opinion: 60,
      },
      {
        word: "able",
        sentiment: 50,
        opinion: 37.5,
      },
      {
        word: "arbitrary",
        sentiment: -10,
        opinion: 40,
      },
      {
        word: "arbitrary",
        sentiment: -10,
        opinion: 40,
      },
    ],
    version: "1.0.15",
  },
  propaganda: [
    {
      type: "Exaggeration,Minimisation",
      start: "3311",
      end: "3331",
    },
  ],

  hoot: {
    opinion: 0.5758456160241873,
    sentiment: 0.9137,
    propaganda: 0.055,
    version: 0.05,
    stealth: 0,
    hootometer: 63,
    weightOpinion: 20,
    weightSentiment: 25,
    weightPropaganda: 30,
    weightRevision: 10,
    weightGhostEdits: 15,
    advanced_label: "Smells Fishy",
    label: "Medium Influence",
  },
};

export default demostory;
