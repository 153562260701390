import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtCard from "../../components/NtCard";
import NtStyleGrid from "./NtStyleGrid";
import Logger from "../../common/utils/Logger";
import NtDisclosure from "../../components/NtDisclosure";
import NtAnalysisTitleView from "./NtAnalysisTitleView";

function NtAnalysisStepThruReaderController({ sentences }) {
  //will set all the states
  const [sentenceIndex, setSentenceIndex] = useState(0);
  const [wordIndex, setWordIndex] = useState(0);

  const handlePreviousSentence = () => {
    Logger("Will handle previsous sentence");
    if (sentenceIndex > 0) {
      setWordIndex(0);
      setSentenceIndex(sentenceIndex - 1);
    }
  };

  const handleNextSentence = () => {
    Logger("Will handle next sentence");
    if (sentenceIndex < sentences.length - 1) {
      setWordIndex(0);
      setSentenceIndex(sentenceIndex + 1);
    }
  };

  const handlePreviousWord = () => {
    Logger("Will handle previsous word");
    if (wordIndex > 0) {
      setWordIndex(wordIndex - 1);
    }
  };

  const handleNextWord = () => {
    Logger("Will handle next word");
    if (wordIndex < sentences[sentenceIndex].words.length - 1) {
      setWordIndex(wordIndex + 1);
    }
  };

  const createArrayOfWords = () => {
    let array = [];
    for (let x = 0; x < sentences[sentenceIndex].words.length; x++) {
      array = [...array, sentences[sentenceIndex].words[x].word];
    }
    return array;
  };

  return (
    <View style={styles.container}>
      {/* title view */}
      <NtAnalysisTitleView
        icon={"unfold-more-vertical"}
        title={"Step-Thru Reader"}
        summary={
          "Step thrugh each sentence and words to view the objectivity and sentiment as you read the article."
        }
      />

      {/* sentence */}
      <NtCard
        style={styles.cardStyle}
        containerStyle={styles.cardContainerStyle}
      >
        <View style={styles.sentenceContainer}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <NtDisclosure
              name={"chevron-left"}
              size={40}
              color={colors.teal}
              onPress={handlePreviousSentence}
            />

            <NtText style={styles.cardtitle}>
              Sentence {sentenceIndex + 1} of {sentences.length}
            </NtText>

            <NtDisclosure
              name={"chevron-right"}
              size={40}
              color={colors.teal}
              onPress={handleNextSentence}
            />
          </View>
          <NtText style={styles.cardText}>
            {sentences[sentenceIndex].text}
            {/* <HighlightText
              highlightStyle={{backgroundColor: 'yellow'}}
              searchWords={createArrayOfWords()}
              textToHighlight={sentences[sentenceIndex].text}
            /> */}
          </NtText>

          <View style={{ height: 30 }} />

          <NtStyleGrid
            objectivity={Math.round(sentences[sentenceIndex].opinion)}
            sentiment={Math.round(sentences[sentenceIndex].sentiment)}
          />
        </View>

        <View style={{ height: 30 }} />

        {sentences[sentenceIndex].words.length > 0 && (
          <View style={styles.wordContainer}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <NtDisclosure
                name={"chevron-left"}
                size={40}
                color={colors.teal}
                onPress={handlePreviousWord}
              />

              <NtText style={styles.cardtitle}>
                Word {wordIndex + 1} of {sentences[sentenceIndex].words.length}
              </NtText>
              <NtDisclosure
                name={"chevron-right"}
                size={40}
                color={colors.teal}
                onPress={handleNextWord}
              />
            </View>
            <NtText
              style={[styles.cardText, { width: "100%", textAlign: "center" }]}
            >
              {sentences[sentenceIndex].words[wordIndex].word}
            </NtText>

            <View style={{ height: 30 }} />

            <NtStyleGrid
              objectivity={Math.round(
                sentences[sentenceIndex].words[wordIndex].opinion
              )}
              sentiment={Math.round(
                sentences[sentenceIndex].words[wordIndex].sentiment
              )}
            />
          </View>
        )}
      </NtCard>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginLeft: 15,
    marginRight: 15,
  },
  cardStyle: {
    marginTop: 30,
  },
  cardContainerStyle: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 8,
    paddingBottom: 8,
  },
  sentenceContainer: {},
  wordContainer: {
    backgroundColor: colors.lightest,
    borderRadius: 8,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 25,
    marginBottom: 10,
  },
  cardtitle: {
    fontSize: 14,
    fontWeight: "500",
    color: colors.darkest,
  },
  cardText: {
    fontSize: 14,
    fontWeight: "300",
    color: colors.darkest,
    marginTop: 8,
  },
  cardTextHighlight: {
    backgroundColor: "red",
  },
});

export default NtAnalysisStepThruReaderController;
