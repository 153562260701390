import React from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import { parseObjectivityFromNewsItem } from "../utils/NewsParserUtils";
import NtAnalysisTitleView from "./NtAnalysisTitleView";
import { opinionIcon, opinionLabel } from "../utils/NoozMetricUtils";
import NtQuickAnalysisMetricContainer from "./quickanalysis/NtQuickAnalysisMetricContainer";
import NtQuickAnalysisMetricOpinion from "./quickanalysis/NtQuickAnalysisMetricOpinion";

function NtAnalysisOpinionController({ story }) {
  const opinion = parseObjectivityFromNewsItem(story);
  const opnLabel = opinionLabel(opinion);
  const opnIcon = opinionIcon(opinion);

  return (
    <View style={styles.container}>
      <NtAnalysisTitleView
        icon={"comment-multiple"}
        title={"Opinion Analysis"}
        summary={
          "See the level of opinion the author expresses throughout the article from start to finish."
        }
      />

      <NtQuickAnalysisMetricContainer
        containerStyle={{ marginTop: 20 }}
        expanded={true}
        metric={"O"}
        value={opinion}
        icon={opnIcon.icon}
        unit={"%"}
        title={opnLabel.label}
        subtitle={"Opinion Expressed"}
        color={colors.noozscoreOpinion}
        renderBody={<NtQuickAnalysisMetricOpinion story={story} />}
        showChevron={false}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  opinionScoreTitle: {
    color: colors.darkest,
    fontSize: 19,
    fontWeight: "600",
  },
  opinionScoreSubtitle: {
    color: colors.darker,
    fontSize: 17,
    fontWeight: "500",
  },
});

export default NtAnalysisOpinionController;
