import React from 'react';
import {View, StyleSheet} from 'react-native';
import NtText from '../../components/NtText';
import colors from '../../config/colors';
import NtObjectivityProgress from './NtObjectivityProgress';
import {
  objectivityStyle,
  sentimentStyle,
} from '../../common/utils/ControllerUtils';
import NtSentimentProgress from './NtSentimentProgress';

function NtStyleGrid({objectivity = 80, sentiment = 60}) {
  const objStyle = objectivityStyle(objectivity);
  const sentStyle = sentimentStyle(sentiment);
  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <NtText style={styles.headerText}>Metric</NtText>
        <NtText style={styles.headerText}>Score</NtText>
        <NtText style={styles.headerText}>Label</NtText>
      </View>

      <View style={styles.rowContainer}>
        <View style={styles.rowCell}>
          <NtText style={styles.metric}>Opinion</NtText>
        </View>

        <View style={styles.rowCell}>
          <NtObjectivityProgress
            progress={objectivity}
            size={36}
            width={3}
            fontSize={10}
            fontColor={objStyle.color}
            filledColor={objStyle.color}
            icon={objStyle.icon}
            iconColor={objStyle.color}
          />
        </View>

        <View style={styles.rowCell}>
          <NtText style={[styles.styleText, {color: objStyle.color}]}>
            {objStyle.label}
          </NtText>
        </View>
      </View>

      <View style={styles.divisor} />

      <View style={styles.rowContainer}>
        <View style={styles.rowCell}>
          <NtText style={styles.metric}>Sentiment</NtText>
        </View>

        <View style={styles.rowCell}>
          <NtSentimentProgress
            progress={sentiment}
            size={36}
            width={3}
            fontSize={10}
            fontColor={sentStyle.color}
            filledColor={sentStyle.color}
            icon={sentStyle.icon}
            iconColor={sentStyle.color}
          />
        </View>

        <View style={styles.rowCell}>
          <NtText style={[styles.styleText, {color: sentStyle.color}]}>
            {sentStyle.label}
          </NtText>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.lighter,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 6,
  },
  headerText: {
    color: colors.darkest,
    fontWeight: '400',
  },

  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  rowCell: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  styleText: {
    color: colors.darker,
    fontSize: 15,
    fontWeight: '400',
    marginLeft: 5,
  },
  divisor: {
    width: '100%',
    height: 1,
    backgroundColor: colors.lighter,
  },
  metric: {
    fontSize: 14,
    color: colors.darkest,
    fontWeight: '500',
  },
});

export default NtStyleGrid;
