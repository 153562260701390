import React from "react";
import { View, StyleSheet } from "react-native";
import NtMaterialIcon from "../../../components/NtMaterialIcon";
import NtText from "../../../components/NtText";
import colors from "../../../config/colors";
import NtTouchableEffect from "../../../components/NtTouchableEffect";

function NtLanguageAnalysisUserButton({
  title,
  icon = "file-document-outline",
  containerStyle,
  onPress,
  selected = false,
}) {
  return (
    <NtTouchableEffect
      onPress={onPress}
      style={[
        styles.container,
        containerStyle,
        selected ? { backgroundColor: colors.dark } : {},
      ]}
    >
      <NtMaterialIcon
        size={18}
        name={icon}
        color={selected ? "white" : colors.dark}
      />
      <NtText
        style={[
          styles.title,
          { marginLeft: 6, color: selected ? "white" : colors.dark },
        ]}
        numberOfLines={1}
      >
        {title}
      </NtText>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 4,
    // borderWidth: 1,
    borderColor: colors.dark,
    padding: 5,
  },
  title: {
    fontSize: 11,
    fontWeight: "500",
    color: colors.dark,
  },
});

export default NtLanguageAnalysisUserButton;
