import React from 'react';
import {View, StyleSheet} from 'react-native';
import NtText from '../../../components/NtText';
import colors from '../../../config/colors';

function NtQuickAnalysisTitleView({title, subtitle, containerStyle}) {
  return (
    <View style={[styles.container, containerStyle]}>
      <View style={{alignItems: 'center'}}>
        <NtText style={styles.title}>{title}</NtText>
        <NtText style={[styles.subtitle, {marginTop: 4}]}>{subtitle}</NtText>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  title: {
    color: colors.darkest,
    fontSize: 16,
    fontWeight: '500',
  },
  subtitle: {
    color: colors.darker,
    fontSize: 15,
    fontWeight: '400',
  },
});

export default NtQuickAnalysisTitleView;
