import React from "react";
import { View, StyleSheet, Text } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtViewCount({
  count,
  icon,
  style,
  textStyle,
  textColor = colors.dark,
}) {
  return (
    <View style={[styles.container, style]}>
      <NtMaterialIcon name={icon} size={16} color={textColor} />
      <NtText style={[styles.views, { color: textColor }, textStyle]}>
        {count}
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  views: {
    marginLeft: 5,
    fontSize: 12,
    fontWeight: "400",
    color: colors.dark,
  },
});

export default NtViewCount;
