import React from "react";
import { View, StyleSheet } from "react-native";
import { useMobile } from "../hooks/useMobile";
import NtTouchableEffect from "./NtTouchableEffect";

function NtSideMenuController({
  renderMenu,
  open,
  children,
  containerStyle,
  onBackgroundPress,
}) {
  const isMobile = true;
  // useMobile();
  return (
    <View
      style={[
        styles.container,
        containerStyle,
        isMobile ? { alignItems: "" } : {},
      ]}
    >
      {!isMobile && renderMenu}

      <View style={{ flex: 1 }}>
        {children}

        {isMobile && open && (
          <NtTouchableEffect
            onPress={onBackgroundPress}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              right: -15,

              backgroundColor: "rgba(52, 52, 52, 0.4)",
            }}
            activeOpacity={1}
          />
        )}
      </View>

      {isMobile && open && (
        <View style={[{ position: "absolute", height: "100%" }]}>
          {renderMenu}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
  },
});

export default NtSideMenuController;
