import React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import BaseController from "../../common/base/BaseController";
import NtSelectScoreLayoutItem from "../components/NtSelectScoreLayoutItem";
import NtNewsPageContainer from "../../news/components/NtNewsPageContainer";

class SettingsController extends BaseController {
  state = {
    isMobile: false,
    scoreLayout: this.context.user.scoreLayout || "default",
  };

  layouts = [
    {
      id: "default",
    },

    {
      id: "metrics",
    },
    {
      id: "reaction",
    },
    {
      id: "advanced",
    },
  ];

  componentDidMount() {
    super.componentDidMount();
  }

  renderRow = (item, index) => {
    return (
      <NtSelectScoreLayoutItem
        key={index}
        layout={item}
        selected={(this.context.user.scoreLayout || "default") === item.id}
        onSelect={(item) => {
          this.context.setScoreLayout(item.id);
          if (this.props.route.params && this.props.route.params.onChange) {
            this.props.route.params.onChange(item.id);
          }
        }}
      />
    );
  };

  render() {
    return (
      <NtNewsPageContainer
        pageTitle="NOOZ SCORE Options"
        pageSubtitle={"Select your preferred view."}
        showNavigation={false}
        showFooter={true}
      >
        <View>
          {this.layouts.map((each, index) => this.renderRow(each, index))}
        </View>
      </NtNewsPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: colors.lightest,
  },
  innderContainer: {
    flex: 1,
    backgroundColor: "white",
    padding: 10,
  },
  text: {
    color: colors.darkest,
    fontWeight: "600",
    fontSize: 16,
  },

  title: {
    color: colors.darkest,
    fontSize: 20,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darkest,
    fontSize: 16,
  },
});

export default SettingsController;
