import React, { useState } from "react";
import { View, StyleSheet, Dimensions, FlatList } from "react-native";
import colors from "../../config/colors";
import NtAnalysisTitleView from "./NtAnalysisTitleView";

function NtAnalysisArticleFlowvisualController({ sentences, stories }) {
  const graphData = [
    {
      id: 0,
    },
    {
      id: 1,
    },
    {
      id: 2,
    },
  ];

  const getOpinionDataSet = () => {
    var data = [];
    for (var x = 0; x < sentences.length; x++) {
      data = [...data, Math.round(sentences[x].opinion)];
    }

    return data;
  };

  const getSentimentDataSet = () => {
    var data = [];
    for (var x = 0; x < sentences.length; x++) {
      data = [...data, Math.round(sentences[x].vader.compound)];
    }

    return data;
  };

  const getLabels = () => {
    var data = ["Start"];
    for (var x = 0; x < sentences.length; x++) {
      data = [...data, ""];
    }
    // return data;
    if (data.length > 15) {
      data[data.length - 4] = `Finish`;
    } else {
      data[data.length - 1] = `Finish`;
    }

    return data;
  };

  const renderRow = (item) => {
    // if (item.id === 0) {
    //   return (
    //     <NtArticleFlowLineChart
    //       width={Dimensions.get('window').width - 40}
    //       height={260}
    //       opinionDataset={getOpinionDataSet()}
    //       sentimentDataset={getSentimentDataSet()}
    //       labels={getLabels()}
    //     />
    //   );
    // } else if (item.id === 1) {
    //   return (
    //     <NtObjectivityLineChart
    //       width={Dimensions.get('window').width - 40}
    //       height={200}
    //       subtitle={'from sentence to sentence'}
    //       labels={getLabels()}
    //       dataset={getOpinionDataSet()}
    //     />
    //   );
    // } else if (item.id === 2) {
    //   return (
    //     <NtSentimentLineChart
    //       width={Dimensions.get('window').width - 40}
    //       height={200}
    //       subtitle={'from sentence to sentence'}
    //       labels={getLabels()}
    //       dataset={getSentimentDataSet()}
    //     />
    //   );
    // }

    return null;
  };

  return (
    <View style={styles.container}>
      {/* title view */}
      <NtAnalysisTitleView
        icon={"chart-areaspline"}
        title={"Article Flow"}
        summary={
          "Displays the changes in opinion and sentiment from sentences to sentence from the start of the article to the end of the article"
        }
      />

      {stories.length > 1 && (
        <View style={{ width: "100%", height: 250 }}>
          {/* <NtStyleLineChart
            width={Dimensions.get('window').width - 40}
            height={250}
            stories={stories}
          /> */}
        </View>
      )}

      <View
        style={{ flex: 1, marginLeft: -5, marginTop: 25, marginBottom: 10 }}
      >
        <FlatList
          horizontal={true}
          style={styles.topStoriesList}
          data={graphData}
          showsHorizontalScrollIndicator={false}
          ItemSeparatorComponent={() => {
            return <View style={{ width: 10 }} />;
          }}
          keyExtractor={(item) => item.id.toString()}
          renderItem={({ item, index }) => renderRow(item)}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginLeft: 15,
    marginRight: 15,
  },
  cardStyle: {
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.22,
    shadowRadius: 2.5,
    elevation: 4,
    marginTop: 30,
  },
  cardContainerStyle: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 8,
    paddingBottom: 8,
  },
  sentenceContainer: {},
  wordContainer: {
    backgroundColor: colors.lightest,
    borderRadius: 8,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 25,
    marginBottom: 10,
  },
  cardtitle: {
    fontSize: 14,
    fontWeight: "500",
    color: colors.darkest,
  },
  cardText: {
    fontSize: 14,
    fontWeight: "300",
    color: colors.darkest,
    marginTop: 8,
  },
  cardTextHighlight: {
    backgroundColor: "red",
  },
});

export default NtAnalysisArticleFlowvisualController;
