import React from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import NtText from "../../../components/NtText";
import colors from "../../../config/colors";
import {
  parseSentencesFromNewsItem,
  parseHootValuesFromNewsItem,
} from "../../utils/NewsParserUtils";
import NtQuickAnalysisReversedCard from "./NtQuickAnalysisReversedCard";
import NtQuickAnalysisTitleView from "./NtQuickAnalysisTitleView";
import { groupPropaganda } from "../../utils/PropagandaUtils";
import NtQuickAnalysisMetricProgressbar from "./NtQuickAnalysisMetricProgressbar";
import {
  propagandaIcon,
  propagandaDescription,
} from "../../utils/NoozMetricUtils";
import useSelection from "../../../hooks/useSelection";
import NtQuickAnalysisMetricPropagandaRow from "./NtQuickAnalysisMetricPropagandaRow";
import NtQuickAnalysisMetricDescription from "./NtQuickAnalysisMetricDescription";
import NtMaterialIcon from "../../../components/NtMaterialIcon";

function NtQuickAnalysisMetricPropaganda({ story }) {
  const selections = useSelection();
  const hoot = parseHootValuesFromNewsItem(story);
  const propaganda = groupPropaganda(story);
  const sentences = parseSentencesFromNewsItem(story);

  const renderReverseCardContent = (icon, name, value) => {
    return (
      <NtQuickAnalysisReversedCard style={styles.topReverseCard}>
        <NtMaterialIcon
          name={icon}
          color={colors.noozscorePropaganda}
          size={24}
        />
        <NtText
          style={[styles.topCardValue, { marginTop: 4, marginBottom: 4 }]}
        >
          {value}
        </NtText>
        <NtText style={[styles.topCardDescription, { marginLeft: 10 }]}>
          {name}
        </NtText>
      </NtQuickAnalysisReversedCard>
    );
  };

  const renderPropaganda = () => {
    const response = groupPropaganda(story);

    if (response.itemKeys.length <= 0) {
      return null;
    }

    return response.itemKeys.map((each) => {
      return (
        <NtQuickAnalysisMetricPropagandaRow
          key={each}
          type={each}
          items={response.items[each]}
          story={story}
          expanded={selections.isSelected(each)}
          onExpandedPress={() => {
            if (selections.isSelected(each)) {
              selections.removeSelection(each);
            } else {
              selections.addSelection(each);
            }
          }}
        />
      );
    });
  };

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, flexDirection: "row" }}>
        {renderReverseCardContent(
          "label-multiple",
          "Types of Propaganda",
          propaganda.itemKeys.length
        )}
        <View style={{ width: 10 }} />
        {renderReverseCardContent(
          "stamper",
          "Propaganda Occurences",
          hoot.propagandaTotal
        )}
        <View style={{ width: 10 }} />
        {renderReverseCardContent(
          "text",
          "Sentences in Article",
          sentences.length
        )}
      </View>

      <NtQuickAnalysisReversedCard style={{ width: "100%", marginTop: 20 }}>
        <NtQuickAnalysisTitleView
          containerStyle={{ marginTop: 8 }}
          title={"Propaganda Score & Label"}
          subtitle={"Range & Explanation"}
        />
        <View
          style={{
            paddingLeft: 20,
            paddingRight: 30,
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          <NtQuickAnalysisMetricProgressbar
            icon={propagandaIcon(0).icon}
            value={hoot.propagandaPersentage}
            multipliers={["0", "25", "50", "75", "100"]}
            labels={[
              { text: "Low", icon: "bullhorn-outline" },
              { text: "Moderate", icon: "bullhorn-outline" },
              { text: "High", icon: "bullhorn" },
              { text: "Extreme", icon: "bullhorn" },
            ]}
            fillColor={colors.noozscorePropaganda}
          />
        </View>

        <View style={{ paddingLeft: 25, paddingRight: 25 }}>
          <NtQuickAnalysisMetricDescription
            containerStyle={{ marginTop: 15, marginBottom: 15 }}
            text={propagandaDescription(hoot.propagandaPersentage)
              .description.replace("<sentence>", sentences.length)
              .replace("<propaganda>", hoot.propagandaTotal)}
          />
        </View>
      </NtQuickAnalysisReversedCard>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          paddingLeft: 10,
          paddingRight: 10,
          marginBottom: 10,
        }}
      >
        <NtText style={styles.propagandaHeader}>Propaganda Type</NtText>
        <NtText style={styles.propagandaHeader}>Occurences</NtText>
      </View>
      {renderPropaganda()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  topReverseCard: {
    flex: 1,
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 15,
  },
  topCardValue: {
    fontSize: 17,
    color: colors.darkest,
    fontWeight: "700",
    textAlign: "center",
  },
  topCardDescription: {
    fontSize: 11,
    color: colors.darkest,
    textAlign: "center",
  },
  propagandaHeader: {
    color: colors.noozscorePropaganda,
    fontSize: 15,
    fontWeight: "500",
  },
});

export default NtQuickAnalysisMetricPropaganda;
