//https://reactnavigation.org/docs/configuring-links/#passing-params
//important links: https://reactnavigation.org/blog/2020/05/16/web-support/

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { View, StyleSheet } from "react-native";
import NoFoundController from "../common/controllers/NoFoundController";
import AppMarketingController from "../marketing/controllers/AppMarketingController";
import NewsController from "../news/controllers/NewsController";
import routes from "./routes";
import AnalysisController from "../news/controllers/AnalysisController";
import PrivacyController from "../legal/controllers/PrivacyController";
import TermsController from "../legal/controllers/TermsController";
import CookiesController from "../legal/controllers/CookiesController";
import MyNewsController from "../news/controllers/MyNewsController";
import ContestRulesController from "../promotions/controllers/ContestRulesController";
import InsiderContestController from "../promotions/controllers/InsiderContestController";
import SettingsController from "../settings/controllers/SettingsController";
import DevelopmentController from "../development/controllers/DevelopmentController";
import NewslikeThisController from "../news/controllers/NewslikeThisController";
import TopSourcesController from "../news/controllers/TopSourcesController";
import TopAuthorsController from "../news/controllers/TopAuthorsController";
import TopFeedsController from "../news/controllers/TopFeedsController";
import TopCategoriesController from "../news/controllers/TopCategoriesController";
import FilterNewsController from "../news/controllers/FilterNewsController";
import DevelopmentJSON from "../development/controllers/DevelopmentJSON";
import KeywordNewsController from "../news/controllers/KeywordNewsController";
import LanguageAnalysisController from "../news/controllers/LanguageAnalysisController";
import TrendingController from "../news/controllers/TrendingController";

const Stack = createNativeStackNavigator();

function AppNavigator({ onControllerChanged, initialRoute }) {
  return (
    <Stack.Navigator
      initialRouteName={initialRoute}
      screenListeners={{
        state: (e) => {
          const controller =
            e.data.state.routes[e.data.state.routes.length - 1].name;
          if (onControllerChanged) {
            onControllerChanged(controller);
          }
        },
      }}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name={routes.news}
        component={NewsController}
        options={{ title: "NOOZ.AI | Latest News" }}
      />

      <Stack.Screen
        name={routes.mynews}
        component={MyNewsController}
        options={{ title: "NOOZ.AI | My News" }}
      />

      <Stack.Screen
        name={routes.trending}
        component={TrendingController}
        options={{ title: "NOOZ.AI | Trending" }}
      />

      <Stack.Screen
        name={routes.morelikethis}
        component={NewslikeThisController}
        options={{ title: "NOOZ.AI | More Like This" }}
      />
      <Stack.Screen
        name={routes.marketing}
        component={AppMarketingController}
        options={{ title: "NOOZ.AI | Wise Up to Media Bias" }}
      />
      <Stack.Screen
        name={routes.analysis}
        component={AnalysisController}
        options={{ title: "NOOZ.AI | Analysis" }}
      />

      <Stack.Screen
        name={routes.deeplink}
        component={AnalysisController}
        options={{ title: "NOOZ.AI | Analysis" }}
      />

      <Stack.Screen
        name={routes.privacy}
        component={PrivacyController}
        options={{ title: "NOOZ.AI | Privacy" }}
      />

      <Stack.Screen
        name={routes.terms}
        component={TermsController}
        options={{ title: "NOOZ.AI | Terms" }}
      />

      <Stack.Screen
        name={routes.cookies}
        component={CookiesController}
        options={{ title: "NOOZ.AI | Cookies" }}
      />

      <Stack.Screen
        name={routes.contestrules}
        component={ContestRulesController}
        options={{ title: "NOOZ.AI | Contest Rules" }}
      />

      <Stack.Screen
        name={routes.insidercontest}
        component={InsiderContestController}
        options={{ title: "NOOZ.AI | Insider Contest" }}
      />

      <Stack.Screen
        name={routes.settings}
        component={SettingsController}
        options={{ title: "NOOZ.AI | Settings" }}
      />

      <Stack.Screen
        name={routes.development}
        component={DevelopmentController}
        options={{ title: "NOOZ.AI | Development" }}
      />

      <Stack.Screen
        name={routes.developmentjson}
        component={DevelopmentJSON}
        options={{ title: "NOOZ.AI | JSON Controller" }}
      />

      <Stack.Screen
        name={routes.sources}
        component={TopSourcesController}
        options={{ title: "NOOZ.AI | Sources" }}
      />

      <Stack.Screen
        name={routes.categories}
        component={TopCategoriesController}
        options={{ title: "NOOZ.AI | Categories" }}
      />

      <Stack.Screen
        name={routes.feeds}
        component={TopFeedsController}
        options={{ title: "NOOZ.AI | Feeds" }}
      />

      <Stack.Screen
        name={routes.authors}
        component={TopAuthorsController}
        options={{ title: "NOOZ.AI | Authors" }}
      />

      <Stack.Screen
        name={routes.filter}
        component={FilterNewsController}
        options={{ title: "NOOZ.AI | Filtered" }}
      />

      <Stack.Screen
        name={routes.keyword}
        component={KeywordNewsController}
        options={{ title: "NOOZ.AI | Keyword" }}
      />

      <Stack.Screen
        name={routes.languageanalysis}
        component={LanguageAnalysisController}
        options={{ title: "NOOZ.AI | Language Analysis" }}
      />

      <Stack.Screen
        name={routes.notFound}
        component={NoFoundController}
        options={{ title: "NOOZ.AI | No Found" }}
      />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default AppNavigator;
