import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtPropagandaImage({
  name,
  size = 20,
  color = "red",
  backgroundColor = "white",
}) {
  return (
    <View style={[styles.container, { backgroundColor: backgroundColor }]}>
      <NtMaterialIcon name={name} size={size} color={color} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    borderColor: colors.teal,
    borderWidth: 1,
    borderRadius: 6,
    padding: 4,
  },
});

export default NtPropagandaImage;
