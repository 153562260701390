const developeroptions = [
  {
    id: "developer",
    title: "View JSON",
    icon: "code-json",
  },
  {
    id: "twitter",
    title: "Post in Twitter",
    icon: "twitter",
  },
];

export default developeroptions;
