export const hootLabel = (id) => {
  if (id === "default") {
    return "Label Only";
  } else if (id === "reaction") {
    return " Reaction Only";
  } else if (id === "metrics") {
    return "Label with Metrics";
  } else if (id === "advanced") {
    return "Reaction with Metrics";
  }
};

export const hootStyle = (value) => {
  let color = "#e46c15";
  let image = require("../../assets/images/owl-kidding.png");
  let label = "Extreme Influence";
  let advancedLabel = "You're Kidding";
  let description =
    "Indicates an extreme amount of persuasive language and activity that could be interpreted as surprising, unserious, or unbelievable.";

  if (value <= 25) {
    color = "#12bc75";
    image = require("../../assets/images/owl-fair.png");
    label = "Low Influence";
    advancedLabel = "Fair Enough";
    description =
      "Indicates a slight amount of persuasive language and activity that could be interpreted as reasonable or understandable.";
  } else if (value <= 50) {
    color = "#4896df";
    image = require("../../assets/images/owl-fishy.png");
    label = "Moderate Influence";
    advancedLabel = "Smells Fishy";
    description =
      "Indicates a partial amount of persuasive language and activity that could be interpreted as something seems suspicious even if it’s not.";
  } else if (value <= 75) {
    color = "#d0c023";
    image = require("../../assets/images/owl-pulling-strings.png");
    label = "High Influence";
    advancedLabel = "Pulling Strings";
    description =
      "Indicates a high amount of persuasive language and activity that could be interpreted as trying to secretly control the narrative.";
  }

  return {
    color: color,
    image: image,
    label: label,
    advancedLabel: advancedLabel,
    description: description,
  };
};
