import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtFollowingItem({ selected = false, onPress, style }) {
  return (
    <NtTouchableEffect
      style={[
        styles.container,
        selected ? styles.selected : styles.unselected,
        style,
      ]}
      onPress={onPress}
    >
      <NtMaterialIcon
        name={selected ? "check-bold" : "plus"}
        size={18}
        color={selected ? "white" : colors.teal}
      />
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 30,
    height: 30,
    backgroundColor: colors.dark,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,
  },
  selected: {
    backgroundColor: colors.dark,
  },
  unselected: {
    backgroundColor: "transparent",
    borderColor: colors.teal,
    borderWidth: 3,
  },
});

export default NtFollowingItem;
