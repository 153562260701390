const settings = {
  apiUrl: "https://nooz.ntlk.co/api/v1",
  apiKey: "SBSuHGcKg32xE58mCfQk49hzqsM8umZK",
  apiUserAgent: "haimdall-news-client-3838320asdu47290",

  twitterApiUrl: "https://p3sfvxu3qi.execute-api.us-west-2.amazonaws.com/dev",

  firebaseKey: "AIzaSyB05veo3ynkchzEKS-195n5_hCgswwm-CI",

  //ad units ....
  // adNativeUnitiOSId: 'ca-app-pub-7409788762662319/1562932915',
  // adNativeUnitAndroidId: 'ca-app-pub-7409788762662319/3422809498',

  //testing units
  adNativeUnitiOSId: "ca-app-pub-3940256099942544/2247696110",
  adNativeUnitAndroidId: "ca-app-pub-3940256099942544/2247696110",

  //crips app id
  cripsAppId: "0e202a3b-af6f-4302-befe-7d1881d16aab",

  pageSizeLoad: 25,
  mobileSize: 880,

  defaultScoreLayout: "default",
};

export default settings;
