import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtCard from "../../components/NtCard";
import colors from "../../config/colors";
import NtRevisionProgress from "./NtRevisionProgress";
import NtText from "../../components/NtText";
import NtObjectivityProgress from "./NtObjectivityProgress";
import NtSentimentProgress from "./NtSentimentProgress";
import {
  parseRevisionFromNewsItem,
  parseTimestampFromNewsItem,
  parseSentimentFromNewsItem,
  parseObjectivityFromNewsItem,
} from "../utils/NewsParserUtils";
import {
  revisionStyle,
  sentimentStyle,
  objectivityStyle,
} from "../../common/utils/ControllerUtils";
import NtDisclosure from "../../components/NtDisclosure";

function NtAnalysisRevisionSelectCard({
  containerStyle,
  story,
  onCardPress,
  showDisclosure = true,
}) {
  //objectivity
  const objectivity = parseObjectivityFromNewsItem(story);
  const objStyle = objectivityStyle(objectivity);

  //sentiment
  const sentiment = parseSentimentFromNewsItem(story);
  const sentStyle = sentimentStyle(sentiment);

  //revision
  const revision = parseRevisionFromNewsItem(story);
  const revStyle = revisionStyle(revision);

  return (
    <NtCard onPress={onCardPress} style={[styles.container, containerStyle]}>
      <View style={styles.innerContainer}>
        <View style={{ width: 60 }}>
          <View style={[styles.revisionContainer]}>
            {/* revision icon */}
            <View style={{ flexDirection: "column", alignItems: "center" }}>
              <NtRevisionProgress
                progress={revision}
                size={30}
                width={3}
                fontSize={10}
                iconSize={25}
                showProgress={false}
                fontColor={revStyle.color}
                filledColor={revStyle.color}
                icon={revStyle.icon}
                iconColor={revStyle.color}
              />
            </View>

            {/* revision count */}
            <View style={{ flexDirection: "column", alignItems: "center" }}>
              <NtText
                style={[styles.revisionText, { color: revStyle.fontColor }]}
              >
                {revision}
              </NtText>
            </View>
          </View>
        </View>

        <View style={{ flex: 1 }}>
          {/* publish date */}
          <View style={[styles.statsContainer]}>
            <NtText style={[styles.time]}>
              {parseTimestampFromNewsItem(story, true)}
            </NtText>
          </View>

          {/* nooz scores */}
          <View style={styles.styleContainer}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <NtObjectivityProgress
                containerStyle={styles.styleGraph}
                progress={objectivity}
                size={30}
                width={3}
                fontSize={10}
                fontColor={objStyle.color}
                filledColor={objStyle.color}
                icon={objStyle.icon}
                iconColor={objStyle.color}
                iconSize={20}
                showProgress={false}
              />
              <NtText style={[styles.styleValue, { color: objStyle.color }]}>
                {objectivity}%
              </NtText>
            </View>

            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <NtSentimentProgress
                containerStyle={styles.styleGraph}
                progress={sentiment}
                size={30}
                width={3}
                fontSize={10}
                fontColor={sentStyle.color}
                filledColor={sentStyle.color}
                icon={sentStyle.icon}
                iconColor={sentStyle.color}
                iconSize={22}
                showProgress={false}
              />
              <NtText style={[styles.styleValue, { color: sentStyle.color }]}>
                {sentiment}%
              </NtText>
            </View>
          </View>
        </View>

        {showDisclosure && (
          <NtDisclosure
            name={"chevron-right"}
            color={colors.darker}
            size={35}
          />
        )}
      </View>
    </NtCard>
  );
}

const styles = StyleSheet.create({
  container: {},
  innerContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  revisionContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    minWidth: 55,
    backgroundColor: colors.lightest,
    paddingHorizontal: 6,
    paddingVertical: 5,
    marginRight: 0,
    borderRadius: 6,
  },
  stats: {
    justifyContent: "center",
    alignItems: "center",
  },
  revisionText: {
    color: colors.darker,
    fontSize: 15,
    fontWeight: "400",
  },
  time: {
    fontSize: 16,
    marginLeft: 10,
    color: colors.darker,
  },
  styleContainer: {
    marginLeft: 0,
    flexDirection: "row",
    marginTop: 10,
    alignItems: "center",
  },
  styleGraph: {
    marginLeft: 10,
  },
  styleValue: {
    fontSize: 13,
    fontWeight: "600",
    marginLeft: 5,
  },
});

export default NtAnalysisRevisionSelectCard;
