import React, { useState } from "react";
import { View, StyleSheet, Image, useWindowDimensions } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";

function NtAnalysisPage({ title, description, image }) {
  const { height, width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);

  //had to make this because of issues with swiper that it preloads the pages and then it will not refresh them...
  if (width <= 760 && !isMobile) {
    setIsMobile(true);
  } else if (width > 760 && isMobile) {
    setIsMobile(false);
  }

  return (
    <View
      style={[styles.container, { flexDirection: isMobile ? "column-reverse" : "row" }]}
    >
      <View style={styles.textContainer}>
        <NtText style={styles.title}>{title}</NtText>
        <NtText style={styles.description}>{description}</NtText>
      </View>
      <Image style={styles.image} source={image} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    padding: 20,
  },
  textContainer: {
    flex: 1,
  },
  title: {
    color: "white",
    fontSize: 24,
    fontWeight: "700",
  },
  description: {
    color: colors.teal,
    fontSize: 17,
    lineHeight:30,
    fontWeight: "500",
    marginTop: 20,
  },
  image: {
    width: 280,
    height: 280,
    resizeMode: "contain",
    marginLeft: 10,
    marginBottom: 30,
  },
});

export default NtAnalysisPage;
