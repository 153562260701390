import React from "react";
import { View, StyleSheet } from "react-native";
import NtAnalysisTitleView from "./NtAnalysisTitleView";
import NtQuickAnalysisController from "./quickanalysis/NtQuickAnalysisController";

function NtAnalysisQuickAnalysisController({
  story,
  stories,
  onGhostEditsPress,
  onRevisionPress,
}) {
  return (
    <View style={styles.container}>
      <NtAnalysisTitleView icon={"lightning-bolt"} title={"Quick Analysis"} />
      <View style={{ height: 15 }} />
      <View
        style={{
          padding: 10,
          marginLeft: -15,
          marginRight: -15,
        }}
      >
        <View style={{}}>
          <NtQuickAnalysisController story={story} stories={stories} />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginLeft: 15,
    marginRight: 15,
  },
});

export default NtAnalysisQuickAnalysisController;
