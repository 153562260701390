import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtRevisionProgress from "./NtRevisionProgress";
import { revisionStyle } from "../../common/utils/ControllerUtils";
import { parseRevisionFromNewsItem } from "../utils/NewsParserUtils";
import NtAnalysisRevisionSelectCard from "./NtAnalysisRevisionSelectCard";
import NtAnalysisSentenceComparison from "./NtAnalysisSentenceComparison";
import NtAnalysisTitleView from "./NtAnalysisTitleView";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtAnalysisComparisonReaderController({
  storyNewer,
  storyOlder,
  historyCount = 1,
  onCompareToPress,
}) {
  const storyLeft =
    parseRevisionFromNewsItem(storyNewer) <=
    parseRevisionFromNewsItem(storyOlder)
      ? storyNewer
      : storyOlder;
  const storyRight =
    parseRevisionFromNewsItem(storyNewer) >=
    parseRevisionFromNewsItem(storyOlder)
      ? storyNewer
      : storyOlder;

  //left
  //revision
  const leftRevision = parseRevisionFromNewsItem(storyLeft);
  const leftRevStyle = revisionStyle(leftRevision);

  //right
  //revision
  const rightRevision = parseRevisionFromNewsItem(storyRight);
  const rightRevStyle = revisionStyle(rightRevision);

  return (
    <View style={styles.container}>
      {/* title view */}
      <NtAnalysisTitleView
        containerStyle={{ marginLeft: 15, marginRight: 15 }}
        icon={"file-compare"}
        title={"Comparison Reader"}
        summary={
          "Select the two versions you want to compare and see the changes made by the editor."
        }
      />

      <View style={{ height: 30 }} />

      <NtText style={[styles.compareTitle]}>Compare To:</NtText>
      <NtAnalysisRevisionSelectCard
        containerStyle={{ marginLeft: 15, marginRight: 15 }}
        onCardPress={() => {
          if (historyCount > 1) {
            onCompareToPress();
          }
        }}
        story={storyOlder}
        showDisclosure={historyCount > 1}
      />

      <View style={{ height: 30 }} />

      {/* will now render the flatlist... for the diff */}
      <View style={styles.compareContainer}>
        <View style={styles.compareTitleContainer}>
          {/* older */}
          <View style={styles.revisionContainer}>
            <NtRevisionProgress
              progress={leftRevision}
              size={30}
              width={3}
              fontSize={10}
              iconSize={25}
              showProgress={false}
              fontColor={leftRevStyle.color}
              filledColor={leftRevStyle.color}
              icon={leftRevStyle.icon}
              iconColor={leftRevStyle.color}
            />
            <NtText style={[styles.revisionText]}>{leftRevision}</NtText>
          </View>

          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <NtMaterialIcon
              name={"arrow-left"}
              size={22}
              color={colors.lightest}
            />
            <NtText style={styles.compareTitleText}>
              Versions{"\n"}Compared
            </NtText>
            <NtMaterialIcon
              name={"arrow-right"}
              size={22}
              color={colors.lightest}
            />
          </View>
          {/* newer */}
          <View style={styles.revisionContainer}>
            <NtRevisionProgress
              progress={rightRevision}
              size={30}
              width={3}
              fontSize={10}
              iconSize={25}
              showProgress={false}
              fontColor={rightRevStyle.color}
              filledColor={rightRevStyle.color}
              icon={rightRevStyle.icon}
              iconColor={rightRevStyle.color}
            />
            <NtText style={[styles.revisionText]}>{rightRevision}</NtText>
          </View>
        </View>
        <View style={styles.compareHeaderContainer}>
          <NtText style={styles.compareHeaderText}>Old{"\n"}Score</NtText>
          <NtText style={styles.compareHeaderText}>Text{"\n"}Edits</NtText>
          <NtText style={styles.compareHeaderText}>New{"\n"}Score</NtText>
        </View>
        {/* will hold the comparison */}
        <View>
          {/* {Array.from(Array(sentencesOld.length - 1).keys()).map(
            (key, index) => (
              <NtAnalysisSentenceComparison
                sentenceNew={sentencesNew[index]}
                sentenceOld={sentencesOld[index]}
              />
            ),
          )} */}

          <NtAnalysisSentenceComparison
            storyOld={storyLeft}
            storyNew={storyRight}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  titleText: {
    fontSize: 25,
    color: colors.darkest,
    marginLeft: 4,
    fontWeight: "600",
  },
  titleSummary: {
    fontSize: 18,
    color: colors.darkest,
    marginTop: 4,
    fontWeight: "200",
    marginLeft: 15,
    marginRight: 15,
  },

  compareTitle: {
    color: colors.darkest,
    fontSize: 17,
    fontWeight: "400",
    marginBottom: 10,
    marginLeft: 10,
    marginLeft: 15,
  },
  compareContainer: {},
  compareTitleContainer: {
    backgroundColor: colors.darker,
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
  },
  compareTitleText: {
    color: colors.lightest,
    fontSize: 17,
    marginRight: 10,
    marginLeft: 10,
    textAlign: "center",
  },
  revisionContainer: {
    width: 35,
    alignItems: "center",
  },
  revisionText: {
    color: "white",
    marginTop: 5,
  },
  compareHeaderContainer: {
    backgroundColor: colors.dark,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
  },
  compareHeaderText: {
    fontSize: 16,
    color: "white",
    fontWeight: "400",
    textAlign: "center",
  },
});

export default NtAnalysisComparisonReaderController;
