//https://reactnavigation.org/docs/configuring-links/#passing-params
//important links: https://reactnavigation.org/blog/2020/05/16/web-support/

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { View, StyleSheet } from "react-native";
import NoFoundController from "../common/controllers/NoFoundController";
import AppMarketingController from "../marketing/controllers/AppMarketingController";
import routes from "./routes";
import PrivacyController from "../legal/controllers/PrivacyController";
import TermsController from "../legal/controllers/TermsController";
import CookiesController from "../legal/controllers/CookiesController";
import AuthController from "../auth/controllers/AuthController";
import ContestRulesController from "../promotions/controllers/ContestRulesController";
import InsiderContestController from "../promotions/controllers/InsiderContestController";

const Stack = createNativeStackNavigator();

function AuthNavigator({ onControllerChanged, initialRoute }) {
  return (
    <Stack.Navigator
      initialRouteName={initialRoute}
      screenListeners={{
        state: (e) => {
          const controller =
            e.data.state.routes[e.data.state.routes.length - 1].name;
          if (onControllerChanged) {
            onControllerChanged(controller);
          }
        },
      }}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name={routes.auth}
        component={AuthController}
        options={{ title: "NOOZ.AI | Auth" }}
      />

      <Stack.Screen
        name={routes.marketing}
        component={AppMarketingController}
        options={{ title: "NOOZ.AI | Wise Up to Media Bias" }}
      />

      <Stack.Screen
        name={routes.privacy}
        component={PrivacyController}
        options={{ title: "NOOZ.AI | Privacy" }}
      />

      <Stack.Screen
        name={routes.terms}
        component={TermsController}
        options={{ title: "NOOZ.AI | Terms" }}
      />

      <Stack.Screen
        name={routes.cookies}
        component={CookiesController}
        options={{ title: "NOOZ.AI | Cookies" }}
      />

      <Stack.Screen
        name={routes.contestrules}
        component={ContestRulesController}
        options={{ title: "NOOZ.AI | Contest Rules" }}
      />

      <Stack.Screen
        name={routes.insidercontest}
        component={InsiderContestController}
        options={{ title: "NOOZ.AI | Insider Contest" }}
      />

      <Stack.Screen
        name={routes.notFound}
        component={NoFoundController}
        options={{ title: "NOOZ.AI | No Found" }}
      />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default AuthNavigator;
