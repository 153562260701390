import React from "react";
import { View, StyleSheet } from "react-native";
import NtLanguageAnalysisScoreDisplayTitle from "./NtLanguageAnalysisScoreDisplayTitle";
import {
  parseHootValuesFromNewsItem,
  parseSentencesFromNewsItem,
} from "../../utils/NewsParserUtils";
import { hootStyle } from "../../utils/HootParserUtils";
import { propagandaLabel, propagandaIcon } from "../../utils/NoozMetricUtils";
import colors from "../../../config/colors";
import { groupPropaganda, propagandaStyle } from "../../utils/PropagandaUtils";
import NtMaterialIcon from "../../../components/NtMaterialIcon";
import NtText from "../../../components/NtText";
import NtQuickAnalysisTitleView from "../quickanalysis/NtQuickAnalysisTitleView";
import NtQuickAnalysisMetricProgressbar from "../quickanalysis/NtQuickAnalysisMetricProgressbar";
import NtQuickAnalysisMetricDescription from "../quickanalysis/NtQuickAnalysisMetricDescription";
import { propagandaDescription } from "../../utils/NoozMetricUtils";
import Logger from "../../../common/utils/Logger";
import NtPropagandaImage from "../NtPropagandaImage";

function NtLanguageAnalysisScoreDisplayPropaganda({ story }) {
  const hoot = parseHootValuesFromNewsItem(story);
  const style = hootStyle(hoot.hoot);

  const propaganda = groupPropaganda(story);
  const sentences = parseSentencesFromNewsItem(story);

  //propaganda
  const propLabel = propagandaLabel(hoot.propagandaPersentage);
  const propIcon = propagandaIcon(hoot.propagandaPersentage);

  const renderPropagandaTypes = (constainerStyle) => {
    if (propaganda.itemKeys.length === 0) {
      return null;
    }

    return (
      <NtText style={[constainerStyle]}>
        {propaganda.itemKeys.map((each, index) =>
          renderPropagandaType(each, index)
        )}
      </NtText>
    );
  };

  const renderPropagandaType = (type, index) => {
    const style = propagandaStyle(type);

    return (
      <View
        key={index}
        style={{
          flexDirection: "row",
          marginRight: 10,
          marginBottom: 6,
          borderRadius: 15,
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 4,
          paddingBottom: 4,
          borderWidth: 1,
          alignItems: "center",
          borderColor: colors.dark,
        }}
      >
        <NtMaterialIcon size={18} name={style.icon} color={colors.dark} />
        <NtText style={[styles.propagandaTypeText, { marginLeft: 8 }]}>
          {style.name}
        </NtText>
        <NtText style={[styles.propagandaTypeCount, { marginLeft: 8 }]}>
          {propaganda.items[type].length}
        </NtText>
      </View>
    );
  };

  const renderReverseCardContent = (icon, name, value) => {
    return (
      <View style={styles.topReverseCard}>
        <NtMaterialIcon
          name={icon}
          color={colors.noozscorePropaganda}
          size={24}
        />
        <NtText
          style={[styles.topCardValue, { marginTop: 4, marginBottom: 4 }]}
        >
          {value}
        </NtText>
        <NtText style={[styles.topCardDescription, { marginLeft: 10 }]}>
          {name}
        </NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtLanguageAnalysisScoreDisplayTitle
        title={"Propaganda Analysis"}
        label={propLabel.label}
        letter={"P"}
        icon={propIcon.icon}
        score={hoot.propagandaPersentage}
        color={colors.noozscorePropaganda}
      />

      <View style={{ flexDirection: "row", marginTop: 20 }}>
        {renderReverseCardContent(
          "label-multiple",
          "Types of Propaganda",
          propaganda.itemKeys.length
        )}
        <View style={{ width: 10 }} />
        {renderReverseCardContent(
          "stamper",
          "Propaganda Occurences",
          hoot.propagandaTotal
        )}
        <View style={{ width: 10 }} />
        {renderReverseCardContent(
          "text",
          "Sentences in Article",
          sentences.length
        )}
      </View>

      {renderPropagandaTypes({ marginTop: 20 })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    borderRadius: 2,
    padding: 15,
  },
  topReverseCard: {
    flex: 1,
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 15,
    borderRadius: 6,
    backgroundColor: colors.lighestGray,
  },
  topCardValue: {
    fontSize: 17,
    color: colors.darkest,
    fontWeight: "700",
    textAlign: "center",
  },
  topCardDescription: {
    fontSize: 11,
    color: colors.darkest,
    textAlign: "center",
  },
  propagandaTypeText: {
    color: colors.dark,
    fontSize: 12,
    fontWeight: "500",
  },
  propagandaTypeCount: {
    color: colors.dark,
    fontSize: 14,
    fontWeight: "bold",
  },
});

export default NtLanguageAnalysisScoreDisplayPropaganda;
