import React, { useState } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import NtTrendingRowArticleItem from "./NtTrendingRowArticleItem";
import NtText from "../../../components/NtText";
import colors from "../../../config/colors";
import NtTouchableEffect from "../../../components/NtTouchableEffect";
import NtTrendingRowTabItem from "./NtTrendingRowTabItem";

function NtTrendingRowArticles({ stories, containerStyle, onReadArticle }) {
  const [selected, setSelected] = useState(stories[0]);

  const tabOptions = [
    {
      name: "Sentiment",
      title: "Sentiment Analysis",
      key: "sentiment",
    },
    {
      name: "Propaganda",
      title: "Propaganda Analysis",
      key: "propaganda",
    },
    {
      name: "Opinion",
      title: "Opinion Analysis",
      key: "opinion",
    },
    {
      name: "CBT",
      title: "Cognitive bias Therapy (CBT)",
      key: "cbt",
    },
    {
      name: "Cognitive Distortion",
      title: "Cognitive Distortion",
      key: "cognitive_distortion",
    },
    {
      name: "Emotion Cause",
      title: "Emotion Cause Extraction",
      key: "emotion_cause",
    },
  ];

  const [tabOption, setTabOption] = useState(tabOptions[0]);

  const renderRow = (item) => {
    return (
      <NtTrendingRowArticleItem
        selected={item === selected}
        story={item}
        onPress={(story) => {
          if (story === selected) {
            setSelected(null);
          } else {
            setSelected(story);
          }
        }}
      />
    );
  };
  return (
    <View style={[styles.container, containerStyle]}>
      <FlatList
        horizontal={true}
        style={styles.topStoriesList}
        data={stories}
        showsHorizontalScrollIndicator={false}
        ItemSeparatorComponent={() => {
          return <View style={{ width: 10 }} />;
        }}
        keyExtractor={(item, index) => item.url}
        renderItem={({ item }) => renderRow(item)}
      />

      {selected && (
        <View>
          <NtText style={[styles.source]}>{selected?.source}</NtText>
          <NtText style={[styles.title, { marginTop: 5 }]}>
            {selected?.title}
          </NtText>
          <NtTouchableEffect
            onPress={() => {
              onReadArticle(selected);
            }}
          >
            <NtText style={[styles.readArticle, { marginTop: 5 }]}>
              Read The Article
            </NtText>
          </NtTouchableEffect>

          <View
            style={{
              backgroundColor: colors.lighestGray,
              borderRadius: 10,
              padding: 8,
              marginTop: 10,
            }}
          >
            <View
              style={{ flexDirection: "row", justifyContent: "flex-start" }}
            >
              {tabOptions.map((each) => (
                <NtTrendingRowTabItem
                  containerStyle={{ marginRight: 10 }}
                  key={each.name}
                  option={each}
                  selected={each.name === tabOption.name}
                  onPress={(option) => {
                    setTabOption(option);
                  }}
                />
              ))}
            </View>
            <NtText style={[styles.tabTitle, { marginTop: 10 }]}>
              {tabOption.title}
            </NtText>

            {tabOption.key !== "sentiment" &&
              tabOption.key !== "propaganda" &&
              tabOption.key !== "opinion" && (
                <NtText style={[styles.summary, { marginTop: 15 }]}>
                  {selected[tabOption.key]?.trim()}
                </NtText>
              )}
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  source: {
    color: colors.dark,
    fontSize: 13,
    fontWeight: "400",
  },
  title: {
    color: colors.darkest,
    fontSize: 16,
    fontWeight: "600",
  },
  tabTitle: {
    color: colors.darker,
    fontSize: 15,
    fontWeight: "600",
  },
  summary: {
    fontSize: 14,
    lineHeight: 20,
    color: colors.darker,
    fontWeight: "400",
  },
  readArticle: {
    color: colors.blue,
    fontSize: 10,
    fontWeight: "600",
  },
});

export default NtTrendingRowArticles;
