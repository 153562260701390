import React from "react";
import { View, StyleSheet } from "react-native";
import NtNewsPageContainer from "../components/NtNewsPageContainer";
import BaseNewsController from "./BaseNewsController";
import settings from "../../config/settings";
import Logger from "../../common/utils/Logger";
import { fetchStories } from "../../api/stories";
import NtNewsLoadMoreButton from "../components/NtNewsLoadMoreButton";
import NtTrendingRow from "../components/trending/NtTrendingRow";
import { parseIdFromNewsItem } from "../utils/NewsParserUtils";

class TrendingController extends BaseNewsController {
  skip = 0;
  pageSizeLoad = 10;
  state = {
    ...super.getParentState(),
    stories: [],
    firstTimeLoading: true,
    loading: false,
  };

  componentDidMount() {
    this.handleStoriesFetch(this.pageSizeLoad, this.skip, null, false);
  }

  handleStoriesFetch = async (
    limit,
    skip,
    startId,
    loadingMore,
    sortColumn = this.state.rankBy.sortColumn,
    sortOrder = this.state.rankBy.sortOrder,
    hours = this.state.rankWithin.hours,
    search = this.state.searchText
  ) => {
    Logger("Will fetch the Stories....", sortColumn, sortOrder, hours, search);

    this.setState({ loading: true });

    const response = await fetchStories(
      limit,
      skip,
      startId,
      { type: "GPT" },
      sortColumn,
      sortOrder,
      hours,
      search
    );
    if (response.ok) {
      if (!Array.isArray(response.data)) {
        Logger(
          "We didnt get an array as response so it could be something else: ",
          response.data
        );
        if (response.data.developerMode) {
          showToast("Developer", "Developer mode successfully enabled.");
          const user = { ...this.context.user, developer: true };
          this.context.updateUser(user);
        } else if (response.data.advancedConfiguration) {
          if (this.context.user.developer) {
            this.props.navigation.navigate(routes.development);
          } else {
            showToast("Oops...", "Need permissions to access this page.");
          }
        }

        this.handleSearch(null);
        return;
      }

      Logger("Finished fetching the Stories: ", response.data, response);

      if (loadingMore) {
        Logger("We are loading more... need to add to the state");
        this.setState({ stories: this.state.stories.concat(response.data) });
      } else {
        Logger("First time loading.... we need to set the state to first ");
        this.setState({ stories: response.data });
      }
    } else {
      Logger("Unable to load the news.....: ", response);
      this.setState({
        error: "Unable to load stories",
        stories: [],
      });
    }

    //will let the refreshing know is done loading....
    this.setState({ loading: false, firstTimeLoading: false });
  };

  renderRow = (item, index) => {
    return (
      <NtTrendingRow
        key={item._id}
        story={item}
        onReadArticle={(article) => {
          this.handleStoryClickRedirect(article);
        }}
      />
    );
  };

  render() {
    return (
      <NtNewsPageContainer pageTitle={"Trending"}>
        <View
          style={{
            flex: 1,
          }}
        >
          {this.state.stories.map((each, index) => this.renderRow(each, index))}
          {/* Will load more storyes button  */}
          {
            <View>
              <NtNewsLoadMoreButton
                loadingText={"Loading"}
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  const lastId = parseIdFromNewsItem(this.state.stories[0]);
                  this.skip = this.skip + this.pageSizeLoad;
                  this.handleStoriesFetch(
                    this.pageSizeLoad,
                    this.skip,
                    lastId,
                    true
                  );
                }}
              />
            </View>
          }
        </View>
      </NtNewsPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default TrendingController;
