import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import Base64 from "Base64";
import { fetchHistory, fetchStories } from "../../api/stories";
import Logger from "../../common/utils/Logger";
import BaseNewsController from "./BaseNewsController";
import NtNewsCardView from "../components/NtNewsCardView";
import colors from "../../config/colors";
import settings from "../../config/settings";
import {
  parseIdFromNewsItem,
  parseKeywordsFromNewsItem,
} from "../utils/NewsParserUtils";

import NtNewsLoadMoreButton from "../components/NtNewsLoadMoreButton";
import NtNewsPageContainer from "../components/NtNewsPageContainer";
import NtNewsPageContainerHeader from "../components/NtNewsPageContainerHeader";

class NewslikeThisController extends BaseNewsController {
  skip = 0;

  state = {
    ...super.getParentState(),
    baseStory: null,
    stories: [],
    loading: false,
  };

  componentDidMount() {
    super.componentDidMount();
    Logger("Parameters passed ", this.props.route.params);
    if (this.props.route && this.props.route.params) {
      //will need to url decode before doing the base64 decode...
      let url = decodeURIComponent(this.props.route.params.token);
      url = Base64.atob(url);

      this.handleHistoryFetch(url);
    }
  }

  handleHistoryFetch = async (url) => {
    const result = await fetchHistory(url);
    if (result.ok) {
      Logger("Just fetched the hisotry from API", result.data);
      this.setState({
        baseStory: result.data[result.data.length - 1],
      });

      setTimeout(() => {
        this.handleStoriesFetch(settings.pageSizeLoad, this.skip, null, false);
      }, 100);
    } else {
      Logger("Failed to get the story", result);
    }
  };

  handleStoriesFetch = async (
    limit,
    skip,
    startId,
    loadingMore,
    sortColumn = this.state.rankBy.sortColumn,
    sortOrder = this.state.rankBy.sortOrder,
    hours = this.state.rankWithin.hours
  ) => {
    Logger(
      "Will fetch the Stories....",
      limit,
      skip,
      startId,
      loadingMore,
      sortColumn,
      sortOrder,
      hours,
      {
        topic: true,
        keywords: parseKeywordsFromNewsItem(this.state.baseStory),
      }
    );

    this.setState({ loading: true });

    this.setState({ error: null });
    const response = await fetchStories(
      limit,
      skip,
      startId,
      {
        topic: true,
        keywords: parseKeywordsFromNewsItem(this.state.baseStory),
      },
      sortColumn,
      sortOrder,
      hours
    );
    if (response.ok) {
      // Logger('Finished fetching the Stories: ', response.data);
      if (loadingMore) {
        Logger("We are loading more... need to add to the state");
        this.setState({ stories: this.state.stories.concat(response.data) });
      } else {
        Logger(
          "First time loading.... we need to set the state to first ",
          response
        );
        this.setState({ stories: response.data });

        if (response.data && response.data.length === 0) {
          this.setState({
            error: `No stories found within the "${this.state.rankWithin.title.toLowerCase()}". ${"\n"}Try increasing the time range.`,
          });
        }
      }
    } else {
      Logger("Unable to load the news.....: ", response);
      this.setState({
        error: "Unable to load stories",
        stories: [],
      });
    }

    this.setState({ loading: false });
  };

  renderRow = (item, index) => {
    return (
      <NtNewsCardView
        key={parseIdFromNewsItem(item).toString() + index.toString()}
        story={item}
        metric={item.read_count}
        metricIcon={"eye"}
        messageCount={0}
        type={"row"}
        onCardPress={() => {
          Logger("Pressed on the card for item: ", item);
          this.handleStoryClickRedirect(item);
        }}
        onOptionPress={(key) => {
          Logger("Pressed on the more", key);
          if (key === "share") {
            this.handleShare(item);
          } else if (key === "morelikethis") {
            this.handleMoreLikeThisRedirect(item);
          }
        }}
        onNoozScorePress={() => {
          this.handleAnalysisNavigate(
            item,
            AnalysisController.TYPE_QUICKANALYSIS
          );
        }}
      />
    );
  };

  render() {
    return (
      <NtNewsPageContainer
        pageTitle="More Like This"
        isLoading={this.state.baseStory == null}
        showFooter={this.state.stories.length > 0}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          {/* Will render the current story */}
          {this.state.baseStory && this.renderRow(this.state.baseStory, -1)}

          {this.state.stories.map((each, index) => this.renderRow(each, index))}

          <View>
            <NtNewsLoadMoreButton
              loadingText={"Finding Similar Stories"}
              loading={this.state.loading}
              containerStyle={{ marginTop: 40, marginBottom: 40 }}
              onPress={() => {
                const lastId = parseIdFromNewsItem(this.state.stories[0]);
                this.skip = this.skip + settings.pageSizeLoad;
                this.handleStoriesFetch(
                  settings.pageSizeLoad,
                  this.skip,
                  lastId,
                  true
                );
              }}
            />
          </View>
        </View>
        {this.renderShareModal()}
      </NtNewsPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  headerText: {
    fontSize: 42,
    fontWeight: "600",

    color: colors.darkest,
  },
});

export default NewslikeThisController;
