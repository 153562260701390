import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtFollowingItem from "./NtFollowingItem";
import NtListSeparator from "../../components/NtListSeparator";
import NtTouchableEffect from "../../components/NtTouchableEffect";

function NtAnalysisTextOnlyReaderFollow({
  selected = false,
  type,
  name,
  onPress,
}) {
  return (
    <View style={styles.container}>
      <NtTouchableEffect
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
        onPress={onPress}
      >
        <NtFollowingItem
          style={{ width: 25, height: 25 }}
          selected={selected}
        />
        <NtText style={[styles.title, { marginLeft: 10 }]}>
          {selected ? "Following" : "Follow"} {type}:
          <NtText style={[styles.name, { marginLeft: 4 }]}> {name}</NtText>
        </NtText>
      </NtTouchableEffect>
      <NtListSeparator />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    flex: 1,
    color: colors.dark,
  },
  name: {
    color: colors.darker,
    fontWeight: "500",
  },
  separator: {
    width: "100%",
    height: 1,
    marginTop: 10,
    backgroundColor: colors.teal,
  },
});

export default NtAnalysisTextOnlyReaderFollow;
