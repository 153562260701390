import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import Logger from "../../common/utils/Logger";
import { fetchStories } from "../../api/stories";
import BaseNewsController from "./BaseNewsController";
import { parseIdFromNewsItem, parseSourceName } from "../utils/NewsParserUtils";
import AnalysisController from "./AnalysisController";
import settings from "../../config/settings";
import NtNewsCardView from "../components/NtNewsCardView";
import NtNewsLoadMoreButton from "../components/NtNewsLoadMoreButton";
import NtNewsPageContainer from "../components/NtNewsPageContainer";
import { showToast } from "../../common/utils/ControllerUtils";
import routes from "../../navigation/routes";
import NtText from "../../components/NtText";
import NtHoverView from "../../components/NtHoverView";

class NewsController extends BaseNewsController {
  skip = 0;

  state = {
    ...super.getParentState(),
    stories: [],
    firstTimeLoading: true,
    loading: false,
    selectionType: null,
    menuOpen: false,
  };

  componentDidMount() {
    super.componentDidMount();

    this.handleStoriesFetch(settings.pageSizeLoad, this.skip, null, false);
  }

  handleStoriesFetch = async (
    limit,
    skip,
    startId,
    loadingMore,
    sortColumn = this.state.rankBy.sortColumn,
    sortOrder = this.state.rankBy.sortOrder,
    hours = this.state.rankWithin.hours,
    search = this.state.searchText
  ) => {
    Logger("Will fetch the Stories....", sortColumn, sortOrder, hours, search);

    this.setState({ loading: true });

    const response = await fetchStories(
      limit,
      skip,
      startId,
      {},
      sortColumn,
      sortOrder,
      hours,
      search
    );
    if (response.ok) {
      if (!Array.isArray(response.data)) {
        Logger(
          "We didnt get an array as response so it could be something else: ",
          response.data
        );
        if (response.data.developerMode) {
          showToast("Developer", "Developer mode successfully enabled.");
          const user = { ...this.context.user, developer: true };
          this.context.updateUser(user);
        } else if (response.data.advancedConfiguration) {
          if (this.context.user.developer) {
            this.props.navigation.navigate(routes.development);
          } else {
            showToast("Oops...", "Need permissions to access this page.");
          }
        }

        this.handleSearch(null);
        return;
      }

      Logger("Finished fetching the Stories: ", response.data, response);

      if (loadingMore) {
        Logger("We are loading more... need to add to the state");
        this.setState({ stories: this.state.stories.concat(response.data) });
      } else {
        Logger("First time loading.... we need to set the state to first ");
        this.setState({ stories: response.data });
      }
    } else {
      Logger("Unable to load the news.....: ", response);
      this.setState({
        error: "Unable to load stories",
        stories: [],
      });
    }

    //will let the refreshing know is done loading....
    this.setState({ loading: false, firstTimeLoading: false });
  };

  handleRankChange = (rankBy, rankWithin) => {
    Logger("Will refresh now....");
    this.setState({ stories: [] });
    this.skip = 0;
    this.handleStoriesFetch(
      settings.pageSizeLoad,
      this.skip,
      null,
      false,
      rankBy.sortColumn,
      rankBy.sortOrder,
      rankWithin.hours
    );
  };

  handleSearch = (text) => {
    Logger("Handling search for text: ", text);
    this.setState({ searchText: text, stories: [] });

    setTimeout(() => {
      this.skip = 0; //will start from the begining.....
      this.handleStoriesFetch(
        settings.pageSizeLoad,
        this.skip,
        null,
        false,
        this.state.rankBy.sortColumn,
        this.state.rankBy.sortOrder,
        this.state.rankWithin.hours,
        text
      );
    }, 100);
  };

  renderRow = (item, index) => {
    return (
      <NtNewsCardView
        key={parseIdFromNewsItem(item).toString() + index.toString()}
        story={item}
        metric={item.read_count}
        metricIcon={"eye"}
        messageCount={0}
        type={index === 0 ? "header" : "row"}
        onCardPress={() => {
          Logger("Pressed on the card for item: ", item);
          this.handleStoryClickRedirect(item);
        }}
        onOptionPress={(key) => {
          Logger("Pressed on the more", key);
          if (key === "share") {
            this.handleShare(item);
          } else if (key === "morelikethis") {
            this.handleMoreLikeThisRedirect(item);
          }
        }}
        onNoozScorePress={() => {
          this.handleAnalysisNavigate(
            item,
            AnalysisController.TYPE_QUICKANALYSIS
          );
        }}
      />
    );
  };

  isFirstTimeLoading = () => {
    return false;
    return this.state.stories.length === 0 && this.state.loading;
  };

  render() {
    return (
      <NtNewsPageContainer
        pageTitle="Latest News"
        isLoading={this.state.firstTimeLoading}
        showFooter={true}
        sidePannelContainerStyle={{ paddingTop: 150 }}
        renderFilter={this.renderFilterController()}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          {this.state.stories.map((each, index) => this.renderRow(each, index))}
          {/* Will load more storyes button  */}
          {
            <View>
              <NtNewsLoadMoreButton
                loadingText={"Loading"}
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  const lastId = parseIdFromNewsItem(this.state.stories[0]);
                  this.skip = this.skip + settings.pageSizeLoad;
                  this.handleStoriesFetch(
                    settings.pageSizeLoad,
                    this.skip,
                    lastId,
                    true
                  );
                }}
              />
            </View>
          }
        </View>
        {this.renderRankByModal()}
        {this.renderRankWithinModal()}
        {this.renderShareModal()}
      </NtNewsPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  filterContainer: {
    width: "100%",
    backgroundColor: "white",
    alignItems: "center",
    flexDirection: "row",
  },
});

export default NewsController;
