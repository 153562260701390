import React from "react";
import { View, StyleSheet } from "react-native";
import NtNewsPageContainer from "../components/NtNewsPageContainer";
import NtNewsLoadMoreButton from "../components/NtNewsLoadMoreButton";
import BaseNewsController from "./BaseNewsController";
import settings from "../../config/settings";
import Logger from "../../common/utils/Logger";
import { fetchFeeds, saveUserFeeds, fetchUserFeeds } from "../../api/feeds";
import NtNewsSubcategoryRow from "../components/NtNewsSubcategoryRow";
import FilterNewsController from "./FilterNewsController";
import routes from "../../navigation/routes";
import rankbyoptions from "../data/rankbyoptions";

class TopFeedsController extends BaseNewsController {
  state = {
    ...super.getParentState(),
    rankBy: rankbyoptions[2],
    refreshing: false,
    feeds: [],
    userDefualts: [],
  };

  loadSkip = 0;

  componentDidMount() {
    super.componentDidMount();
    this.handleUserDefaultsFetch();
    this.handleFeedsFetch(settings.pageSizeLoad, this.loadSkip, false);
  }

  handleUserDefaultsFetch = async () => {
    const response = await fetchUserFeeds();
    this.setState({ userDefualts: response });
  };

  handleFeedsFetch = async (
    limit,
    skip,
    loadingMore,
    sortColumn = this.state.rankBy.sortColumn,
    sortOrder = this.state.rankBy.sortOrder,
    hours = this.state.rankWithin.hours,
    search = this.state.searchText
  ) => {
    Logger(
      "Will fetch the Feeds....",
      search ? { name: [search] } : {},
      limit,
      skip,
      loadingMore,
      search
    );
    this.setState({ refreshing: true });
    const response = await fetchFeeds(
      limit,
      skip,
      search ? { name: [search] } : {},
      sortColumn,
      sortOrder,
      hours
    );
    if (response.ok) {
      // Logger('Finished fetching the Feeds: ', response.data);
      if (loadingMore) {
        Logger("We are loading more... need to add to the state");
        this.setState({ feeds: this.state.feeds.concat(response.data) });
      } else {
        Logger("First time loading.... we need to set the state to first ");
        this.setState({ feeds: response.data });
      }
    }

    this.setState({ refreshing: false });
  };

  handleRankChange = (rankBy, rankWithin) => {
    this.loadSkip = 0;
    this.handleFeedsFetch(
      settings.pageSizeLoad,
      this.loadSkip,
      false,
      rankBy.sortColumn,
      rankBy.sortOrder,
      rankWithin.hours
    );
  };

  handleSearch = (text) => {
    Logger("Handling search for text: ", text);
    this.setState({ searchText: text });

    setTimeout(() => {
      this.loadSkip = 0; //will start from the begining.....
      this.handleFeedsFetch(settings.pageSizeLoad, this.loadSkip, false);
    }, 100);
  };

  checkIfUserDefault = (item) => {
    return (
      this.state.userDefualts.filter((each) => each.name === item.name).length >
      0
    );
  };

  renderRow = (item, index) => {
    return (
      <NtNewsSubcategoryRow
        key={index}
        name={item.name}
        metric={item.read_count}
        selected={this.checkIfUserDefault(item)}
        onSelection={() => {
          if (this.checkIfUserDefault(item)) {
            this.state.userDefualts = this.state.userDefualts.filter(
              (each) => each.name !== item.name
            );
          } else {
            this.state.userDefualts = [
              ...this.state.userDefualts,
              { name: item.name },
            ];
          }
          this.setState({ userDefaults: this.state.userDefualts });
          saveUserFeeds(this.state.userDefualts);
        }}
        onPress={() => {
          this.props.navigation.navigate(routes.filter, {
            type: FilterNewsController.sFILTER_FEED,
            name: item.name,
          });
        }}
      />
    );
  };

  render() {
    return (
      <NtNewsPageContainer
        pageTitle="Feeds"
        showFooter={true}
        renderFilter={this.renderFilterController()}
      >
        <View>
          {this.state.feeds.map((each, index) => this.renderRow(each, index))}

          {this.state.feeds.length > 0 && (
            <View>
              <NtNewsLoadMoreButton
                title={"Load More Feeds"}
                loadingText={"Loading"}
                loading={this.state.refreshing}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.loadSkip += settings.pageSizeLoad;
                  this.handleFeedsFetch(
                    settings.pageSizeLoad,
                    this.loadSkip,
                    true
                  );
                }}
              />
            </View>
          )}
        </View>

        {this.renderRankByModal()}
        {this.renderRankWithinModal()}
      </NtNewsPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default TopFeedsController;
