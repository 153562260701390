import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtNewsPageNavigationHamburgerButton from "./NtNewsPageNavigationHamburgerButton";
import NtNoozScoreInfoCard from "./NtNoozScoreInfoCard";
import NtNoozMetricInfoCard from "./NtNoozMetricInfoCard";
import NtNoozFormulaInfoCard from "./NtNoozFormulaInfoCard";
import colors from "../../config/colors";

function NtNewsPageInfoPannel({}) {
  return (
    <View style={styles.container}>
      {/* <View
        style={{
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <NtText style={styles.title}>Hide Panel</NtText>
        <NtNewsPageNavigationHamburgerButton color={colors.blue} />
      </View> */}

      <NtNoozScoreInfoCard containerStyle={{ marginTop: 40 }} />
      <NtNoozMetricInfoCard containerStyle={{ marginTop: 25 }} />
      <NtNoozFormulaInfoCard containerStyle={{ marginTop: 25 }} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {},
});

export default NtNewsPageInfoPannel;
