import { create } from "apisauce";

const twitterclient = create({
  baseURL: global.twitterApiUrl,
  timeout: 60000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

twitterclient.addAsyncRequestTransform(async (request) => {
  request.headers["Content-Type"] = "application/json";
  request.headers["Accept"] = "application/json";
  request.headers["key"] = "Oc7CJJCXUb83Cxs2HqBSzDE4Xv34O6B28psBfFF9";
});

export default twitterclient;
