import { Fontisto } from "@expo/vector-icons";
import React from "react";
import { View, StyleSheet, Image, ScrollView, FlatList } from "react-native";
import { TouchableOpacity } from "react-native-web";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtAnalysisPage from "../components/NtAnalysisPage";
import Swiper from "react-native-web-swiper";
import NtFeatureItem from "../components/NtFeatureItem";
import features from "../data/features";
import { asyncStorageGetItem } from "../../common/utils/ControllerUtils";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtFooterBar from "../../root/components/NtFooterBar";
import BaseController from "../../common/base/BaseController";
import NtMaterialIcon from "../../components/NtMaterialIcon";

const KEY_SUCCESS_SUBMITTED = "key-success-heimdall-submitted0";
class AppMarketingController extends BaseController {
  state = {
    email: "",
    isLoading: false,
    allowSubmit: false,
    isMobile: false,
  };
  swiperRef = React.createRef();

  componentDidMount() {
    this.checkIfSubmittable();
    super.componentDidMount();

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://app.viral-loops.com/widgetsV2/core/loader.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }

  checkIfSubmittable = async () => {
    const successSubmitted = await asyncStorageGetItem(KEY_SUCCESS_SUBMITTED);
    if (successSubmitted) {
      this.setState({ allowSubmit: false });
    } else {
      this.setState({ allowSubmit: true });
    }
  };

  renderButtonLink = (name, onPress, style = {}) => {
    return (
      <NtTouchableEffect style={style} onPress={onPress}>
        <NtText style={styles.footerTextLink}>{name}</NtText>
      </NtTouchableEffect>
    );
  };

  renderDownloadButton = (image, downloadLink) => {
    return (
      <TouchableOpacity
        onPress={() => {
          if (downloadLink) {
            window.open(downloadLink);
          }
        }}
      >
        <Image style={styles.downloadImage} source={image} />
      </TouchableOpacity>
    );
  };

  renderDownloadButtons = () => {
    return (
      <View style={{ marginTop: 20, marginBottom: 40, flexDirection: "row" }}>
        {this.renderDownloadButton(
          require("../../assets/images/landing-button-apple.png"),
          "https://apps.apple.com/us/app/nooz-ai-wise-up-to-media-bias/id1607868155"
        )}
        <View style={{ width: 10 }} />
        {this.renderDownloadButton(
          require("../../assets/images/landing-button-android.png"),
          "https://play.google.com/store/apps/details?id=com.heimdall.ntnooz"
        )}
      </View>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          {/* top container */}
          <View
            style={[
              styles.bannerContainer,
              { flexDirection: this.state.isMobile ? "column" : "row" },
            ]}
          >
            <View
              style={[
                styles.bannerInnerContainer,
                { flexDirection: this.state.isMobile ? "column" : "row" },
              ]}
            >
              <View style={{ flex: 1 }}>
                <Image
                  style={styles.bannerLogo}
                  source={require("../../assets/images/landing-logo-white.png")}
                />
                <NtText style={styles.bannerTitle}>
                  Wise Up to Media Bias
                </NtText>

                <View style={{ flex: 1 }} />

                {this.renderDownloadButtons()}
              </View>

              <Image
                style={styles.bannerImage}
                source={require("../../assets/images/nooz-banner-image-with-circle-v2.png")}
              />
            </View>
          </View>

          {/* slider container */}
          <View style={styles.sliderContainer}>
            <View
              style={[
                styles.sliderInnerContainer,
                { width: this.state.isMobile ? "100%" : 760 },
              ]}
            >
              <form-widget ucid="TcUr3bsaX7xSb7fPjzcQxVasus8"></form-widget>

              <NtText style={styles.sliderHeader}>What We Provide</NtText>
              <NtText style={styles.sliderTitle}>Article Analysis</NtText>
              <NtText style={styles.sliderDescription}>
                NOOZ is an AI-driven news aggregator that analyzes the news
                using natural language processing and machine learning to
                empower readers in identifying media influence. NOOZ provides
                insight into media bias by identifying opinion, sentiment,
                propaganda, and revisions.
              </NtText>

              <View
                style={[
                  styles.sliderImageContainer,
                  { flexDirection: this.state.isMobile ? "column" : "row" },
                ]}
              >
                <Image
                  style={styles.sliderImage}
                  source={require("../../assets/images/landing-nooz-whatshot.png")}
                />
                <Image
                  style={styles.sliderImage}
                  source={require("../../assets/images/landing-nooz-latestnews.png")}
                />
                <Image
                  style={styles.sliderImage}
                  source={require("../../assets/images/landing-nooz-revision.png")}
                />
              </View>
              <NtText style={styles.sliderSubTitle}>Types of Analysis</NtText>

              {/* pager */}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {!this.state.isMobile && (
                  <TouchableOpacity
                    onPress={() => {
                      this.swiperRef.current.goToPrev();
                    }}
                  >
                    <NtMaterialIcon
                      name="chevron-left"
                      color={"#55BFED"}
                      size={50}
                    />
                  </TouchableOpacity>
                )}

                <View
                  style={[
                    styles.pagerContainer,
                    { height: this.state.isMobile ? 560 : 320 },
                  ]}
                >
                  <Swiper
                    ref={this.swiperRef}
                    innerContainerStyle={{}}
                    containerStyle={{}}
                    slideWrapperStyle={{}}
                    from={1}
                    loop={true}
                    timeout={6}
                    controlsProps={{
                      prevTitle: "",
                      nextTitle: "",
                      dotsTouchable: true,
                    }}
                  >
                    <NtAnalysisPage
                      title={"Opinion Analysis"}
                      description={
                        "Discover how much a journalist expresses personal feelings, views, or judgments regarding the story's topic."
                      }
                      image={require("../../assets/images/opinion-gauge.png")}
                    />
                    <NtAnalysisPage
                      title={"Sentiment Analysis"}
                      description={
                        "Gauge the journalist's positivity (sympathy & support) or negativity (antagonism & opposition) concerning the story's topic."
                      }
                      image={require("../../assets/images/sentiment-gauge.png")}
                    />
                    <NtAnalysisPage
                      title={"Propaganda Analysis"}
                      description={
                        "Detect potential disinformation by identifying the usage of up to 18 possible persuasion techniques."
                      }
                      image={require("../../assets/images/propaganda-wheel.png")}
                    />
                    <NtAnalysisPage
                      title={"Revision Analysis"}
                      description={
                        "Investigate the evolution of a news story and its manipulation of opinion, sentiment, and propaganda over time."
                      }
                      image={require("../../assets/images/revisions-graphic.png")}
                    />
                  </Swiper>
                </View>

                {!this.state.isMobile && (
                  <TouchableOpacity
                    onPress={() => {
                      this.swiperRef.current.goToNext();
                    }}
                  >
                    <NtMaterialIcon
                      name="chevron-right"
                      color={"#55BFED"}
                      size={50}
                    />
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>

          {/* bottom controller */}
          <View style={styles.handContainer}>
            <View style={styles.handInnerContainer}>
              <View style={{ flex: 1, maxWidth: 440 }}>
                <NtText style={styles.handTitle}>Your News. Analyzed.</NtText>
                <NtText style={styles.handSubtitle}>
                  Our Artificial Intelligence keeps you mindful of news media
                  influence.
                </NtText>

                {this.renderDownloadButtons()}
              </View>

              {!this.state.isMobile && (
                <Image
                  style={styles.handImage}
                  source={require("../../assets/images/landing-nooz-hand.png")}
                />
              )}
            </View>
          </View>

          {/* featureContainer */}
          <View style={styles.featureContainer}>
            <NtText style={styles.featureTitle}>Features</NtText>
            <FlatList
              key={this.state.isMobile ? "#" : "_"}
              style={styles.flatlist}
              data={features}
              numColumns={this.state.isMobile ? 2 : 4}
              ItemSeparatorComponent={() => <View style={{ height: 20 }} />}
              keyExtractor={(item) =>
                (this.state.isMobile ? "#" : "_") + item.title.toString()
              }
              renderItem={({ item }) => (
                <NtFeatureItem
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  containerStyle={{ width: 200 }}
                />
              )}
            />
          </View>

          {/* Quote Container */}
          <View style={styles.quoteContainer}>
            <View style={{ flex: 1 }}>
              <Fontisto name={"quote-a-right"} size={35} color={"#140035"} />
              <NtText style={styles.quoteText}>
                Early in life I have noticed that no event is ever correctly
                reported in a newspaper, but in Spain, for the first time, I saw
                newspaper reports which did not bear any relation to the facts,
                not even the relationship which is implied in an ordinary lie. I
                saw great battles reported where there had been no fighting, and
                complete silence when hundreds of men had been killed. I saw
                troops who had fought bravely, denouced as cowards and traitors,
                and others who had never seen a shot fired, hailed as heroes of
                imaginary victories; and I saw newspapers in London retelling
                these lies and eager intellectuals building emotional
                superstructures over events that never happened. I saw, in fact,
                history being written, not in terms of what happened, but of
                what ought to have happened according to various party lines.
              </NtText>
              <View style={{ flex: 1, alignItems: "flex-end", marginTop: 10 }}>
                <Fontisto name={"quote-a-left"} size={35} color={"#140035"} />
              </View>

              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <Image
                  style={{ width: 40, height: 40 }}
                  source={require("../../assets/images/landing-george.png")}
                />

                <NtText style={styles.quoteAuthor}>George Orwell</NtText>
              </View>
            </View>
          </View>
          <NtFooterBar />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#57D1EF",
  },
  bannerLogo: {
    width: 300,
    height: 90,
    resizeMode: "contain",
  },
  bannerImage: {
    width: 400,
    height: 400,
    resizeMode: "contain",
    marginBottom: -42,
  },
  bannerTitle: {
    color: "white",
    fontSize: 25,
    fontWeight: "400",
  },
  bannerContainer: {
    width: "100%",
    backgroundColor: "#55BFED",
    paddingLeft: 30,
    paddingTop: 30,
    paddingRight: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  bannerInnerContainer: {
    minWidth: 760,
    justifyContent: "center",
    alignItems: "center",
  },
  pagerContainer: {
    flex: 1,
  },
  sliderContainer: {
    backgroundColor: "#140035",
    paddingTop: 30,
    paddingBottom: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  sliderInnerContainer: {
    justifyContent: "center",
    // alignItems: 'center',
  },
  sliderHeader: {
    marginTop: 30,
    color: "#53C3EA",
    fontSize: 40,
    width: "100%",
    fontWeight: "600",
    textAlign: "center",
  },
  sliderTitle: {
    color: "white",
    marginLeft: 30,
    fontSize: 25,
    fontWeight: "500",
    marginTop: 30,
  },
  sliderDescription: {
    fontSize: 17,
    lineHeight: 30,
    fontWeight: "500",
    color: colors.teal,
    marginLeft: 30,
    marginTop: 10,
  },
  sliderImageContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  sliderImage: {
    width: 200,
    height: 300,
    resizeMode: "contain",
  },
  sliderSubTitle: {
    color: "#53C3EA",
    fontSize: 35,
    width: "100%",
    fontWeight: "600",
    textAlign: "center",
    marginTop: 30,
  },
  handContainer: {
    width: "100%",
    backgroundColor: "#57D1EF",
    flexDirection: "row",
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 50,
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    //alignItems: 'center',
  },
  handInnerContainer: {
    maxWidth: 760,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  handTitle: {
    color: "white",
    fontSize: 40,
    width: "100%",
    fontWeight: "600",
    marginTop: 30,
    marginBottom: -20,
  },
  handSubtitle: {
    color: colors.darkest,
    fontSize: 27,
    width: "100%",
    fontWeight: "500",
    marginTop: 25,
  },
  handImage: {
    width: 300,
    height: 250,
    resizeMode: "contain",
  },
  downloadImage: {
    width: 140,
    height: 60,
    resizeMode: "contain",
  },
  featureContainer: {
    backgroundColor: "#140035",
    padding: 50,
    alignItems: "center",
  },
  featureTitle: {
    color: "white",
    fontSize: 35,
    fontWeight: "500",
    marginBottom: 20,
  },
  quoteContainer: {
    padding: 40,
    maxWidth: 900,
    alignSelf: "center",
    backgroundColor: "#57D1EF",
  },
  quoteText: {
    flex: 1,
    fontSize: 17,
    lineHeight: 26,
    color: colors.darkest,
    marginTop: 10,
    fontStyle: "italic",
  },
  quoteAuthor: {
    color: colors.darkest,
    fontSize: 18,
    fontWeight: "600",
    marginLeft: 10,
  },

  earlyAccessContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 30,
  },
  earlyAccessLable: {
    color: "white",
  },
  earlyAccessSubmitted: {
    color: colors.lightest,
    fontSize: 16,
    marginLeft: 6,
  },
});

export default AppMarketingController;
