import React, { useState, useContext } from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../../components/NtText";
import colors from "../../../config/colors";
import NtTouchableEffect from "../../../components/NtTouchableEffect";
import {
  parseConversationAudioFromItem,
  parseImageUrlFromNewsItem,
} from "../../utils/NewsParserUtils";
import { useMobile } from "../../../hooks/useMobile";
import NtTrendingArticleItem from "./NtTrendingRowArticleItem";
import NtMaterialIcon from "../../../components/NtMaterialIcon";
import NtTrendingArticleTabItem from "./NtTrendingArticleTabItem";
import UserContext from "../../../context/UserContext";
import { useNavigation } from "@react-navigation/native";
import routes from "../../../navigation/routes";
import NtTrendingRowTabItem from "./NtTrendingRowTabItem";
import NtTrendingRowArticles from "./NtTrendingRowArticles";
import ReactAudioPlayer from "react-audio-player";

function NtTrendingRow({ story, onReadArticle }) {
  const [open, setOpen] = useState(false);
  const [article, setArticle] = useState(null);
  const [tab, setTab] = useState(null);
  const isMobile = useMobile();
  const context = useContext(UserContext);
  const navigation = useNavigation();

  const imageUrl = parseImageUrlFromNewsItem(story);

  const tabOptions = [
    {
      name: "Story",
      title: "Story Summary",
      key: "story",
    },
    {
      name: "Conservative",
      title: "Conservative View Point",
      key: "story_conservative",
    },
    {
      name: "Liberal",
      title: "Liberal View Point",
      key: "story_liberal",
    },
    {
      name: "Highlights",
      title: "Story Highlights",
      key: "highlights",
    },
    {
      name: "Styles",
      title: "Writing Styles Comparison",
      key: "compare",
    },
    {
      name: "Persuasions",
      title: "Persuasion Techniques",
      key: "persuasion",
    },
    {
      name: "Articles",
      title: "Articles Examined",
      key: "articles",
    },
    {
      name: "Sarcasm",
      title: "Sarcasm",
      key: "sarcasm",
    },
    {
      name: "Satire",
      title: "Satire",
      key: "satire",
    },
  ];

  const [tabOption, setTabOption] = useState(tabOptions[0]);

  const tabs = [
    {
      title: "CBT",
      key: "cbt",
    },
    {
      title: "Cognitive Distortion",
      key: "cognitive_distortion",
    },
    {
      title: "Emotion Cause",
      key: "emotion_cause",
    },
    {
      title: "Persuasion",
      key: "persuasion",
    },
  ];

  const renderCloseButton = () => {
    return (
      <NtTouchableEffect
        style={{ flexDirection: "row", alignItems: "center" }}
        onPress={() => {
          setOpen(false);
        }}
      >
        <NtMaterialIcon size={18} name={"menu-up"} color={colors.blue} />
        <NtText style={styles.numberOfArticles}>{"Close"}</NtText>
      </NtTouchableEffect>
    );
  };

  const renderDevelopmentButton = (containerStyle) => {
    if (!context.user.developer) {
      return null;
    }

    return (
      <NtTouchableEffect
        style={[{ flexDirection: "row", alignItems: "center" }, containerStyle]}
        onPress={() => {
          navigation.navigate(routes.developmentjson, {
            item: story,
          });
        }}
      >
        <NtMaterialIcon size={18} name={"code-json"} color={colors.blue} />
        <NtText style={[styles.numberOfArticles, { marginLeft: 4 }]}>
          View JSON
        </NtText>
      </NtTouchableEffect>
    );
  };

  const renderArticles = (story, index) => {
    return (
      <NtTrendingArticleItem
        key={index}
        selected={story === article}
        story={story}
        onPress={() => {
          if (story === article) {
            setArticle(null);
          } else {
            setArticle(story);
            setTab(tabs[0]);
          }
        }}
      />
    );
  };

  return (
    <View style={styles.container}>
      <View>
        {/* compact view */}
        <NtTouchableEffect
          style={{
            flexDirection: isMobile ? "column" : "row",
            marginTop: 20,
          }}
          onPress={() => {
            setOpen(true);
          }}
        >
          {imageUrl && (
            <Image
              style={[
                styles.image,
                { marginRight: 15 },
                isMobile
                  ? { width: "100%", height: 200, marginBottom: 15 }
                  : {},
              ]}
              source={{
                uri: imageUrl,
              }}
            />
          )}

          <View
            style={{
              flex: 1,
            }}
          >
            <NtText style={[styles.headline]}>{story.headline?.trim()}</NtText>
            <View
              style={{
                marginTop: 8,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <NtText style={[styles.numberOfArticles, { marginRight: 15 }]}>
                {story.articles?.length} articles
              </NtText>

              {renderDevelopmentButton()}
            </View>
            <ReactAudioPlayer
              src={parseConversationAudioFromItem(context.user, story)}
              controls
            />
          </View>
        </NtTouchableEffect>

        {open && (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              borderColor: colors.lighestGray,
              borderWidth: 2,
              borderRadius: 10,
              padding: 10,
              marginTop: 10,
            }}
          >
            <View style={{ alignItems: "flex-end" }}>
              {renderCloseButton()}
            </View>

            {/* tabs options */}
            <View
              style={{ flexDirection: "row", justifyContent: "flex-start" }}
            >
              {tabOptions.map((each) => (
                <NtTrendingRowTabItem
                  containerStyle={{ marginRight: 10 }}
                  key={each.name}
                  option={each}
                  selected={each.name === tabOption.name}
                  onPress={(option) => {
                    setTabOption(option);
                  }}
                />
              ))}
            </View>

            <NtText style={[styles.title, { marginTop: 10 }]}>
              {tabOption.title}
            </NtText>

            {tabOption.key !== "articles" && (
              <NtText style={[styles.summary, { marginTop: 15 }]}>
                {story[tabOption.key]?.trim()}
              </NtText>
            )}

            {tabOption.key === "articles" && (
              <NtTrendingRowArticles
                containerStyle={{ marginTop: 15 }}
                stories={story[tabOption.key]}
                onReadArticle={(article) => {
                  onReadArticle(article);
                }}
              />
            )}

            <View style={{ height: 15 }} />
          </View>
        )}
      </View>

      <View
        style={{
          marginTop: 20,
          width: "100%",
          height: 1,
          backgroundColor: colors.lighter,
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  readmore: {
    color: colors.blue,
    fontWeight: "700",
    fontSize: 13,
  },

  image: {
    width: 200,
    height: 120,
    resizeMode: "cover",
  },
  headline: {
    fontSize: 18,
    lineHeight: 24,
    color: colors.darkest,
    fontWeight: "600",
  },
  numberOfArticles: {
    fontSize: 12,
    color: colors.blue,
    fontWeight: "600",
  },
  title: {
    color: colors.darker,
    fontSize: 18,
    fontWeight: "600",
  },

  summary: {
    fontSize: 14,
    lineHeight: 20,
    color: colors.darker,
    fontWeight: "400",
  },
  similarStories: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darkest,
  },
});

export default NtTrendingRow;
