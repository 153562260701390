import React from 'react';
import {View, StyleSheet} from 'react-native';
import NtText from '../../components/NtText';
import NtObjectivityProgress from './NtObjectivityProgress';
import NtSentimentProgress from './NtSentimentProgress';
import Logger from '../../common/utils/Logger';
import NtPropagandaImage from './NtPropagandaImage';
import {propagandaStyle} from '../utils/PropagandaUtils';
import {
  objectivityStyle,
  sentimentStyle,
} from '../../common/utils/ControllerUtils';
import colors from '../../config/colors';
import {parseCleanText} from '../utils/NewsParserUtils';

function NtAnalysisColoredReaderSentenceRow({
  sentence,
  propagandaSubstrings,
  containerStyle,
}) {
  const objectivity = Math.round(sentence.opinion);
  const objStyle = objectivityStyle(objectivity);

  //sentiment
  const sentiment = Math.round(sentence.sentiment);
  const sentStyle = sentimentStyle(sentiment);

  const backgroundColor = objectivity => {
    let color = '#0C107A';

    if (objectivity == 0) {
      color = '#0C615C';
    } else if (objectivity <= 25) {
      color = '#1A6B7C';
    } else if (objectivity <= 50) {
      color = '#0E5486';
    } else if (objectivity <= 75) {
      color = '#0B368A';
    }

    return color;
  };

  const textColor = sentiment => {
    let color = '#61C035';

    if (sentiment <= -50) {
      color = '#EC6D66';
    } else if (sentiment < 0) {
      color = '#ECC0BD';
    } else if (sentiment === 0) {
      color = '#BDC5E3';
    } else if (sentiment <= 50) {
      color = '#C3E98D';
    }

    return color;
  };

  let propagandaMetric = [];

  propagandaSubstrings.map(each => {
    let count = 0;
    each.items.map(item => {
      if (sentence.text.includes(item)) {
        count++;
      }
    });
    if (count > 0) {
      propagandaMetric = [...propagandaMetric, {type: each.type, count: count}];
    }
  });

  const renderPropaganda = () => {
    return propagandaMetric.map(each => {
      const style = propagandaStyle(each.type);
      return (
        <View
          key={each.type}
          style={{flexDirection: 'row', alignItems: 'center', marginBottom: 6}}>
          <NtPropagandaImage
            name={style.icon}
            color={style.color}
            size={20}
            backgroundColor={style.backgroundColor}
          />
          <NtText style={styles.propagandaCount}>{each.count}</NtText>
        </View>
      );
    });
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={[
          styles.sentenceContainer,
          {backgroundColor: backgroundColor(objectivity)},
        ]}>
        <NtText style={[styles.sentenceText, {color: textColor(sentiment)}]}>
          {parseCleanText(sentence.text)}
        </NtText>
      </View>
      <View
        style={{
          position: 'absolute',
          width: 20,
          height: 20,
          backgroundColor: 'white',
          transform: [{rotate: '45deg'}],
          right: 65,
          top: 5,
        }}
      />
      <View style={styles.statsContainer}>
        <View style={styles.stats}>
          <NtObjectivityProgress
            containerStyle={styles.styleGraph}
            progress={objectivity}
            size={30}
            width={3}
            fontSize={10}
            fontColor={objStyle.color}
            filledColor={objStyle.color}
            icon={objStyle.icon}
            iconColor={objStyle.color}
            iconSize={20}
            showProgress={false}
          />
          <NtText style={[styles.statsText, {color: objStyle.color}]}>
            {objectivity}%
          </NtText>
        </View>

        <View style={[styles.stats, {marginTop: 10}]}>
          <NtSentimentProgress
            containerStyle={styles.styleGraph}
            progress={sentiment}
            size={30}
            width={3}
            fontSize={10}
            fontColor={sentStyle.color}
            filledColor={sentStyle.color}
            icon={sentStyle.icon}
            iconColor={sentStyle.color}
            iconSize={22}
            showProgress={false}
          />
          <NtText style={[styles.statsText, {color: sentStyle.color}]}>
            {sentiment}%
          </NtText>
        </View>

        <View style={{height: 10}} />

        {renderPropaganda()}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  sentenceContainer: {
    flex: 1,
    padding: 15,
  },
  sentenceText: {
    fontSize: 15,
    textShadowColor: 'black',
    textShadowOffset: {width: 1, height: 1},
    textShadowRadius: 1,
  },
  statsContainer: {
    width: 80,
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: 'white',
  },
  stats: {
    alignItems: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  statsText: {
    marginLeft: 8,
  },
  propagandaCount: {
    color: colors.darkest,
    marginLeft: 4,
    fontWeight: '400',
  },
});

export default NtAnalysisColoredReaderSentenceRow;
