import client from "./client";
import settings from "../config/settings";
import md5 from "react-native-md5";
import {
  asyncStorageGetData,
  asyncStorageSetData,
} from "../common/utils/ControllerUtils";

const endpoint = "/heimdall";
const storageKey = "heimdallUserFeedsStorageKey";

export const fetchFeeds = (
  limit,
  skip,
  filter = {},
  sortField = "read_count",
  sortOrder = "desc",
  hours = 24 //will be 1 day (default)
) => {
  const order = sortOrder === "asc" ? 1 : -1;

  let body = {
    apiKey: settings.apiKey,
    type: "Feeds",
    limit: limit,
    skip: skip,
    sort_field: sortField,
    sort_order: order,
    hours: hours,
    ...filter, //will be the filters......
  };

  return client.post(endpoint, body, {
    headers: { "x-nooz-hash": md5.hex_md5(JSON.stringify(body)) },
  });
};

export const saveUserFeeds = (data) => {
  return asyncStorageSetData(storageKey, data);
};

export const fetchUserFeeds = async () => {
  const jsonValue = await asyncStorageGetData(storageKey);

  var data = [];
  if (jsonValue != null) {
    data = JSON.parse(jsonValue);
  }

  return data;
};
