import React from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import { hootLabel } from "../../news/utils/HootParserUtils";
import NtHootContainer from "../../news/components/NtHootContainer";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import demostory from "../../news/data/demostory";

function NtSelectScoreLayoutItem({ layout, selected = false, onSelect }) {
  const renderCheck = () => {
    if (!selected) {
      return <View style={styles.checkNoSelected} />;
    } else {
      return (
        <View
          style={[styles.checkNoSelected, { backgroundColor: colors.dark }]}
        >
          <NtMaterialIcon name={"check-bold"} color={"white"} size={16} />
        </View>
      );
    }
  };

  return (
    <View
      style={{
        width: "100%",
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15,
      }}
    >
      <NtTouchableEffect
        style={[
          styles.container,
          {
            borderWidth: selected ? 2 : 0,
            backgroundColor: selected ? "white" : "rgba(255,255,255,0.5)",
          },
        ]}
        onPress={() => {
          if (onSelect) {
            onSelect(layout);
          }
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {renderCheck()}
          <NtText style={[styles.title, { marginLeft: 8 }]}>
            {hootLabel(layout.id)}
          </NtText>
        </View>

        <NtHootContainer
          story={demostory}
          containerStyle={{ marginTop: 20 }}
          type={layout.id}
        />
      </NtTouchableEffect>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: 15,
    borderColor: colors.dark,
    borderRadius: 10,
    backgroundColor: "white",
  },
  title: {
    color: colors.darkest,
    fontWeight: "600",
  },
  image: {
    flex: 1,
    resizeMode: "contain",
  },
  checkNoSelected: {
    width: 26,
    height: 26,
    backgroundColor: "white",
    borderColor: colors.dark,
    borderWidth: 2,
    borderRadius: 13,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default NtSelectScoreLayoutItem;
