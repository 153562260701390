import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import NtTextInput from "./NtTextInput";
import NtTouchableEffect from "./NtTouchableEffect";
import colors from "../config/colors";
import NtMaterialIcon from "./NtMaterialIcon";

function NtSearchBar({ containerStyle, onSearch }) {
  const [text, setText] = useState("");

  const handleSearch = (text) => {
    if (onSearch) {
      onSearch(text);
      setText("");
    }
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={{ flex: 1, alignItems: "flex-end", justifyContent: "center" }}
      >
        <NtTextInput
          placeholder="Search"
          value={text}
          onChangeText={(text) => setText(text)}
          containerStyle={{ flex: 1, borderRadius: 30 }}
          type={"text"}
          autoCapitalize="none"
          returnKeyType="search"
          onSubmitEditing={() => {
            handleSearch(text);
          }}
          bgColor={colors.lightest}
          textColor={colors.darkest}
        />

        <View
          style={{
            position: "absolute",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <NtTouchableEffect
            style={styles.searchButton}
            onPress={() => {
              handleSearch(text);
            }}
          >
            <NtMaterialIcon name="magnify" size={25} color={colors.darkest} />
          </NtTouchableEffect>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  searchButton: {
    height: 40,
    width: 40,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.lighter,
    marginLeft: 5,
  },
  cancelSearchButton: {
    height: 20,
    width: 20,
    backgroundColor: colors.teal,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default NtSearchBar;
