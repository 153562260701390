import React from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtAnalysisTitleView from "./NtAnalysisTitleView";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import { parseSentencesFromNewsItem } from "../utils/NewsParserUtils";
import NtAnalysisColoredReaderSentenceRow from "./NtAnalysisColoredReaderSentenceRow";
import { extractAllPropagandaSubstringsFromStory } from "../utils/PropagandaUtils";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtAnalysisColoredReaderController({ story, onColorGuidePress }) {
  const sentences = parseSentencesFromNewsItem(story);
  const propagandaSubstrings = extractAllPropagandaSubstringsFromStory(story);

  return (
    <View style={styles.container}>
      <NtAnalysisTitleView
        icon={"palette"}
        title={"Colored Reader"}
        summary={
          "Colors allow you to see how the opinion and sentiment changes per sentence and certain words."
        }
      />

      <NtTouchableEffect
        style={{ marginTop: 20, flexDirection: "row", alignItems: "center" }}
        onPress={onColorGuidePress}
      >
        <NtMaterialIcon
          name={"palette-advanced"}
          size={20}
          color={colors.blue}
        />
        <NtText style={styles.colorGuide}>View Color Guide</NtText>
      </NtTouchableEffect>

      {/* header */}
      <View style={{ marginTop: 20 }}>
        <NtText style={styles.headerTitle}>SENTENCES</NtText>
        <View
          style={{
            marginTop: 6,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <NtText style={styles.headerText}>
            Sentences separated with scores
          </NtText>
          <NtText style={styles.headerText}>Metrics</NtText>
        </View>
      </View>

      <View style={{ height: 15 }} />

      {sentences.map((each, index) => (
        <View key={each.text + index.toString()}>
          <NtAnalysisColoredReaderSentenceRow
            containerStyle={{ marginLeft: -15, marginRight: -15 }}
            sentence={each}
            propagandaSubstrings={propagandaSubstrings}
          />
          <View
            style={{
              flex: 1,
              height: 2,
              marginRight: -15,
              marginLeft: -15,
              backgroundColor: colors.lighter,
            }}
          />
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginLeft: 15,
    marginRight: 15,
  },
  colorGuide: {
    color: colors.blue,
    fontSize: 14,
    fontWeight: "600",
    marginLeft: 6,
  },
  headerTitle: {
    color: colors.darkest,
    fontSize: 20,
    fontWeight: "600",
  },
  headerText: {
    color: colors.darker,
    fontSize: 14,
  },
});

export default NtAnalysisColoredReaderController;
