import React from "react";
import { View, StyleSheet } from "react-native";
import NtObjectivityProgress from "./NtObjectivityProgress";
import NtSentimentProgress from "./NtSentimentProgress";
import {
  parseObjectivityFromNewsItem,
  parseSentimentFromNewsItem,
  parseCompoundTextFromNewsItem,
} from "../utils/NewsParserUtils";
import NtText from "../../components/NtText";

import NtTextDiffView from "../../components/NtTextDiffView";
import {
  sentimentStyle,
  objectivityStyle,
} from "../../common/utils/ControllerUtils";
import colors from "../../config/colors";
import NtPropagandaContainer from "./NtPropagandaContainer";

function NtAnalysisSentenceComparison({ storyOld, storyNew }) {
   
  const compoundTextOld = parseCompoundTextFromNewsItem(storyOld);
  const compoundTextNew = parseCompoundTextFromNewsItem(storyNew);

  //old
  //objectivity
  const oldObjectivity = parseObjectivityFromNewsItem(storyOld);
  const oldObjStyle = objectivityStyle(oldObjectivity);

  //sentiment
  const oldSentiment = parseSentimentFromNewsItem(storyOld);
  const oldSentStyle = sentimentStyle(oldSentiment);

  //new
  //objectivity
  const newObjectivity = parseObjectivityFromNewsItem(storyNew);
  const newObjStyle = objectivityStyle(newObjectivity);

  //sentiment
  const newSentiment = parseSentimentFromNewsItem(storyNew);
  const newSentStyle = sentimentStyle(newSentiment);

  return (
    <View style={styles.container}>
      {/* old stats */}
      <View>
        <View style={{ alignItems: "center" }}>
          <NtObjectivityProgress
            containerStyle={styles.styleGraph}
            progress={oldObjectivity}
            size={30}
            width={3}
            fontSize={10}
            fontColor={oldObjStyle.color}
            filledColor={oldObjStyle.color}
            icon={oldObjStyle.icon}
            iconColor={oldObjStyle.color}
            iconSize={20}
            showProgress={false}
          />
          <NtText style={[styles.styleValue, { color: oldObjStyle.color }]}>
            {oldObjectivity}%
          </NtText>
        </View>

        <View style={{ marginTop: 10, alignItems: "center" }}>
          <NtSentimentProgress
            containerStyle={styles.styleGraph}
            progress={oldSentiment}
            size={30}
            width={3}
            fontSize={10}
            fontColor={oldSentStyle.color}
            filledColor={oldSentStyle.color}
            icon={oldSentStyle.icon}
            iconColor={oldSentStyle.color}
            iconSize={22}
            showProgress={false}
          />
          <NtText style={[styles.styleValue, { color: oldSentStyle.color }]}>
            {oldSentiment}%
          </NtText>

          <NtPropagandaContainer
            containerStyle={{
              flexDirection: "column",
              marginTop: 10,
              backgroundColor: colors.lighter,
            }}
            itemStyle={{ marginBottom: 10 }}
            story={storyOld}
            iconSize={15}
            display={20}
          />
        </View>
      </View>
      <View
        style={{
          flex: 1,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        {compoundTextNew && compoundTextOld && (
          <NtTextDiffView inputA={compoundTextOld} inputB={compoundTextNew} />
        )}
        {(!compoundTextNew || !compoundTextOld) && (
          <NtText style={styles.error}>Unable to generate diff</NtText>
        )}
      </View>
      {/* new stats */}
      <View>
        <View style={{ alignItems: "center" }}>
          <NtObjectivityProgress
            containerStyle={styles.styleGraph}
            progress={newObjectivity}
            size={30}
            width={3}
            fontSize={10}
            fontColor={newObjStyle.color}
            filledColor={newObjStyle.color}
            icon={newObjStyle.icon}
            iconColor={newObjStyle.color}
            iconSize={20}
            showProgress={false}
          />
          <NtText style={[styles.styleValue, { color: newObjStyle.color }]}>
            {newObjectivity}%
          </NtText>
        </View>

        <View style={{ marginTop: 10, alignItems: "center" }}>
          <NtSentimentProgress
            containerStyle={styles.styleGraph}
            progress={newSentiment}
            size={30}
            width={3}
            fontSize={10}
            fontColor={newSentStyle.color}
            filledColor={newSentStyle.color}
            icon={newSentStyle.icon}
            iconColor={newSentStyle.color}
            iconSize={22}
            showProgress={false}
          />
          <NtText style={[styles.styleValue, { color: newSentStyle.color }]}>
            {newSentiment}%
          </NtText>
        </View>

        <NtPropagandaContainer
          containerStyle={{
            flexDirection: "column",
            marginTop: 10,
            backgroundColor: colors.lighter,
          }}
          itemStyle={{ marginBottom: 10 }}
          story={storyNew}
          iconSize={15}
          display={20}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    padding: 10,
  },
  error: {
    color: colors.darkest,
    fontSize: 16,
    textAlign: "center",
  },
});

export default NtAnalysisSentenceComparison;
