import React from "react";
import { View, StyleSheet } from "react-native";
import Lottie from "lottie-react";

function NtLanguageAnalysisLoading({ containerStyle }) {
  return (
    <View style={[styles.container, containerStyle]}>
      <Lottie
        style={{ width: 250, heigth: 250 }}
        animationData={require("../../../lottie/lottie_launguage_analyzing.json")}
        loop={true}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default NtLanguageAnalysisLoading;
