const rankwithinoptions = [
  {
    id: "pastday",
    title: "Past Day",
    icon: "calendar-today",
    hours: 24,
  },
  {
    id: "pastthreedays",
    title: "Past Three Days",
    icon: "calendar-range",
    hours: 72,
  },
  {
    id: "pastweek",
    title: "Past Week",
    icon: "calendar-week",
    hours: 168,
  },
  // {
  //   id: "past2weeks",
  //   title: "Past Two Weeks",
  //   icon: "calendar-text",
  //   hours: 336,
  // },
  // {
  //   id: 'pastmonth',
  //   title: 'Past Month',
  //   icon: 'calendar-month',
  //   hours: 720,
  // },
  // {
  //   id: 'past3month',
  //   title: 'Past 3 Months',
  //   icon: 'calendar-range',
  //   hours: 2160,
  // },
  // {
  //   id: 'pastyear',
  //   title: 'Past Year',
  //   icon: 'calendar-blank-outline',
  //   hours: 8760,
  // },
];

export default rankwithinoptions;
