import React from "react";
import { View, StyleSheet, Text } from "react-native";
import colors from "../config/colors";
import NtTouchableEffect from "./NtTouchableEffect";
import NtText from "./NtText";
import NtMaterialIcon from "./NtMaterialIcon";

function NtSelectionSheetrow({
  icon,
  renderIcon,
  title,
  onPress,
  selected = false,
}) {
  return (
    <NtTouchableEffect onPress={onPress}>
      <View style={styles.container}>
        {icon && <NtMaterialIcon name={icon} size={25} color={colors.darker} />}
        {renderIcon && renderIcon}
        <NtText style={styles.text}>{title}</NtText>
        {selected && (
          <NtMaterialIcon name="check" size={25} color={colors.darker} />
        )}
      </View>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: 45,
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 20,
    paddingRight: 20,
  },
  text: {
    fontSize: 15,
    color: colors.darkest,
    fontWeight: "500",
    marginLeft: 15,
    flex: 1,
  },
});

export default NtSelectionSheetrow;
