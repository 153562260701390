import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../../components/NtText";
import colors from "../../../config/colors";
import { parseTitleFromNewsItem } from "../../utils/NewsParserUtils";
import NtLanguageAnalysisUserButton from "./NtLanguageAnalysisUserButton";

function NtLanguageAnalysisUserController({
  stories,
  selectedIndex,
  showNewAnalyzeButton = false,
  onUserSelect,
  onNewWriting,
  containerStyle,
}) {
  return (
    <View style={[styles.container, containerStyle]}>
      {showNewAnalyzeButton && (
        <NtLanguageAnalysisUserButton
          icon={"plus"}
          title={"New Writing"}
          containerStyle={{ borderWidth: 1 }}
          onPress={onNewWriting}
        />
      )}

      {stories && (
        <View style={{ marginTop: 20 }}>
          <NtText style={[styles.title, { marginBottom: 20 }]}>
            Past Writings
          </NtText>
          {stories.map((each, index) => (
            <NtLanguageAnalysisUserButton
              key={index}
              title={parseTitleFromNewsItem(each)}
              containerStyle={{ marginBottom: 6 }}
              selected={index === selectedIndex}
              onPress={() => {
                if (onUserSelect) {
                  onUserSelect(index);
                }
              }}
            />
          ))}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: 150,
    backgroundColor: colors.lighestGray,
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 10,
  },
  title: {
    fontSize: 13,
    color: colors.darkest,
    fontWeight: "600",
  },
});

export default NtLanguageAnalysisUserController;
