import React from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtAnalysisMorelikeThisHint from "./NtAnalysisMorelikeThisHint";

function NtAnalysisMorelikeThisController({
  stories,
  containerStyle,
  onPress,
}) {
  if (stories.length === 0) {
    return null;
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={styles.title}>Similar Stories</NtText>

      <NtText style={{ marginTop: 10 }}>
        {stories.map((each, index) => (
          <NtAnalysisMorelikeThisHint
            key={index}
            story={each}
            onPress={() => {
              if (onPress) {
                onPress(each);
              }
            }}
          />
        ))}
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    color: colors.darkest,
    fontWeight: "600",
  },
});

export default NtAnalysisMorelikeThisController;
