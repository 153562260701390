import React from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../config/colors";
import NtRevisionProgress from "./NtRevisionProgress";
import NtText from "../../components/NtText";
import NtObjectivityProgress from "./NtObjectivityProgress";
import NtSentimentProgress from "./NtSentimentProgress";
import {
  parseRevisionFromNewsItem,
  parseTimestampFromNewsItem,
  parseSentimentFromNewsItem,
  parseObjectivityFromNewsItem,
  parseNeuFromNewsItem,
  parseNegFromNewsItem,
  parsePosFromNewsItem,
} from "../utils/NewsParserUtils";
import {
  revisionStyle,
  sentimentStyle,
  objectivityStyle,
} from "../../common/utils/ControllerUtils";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtVaderProgress from "./NtVaderProgress";
import NtPropagandaContainer from "./NtPropagandaContainer";

function NtAnalysisRevisionSelect({
  containerStyle,
  story,
  isStealthEdit = false,
  onCardPress,
}) {
  //objectivity
  const objectivity = parseObjectivityFromNewsItem(story);
  const objStyle = objectivityStyle(objectivity);

  //sentiment
  const sentiment = parseSentimentFromNewsItem(story);
  const sentStyle = sentimentStyle(sentiment);

  //revision
  const revision = parseRevisionFromNewsItem(story);
  const revStyle = revisionStyle(revision);

  return (
    <View style={[styles.container, containerStyle]}>
      <NtTouchableEffect onPress={onCardPress} style={styles.innerContainer}>
        <View style={{ height: 130, width: 60 }}>
          <View style={[styles.revisionConatainer]}>
            {/* revision icon */}
            <View style={{ flexDirection: "column", alignItems: "center" }}>
              <NtRevisionProgress
                progress={revision}
                size={30}
                width={3}
                fontSize={10}
                iconSize={25}
                showProgress={false}
                fontColor={revStyle.color}
                filledColor={revStyle.color}
                icon={isStealthEdit ? "ghost" : revStyle.icon}
                iconColor={
                  isStealthEdit
                    ? colors.sentimentNegativeFilled
                    : revStyle.color
                }
              />
            </View>

            {/* revision count */}
            <View style={{ flexDirection: "column", alignItems: "center" }}>
              <NtText
                style={[styles.revisionText, { color: revStyle.fontColor }]}
              >
                {revision}
              </NtText>
            </View>
          </View>
        </View>

        <View style={{ flex: 1 }}>
          {/* publish date */}
          <View style={[styles.statsContainer]}>
            {revision != 1 && (
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <NtText style={[styles.time]}>
                  <NtText style={{ fontWeight: "800" }}>Updated:</NtText>
                  {"    "}
                  {parseTimestampFromNewsItem(story, true)}
                </NtText>
              </View>
            )}
            <NtText style={[styles.time, { marginTop: 6 }]}>
              <NtText style={{ fontWeight: "800" }}>Published:</NtText>{" "}
              {parseTimestampFromNewsItem(story, true, "pubDate")}
            </NtText>
          </View>

          {/* nooz scores */}
          <View style={styles.styleContainer}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <NtObjectivityProgress
                containerStyle={styles.styleGraph}
                progress={objectivity}
                size={30}
                width={3}
                fontSize={10}
                fontColor={objStyle.color}
                filledColor={objStyle.color}
                icon={objStyle.icon}
                iconColor={objStyle.color}
                iconSize={20}
                showProgress={false}
              />
              <NtText style={[styles.styleValue, { color: objStyle.color }]}>
                {objectivity}%
              </NtText>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <NtSentimentProgress
                containerStyle={styles.styleGraph}
                progress={sentiment}
                size={30}
                width={3}
                fontSize={10}
                fontColor={sentStyle.color}
                filledColor={sentStyle.color}
                icon={sentStyle.icon}
                iconColor={sentStyle.color}
                iconSize={22}
                showProgress={false}
              />
              <NtText style={[styles.styleValue, { color: sentStyle.color }]}>
                {sentiment}%
              </NtText>
            </View>

            <NtVaderProgress
              containerStyle={{ marginLeft: 10 }}
              neg={parseNegFromNewsItem(story)}
              neu={parseNeuFromNewsItem(story)}
              pos={parsePosFromNewsItem(story)}
            />
          </View>
          <View style={{ alignItems: "flex-start" }}>
            <NtPropagandaContainer
              containerStyle={{ marginLeft: 6, marginTop: 5 }}
              story={story}
              display={6}
            />
          </View>
        </View>
      </NtTouchableEffect>
      <View
        style={{
          height: 0.5,
          width: "100%",
          backgroundColor: colors.teal,
          marginTop: 15,
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 15,
    flex: 1,
  },
  innerContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  revisionConatainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 55,
    backgroundColor: colors.lightest,
    paddingHorizontal: 6,
    paddingVertical: 5,
    marginRight: 0,
    borderRadius: 6,
  },
  stats: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  revisionText: {
    color: colors.darker,
    fontSize: 15,
    fontWeight: "400",
    marginTop: 4,
  },
  time: {
    fontSize: 14,
    marginLeft: 10,
    color: colors.darker,
  },
  styleContainer: {
    marginLeft: 0,
    flexDirection: "row",
    marginTop: 10,
    alignItems: "center",
  },
  styleGraph: {
    marginLeft: 10,
  },
  styleValue: {
    fontSize: 13,
    fontWeight: "600",
    marginLeft: 5,
  },
  statsContainer: {},
});

export default NtAnalysisRevisionSelect;
