import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtAnalysisNavigationItem from "./NtAnalysisNavigationItem";

function NtAnalysisNavigationOptions({
  title,
  options,
  selectedKey,
  loadingKey,
  onPress,
  containerStyle,
}) {
  const renderMenuOptions = (item, index) => {
    return (
      <NtAnalysisNavigationItem
        key={item.id}
        name={item.title}
        icon={item.icon}
        style={{ marginRight: 10 }}
        selected={item.id === selectedKey}
        loading={item.id === loadingKey}
        onPress={() => {
          if (onPress) {
            onPress(item);
          }
        }}
      />
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      {title && <NtText style={[styles.title]}>{title}</NtText>}
      <NtText style={{ marginLeft: 10 }}>
        {options.map((each, index) => renderMenuOptions(each, index))}
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: 12,
    color: colors.darkest,
    fontWeight: "700",
  },
});

export default NtAnalysisNavigationOptions;
