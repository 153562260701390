import React from "react";
import { View, StyleSheet } from "react-native";
import Diff from "react-native-diff-component";
import colors from "../config/colors";
import { useMobile } from "../hooks/useMobile";

function NtTextDiffView({ inputA, inputB, textColor = colors.darkest }) {
  const isMobile = useMobile();
  return (
    <View style={styles.container}>
      <Diff
        viewStyle={{ width: isMobile ? 300 : 400 }}
        textStyle={{ fontSize: 15, color: textColor }}
        inputA={inputA}
        inputB={inputB}
        type="sentences"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default NtTextDiffView;
