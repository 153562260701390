import React from "react";
import { View, StyleSheet, ScrollView, Image } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import Logger from "../../common/utils/Logger";
import NtAnalysisStepThruReaderController from "../components/NtAnalysisStepThruReaderController";
import {
  parseColorReaderRestrictionFromNewsItem,
  parseComparisonReaderRestrictionFromNewsItem,
  parseSentencesFromNewsItem,
  parseTextOnlyReaderRestrictionFromNewsItem,
  parseStepThruReaderRestrictionFromNewsItem,
  parseKeywordsFromNewsItem,
} from "../utils/NewsParserUtils";
import routes from "../../navigation/routes";
import NtAnalysisComparisonReaderController from "../components/NtAnalysisComparisonReaderController";
import { fetchHistory, fetchStoryById, fetchStories } from "../../api/stories";
import NtAnalysisArticleFlowvisualController from "../components/NtAnalysisArticleFlowVisualController";
import NtAnalysisPropagandaController from "../components/NtAnalysisPropagandaController";
import NtAnalysisQuickAnalysisController from "../components/NtAnalysisQuickAnalysisController";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtAnalysisPreviewStoryCard from "../components/NtAnalysisPreviewStoryCard";
import BaseNewsController from "./BaseNewsController";
import NtAnalysisOpinionController from "../components/NtAnalysisOpinionController";
import NtAnalysisColoredReaderController from "../components/NtAnalysisColoredReaderController";
import NtAnalysisTextOnlyReaderController from "../components/NtAnalysisTextOnlyReaderController";
import { saveStory } from "../utils/SavedNewsUtils";
import { showOkDialog, showToast } from "../../common/utils/ControllerUtils";
import developeroptions from "../data/developeroptions";
import Base64 from "Base64";
import NtModal from "../../components/NtModal";
import NtAnalysisColoredReaderColorGuide from "../components/NtAnalysisColoredReaderColorGuide";
import AnalysisRevisionSelectController from "./AnalysisRevisionSelectController";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtAnalysisSentimentController from "../components/NtAnalysisSentimentController";
import NtNewsPageContainer from "../components/NtNewsPageContainer";
import NtNewsSelectItem from "../components/NtNewsSelectItem";
import { tweet } from "../../api/twitter";
import NtViewOnDeviceButton from "../components/NtViewOnDeviceButton";
import { isMobile } from "react-device-detect";
import NtAnalysisNavigationOptions from "../components/NtAnalysisNavigationOptions";
import NtAnalysisScoreController from "../components/NtAnalysisScoreController";
import NtAnalysisMorelikeThisController from "../components/NtAnalysisMorelikeThisController";

class AnalysisController extends BaseNewsController {
  static TYPE_STEP_THRU = "step-thru";
  static TYPE_COMP_READER = "comp-reader";
  static TYPE_ARTCLE_FLOW = "article-flow";
  static TYPE_PROPAGANDA = "propaganda";
  static TYPE_QUICKANALYSIS = "quickanalysis";
  static TYPE_REVISIONS = "revisions";
  static TYPE_OPINION = "opinion";
  static TYPE_SENTIMENT = "sentiment";
  static TYPE_TEXT_ONLY_READER = "textonlyreader";
  static TYPE_COLORED_READER = "coloredreader";

  state = {
    ...this.getParentState(),
    open: false,
    stories: [],
    moreLikeThisStories: [],
    type: AnalysisController.TYPE_QUICKANALYSIS,
    historyIndexNewer: 0,
    historyIndexOlder: 0,
    sendingTweet: false,
    token: null,
  };

  colorGuideRef = React.createRef();
  revisionSelectRef = React.createRef();
  scrollViewRef = React.createRef();

  reader = [
    {
      id: AnalysisController.TYPE_TEXT_ONLY_READER,
      icon: "read",
      title: "Classic",
    },
    {
      id: AnalysisController.TYPE_COLORED_READER,
      icon: "palette",
      title: "Colored",
    },
    {
      id: AnalysisController.TYPE_STEP_THRU,
      icon: "unfold-more-vertical",
      title: "Step-Thru",
      postfix: "Reader",
    },
    {
      id: AnalysisController.TYPE_COMP_READER,
      icon: "file-compare",
      title: "Compare",
    },
  ];

  options = [
    {
      title: "Metrics",
      items: [
        {
          id: AnalysisController.TYPE_QUICKANALYSIS,
          icon: "lightning-bolt",
          title: "Overview",
          postfix: "Metrics",
        },
        {
          id: AnalysisController.TYPE_OPINION,
          icon: "comment-multiple",
          title: "Opinion",
          postfix: "Metrics",
        },
        {
          id: AnalysisController.TYPE_SENTIMENT,
          icon: "emoticon-happy",
          title: "Sentiment",
          postfix: "Metrics",
        },
        {
          id: AnalysisController.TYPE_PROPAGANDA,
          icon: "bullhorn",
          title: "Propaganda",
          postfix: "Metrics",
        },
        {
          id: AnalysisController.TYPE_REVISIONS,
          icon: "file-outline",
          title: "Revisions",
          postfix: "Metrics",
        },
      ],
    },
    {
      title: "Readers",
      items: [
        {
          id: AnalysisController.TYPE_TEXT_ONLY_READER,
          icon: "read",
          title: "Classic",
          postfix: "Reader",
        },
        {
          id: AnalysisController.TYPE_COLORED_READER,
          icon: "palette",
          title: "Colored-Coded",
          postfix: "Reader",
        },
        {
          id: AnalysisController.TYPE_COMP_READER,
          icon: "file-compare",
          title: "Comparison",
          postfix: "Reader",
        },
        {
          id: AnalysisController.TYPE_STEP_THRU,
          icon: "unfold-more-vertical",
          title: "Step-Thru",
          postfix: "Reader",
        },
      ],
    },
  ];

  devOptions = [{ items: developeroptions }];

  metricOptions = [
    {
      id: AnalysisController.TYPE_QUICKANALYSIS,
      icon: "lightning-bolt",
      title: "Quick Analysis",
    },
    {
      id: AnalysisController.TYPE_OPINION,
      icon: "comment-multiple",
      title: "Opinion",
    },
    {
      id: AnalysisController.TYPE_SENTIMENT,
      icon: "emoticon-happy",
      title: "Sentiment",
    },
    {
      id: AnalysisController.TYPE_PROPAGANDA,
      icon: "bullhorn",
      title: "Propaganda",
    },
    {
      id: AnalysisController.TYPE_REVISIONS,
      icon: "file-outline",
      title: "Revisions",
    },
  ];
  readerOptions = [
    {
      id: AnalysisController.TYPE_TEXT_ONLY_READER,
      icon: "read",
      title: "Basic Reader",
    },
    {
      id: AnalysisController.TYPE_COLORED_READER,
      icon: "palette",
      title: "Colored Reader",
    },
    {
      id: AnalysisController.TYPE_COMP_READER,
      icon: "file-compare",
      title: "Comparison Reader",
    },
    {
      id: AnalysisController.TYPE_STEP_THRU,
      icon: "unfold-more-vertical",
      title: "Step-Thru Reader",
    },
  ];

  componentDidMount() {
    super.componentDidMount();

    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    Logger("Parameters passed ", this.props.route.params);
    const token = this.handleParametersParse();
    /*
      Here we have to deside if we are getting a short link (only the story ID(short) or the story URL(long))
      1. If we are getting a short link (only from else where as deep link) there is always 
         going to refresh the controller so no need to do it anywhere else.... 
         In this case, I will set the type to read mode and fetch the story first then the history
      2. I we are getting a long link (regular link on the app) we go diraectly to get the history   
    */
    if (token) {
      if (!token.includes("http")) {
        Logger("We have a short link in hand... will handle approprietly....");
        this.setState({ type: AnalysisController.TYPE_TEXT_ONLY_READER });
        this.handleStoryByIdFetch(token);
      } else {
        this.handleHistoryFetch(token);
      }
      this.setState({ token: token });
    }

    // In case we sent a type....
    if (
      this.props.route &&
      this.props.route.params &&
      this.props.route.params.type
    ) {
      this.setState({ type: this.props.route.params.type });
    }
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    const passedToken = this.handleParametersParse();

    if (this.state.token == null) {
      Logger("Aborting the focus this time since we have not load yet.");
    } else if (passedToken === this.state.token) {
      Logger(
        "Aborting the focus this time since we have not changed the params..."
      );
    } else {
      Logger("Executing the focus since we have a change in params....");
      this.setState({ token: passedToken, stories: [] });
      this.handleHistoryFetch(passedToken);
    }
  };

  handleParametersParse = () => {
    if (this.props.route && this.props.route.params) {
      let url = decodeURIComponent(this.props.route.params.token);
      url = Base64.atob(url);
      return url;
    }
    return null;
  };

  handleStoryByIdFetch = async (token) => {
    const result = await fetchStoryById(token);
    if (result.ok && Array.isArray(result.data) && result.data.length > 0) {
      Logger(
        "Successfully got the story by ID... now will get the history...."
      );
      this.handleHistoryFetch(result.data[0].url);
    } else {
      Logger("Failed to get the story", result);
    }
  };

  handleHistoryFetch = async (token) => {
    const result = await fetchHistory(token);
    if (result.ok) {
      Logger("Just fetched the hisotry from API", result.data);
      this.setState({
        stories: result.data,
        historyIndexNewer: result.data.length - 1,
      });
      this.handleMoreLikeThisFetch();
    } else {
      Logger("Failed to get the story", result);
    }
  };

  handleMoreLikeThisFetch = async () => {
    Logger("Will fetch the More like this....", {
      topic: true,
      keywords: parseKeywordsFromNewsItem(
        this.state.stories[this.state.historyIndexNewer]
      ),
    });

    const response = await fetchStories(
      10,
      0,
      null,
      {
        topic: true,
        keywords: parseKeywordsFromNewsItem(
          this.state.stories[this.state.historyIndexNewer]
        ),
      },
      "update",
      "desc",
      168
    );
    if (response.ok) {
      Logger("More like this stories just loaded", response);
      this.setState({
        moreLikeThisStories: response.data.filter(
          (each) =>
            each.url != this.state.stories[this.state.historyIndexNewer].url
        ),
      });
    }
  };

  handleRevisionNavigate = () => {
    this.revisionSelectRef.current.show();
  };

  handleMenuSelection = (item) => {
    Logger("Menu Selection", item);
    if (item.restricted) {
      ///handle restriction here
      showOkDialog(
        "Full Text Unavailable",
        "The full text of the article is inaccessible due to a subscription service requirement."
      );
    } else if (
      item.id === AnalysisController.TYPE_STEP_THRU ||
      item.id === AnalysisController.TYPE_ARTCLE_FLOW ||
      item.id === AnalysisController.TYPE_COMP_READER ||
      item.id === AnalysisController.TYPE_PROPAGANDA ||
      item.id === AnalysisController.TYPE_QUICKANALYSIS ||
      item.id === AnalysisController.TYPE_SENTIMENT ||
      item.id === AnalysisController.TYPE_OPINION ||
      item.id === AnalysisController.TYPE_COLORED_READER ||
      item.id === AnalysisController.TYPE_TEXT_ONLY_READER
    ) {
      this.setState({ type: item.id });
    } else if (item.id === AnalysisController.TYPE_REVISIONS) {
      this.handleRevisionNavigate();
    } else if (item.id === "developer") {
      this.props.navigation.navigate(routes.developmentjson, {
        item: this.state.stories,
      });
    } else if (item.id === "twitter") {
      this.handleTweet();
    }

    this.setState({ open: false });
  };

  handleTweet = async () => {
    this.setState({ sendingTweet: true });
    const response = await tweet(
      this.state.stories[this.state.historyIndexNewer].url
    );

    Logger("Response from twitter", response);
    if (response.ok) {
      showToast("Success", "Successfully posted on twitter.");
    } else {
      showToast(
        "Oops...",
        "Time out trying to post. Process will continue on background. Check twitter for post",
        "error"
      );
    }
    this.setState({ sendingTweet: false });
  };

  renderActionButton = () => {
    return (
      <View
        style={{
          width: 46,
          height: 46,
          backgroundColor: colors.blue,
          position: "absolute",
          bottom: 20,
          right: 20,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 23,
        }}
      >
        <NtTouchableEffect
          onPress={() => {
            this.setState({ open: true });
          }}
        >
          <NtMaterialIcon name="menu" size={25} color={"white"} />
        </NtTouchableEffect>
      </View>
    );
  };

  renderColorGuideModal = () => {
    return (
      <NtModal
        ref={this.colorGuideRef}
        renderBody={<NtAnalysisColoredReaderColorGuide />}
      />
    );
  };

  renderStepThruReaderController = () => {
    if (
      this.state.type !== AnalysisController.TYPE_STEP_THRU ||
      !Array.isArray(this.state.stories)
    ) {
      Logger("We will not show the step thru reader controller");
      return null;
    } else if (
      !false &&
      parseStepThruReaderRestrictionFromNewsItem(
        this.state.stories[this.state.historyIndexNewer]
      )
    ) {
      Logger("***User tried to access a restricted feature***");
      this.setState({ type: AnalysisController.TYPE_QUICKANALYSIS });
      return;
    }
    return (
      <NtAnalysisStepThruReaderController
        sentences={parseSentencesFromNewsItem(
          this.state.stories[this.state.historyIndexNewer]
        )}
      />
    );
  };

  renderComparisonReaderController = () => {
    if (
      this.state.type !== AnalysisController.TYPE_COMP_READER ||
      !Array.isArray(this.state.stories)
    ) {
      Logger("We will not show the Comparison Reader Controller");
      return null;
    } else if (
      !false &&
      parseComparisonReaderRestrictionFromNewsItem(
        this.state.stories[this.state.historyIndexNewer]
      )
    ) {
      Logger("***User tried to access a restricted feature***");
      this.setState({ type: AnalysisController.TYPE_QUICKANALYSIS });
      return;
    }
    return (
      <NtAnalysisComparisonReaderController
        onCompareToPress={() =>
          this.props.navigation.navigate(
            routes.commonAnalysisRevisionSelectController,
            {
              stories: this.state.stories,
              selectAction: (index) => {
                Logger("Here is the selected item: ", index);
                this.setState({ historyIndexOlder: index });
              },
            }
          )
        }
        historyCount={this.state.stories.length}
        storyNewer={this.state.stories[this.state.historyIndexNewer]}
        storyOlder={this.state.stories[this.state.historyIndexOlder]}
      />
    );
  };

  renderArticleFlowVisualController = () => {
    if (this.state.type !== AnalysisController.TYPE_ARTCLE_FLOW) {
      Logger("We will not show the article flow visual controller");
      return null;
    }
    return (
      <NtAnalysisArticleFlowvisualController
        sentences={parseSentencesFromNewsItem(
          this.state.stories[this.state.historyIndexNewer]
        )}
        stories={this.state.stories}
      />
    );
  };

  renderPropagandaController = () => {
    if (this.state.type !== AnalysisController.TYPE_PROPAGANDA) {
      Logger("We will not show the propaganda controller");
      return null;
    }
    return (
      <NtAnalysisPropagandaController
        story={this.state.stories[this.state.historyIndexNewer]}
      />
    );
  };

  renderTextOnlyReaderController = () => {
    if (this.state.type !== AnalysisController.TYPE_TEXT_ONLY_READER) {
      Logger("We will not show the text-only reader controller");
      return null;
    } else if (
      !false &&
      parseTextOnlyReaderRestrictionFromNewsItem(
        this.state.stories[this.state.historyIndexNewer]
      )
    ) {
      Logger("***User tried to access a restricted feature***");
      this.setState({ type: AnalysisController.TYPE_QUICKANALYSIS });
      return;
    }
    return (
      <NtAnalysisTextOnlyReaderController
        story={this.state.stories[this.state.historyIndexNewer]}
        onShare={(story) => {
          this.handleShare(story);
        }}
        onMoreLikeThis={(story) => {
          this.handleMoreLikeThisRedirect(story);
        }}
        onSave={(story) => {
          saveStory(story);
        }}
        onWebView={(story) => {
          window.open(story.url);
        }}
        onKeyboard={(item) => {
          this.props.navigation.navigate(routes.keyword, {
            name: item.text,
          });
        }}
      />
    );
  };

  renderOpinionController = () => {
    if (this.state.type !== AnalysisController.TYPE_OPINION) {
      Logger("We will not show the opinion controller");
      return null;
    }
    return (
      <NtAnalysisOpinionController
        story={this.state.stories[this.state.historyIndexNewer]}
      />
    );
  };

  renderSentimentController = () => {
    if (this.state.type !== AnalysisController.TYPE_SENTIMENT) {
      Logger("We will not show the sentiment controller");
      return null;
    }
    return (
      <NtAnalysisSentimentController
        story={this.state.stories[this.state.historyIndexNewer]}
      />
    );
  };

  renderColoredReaderController = () => {
    if (this.state.type !== AnalysisController.TYPE_COLORED_READER) {
      Logger("We will not show the colored reader controller");
      return null;
    } else if (
      !false &&
      parseColorReaderRestrictionFromNewsItem(
        this.state.stories[this.state.historyIndexNewer]
      )
    ) {
      Logger("***User tried to access a restricted feature***");
      this.setState({ type: AnalysisController.TYPE_QUICKANALYSIS });
      return;
    }
    return (
      <NtAnalysisColoredReaderController
        story={this.state.stories[this.state.historyIndexNewer]}
        onColorGuidePress={() => {
          this.colorGuideRef.current.show();
        }}
      />
    );
  };

  renderQuickAnalysisController = () => {
    if (this.state.type !== AnalysisController.TYPE_QUICKANALYSIS) {
      Logger("We will not show the Quick Analysis controller");
      return null;
    }
    return (
      <NtAnalysisQuickAnalysisController
        story={this.state.stories[this.state.historyIndexNewer]}
        stories={this.state.stories}
        onGhostEditsPress={() => {
          this.handleRevisionNavigate();
        }}
        onRevisionPress={() => {
          this.handleRevisionNavigate();
        }}
      />
    );
  };

  renderRevisionModal = () => {
    return (
      <NtModal
        ref={this.revisionSelectRef}
        renderBody={
          <View style={{ width: 400 }}>
            <AnalysisRevisionSelectController
              stories={this.state.stories}
              onSelect={(index) => {
                Logger("Here is the selected index: ", index);
                this.setState({ historyIndexNewer: index });
                this.revisionSelectRef.current.dismiss();
              }}
            />
          </View>
        }
      />
    );
  };

  render() {
    if (this.state.stories.length < 1) {
      return this.renderLottieLoading(true);
    }

    return (
      <NtNewsPageContainer
        renderTitleView={
          <NtAnalysisPreviewStoryCard
            showImage={
              this.state.type !== AnalysisController.TYPE_TEXT_ONLY_READER
            }
            story={this.state.stories[this.state.historyIndexNewer]}
            showVersionChange={this.state.stories.length > 1}
            onVersionChange={() => {
              if (this.state.stories.length > 1) {
                this.handleRevisionNavigate();
              }
            }}
            onCardPress={() => {
              window.open(this.state.stories[this.state.historyIndexNewer].url);
            }}
          />
        }
        scrollViewRef={this.scrollViewRef}
        showFooter={true}
      >
        <View style={{ flex: 1 }}>
          {isMobile && (
            <View style={{ alignItems: "center" }}>
              <NtViewOnDeviceButton
                containerStyle={{ marginTop: 10, marginBottom: 20 }}
                onPress={() => {
                  this.handleViewOnMobileRedirect(
                    this.state.stories[this.state.historyIndexNewer]
                  );
                }}
              />
            </View>
          )}

          <NtAnalysisScoreController
            containerStyle={{ marginBottom: 20 }}
            story={this.state.stories[this.state.historyIndexNewer]}
            onQuickAnalysisPress={() => {
              this.handleMenuSelection({
                id: AnalysisController.TYPE_QUICKANALYSIS,
              });
            }}
            onSentimentPress={() => {
              this.handleMenuSelection({
                id: AnalysisController.TYPE_SENTIMENT,
              });
            }}
            onPropagandaPress={() => {
              this.handleMenuSelection({
                id: AnalysisController.TYPE_PROPAGANDA,
              });
            }}
            onOpinionPress={() => {
              this.handleMenuSelection({
                id: AnalysisController.TYPE_OPINION,
              });
            }}
            onRevisionPress={() => {
              this.handleRevisionNavigate();
            }}
          />

          <NtAnalysisNavigationOptions
            title={"Readers:"}
            options={this.reader}
            selectedKey={this.state.type}
            onPress={(item) => {
              this.handleMenuSelection(item);
            }}
            isMobile={this.state.isMobile}
          />
          {this.context.user.developer && (
            <NtAnalysisNavigationOptions
              title={"Development Options"}
              options={developeroptions}
              onPress={(item) => {
                this.handleMenuSelection(item);
              }}
              loadingKey={this.state.sendingTweet ? "twitter" : null}
              containerStyle={{ marginTop: 10 }}
            />
          )}

          {/* <View style={{ height: 30 }} /> */}
          <View style={{ flex: 1, marginTop: 30 }}>
            {this.renderStepThruReaderController()}

            {this.renderComparisonReaderController()}

            {this.renderArticleFlowVisualController()}

            {this.renderPropagandaController()}

            {this.renderOpinionController()}

            {this.renderSentimentController()}

            {this.renderColoredReaderController()}

            {this.renderQuickAnalysisController()}

            {this.renderTextOnlyReaderController()}

            {this.renderColorGuideModal()}

            {this.renderRevisionModal()}

            {this.renderShareModal()}

            <NtAnalysisMorelikeThisController
              containerStyle={{ marginTop: 10 }}
              stories={this.state.moreLikeThisStories}
              onPress={(story) => {
                this.handleHistoryFetch(story.url);
                setTimeout(() => {
                  this.scrollViewRef.current?.scrollTo({
                    y: 0,
                    animated: true,
                  });
                }, 200);
              }}
            />
          </View>
        </View>
      </NtNewsPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 15,
    marginRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    color: colors.blue,
    fontSize: 16,
    fontWeight: "800",
    marginLeft: 10,
  },
  image: {
    height: 30,
    width: 30,
    marginLeft: 10,
    resizeMode: "contain",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  infoContainer: {
    maxWidth: 240,
    padding: 10,
    paddingLeft: 0,
    marginTop: 4,
    marginLeft: 10,
  },
  infoTitle: {
    fontSize: 18,
    color: colors.blue,
    fontWeight: "600",
  },
  similarStoriesTitle: {
    color: colors.darkest,
    fontWeight: "600",
  },
});

export default AnalysisController;
