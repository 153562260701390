import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtNewsSelectItem({
  name,
  icon,
  selected = false,
  loading = false,
  onPress,
  style,
}) {
  return (
    <NtTouchableEffect
      style={[
        styles.container,
        selected ? styles.selected : styles.unselected,
        style,
      ]}
      onPress={() => {
        if (!loading && onPress) {
          onPress();
        }
      }}
    >
      {icon && (
        <NtMaterialIcon
          containerStyle={{ marginRight: 6 }}
          name={icon}
          size={18}
          color={selected ? colors.darkest : "#a8a8a8"}
        />
      )}

      <NtText
        style={[styles.text, { color: selected ? colors.darkest : "#a8a8a8" }]}
      >
        {name}
      </NtText>

      {loading && (
        <NtActivityIndicator
          containerStyle={{ marginLeft: 10 }}
          size="small"
          color={colors.darkest}
        />
      )}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  selected: {
    backgroundColor: colors.lighter,
  },
  unselected: {},
  text: {
    color: colors.darkest,
    fontWeight: "600",
  },
});

export default NtNewsSelectItem;
