import React, { useState } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import colors from "../../../config/colors";
import {
  parseSentencesFromNewsItem,
  parseSentimentFromNewsItem,
} from "../../utils/NewsParserUtils";
import NtQuickAnalysisLineChart from "./NtQuickAnalysisLineChart";
import NtQuickAnalysisMetricSentimentMoodChange from "./NtQuickAnalysisMetricSentimentMoodChange";
import NtQuickAnalysisReversedCard from "./NtQuickAnalysisReversedCard";
import NtQuickAnalysisTitleView from "./NtQuickAnalysisTitleView";
import NtQuickAnalysisMetricProgressbar from "./NtQuickAnalysisMetricProgressbar";
import {
  sentimentIcon,
  sentimentDescription,
} from "../../utils/NoozMetricUtils";
import NtQuickAnalysisMetricDescription from "./NtQuickAnalysisMetricDescription";

function NtQuickAnalysisMetricSentiment({ story }) {
  const [viewWidth, setViewWidth] = useState(0);
  const sentences = parseSentencesFromNewsItem(story);
  const sentiment = parseSentimentFromNewsItem(story);

  const getLabels = () => {
    var data = ["Start"];
    for (var x = 0; x < sentences.length; x++) {
      data = [...data, ""];
    }
    // return data;
    if (data.length > 15) {
      data[data.length - 4] = `Finish`;
    } else {
      data[data.length - 1] = `Finish`;
    }

    return data;
  };

  const getSentimentDataSet = () => {
    var data = [];
    for (var x = 0; x < sentences.length; x++) {
      data = [...data, Math.round(sentences[x].vader.compound)];
    }

    return data;
  };

  return (
    <View style={styles.container}>
      <NtQuickAnalysisReversedCard style={{ width: "100%" }}>
        <NtQuickAnalysisTitleView
          containerStyle={{ marginTop: 8 }}
          title={"Sentiment Score & Label"}
          subtitle={"Range & Explanation"}
        />

        <View
          style={{
            paddingLeft: 20,
            paddingRight: 30,
            marginTop: 20,
            marginBottom: 30,
          }}
        >
          <NtQuickAnalysisMetricProgressbar
            labels={[
              { text: "Low" },
              { text: "Moderate" },
              { text: "High" },
              { text: "Extreme" },
            ]}
            icon={sentimentIcon(0).icon}
            value={sentiment}
            fillColor={colors.noozscoreSentiment}
          />
        </View>

        <View style={{ paddingLeft: 30, paddingRight: 30 }}>
          <NtQuickAnalysisMetricSentimentMoodChange sentiment={sentiment} />
        </View>
        <View style={{ paddingLeft: 25, paddingRight: 25 }}>
          <NtQuickAnalysisMetricDescription
            containerStyle={{ marginTop: 15, marginBottom: 15 }}
            text={sentimentDescription(sentiment).description.replace(
              "<value>",
              Math.abs(sentiment)
            )}
          />
        </View>
      </NtQuickAnalysisReversedCard>

      <NtQuickAnalysisReversedCard
        style={{
          width: "100%",
          marginTop: 20,
        }}
        onLayoutChange={(size) => {
          setViewWidth(size.width);
        }}
      >
        <NtQuickAnalysisTitleView
          containerStyle={{ marginTop: 8 }}
          title={"Sentiment Flow"}
          subtitle={"from sentence to sentence"}
        />
        <NtQuickAnalysisLineChart
          width={viewWidth - 20}
          height={200}
          lineColor={"#46AB17"}
          labels={getLabels()}
          dataset={getSentimentDataSet()}
        />
      </NtQuickAnalysisReversedCard>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
});

export default NtQuickAnalysisMetricSentiment;
