import React from 'react';
import {View, StyleSheet} from 'react-native';
import NtText from '../../components/NtText';
import colors from '../../config/colors';
import NtTouchableEffect from '../../components/NtTouchableEffect';
import NtPropagandaImage from './NtPropagandaImage';
import {groupPropaganda, propagandaStyle} from '../utils/PropagandaUtils';

function NtPropagandaContainer({
  story,
  display = 3,
  iconSize,
  textSize = 14,
  textOnly = false,
  showCount = true,
  onPress,
  containerStyle,
  itemStyle,
  textStyle,
}) {
  const response = groupPropaganda(story);

  const renderItem = type => {
    const item = response.items[type];
    const style = propagandaStyle(type);

    if (textOnly) {
      return (
        <View style={{flexDirection: 'row', flex: 1}} key={type}>
          <View style={{width: 20}}>
            <NtText style={styles.textCount}>{item.length}</NtText>
          </View>
          <View style={{flex: 1}}>
            <NtText style={[styles.textCount, {flex: 1}]}>{style.name}</NtText>
          </View>
        </View>
      );
    } else {
      return (
        <View style={[styles.imageContainer, itemStyle]} key={type}>
          <NtPropagandaImage
            name={style.icon}
            color={style.color}
            size={iconSize}
            backgroundColor={style.backgroundColor}
          />
          {item.length > 0 && showCount && (
            <NtText style={[styles.textCount, {fontSize: textSize}, textStyle]}>
              {item.length}
            </NtText>
          )}
        </View>
      );
    }
  };

  if (textOnly) {
    return (
      <NtTouchableEffect
        style={[
          styles.container,
          {flexDirection: 'column', flex: 1},
          containerStyle,
        ]}
        onPress={onPress}>
        {response.itemKeys.slice(0, display).map(each => renderItem(each))}

        {response.itemKeys.length > display && (
          <NtText style={styles.text}>
            +{response.itemKeys.length - display}
          </NtText>
        )}
      </NtTouchableEffect>
    );
  } else {
    return (
      <NtTouchableEffect
        style={[styles.container, containerStyle]}
        onPress={onPress}>
        {response.itemKeys.slice(0, display).map(each => renderItem(each))}

        {response.itemKeys.length > display && (
          <NtText style={styles.text}>
            +{response.itemKeys.length - display}
          </NtText>
        )}
      </NtTouchableEffect>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lightest,
    borderRadius: 8,
    padding: 8,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  imageContainer: {
    alignItems: 'center',
    paddingLeft: 1,
    paddingRight: 1,
  },
  textCount: {
    color: colors.dark,
    marginTop: 4,
    fontWeight: '400',
  },
  text: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: '500',
    marginLeft: 6,
  },
});

export default NtPropagandaContainer;
