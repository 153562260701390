import { useState } from "react";

const useSelection = () => {
  const [selections, setSelections] = useState([]);

  const addSelection = (id) => {
    if (!isSelected(id)) {
      setSelections([...selections, id]);
    }
  };

  const isSelected = (id) => {
    return selections.includes(id);
  };

  const removeSelection = (id) => {
    setSelections(selections.filter((each) => each !== id));
  };

  const removeAllSelections = () => {
    setSelections([]);
  };

  return {
    addSelection,
    isSelected,
    removeSelection,
    removeAllSelections,
    selections,
  };
};

export default useSelection;
