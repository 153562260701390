import React from "react";
import { View, StyleSheet } from "react-native";

import { AnimatedCircularProgress } from "react-native-circular-progress";
import NtText from "../../components/NtText";
import { hootStyle } from "../utils/HootParserUtils";

function NtHootCircularProgress({
  score,
  size = 35,
  showScore = true,
  containerStyle,
}) {
  const style = hootStyle(score);
  return (
    <View style={[styles.container, containerStyle]}>
      <AnimatedCircularProgress
        size={size}
        width={6}
        rotation={180}
        backgroundWidth={3}
        fill={score}
        tintColor={style.color}
        backgroundColor="#d9d9d9"
      >
        {(fill) => (
          <View>
            {showScore && (
              <NtText
                style={{
                  fontSize: 12,
                  fontWeight: "600",
                  color: style.color,
                }}
              >
                {score}
              </NtText>
            )}
          </View>
        )}
      </AnimatedCircularProgress>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtHootCircularProgress;
