import React from "react";
import { View, StyleSheet, Image } from "react-native";
import colors from "../../../config/colors";
import { parseHootValuesFromNewsItem } from "../../utils/NewsParserUtils";
import { hootStyle } from "../../utils/HootParserUtils";
import NtHootCircularProgress from "../NtHootCircularProgress";
import NtHootTMLabel from "../NtHootTMLabel";
import NtText from "../../../components/NtText";
import {
  sentimentIcon,
  propagandaIcon,
  opinionIcon,
} from "../../utils/NoozMetricUtils";
import NtHootProgress from "../NtHootProgress";
import NtMaterialIcon from "../../../components/NtMaterialIcon";
import NtTouchableEffect from "../../../components/NtTouchableEffect";

function NtLanguageAnalysisScoreController2({
  story,
  onScorePress,
  onSentimentPress,
  onPropagandaPress,
  onOpinionPress,
  isSelected = null,
}) {
  const hoot = parseHootValuesFromNewsItem(story);
  const style = hootStyle(hoot.hoot);

  //sentiment
  const stmtIcon = sentimentIcon(hoot.sentiment);

  //propaganda
  const propIcon = propagandaIcon(hoot.propagandaPersentage);

  //opinion
  const opnIcon = opinionIcon(hoot.opinion);

  const renderScore = (
    color,
    letter,
    score,
    icon,
    onPress,
    selected,
    containerStyle
  ) => {
    return (
      <NtTouchableEffect
        style={[
          {
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 6,
            flexDirection: "row",
            borderWidth: 2,
            borderColor: selected ? color : "transparent",
          },
          containerStyle,
        ]}
        onPress={onPress}
      >
        <NtHootProgress
          progress={score}
          fillColor={color}
          letter={letter}
          letterSize={15}
        />
        <View style={{ marginLeft: 6 }}>
          <NtMaterialIcon color={color} name={icon} />
          <NtText style={[styles.percentageText, { color: color }]}>
            {score}%
          </NtText>
        </View>
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <NtHootTMLabel secondWord="SCORE" />

      <NtTouchableEffect
        style={{
          width: "100%",
          alignItems: "center",
          backgroundColor: "white",
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 6,
          marginTop: 6,
          borderWidth: 2,
          borderColor: isSelected === null ? style.color : "transparent",
        }}
        onPress={onScorePress}
      >
        <NtHootCircularProgress score={hoot.hoot} />
        <NtText
          style={[styles.scoreText, { color: style.color, marginTop: 10 }]}
        >
          {hoot.hootObject.label.replace(" ", "\n")}
        </NtText>

        <Image style={[styles.image, { marginTop: 15 }]} source={style.image} />

        <NtText style={[styles.scoreText, { marginTop: 10 }]}>
          {hoot.hootObject.advanced_label.replace(" ", "\n")}
        </NtText>
      </NtTouchableEffect>

      <NtHootTMLabel
        containerStyle={{ marginTop: 10, marginBottom: 10 }}
        secondWord="METRICS"
      />

      {renderScore(
        colors.noozscoreSentiment,
        "S",
        hoot.sentiment,
        stmtIcon.icon,
        onSentimentPress,
        isSelected === "sentiment"
      )}

      {renderScore(
        colors.noozscorePropaganda,
        "P",
        hoot.propagandaPersentage,
        propIcon.icon,
        onPropagandaPress,
        isSelected === "propaganda",
        { marginTop: 10 }
      )}

      {renderScore(
        colors.noozscoreOpinion,
        "O",
        hoot.opinion,
        opnIcon.icon,
        onOpinionPress,
        isSelected === "opinion",
        { marginTop: 10 }
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.lighestGray,
    width: 90,
    borderRadius: 6,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 10,
    paddingBottom: 10,
  },
  image: {
    width: 35,
    height: 35,
    resizeMode: "contain",
  },
  scoreText: {
    fontSize: 11,
    fontWeight: "600",
    textAlign: "center",
    color: colors.darkest,
  },
  percentageText: {
    fontSize: 12,
    fontWeight: "600",
  },
});

export default NtLanguageAnalysisScoreController2;
