import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../../components/NtText";
import parse from "html-react-parser";
import { groupPropaganda, propagandaStyle } from "../../utils/PropagandaUtils";
import Logger from "../../../common/utils/Logger";
import {
  parseCompoundTextFromNewsItem,
  parseFullTextFromNewsItem,
} from "../../utils/NewsParserUtils";

function NtLanguageAnalysisHighlightPropagandaController({
  story,
  containerStyle,
}) {
  //Will construct the html for the text to include the tooltips for the propaganda
  const compountText = parseCompoundTextFromNewsItem(story);
  let fullText = parseFullTextFromNewsItem(story);
  const propaganda = groupPropaganda(story);
  let tmpHolders = [];

  if (propaganda.found) {
    propaganda.itemKeys.map((each) => {
      propaganda.items[each].map((eachIndexes, index) => {
        let phrase = compountText.substring(eachIndexes.start, eachIndexes.end);
        Logger("Each propaganda found", eachIndexes, phrase);

        const propStyle = propagandaStyle(eachIndexes.type);
        const toolTip = (style) => {
          return `<b>${style.name}</b><br>${style.description}`;
        };

        const holder = eachIndexes.type + index;
        tmpHolders.push({ holder: holder, phrase: phrase });

        //will replace all the phrases with tmp holders to construct the tooltips....
        fullText = fullText.replace(
          phrase,
          `<div className="tooltip">${holder}<span className="tooltiptext">${toolTip(
            propStyle
          )}</span></div>`
        );
      });
    });

    //finally... will put back the real phrases instead of the holders.
    tmpHolders.map((each) => {
      fullText = fullText.replace(each.holder, each.phrase);
    });
  }

  fullText = `<div>${fullText}</div>`;
  const css = require("../../data/css.html");

  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={[styles.fullText, { flex: 1 }]}>
        <div className="container">
          {parse(css)}
          {parse(fullText)}
        </div>
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  fullText: {
    fontSize: 15,
    color: "#727272",
    fontWeight: "400",
    lineHeight: 24,
  },
});

export default NtLanguageAnalysisHighlightPropagandaController;
