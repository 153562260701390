import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../../common/base/BaseController";

class BaseDevelopmentController extends BaseController {
  state = {};

  componentDidMount() {
    //will prevent coming to this screen if user is not a developer already
    if (!this.context.user.developer) {
      if (this.props.navigation.canGoBack()) {
        this.props.navigation.goBack();
      } else {
        this.props.navigation.navigate(routes.news);
      }
    }
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default BaseDevelopmentController;
