import React, { useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import NtText from "../../../components/NtText";
import colors from "../../../config/colors";
import { hootStyle } from "../../utils/HootParserUtils";
import { parseHootValuesFromNewsItem } from "../../utils/NewsParserUtils";
import NtQuickAnalysisBallun from "./NtQuickAnalysisBallun";

function NtQuickAnalysisMetricReactionScoreProgressbar({ story }) {
  const [progressWidth, setProgressWidth] = useState(0);
  const hoot = parseHootValuesFromNewsItem(story);
  const flex = Math.abs(hoot.hoot / 100);
  const multipliers = ["0", "25", "50", "75", "100"];

  const selectedLabel = Math.floor((hoot.hoot - 0.1) / (100 / 4));
  const renderSection = (color, text, subtext, image, selected = false) => {
    return (
      <View
        style={[
          styles.sectionContainer,
          selected ? styles.sectionSelected : {},
        ]}
      >
        <View style={{ flex: 1 }}>
          <View style={{ height: 15, backgroundColor: color }} />
          <View
            style={{
              alignItems: "center",
              paddingLeft: 5,
              paddingRight: 5,
              paddingBottom: 12,
            }}
          >
            <NtText
              style={[
                styles.labels,
                { marginTop: 6 },
                selected ? { fontWeight: "500", color: color } : {},
              ]}
            >
              {text}
            </NtText>
            <Image style={[styles.image, { marginTop: 15 }]} source={image} />

            <NtText
              style={[
                styles.labels,
                { marginTop: 10 },
                selected ? { fontWeight: "500", color: colors.darkest } : {},
              ]}
            >
              {subtext}
            </NtText>
          </View>
        </View>

        <View style={styles.sectionDevider} />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View>
        <NtQuickAnalysisBallun
          value={`${hoot.hoot}%`}
          containerStyle={{
            marginBottom: 18,
            marginLeft: progressWidth - 30,
            backgroundColor: hootStyle(hoot.hoot).color,
          }}
        />
      </View>
      <View style={{ flexDirection: "row", marginBottom: 4 }}>
        <NtText style={[styles.labels, { textAlign: "right", flex: 0 }]}>
          {multipliers[0]}
        </NtText>
        {Array.from(Array(4).keys()).map((key, index) => (
          <NtText
            key={index}
            style={[styles.labels, { flex: 1, textAlign: "right" }]}
          >
            {multipliers[index + 1]}
          </NtText>
        ))}
      </View>

      {/* Dommy view just to know where to place the balloon */}
      <View
        style={{
          // flex: 1,
          flexDirection: "row",
          height: 0,
        }}
      >
        <View
          style={{ flex: flex }}
          onLayout={(event) => {
            setProgressWidth(event.nativeEvent.layout.width);
          }}
        />
      </View>

      <View style={{ flex: 1, flexDirection: "row" }}>
        <View style={styles.sectionDevider} />

        {renderSection(
          "#12bc75",
          "Low",
          "Fair Enough",
          require("../../../assets/images/owl-fair.png"),
          selectedLabel == 0
        )}
        {renderSection(
          "#4896df",
          "Moderate",
          "Smells Fishy",
          require("../../../assets/images/owl-fishy.png"),
          selectedLabel == 1
        )}
        {renderSection(
          "#d0c023",
          "High",
          "Pulling Strings",
          require("../../../assets/images/owl-pulling-strings.png"),
          selectedLabel == 2
        )}
        {renderSection(
          "#e46c15",
          "Extreme",
          "You're Kidding",
          require("../../../assets/images/owl-kidding.png"),
          selectedLabel == 3
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  sectionContainer: {
    flex: 1,
    flexDirection: "row",
  },
  sectionSelected: {
    borderWidth: 1,
    borderColor: "black",
  },
  sectionDevider: {
    width: 1,
    backgroundColor: colors.teal,
  },
  labels: {
    textAlign: "center",
    fontSize: 12,
    color: colors.dark,
  },

  image: {
    width: 35,
    height: 35,
    resizeMode: "contain",
  },
});

export default NtQuickAnalysisMetricReactionScoreProgressbar;
