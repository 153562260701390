import React, { useState } from "react";
import { View, StyleSheet, Image } from "react-native";
import NtCard from "../../components/NtCard";
import NtHootTMLabel from "./NtHootTMLabel";
import { hootStyle } from "../utils/HootParserUtils";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtText from "../../components/NtText";
import NtHootCircularProgress from "./NtHootCircularProgress";
import NtHootProgress from "./NtHootProgress";

function NtNoozMetricInfoCard({ containerStyle }) {
  const [selection, setSelection] = useState("S");

  const displayStyle = (text) => {
    let title = "";
    let description = "";
    let color = "";
    let renderGraph = () => {};

    if (text === "S") {
      title = "Sentiment Impact";
      description =
        "Gauges the level of positive or negative influence impacting the sentiment towards the topic.";
      color = colors.noozscoreSentiment;
      renderGraph = () => {
        return (
          <NtHootProgress
            containerStyle={{ marginLeft: 4 }}
            height={40}
            showLabels={true}
            letterSize={13}
            progress={50}
            fillColor={colors.noozscoreSentiment}
            letter="S"
          />
        );
      };
    } else if (text === "P") {
      title = "Propaganda Used";
      description =
        "Displays the amount of persuasion techniques and logical fallacies detected, indicating the presence of propaganda.";
      color = colors.noozscorePropaganda;
      renderGraph = () => {
        return (
          <NtHootProgress
            containerStyle={{ marginLeft: 4 }}
            height={40}
            showLabels={true}
            letterSize={13}
            progress={80}
            fillColor={colors.noozscorePropaganda}
            letter="P"
          />
        );
      };
    } else if (text === "O") {
      title = "Opinion Expressed";
      description =
        "Identifies how much a journalist expresses opinion affecting objectivity regarding the story’s topic.";
      color = colors.noozscoreOpinion;
      renderGraph = () => {
        return (
          <NtHootProgress
            containerStyle={{ marginLeft: 4 }}
            height={40}
            showLabels={true}
            letterSize={13}
            progress={30}
            fillColor={colors.noozscoreOpinion}
            letter="O"
          />
        );
      };
    } else if (text === "R") {
      title = "Revision Activity";
      description =
        "Reveals the amount of revisions published after the original release.";
      color = colors.noozscoreRevision;
      renderGraph = () => {
        return (
          <NtHootProgress
            containerStyle={{ marginLeft: 4 }}
            height={40}
            showLabels={true}
            letterSize={13}
            progress={10}
            fillColor={colors.noozscoreRevision}
            letter="R"
          />
        );
      };
    } else if (text === "G") {
      title = "Ghost Edits";
      description =
        "Captures the authors’ stealthy attempt to retroactively edit what was published without publicly recording the change.";
      color = colors.noozscoreGhostEdits;
      renderGraph = () => {
        return (
          <NtHootProgress
            containerStyle={{ marginLeft: 4 }}
            height={40}
            showLabels={true}
            letterSize={13}
            progress={40}
            fillColor={colors.noozscoreGhostEdits}
            letter="G"
          />
        );
      };
    }

    return {
      title: title,
      description: description,
      renderGraph: renderGraph(),
      color: color,
    };
  };

  const selectionStyle = displayStyle(selection);

  const renderGraphs = () => {
    return (
      <View style={{ flexDirection: "row" }}>
        <NtHootProgress
          progress={50}
          fillColor={colors.noozscoreSentiment}
          letter="S"
        />

        <NtHootProgress
          containerStyle={{ marginLeft: 4 }}
          progress={80}
          fillColor={colors.noozscorePropaganda}
          letter="P"
        />

        <NtHootProgress
          containerStyle={{ marginLeft: 4 }}
          progress={30}
          fillColor={colors.noozscoreOpinion}
          letter="O"
        />

        <NtHootProgress
          containerStyle={{ marginLeft: 4 }}
          progress={10}
          fillColor={colors.noozscoreRevision}
          letter="R"
        />

        <NtHootProgress
          containerStyle={{ marginLeft: 4 }}
          progress={40}
          fillColor={colors.noozscoreGhostEdits}
          letter="G"
        />
      </View>
    );
  };

  const renderButton = (text) => {
    return (
      <NtTouchableEffect
        style={[
          styles.buttonContainer,
          {
            alignItems: "center",
            padding: 10,
            paddingLeft: 12,
            paddingRight: 12,
            backgroundColor: selection === text ? "white" : "transparent",
            borderColor: selection === text ? colors.teal : "transparent",
          },
        ]}
        onPress={() => {
          setSelection(text);
        }}
      >
        <NtText
          style={[styles.buttonText, { color: displayStyle(text).color }]}
        >
          {text}
        </NtText>
      </NtTouchableEffect>
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <NtHootTMLabel secondWord="METRICS" size={15} />
      <View style={{ flexDirection: "row", marginTop: 10, flex: 1 }}>
        {renderGraphs()}
        <NtText style={[styles.title, { marginLeft: 8 }]}>
          Visual indicators on how much each NOOZ Metric influenced the final
          NOOZ SCORE.
        </NtText>
      </View>

      <View
        style={{
          flexDirection: "row",
          backgroundColor: colors.lightest,
          marginTop: 10,
          justifyContent: "space-around",
          borderWidth: 1,
          borderColor: colors.teal,
          borderRadius: 5,
        }}
      >
        {renderButton("S")}
        {renderButton("P")}
        {renderButton("O")}
        {renderButton("R")}
        {renderButton("G")}
      </View>

      <View style={{ flexDirection: "row", marginTop: 15 }}>
        <View style={{ flex: 1, marginRight: 6 }}>
          <NtText style={[styles.subtitle, { color: selectionStyle.color }]}>
            {selectionStyle.title}
          </NtText>
          <NtText style={[styles.description, { marginTop: 5 }]}>
            {selectionStyle.description}
          </NtText>
        </View>
        {selectionStyle.renderGraph}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 14,
    paddingBottom: 18,
    backgroundColor: "#F5F5F5",
  },
  title: {
    fontSize: 10,
    color: colors.darkest,
    fontWeight: "400",
    lineHeight: 14,
  },
  buttonContainer: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.teal,
  },
  subtitle: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.darkest,
  },
  description: {
    flex: 1,
    fontSize: 11,
    fontWeight: "400",
    color: colors.darkest,
    lineHeight: 15,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: "600",
  },
});

export default NtNoozMetricInfoCard;
